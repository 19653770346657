import { FC, Fragment, ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Box,
  Card,
  CardContent,
  Grid,
  CardHeader,
  Divider,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Alert,
  Select,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { SelectChangeEvent } from '@mui/material/Select'

import {
  PrepareIssueItem,
  IssueItemRequest,
  TimeTypeEnum,
  EntryType,
  Bill,
} from '../../../../services'

// Components
import { AppDialog } from '../../../../components'
import ItemComponent from '../../IssueItems/Item'
import PreviewItem from '../../IssueItems/PreviewItem'

// Iconse
import CloseIcon from '@mui/icons-material/Close'

// Utils
import { getEncodedFormattedDate, getFormattedDate } from '../../../../utils'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  issueItemAction,
  issueItemRequestAction,
  syncSelectOptionsAction,
  fetchtItemOptionsAction,
  updateSelectOptions,
} from '../../../../store/items'
import { showAlertAction } from '../../../../store/alerts'
import { fetchCustomerAccountAction } from '../../../../store/customerAccounts'
import { AccountBalance } from '@mui/icons-material'
import QuotationItem from '.'
import AddQuotationItem from './AddQuotationItem'
import AddQuotationPreviewItem from './AddQuotationItemPreview'

export interface AddQuotationItemsProps {
  onClose?: () => void
  bill_id: number
  customer_id: number
  account: Bill
}

const AddQuotationItems: FC<AddQuotationItemsProps> = ({
  onClose,
  bill_id,
  customer_id,
  account,
}) => {
  const dispatchAction = useAppDispatch()
  const navigate = useNavigate()
  const { selectOptions } = useAppSelector((state) => state.manageOptionsItems)
  const {
    list: { data: customerAccounts, selectedTab },
  } = useAppSelector((state) => state.manageCustomerAccounts)

  const { success: issueItemSuccess, message: issueItemFail } = useAppSelector(
    (state) => state.issueItems
  )
  const [popupTitle, setPopupTitle] = useState<string | ReactNode>(
    'Issue item '
  )
  const [items, setItem] = useState<PrepareIssueItem[]>([])
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [itemRequest, setItemRequest] = useState<IssueItemRequest | null>(null)

  // All manadatory fields are collected
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(true)

  useEffect(() => {
    if (customer_id) {
      dispatchAction(fetchtItemOptionsAction(customer_id))
    }
    return () => {
      dispatchAction(syncSelectOptionsAction())
    }
  }, [customer_id])

  useEffect(() => {
    setItemRequest((request) => {
      const formatedItem =
        items &&
        items.map((data) => {
          const { item } = data
          delete item.label
          delete item.category
          return data
        })
      return {
        ...request,
        ...(items && { items: formatedItem }),
        ...(customer_id && { customerId: customer_id }),
        ...(bill_id && { bill_id }),
        ...(new Date() && { date_entry: getFormattedDate(new Date()) }),
      }
    })
  }, [items, customer_id, bill_id])

  useEffect(() => {
    dispatchAction(syncSelectOptionsAction(items))
  }, [items])

  useEffect(() => {
    if (account) {
      const accountInfo = account
      const title = ` Party: ${accountInfo?.customer.name}, ${account.status === 'QUOTATION' ? 'Quotation' : 'Agreement'} No: ${accountInfo.id}`
      setPopupTitle(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill_id])

  useEffect(() => {
    if (issueItemSuccess) {
      setItem([])
      setItemRequest(null)
      dispatchAction(
        showAlertAction({
          message: 'Item saved Successfully',
          severity: 'success',
        })
      )
      onClose()
      dispatchAction(fetchCustomerAccountAction(selectedTab))
      dispatchAction(issueItemRequestAction())
      navigate(`/quotation/view-quotation/${bill_id}`)
    } else if (issueItemFail) {
      dispatchAction(
        showAlertAction({
          message: issueItemFail,
          severity: 'error',
        })
      )
    }
  }, [issueItemSuccess, dispatchAction, issueItemFail])

  const submitHandle = () => {
    const { items, customerId, date_entry } = itemRequest
    if (!customerId || !date_entry || items.length <= 0) {
      setReadyToSubmit(false)
    } else {
      setReadyToSubmit(true)
      console.log(account)
      itemRequest.billStatus = account?.status
      itemRequest.quotationItems = itemRequest.items
      itemRequest.items = []
      dispatchAction(issueItemAction(itemRequest))
    }
  }

  const addItemHandler = (item) => {
    const newArray = [item, ...items]
    setItem(newArray)
    // dispatchAction(
    //   updateSelectOptions(
    //     selectOptions.filter((option) => option.id !== item.item.id)
    //   )
    // )
  }

  const deleteItemHandler = (itemId, item) => {
    setItem(items.filter((data) => data?.item?.id !== itemId))
  }

  const feildWidth = 278

  return (
    <AppDialog
      id="issue-more-item"
      title={popupTitle}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      showErrorMessage={issueItemFail}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 0 }}>
            <CardHeader title={account?.status === 'QUOTATION' ? 'Select quotation items' : 'Select agreement items'} sx={{ maxHeight: 10 }} />
            <CardContent sx={{ padding: 1 }}>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <AddQuotationItem
                  account={account}
                  selectOptions={selectOptions}
                  addItem={addItemHandler}
                  submission_type={'SUBMIT'}
                />
                <Collapse in={!readyToSubmit}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setReadyToSubmit(true)
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2, mt: 2 }}
                  >
                    {items.length == 0
                      ? 'It seems you did not click ADD button after filling the item details'
                      : 'Please fill all mandatory fields!'}
                  </Alert>
                </Collapse>
                {items.map((item, id) => {
                  return (
                    <Fragment key={item?.item?.id}>
                      {id > 0 && <Divider sx={{ml:2, mr:2}} />}
                      <AddQuotationPreviewItem
                        account={account}
                        item={item}
                        deleteItem={(itemId) => {
                          deleteItemHandler(itemId, item)
                        }}
                        key={item?.item?.id}
                      />
                    </Fragment>
                  )
                })}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AppDialog>
  )
}

export default AddQuotationItems
