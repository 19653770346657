import { FC, useEffect, useState } from 'react';
import { Typography, Grid, Button, IconButton, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useAppDispatch } from '../../../hooks';
import { getFormattedEndDateString, getFormattedStartDateString } from '../../../utils';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { fetchSitesPdf } from './Services';
import { showAlertAction } from '../../../store/alerts';
import { getSitesMonthlyDataAction } from './actions';
export const circleButtonSize = '32px'
export const circleIconSize = '18px'

const SitesMonthlyRentReportHeader: FC = () => {
  const today = new Date();
  const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, 1);
  const maxDate = today;

  const [fromDate, setFromDate] = useState<Date | null>(sixMonthsAgo);
  const [toDate, setToDate] = useState<Date | null>(today);
  const [minFromDate, setMinFromDate] = useState<Date | null>(sixMonthsAgo);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isEnabled, setIsEnabled] = useState(true);

  const dispatchAction = useAppDispatch();
  const fetchPDF = (() => {
    const startDate = getFormattedStartDateString(fromDate, true);
    const endDate = getFormattedEndDateString(toDate, true);
    setIsEnabled(false)
    fetchSitesPdf(startDate, endDate).then(
      (response) => {
        setIsEnabled(true)
        if (response.success) {
          window.open(response.data, '_blank')
        } else {
          dispatchAction(
            showAlertAction({
              message: response.message,
              severity: 'error',
            })
          )
        }

      }
    )

  })
  useEffect(() => {
    if (isInitialLoad && fromDate && toDate) {
      const startDate = getFormattedStartDateString(fromDate, true);
      const endDate = getFormattedEndDateString(toDate, true);
      dispatchAction(getSitesMonthlyDataAction(startDate, endDate));
      setIsInitialLoad(false);
    }
  }, [fromDate, toDate, isInitialLoad]);

  const startDateChangeHandler = (value: Date | null) => {
    if (value) {
      setFromDate(value);
      if (toDate && toDate.getTime() < value.getTime()) {
        setToDate(value);
      }
    }
  };

  const endDateChangeHandler = (value: Date | null) => {
    if (value) {
      setToDate(value);
      // Update minFromDate to be 6 months before the selected endDate
      const newMinFromDate = new Date(value.getFullYear(), value.getMonth() - 6, 1);
      setMinFromDate(newMinFromDate);

      if (fromDate && value.getTime() < fromDate.getTime()) {
        setFromDate(newMinFromDate); // Reset fromDate if it's after the new endDate
      }
    }
  };

  const fetchData = () => {
    if (fromDate && toDate) {
      const startDate = getFormattedStartDateString(fromDate, true);
      const endDate = getFormattedEndDateString(toDate, true);
      dispatchAction(getSitesMonthlyDataAction(startDate, endDate));
    }
  };

  return (
    <>
      <Grid container alignItems="stretch">
        <Grid item md={5}>
          <Typography variant="h3" component="h3" gutterBottom pl={2.5}>
           Sites Rent Report
          </Typography>
        </Grid>
        <Grid
          md={7}
          item
          sx={{ width: 250, textAlign: 'right', display: 'flex', justifyContent: 'right', alignItems: 'center' }}
        >
           <Tooltip title="Export PDF">
         <IconButton
           disabled={!isEnabled}
           onClick={() => {
             fetchPDF();
           }}
           sx={{
            mr:2,
             backgroundColor: '#1976d2',
             '&:hover': {
               backgroundColor: '#1565c0',
             },
             color: 'white',
             width: {circleButtonSize},
             height: {circleButtonSize},
             borderRadius: '50%', // Circular shape
             justifyContent: 'center',
             alignItems: 'center',
           }}
         >
           <PictureAsPdfIcon sx={{width:circleIconSize, height:circleIconSize}}/>
         </IconButton>
       </Tooltip>
          <DatePicker
            value={fromDate}
            onChange={startDateChangeHandler}
            label="Start Date"
            format="dd/MM/yyyy"
            slotProps={{ textField: { size: 'small' } }}
            minDate={minFromDate} // Use the dynamically calculated minFromDate
            maxDate={maxDate}
          />
          <DatePicker
            sx={{ pl: 1, pr: 1 }}
            value={toDate}
            onChange={endDateChangeHandler}
            label="End Date"
            format="dd/MM/yyyy"
            slotProps={{ textField: { size: 'small' } }}
            minDate={sixMonthsAgo}
            maxDate={maxDate}
          />
          <Button 
            variant="contained" 
            sx={{ ml: 1, backgroundColor:'#37A239', height:36 }}
            onClick={fetchData} // Trigger the API call on button click
          >
            View
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SitesMonthlyRentReportHeader;
