import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import StorePaymentSummaryHeader from './PageHeader';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Paper, Tooltip, Link } from '@mui/material';
import { currencyFormatter } from '../../../utils';
import { tableStyles } from '../Reports';

export interface StorePaymentSummaryProps {}

export const StorePaymentSummary: FC<StorePaymentSummaryProps> = ({}) => {
  const classes = tableStyles();
  const { data: storePaymentSummaries } = useAppSelector(
    (state) => state.storePaymentSummaryState
  );

  const getLastDateOfPreviousMonth = (date: Date) => {
    const previousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
    return previousMonth;
  };

  const getRowBackgroundColor = (summary) => {
    const currentDate = new Date();
    const lastDateOfPrevMonth = getLastDateOfPreviousMonth(currentDate);
    const subscriptionEndDate = new Date(summary.subscriptionEndDate);
   
    // Check if no payments in the last 2 months
    const noPaymentsInLastTwoMonths = summary.monthlyPayments.slice(-2).every(payment => payment.payment === 0);

    // Check if no payments in more than the last 2 months
    const noPaymentsInMoreThanTwoMonths = summary.monthlyPayments.slice(-3).every(payment => payment.payment === 0);
    if (noPaymentsInMoreThanTwoMonths && subscriptionEndDate < lastDateOfPrevMonth) {
  
      return '#F08080'; // Light coral color (Hex code for a light coral shade)
    }
    if (noPaymentsInLastTwoMonths && subscriptionEndDate < lastDateOfPrevMonth) {
      return '#FFD580'; // Light orange color (Hex code for a light orange shade)
    }
  
    return 'inherit'; // Default background color
};


  return (
    <>
      <Helmet>
        <title>Payment Summary</title>
      </Helmet>
      <PageTitleWrapper>
        <StorePaymentSummaryHeader />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Table aria-label="payment summary">
          <TableHead>
            <TableRow>
              <TableCell width={20}>S.N.</TableCell> {/* Serial Number column */}
              <TableCell className={classes.tableCell} sx={{ maxWidth: 200 }}>
                ID
              </TableCell>
              <TableCell className={classes.tableCell} sx={{ maxWidth: 200 }}>
                Store Name
              </TableCell>
              <TableCell className={classes.tableCell} sx={{ maxWidth: 200 }}>
                Mobile
              </TableCell>
              {storePaymentSummaries && storePaymentSummaries.length > 0 &&
                storePaymentSummaries[0].monthlyPayments?.map((month, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    {month.month}
                  </TableCell>
                ))}
              <TableCell className={classes.tableCell}>Subscription End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {storePaymentSummaries &&
              storePaymentSummaries.map((summary, index) => (
                <TableRow key={index} sx={{ backgroundColor: getRowBackgroundColor(summary) }}>
                  <TableCell>{index + 1}</TableCell> {/* Serial number */}
                  <TableCell className={classes.tableCell}>
                    {summary.storeId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="body1">
                      {summary.storeName.length > 30
                        ? `${summary.storeName.substring(0, 30)}...`
                        : `${summary.storeName}`}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Link 
                      href={`https://web.whatsapp.com/send?phone=${summary.mobile}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {summary.mobile}
                    </Link>
                  </TableCell>

                  {summary.monthlyPayments.map((payment, idx) => (
                    <TableCell key={idx} className={classes.tableCell}>
                      <Typography
                        variant="body1"
                        style={{ color: payment.payment > 0 ? 'green' : 'inherit' }}
                      >
                        {currencyFormatter.format(payment.payment)}
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell className={classes.tableCell}>
                    {new Date(summary.subscriptionEndDate).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </PageContainerWrapper>
    </>
  );
};

export default StorePaymentSummary;
