import React from 'react'
import Status500 from '../../content/pages/Status/Status500'
import { logShutteringEvent } from './../../firebase'
type MyProps = {
  // using `interface` is also ok
  fallback?: React.ReactNode
}
type MyState = {
  hasError: boolean // like this
}
class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    logShutteringEvent('WEB_APP_ERROR', { error: error, errorInfo: errorInfo })
    console.error('Error:', error)
    console.error('Error Info:', errorInfo)
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback ? this.props.fallback : <Status500 />
    }
    return this.props.children
  }
}

export default ErrorBoundary
