import {
  FC,
  ChangeEvent,
  useState,
  useReducer,
  useEffect,
  InputHTMLAttributes,
} from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  CardHeader,
  Divider,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Alert,
  Select,
  Collapse,
  IconButton,
  Snackbar,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { SelectChangeEvent } from '@mui/material/Select'

import {
  TimeTypeEnum,
  ItemEntry,
  EditItemRequest,
  EntryType,
} from '../../../../services'

// Components
import { AppDialog } from '../../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  updateIssuedItemAction,
  updateIssuedItemResetAction,
} from '../../../../store/items'
import {
  fetchQuotationEntriesAction,
  fetchSlipsListAction,
  refreshFetchSlipsAction,
} from '../../../../store/slips'
import { showAlertAction } from '../../../../store/alerts'
import { getFormattedDate } from '../../../../utils'
import CustomNumberField from '../../Users/profile/CustomNumberField'

type IssueItemFields = 'quantity' | 'rate'
type IssueItemFieldsState = Record<
  IssueItemFields,
  Partial<{
    value: string
    error: boolean
    helperText: string
  }>
>

type IssueItemErrors = Record<IssueItemFields, Record<string, string>>

const issueItemErrors: IssueItemErrors = {
  quantity: {
    empty: 'Please enter quantity',
  },
  rate: {
    empty: 'Please enter rate',
  },
}

const initialItemIssueState: IssueItemFieldsState = {
  quantity: {
    error: false,
    helperText: 'Please enter quantity',
  },
  rate: {
    error: false,
    helperText: 'Please enter rate',
  },
}
const itemReducer = (state: Partial<IssueItemFieldsState>, action) => {
  const {
    type,
    payload: { name, value },
    helperText,
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: helperText
            ? helperText
            : initialItemIssueState[name]?.helperText,
        },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: issueItemErrors[name]['empty'],
        },
      }
    case 'RESET':
      return {
        ...initialItemIssueState,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: initialItemIssueState[name]?.helperText,
        },
      }
    default:
      return state
  }
}

export interface EditItemProps {
  children?: React.ReactNode
  onClose?: () => void
  onSubmitSuccess?: () => void
  item: ItemEntry
  accountId: number
}

export const EditItem: FC<EditItemProps> = ({
  onClose,
  onSubmitSuccess,
  item,
  accountId,
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success: updateIssuedItemSuccess,
    error: updateIssuedItemError,
    message,
  } = useAppSelector((state) => state.updateIssuedItems)
  const [date, setDate] = useState<Date | null>(() => new Date(item?.entryDate))
  const [timeType, setTimeType] = useState<TimeTypeEnum>(item?.timeType)
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [itemData, dispatch] = useReducer(itemReducer, initialItemIssueState)
  const [request, setRequest] = useState<EditItemRequest | null>(null)

  useEffect(() => {
    if (item) {
      dispatch({
        type: 'SET',
        payload: { name: 'quantity', value: item.quantity },
      })
      dispatch({ type: 'SET', payload: { name: 'rate', value: item.rate } })
      setRequest({
        ...request,
        ...(item.entryDate && {
          entryDate: getFormattedDate(new Date(item.entryDate)),
        }),
        ...(item.quantity && { quantity: item.quantity }),
        ...(item.rate &&
          item?.entryType == EntryType.OUT && { rate: item.rate }),
        ...(item.timeType && { timeType: item.timeType }),
        id: item.id,
      })
    }
  }, [item, setRequest, dispatch])

  useEffect(() => {
    if (updateIssuedItemSuccess) {
      dispatchAction(fetchQuotationEntriesAction(accountId))
      dispatchAction(
        showAlertAction({
          message: 'Item Updated Successfully',
          severity: 'success',
        })
      )
      onSubmitSuccess() // To close the popup
    } else if (updateIssuedItemError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onSubmitSuccess() // To close the popup
    }
  }, [updateIssuedItemSuccess, updateIssuedItemError, message])

  const onChangeHandler = (e) => {
    const { name, valueAsNumber, value } = e.target
    if (valueAsNumber) {
      dispatch({ type: 'SET', payload: { name, value: valueAsNumber } })
      setRequest({
        ...request,
        [name]: valueAsNumber,
      })
    } else {
      dispatch({ type: 'ERROR', payload: { name, value: value } })
      setRequest({
        ...request,
        [name]: value,
      })
    }
  }

  const submitHandle = () => {
    const checkRequest = Object.keys(request)?.every((key) => !!request[key])
    if (checkRequest) {
      request.isQuotation = true
      dispatchAction(updateIssuedItemAction(request))
    }
  }

  useEffect(() => {
    return () => {
      dispatchAction(updateIssuedItemResetAction()) // To manage the Alert
    }
  }, [])
  return (
    <AppDialog
      id="edit-item"
      title={item?.itemName}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} sx={{ pb: 2, mr: 2 }}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item={true} xs={12} mt={2}>
                <CustomNumberField
                  maxLength={6}
                  name="quantity"
                  value={itemData.quantity.value}
                  label="Quantity"
                  onChange={onChangeHandler}
                  fullWidth
                  helperText={
                    itemData['quantity']['error']
                      ? itemData.quantity.helperText
                      : ''
                  }
                  error={itemData['quantity']['error']}
                />{' '}
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
              <Grid item={true} xs={12}>
                <CustomNumberField
                  maxLength={6}
                  name="rate"
                  value={itemData.rate.value}
                  label="Rate"
                  onChange={onChangeHandler}
                  fullWidth
                  error={itemData['rate']['error']}
                  helperText={
                    itemData['rate']['error'] ? itemData.rate.helperText : ''
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
