import { FC, useRef, useState } from 'react'

import { NavLink, useNavigate } from 'react-router-dom'

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'

import { styled } from '@mui/material/styles'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import { removeToken, removeSelectedStoreId } from '../../../../utils'
import { useAppDispatch } from '../../../../hooks'

import { UserLoginData } from '../../../../services'
import ApplicationLinks from '../../../../ApplicationLinks'
const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
)

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
)

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
)

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
)

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
)

type HeaderUserboxProps = {
  data: UserLoginData
}

const HeaderUserbox: FC<HeaderUserboxProps> = ({ data }) => {
  const dispatchAction = useAppDispatch()
  const navigate = useNavigate()

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = (): void => {
    removeToken()
    removeSelectedStoreId()
    dispatchAction({ type: 'CLEAR_ALL_STORE_DATA' })
    navigate('/login')
  }
  return (
    <>
      {data?.store?.name && (
        <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 25,
              width: 44,
              height: 44,
              backgroundColor: '#314367',
            }}
          >
            <Typography variant="h2" sx={{ color: 'white' }}>
              {' '}
              {data?.store?.name ? Array.from(data?.store?.name)[0] : ''}{' '}
            </Typography>
          </Box>
          <Hidden mdDown>
            <UserBoxText>
              <UserBoxLabel variant="body1">
                <Typography variant="h5" noWrap sx={{ maxWidth: 250 }}>
                  {data?.store?.name}
                </Typography>
              </UserBoxLabel>
              <UserBoxDescription variant="body2">
                <Typography noWrap sx={{ maxWidth: 250 }}>
                  {data?.store?.address}
                </Typography>
              </UserBoxDescription>
            </UserBoxText>
          </Hidden>
          <Hidden smDown>
            <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
          </Hidden>
        </UserBoxButton>
      )}
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={data?.store?.name} src={''} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{data?.store?.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              <Typography noWrap maxWidth={300}>
                {data?.store?.address}
              </Typography>
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem
            button
            to={ApplicationLinks.profileDetailLink()}
            component={NavLink}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem
            button
            to={ApplicationLinks.changePasswordLink()}
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Change Password" />
          </ListItem>
          <ListItem
            button
            to={ApplicationLinks.profileSettingsLink()}
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default HeaderUserbox
