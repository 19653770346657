import  { AxiosResponse } from "axios";
import { http } from "../../../src/utils";


export function fetchSites() {
  let url = `/bill/pvt/sites`
  return http
    .get(
      url
    )
    .then((response: AxiosResponse) => response.data)
}

export interface SiteDto {
  id: number ;         // TypeScript uses `number` for Long, and `null` for optional values
  customerName: string;      // TypeScript uses `string` for String type in Java
  siteAddress: string;       // String type in Java maps to `string`
  customerMobile: string;    // String type in Java maps to `string`
  agreementId?: number;
  customerId: number;
  billType: string;
  ratePerSqft?: number
}
