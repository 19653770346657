import { CreateMonthlyBillRequest, fetchAllBills, fetchGSTR1Json } from '../../../services/monthlyBills'
import {
  createMonthlyBill,
  fetchMonthlyBill,
  deleteMonthlyBill,
} from '../../../services/monthlyBills'

export const ActionTypes = {
  CREATE_MONTHLY_BILL: 'CREATE_MONTHLY_BILL',
  FETCH_MONTHLY_BILL: 'FETCH_MONTHLY_BILL',
  DELETE_MONTHLY_BILL: 'DELETE_MONTHLY_BILL',
  FETCH_BILLS_BW_DATES: 'FETCH_BILLS_BW_DATES',
  FETCH_GSTR1_JSON: 'FETCH_GSTR1_JSON',
}

export const createMonthlyBillAction = (request: CreateMonthlyBillRequest) => ({
  type: ActionTypes.CREATE_MONTHLY_BILL,
  promise: createMonthlyBill(request),
})

export const fetchMonthlyBillsAction = (accountId?: number) => ({
  type: ActionTypes.FETCH_MONTHLY_BILL,
  promise: fetchMonthlyBill(accountId ? accountId : 0, null, null, null),
})

export const fetchBillsBwDatesAction = (finYear: string, isGst: boolean, from: string, to: string) => ({
  type: ActionTypes.FETCH_BILLS_BW_DATES,
  promise: fetchAllBills(finYear, isGst, from, to),
})

export const fetchBillsBwDatesResetAction = () => ({
  type: `${ActionTypes.FETCH_BILLS_BW_DATES}_RESET`,
})

export const fetchGSTR1JsonAction = (from: string, to: string) => ({
  type: ActionTypes.FETCH_GSTR1_JSON,
  promise: fetchGSTR1Json(from, to),
})

export const fetchGSTR1JsonResetAction = () => ({
  type: `${ActionTypes.FETCH_GSTR1_JSON}_RESET`,
})

export const createMonthlyBillResetAction = () => ({
  type: `${ActionTypes.CREATE_MONTHLY_BILL}_RESET`,
})

export const fetchMonthlyBillResetAction = () => ({
  type: `${ActionTypes.FETCH_MONTHLY_BILL}_RESET`,
})

export const deleteMonthlyBillsAction = (id: number) => ({
  type: ActionTypes.DELETE_MONTHLY_BILL,
  promise: deleteMonthlyBill(id),
})

export const deleteMonthlyBillResetAction = () => ({
  type: `${ActionTypes.DELETE_MONTHLY_BILL}_RESET`,
})
