import { Item, FetchItemResponse } from '../../../services'
import { ActionTypes, prepareSelectOptions } from '../actions'
import {
  BaseReducerState,
  ItemCategory,
  SelectOptions,
} from '../../../services/types'

export interface ItemList {
  data: Item[]
  searchResults: Item[]
  searchTerm: string
  category: ItemCategory
}

export type ItemListState = BaseReducerState & ItemList
export type ItemManageState = {
  list: ItemListState
}

type SearchUpdate = {
  data: Item[]
  searchTerm: string
}

const initialState: ItemManageState = {
  list: {
    loading: false,
    success: false,
    message: '',
    error: null,
    data: [],
    searchResults: [],
    searchTerm: '',
    category: null,
  },
}

export const manageItems = (
  state: ItemManageState = initialState,
  action: { type: string; res: any }
): ItemManageState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_ITEMS}_REQUEST`:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          success: false,
          message: null,
        },
      }

    case `${ActionTypes.FETCH_ITEMS}_SUCCESS`: {
      const { success, data, message } = <FetchItemResponse>action.res
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success,
          message,
          category: data.category,
          ...(success && data && { data: data.items }),
        },
      }
    }

    case `${ActionTypes.UPDATE_SEARCH_ITEM_RESULTS}`: {
      const { data, searchTerm } = <SearchUpdate>action.res
      return {
        ...state,
        list: {
          ...state.list,
          searchResults: data,
          searchTerm,
        },
      }
    }

    case `${ActionTypes.FETCH_ITEMS}_FAILURE`:
      const { message, error } = <FetchItemResponse>action.res
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success: false,
          message,
          error,
        },
      }
  }
  return state
}
