import { Container, styled, Typography } from '@mui/material'

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
)

function Footer() {
  return <FooterWrapper className="footer-wrapper"></FooterWrapper>
}

export default Footer
