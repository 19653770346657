import { http } from '../../../utils'

import { AxiosResponse } from 'axios'

export const ActionTypes = {
  GET_ALL_SITES_LEDGER_DATA: 'GET_ALL_SITES_LEDGER_DATA'
}

export const getAllSitesLedgerData = (startDate?: string, endDate?: string) => {
  let url = '/charts/pvt/all_sites_ledger'
  if(startDate && endDate) {
    url = `/charts/pvt/all_sites_ledger?start_date=${startDate}&end_date=${endDate}`
  }
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export const getAllSitesLedgerDataAction = (startDate?: string, endDate?: string) => ({
  type: ActionTypes.GET_ALL_SITES_LEDGER_DATA,
  promise: getAllSitesLedgerData(startDate, endDate),
})
