import { FC, useState } from 'react'
import { z, infer as Infer, object, number, string } from 'zod'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Grid } from '@mui/material'

import { ItemEntryDetail, cancelEwayBill } from '../../../services'

// Components
import {
  AppDialog,
  FormInputTextField,
  FormInputAutosuggest,
  Loader,
} from '../../../components'

// Redux
import { useAppDispatch } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'
import { fetchSlipsListAction } from '../../../store/slips'
import { tr } from 'date-fns/locale'

const schema = object({
  cancelReason: number({
    required_error: 'Please select a cancel reason',
  }),
  cancelRmrk: string().min(1, 'Please enter a remark'),
})

export interface CancelEwayBillProps {
  children?: React.ReactNode
  onClose?: () => void
  slip: ItemEntryDetail
  accountId: number
}

type Schema = Infer<typeof schema>

export const CancelEwayBill: FC<CancelEwayBillProps> = ({
  onClose,
  slip,
  accountId,
}) => {
  const dispatchAction = useAppDispatch()

  const reachHookForm = useForm<Schema>({
    mode: 'all',
    resolver: zodResolver(schema),
  })

  const { control, handleSubmit } = reachHookForm

  const reasons = [
    { label: 'Duplicate', id: 1 },
    { label: 'Order Cancelled', id: 2 },
    { label: 'Data Entry mistake', id: 3 },
    { label: 'Others', id: 4 },
  ]

  const [isClose, setClosePopup] = useState<boolean>(false)
  const [cancelEWayBill, setCancelEWayBill] = useState(false)

  const submitHandle = handleSubmit((data: Schema) => {
    console.log('Form Data:', data) // Log form data
    const request = {
      ewbNo: slip.ewayBillNo,
      challanId: slip?.challanId,
      cancelRsnCode: data.cancelReason,
      cancelRmrk: data.cancelRmrk,
    }
    setCancelEWayBill(true)
    cancelEwayBill(request).then((response) => {
      setCancelEWayBill(false)
      if (response.data.success) {
        showAlertAction({
          message: 'EWay bill cancelled successfully',
          severity: 'success',
        })
        dispatchAction(fetchSlipsListAction(accountId))
        onClose()
      } else {
        dispatchAction(
          showAlertAction({
            message: response.data?.message,
            severity: 'error',
          })
        )
      }
    })
  })

  return (
    <AppDialog
      id="edit-item"
      title="Cancel e-Way Bill"
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      {cancelEWayBill && <Loader />}
      <FormProvider {...reachHookForm}>
        <form>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12} sx={{ pb: 2, mr: 2 }}>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} mt={2}>
                    <FormInputAutosuggest
                      control={control}
                      name={'cancelReason'}
                      label="Select transaction type"
                      options={reasons}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} mt={2}>
                    <FormInputTextField
                      control={control}
                      name={'cancelRmrk'}
                      label={'Remark'}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </AppDialog>
  )
}
