import { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'
interface BaseLayoutProps {
  children?: ReactNode
}

const HeaderLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          margin: '100px auto',
          width: '80vh',
          height: '80vh',
        }}
      >
        {children || <Outlet />}
      </Box>
    </>
  )
}

HeaderLayout.propTypes = {
  children: PropTypes.node,
}

export default HeaderLayout
