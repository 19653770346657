import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import PageTitleWrapper from '../../../components/PageTitleWrapper'
import PageContainerWrapper from '../../../components/PageContainerWrapper'
import { Grid, Typography } from '@mui/material'
import Footer from '../../../components/Footer'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { fetchPendingBillsAction } from '../../../store/pendingBills'
// import { createPendingBills } from '../../../services'
import ListTable from './ListTable'

function PendingBills() {
  const { data } = useAppSelector((state) => state.pendingBills)
  const dispatchAction = useAppDispatch()

  useEffect(() => {
    //createPendingBills().then((a)=>{console.log(a)}).catch((e)=>console.log(e))
    dispatchAction(fetchPendingBillsAction(false))
  }, [])

  return (
    <>
      <Helmet>
        <title>Manage Pending Bills</title>
      </Helmet>
      <PageTitleWrapper>
        <Grid container alignItems="flex-end">
          <Grid item xs>
            <Typography variant="h3" component="h3" gutterBottom pl={2.5}>
              Service usage bills
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <ListTable pendingBills={data} />
          </Grid>
        </Grid>
      </PageContainerWrapper>
      <Footer />
    </>
  )
}

export default PendingBills
