import { FC, useState } from 'react'
import { Typography, Button, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AddStaff from './AddStaff'
import { Search } from '../../../components'

export interface StaffsHeaderProps {
  onClose?: () => void
}

export const StaffsHeader: FC<StaffsHeaderProps> = ({ onClose }) => {
  const [showAddStaff, setShowAddStaff] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  return (
    <>
      <Grid container alignItems="flex-end">
        <Grid item xs>
          <Typography variant="h3" component="h3" gutterBottom pl={2.5}>
            Users
          </Typography>
        </Grid>
        <Grid item sx={{ width: '30%' }}></Grid>
        <Grid item sx={{ width: 190, textAlign: 'right' }}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            sx={{ backgroundColor: '#eb627a', height: 41.5, width: 180 }}
            onClick={() => {
              setShowAddStaff(true)
            }}
          >
            ADD USER
          </Button>
        </Grid>
      </Grid>
      {showAddStaff && (
        <AddStaff
          onClose={() => {
            setShowAddStaff(false)
          }}
        />
      )}
    </>
  )
}

export default StaffsHeader
