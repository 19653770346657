import { fetchActivities } from '../../../services'

export const ActionTypes = {
  FETCH_ACTIVITIES: 'FETCH_ACTIVITIES',
}

export const fetchActivityAction = (date?: string) => ({
  type: ActionTypes.FETCH_ACTIVITIES,
  promise: fetchActivities(date),
})
