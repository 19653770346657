import React, { FC, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { infer as Infer, number, object, string, date } from 'zod'
import { Box } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

import {
  EntryType,
  ItemEntryDetail,
  updatePartBEwayBill,
} from '../../../services'

import {
  eWayformattedDate,
  getFormattedDate,
  vehicleNumberRegex,
} from '../../../utils'

// Components
import {
  AppDialog,
  FormInputAutosuggest,
  FormInputDateField,
  FormInputTextField,
  Loader,
} from '../../../components'
import { showAlertAction } from '../../../store/alerts'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'

// Reasons Type Array
const reasons = [
  { id: 1, label: 'Due to Break Down' },
  { id: 2, label: 'Due to Transhipment' },
  { id: 3, label: 'Others' },
]

// Formschema
const schema = object({
  vehicleNumber: string()
    .min(1, 'Please enter vehicle number')
    .regex(vehicleNumberRegex, 'Please provide valid vehicle number'),
  place: string().min(1, 'Please enter place of change'),
  reason: number({
    required_error: 'Please select update reason',
  }),
  transDocNo: string().optional(),
  transDocDate: date().optional(),
})

type Schema = Infer<typeof schema>

export interface UpdateEwayBillPartBProps {
  children?: React.ReactNode
  onClose?: () => void
  entryType: string
  eWayNo: number
}

export const UpdateEwayBillPartB: FC<UpdateEwayBillPartBProps> = ({
  onClose,
  entryType,
  eWayNo,
}) => {
  const { data: userData } = useAppSelector((state) => state.userData)
  const { data: slipsData } = useAppSelector((state) => state.manageSlips)
  const reachHookForm = useForm<Schema>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {
      transDocDate: new Date(),
    },
  })

  const { control, handleSubmit, watch, setValue } = reachHookForm
  const [updatingEWayBill, setUpdatingEWayBill] = useState(false)

  const dispatchAction = useAppDispatch()

  function getReasonLabelById(id) {
    const reason = reasons.find((reason) => reason.id === id)
    return reason ? reason.label : null // Return the label if found, otherwise return null
  }

  const submitHandle = handleSubmit((data: Schema) => {
    console.log(entryType)
    const gstin =
      entryType === EntryType.OUT
        ? userData.store.gstin
        : slipsData.accountInfo?.customer?.gstin

    const firstTwoDigits = gstin.substring(0, 2)
    const fromState = parseInt(firstTwoDigits, 10)
    const formattedDate = eWayformattedDate(data.transDocDate)
    const transDocDate: string | null =
      formattedDate !== '' && data.transDocNo ? formattedDate : null

    const request = {
      ewbNo: eWayNo,
      vehicleNo: data.vehicleNumber,
      fromPlace: data.place,
      fromState: fromState,
      reasonCode: data.reason.toString(),
      reasonRem: getReasonLabelById(data.reason),
      transDocNo: data.transDocNo,
      transDocDate: transDocDate,
      transMode: '1',
      vehicleType: 'R',
    }
    console.log(request)
    setUpdatingEWayBill(true)

    updatePartBEwayBill(request).then((response) => {
      setUpdatingEWayBill(false)
      if (response.data.success) {
        showAlertAction({
          message: 'EWay bill updated successfully',
          severity: 'success',
        })
        onClose()
      } else if (response.data.message) {
        dispatchAction(
          showAlertAction({
            message: response.data?.message,
            severity: 'error',
          })
        )
      }
    })
  })

  const handleVehicleNumberChange = (newValue) => {
    // Convert the vehicle number to uppercase
    const upperCaseValue = newValue.target.value.toUpperCase()
    setValue('vehicleNumber', upperCaseValue)
  }

  return (
    <AppDialog
      id="edit-item"
      title={`Update Part-B For The EWB No: ${eWayNo}`}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={false}
      maxWidth="sm"
    >
      {/* Loader overlay */}
      {updatingEWayBill && <Loader />}
      <FormProvider {...reachHookForm}>
        <form>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              width: '100%',
            }}
          >
            <FormInputTextField
              control={control}
              name={'vehicleNumber'}
              label={'Vehicle No'}
              onChange={handleVehicleNumberChange}
              InputProps={{ inputProps: { maxLength: 15, minLength: 7 } }}
              required
            />
            <FormInputTextField
              control={control}
              name={'place'}
              label={'Place of Change'}
              InputProps={{ inputProps: { maxLength: 50 } }}
              required
            />
            <FormInputAutosuggest
              control={control}
              name={'reason'}
              label="Select Reason"
              options={reasons}
            />
            <FormInputTextField
              control={control}
              name={'transDocNo'}
              label={'Transporter Doc'}
              InputProps={{ inputProps: { maxLength: 15 } }}
            />
            <FormInputDateField
              control={control}
              name={'transDocDate'}
              label="Date"
              disabled
            />
          </Box>
        </form>
      </FormProvider>
    </AppDialog>
  )
}
