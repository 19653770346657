import { FC, useContext, useEffect, useState } from 'react'

import OneDayDiscount from './OneDayDiscount'
import GenerateBill from './GenerateBill'
import DaysCounting from './DaysCounting'
import { CreateBillManagerContext } from './CreateBillManager'
import { getFormattedEndDateString } from '../../../utils'

export interface RenderSelectedComponentProps {
  plotArea: number
  startDate: string
  endDate: string
  billType: string
  isFlateRate: boolean
  onClose: () => void
}
const RenderSelectedComponent: FC<RenderSelectedComponentProps> = ({
  plotArea,
  startDate,
  endDate,
  billType,
  isFlateRate,
  onClose,
}) => {
  const { activeStep } = useContext(CreateBillManagerContext)
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(
    null
  )
  const [selectedEndDate, setSelectedEndDate] = useState<string | null>(null)

  useEffect(() => {
    if (startDate) {
      setSelectedStartDate(getFormattedEndDateString(new Date(startDate)))
    }
    if (endDate) {
      setSelectedEndDate(getFormattedEndDateString(new Date(endDate)))
    }
  }, [startDate, endDate])

  switch (activeStep) {
    case 1:
      return (
        <DaysCounting
          sqftStartDate={selectedStartDate}
          sqftEndDate={selectedEndDate}
        />
      )
    case 2:
      return (
        <GenerateBill
          sqftStartDate={selectedStartDate}
          sqftEndDate={selectedEndDate}
          onClose={onClose}
        />
      )
    default:
      return (
        <OneDayDiscount
          sqftStartDate={startDate}
          sqftEndDate={endDate}
          plotArea={plotArea}
          hideDayDiscountList={
            (isFlateRate && billType === 'PER_SQFT') ||
            (billType === 'PER_SQFT' && plotArea > 0)
          }
          onStartDateChange={(newDate) => {
            setSelectedStartDate(newDate)
          }}
          onEndDateChange={(newDate) => {
            setSelectedEndDate(newDate)
          }}
        />
      )
  }
}

export default RenderSelectedComponent
