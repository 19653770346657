import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, Challan } from '../types'

export interface FetchAllChallansAPIResponse extends Response {
  data: Challan[]
}

export function fetchAllChallans(isGst: boolean, challanType: string, finYear:string) {
  let url = `/challan/pvt/recent?challan_type=${challanType}&fin_year=${finYear}`
  if(isGst === true || isGst === false) {
    url = `${url}&is_gst=${isGst}`
  }
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}
