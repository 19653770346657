import ApplicationLinks from '../../../../ApplicationLinks'
import { Box, Typography, Container, Button, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone'

import { styled } from '@mui/material/styles'

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
)
function Status500() {
  return (
    <>
      <Helmet>
        <title>Error </title>
      </Helmet>
      <MainContent>
        <Grid
          container
          sx={{ height: '100%' }}
          alignItems="center"
          justifyContent={'center'}
        >
          <Grid item>
            <Container maxWidth="sm">
              <Box textAlign="center">
                <Typography variant="h2" sx={{ my: 2 }}>
                  There was an error, please try again later
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 4 }}
                >
                  The server encountered an internal error and was not able to
                  complete your request
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  href=""
                  onClick={() => history.back()}
                  startIcon={<RefreshTwoToneIcon />}
                >
                  Refresh Page
                </Button>
                <Button
                  href={ApplicationLinks.currentAccountLink()}
                  variant="contained"
                  sx={{ ml: 1 }}
                >
                  Go to Home
                </Button>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </MainContent>
    </>
  )
}

export default Status500
