import { Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'

import { FormInputProps } from './../FormInputProps'

export const FormInputTextField = ({
  name,
  control,
  label,
  rules,
  InputProps,
  onChange,
  required = false,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange: fieldOnChange, value },
        fieldState: { error },
      }) => (
        <TextField
          InputLabelProps={{ shrink: true }}
          required={required}
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={(e) => {
            fieldOnChange(e) // Call the field's onChange
            if (onChange) {
              onChange(e) // Call the onChange passed from props
            }
          }}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          InputProps={{
            ...InputProps,
            sx: {
              backgroundColor: InputProps?.readOnly ? '#efefef' : 'white',
            },
          }}
        />
      )}
    />
  )
}
