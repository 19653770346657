import { BaseReducerState} from '../../../services'
import { ActionTypes } from './actions'
import { SiteReportRow, SitesMonthlyRentReportAPIResponse } from './types'

export type SitesMonthlyRentReportState = BaseReducerState & {
  data: [SiteReportRow]
}

export const initialState: SitesMonthlyRentReportState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const sitesMonthlyRentReportState = (
  state: SitesMonthlyRentReportState = initialState,
  action: { type: string; res: any }
): SitesMonthlyRentReportState => {
  switch (action.type) {
    case `${ActionTypes.GET_SITES_MONTHLY_RENT_DATA}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.GET_SITES_MONTHLY_RENT_DATA}_SUCCESS`: {
      const { success, data, message } = <SitesMonthlyRentReportAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.GET_SITES_MONTHLY_RENT_DATA}_FAILURE`:
      const res = <SitesMonthlyRentReportAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.GET_SITES_MONTHLY_RENT_DATA}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
