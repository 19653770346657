import {
  ItemCategory,
  FetchItemCategoryResponse,
  AddItemCategoryResponse,
} from '../../../services'
import { ActionTypes, searchItemCategory } from '../actions'
import { BaseReducerState } from '../../../services/types'

export interface ItemCategoryList {
  data: ItemCategory[]
  searchResults: ItemCategory[]
  searchTerm: string
}

export interface ItemCategoryAdd {
  data: ItemCategory | null
}

export type ItemCategoryListState = BaseReducerState & ItemCategoryList
export type ItemCategoryAddState = BaseReducerState & ItemCategoryAdd
export type ItemCategoryManageState = {
  list: ItemCategoryListState
  add: ItemCategoryAddState
}

type SearchUpdate = {
  data: ItemCategory[]
  searchTerm: string
}

const initialState: ItemCategoryManageState = {
  list: {
    loading: false,
    success: false,
    message: '',
    data: [],
    searchResults: [],
    searchTerm: '',
  },
  add: {
    loading: false,
    success: false,
    message: '',
    data: null,
  },
}

export const manageItemCategories = (
  state: ItemCategoryManageState = initialState,
  action: {
    type: string
    res: FetchItemCategoryResponse | AddItemCategoryResponse | SearchUpdate
  }
): ItemCategoryManageState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_ITEM_CATEGORY}_REQUEST`:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          success: false,
          message: null,
        },
      }

    case `${ActionTypes.FETCH_ITEM_CATEGORY}_SUCCESS`: {
      const { success, data, message } = <FetchItemCategoryResponse>action.res
      const searchResults = searchItemCategory(state.list.searchTerm, data)
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success,
          message,
          searchResults: searchResults,
          ...(success && data && { data }),
        },
      }
    }

    case `${ActionTypes.UPDATE_SEARCH_CATEGORIES_RESULTS}`: {
      const { data, searchTerm } = <SearchUpdate>action.res
      return {
        ...state,
        list: {
          ...state.list,
          searchResults: data,
          searchTerm,
        },
      }
    }

    case `${ActionTypes.FETCH_ITEM_CATEGORY}_FAILURE`: {
      const { message } = <FetchItemCategoryResponse>action.res

      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success: false,
          message: message,
        },
      }
    }
  }
  return state
}
