import { List, ListItem, Popover } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router'

import { CardActionButton } from '../../../components'
import { useAppDispatch } from '../../../hooks'
import {
  getBillTillTodayPDFAction,
} from '../../../store/items'
import { Bill, BillPDFRequest } from '../../../services'
import { CurrentAccountMoreActionsEnum } from '../../../services/types'

export interface MoreActionsProps {
  onClose?: () => void
  onChangeTab: (tab) => void
  bill: Bill
  myref: any
  showMore: boolean
  setShowMoreAction?: (string) => void
}

export const MoreActions: FC<MoreActionsProps> = ({
  onClose,
  bill,
  myref,
  onChangeTab,
  showMore,
  setShowMoreAction,
}) => {
  const dispatchAction = useAppDispatch()
  const navigate = useNavigate()

  const handleViewPDF = (request: BillPDFRequest) => {
    dispatchAction(getBillTillTodayPDFAction(request))
  }

  const setShowMore = (value) => {
    onClose()
  }
  return (
    <>
      <Popover
        open={showMore}
        anchorEl={myref?.current}
        onClose={() => {
          onClose()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List sx={{ p: 0 }} component="nav">
          <ListItem
            id={`view-${
              bill?.status === 'OPEN'
                ? 'view_bill_till_today_list_item'
                : 'view_bill_list_item'
            }`}
          >
            <CardActionButton
              buttonText={
                bill?.status === 'OPEN' ? 'View Bill till Today' : 'View Bill'
              }
              data={true}
              icon={'receipt'}
              onClickHandler={() => {
                if (bill?.totalCeilingArea > 0) {
                  setShowMoreAction(
                    CurrentAccountMoreActionsEnum.SHOW_PLOT_AREA_DATE_PICKER
                  )
                } else {
                  handleViewPDF({ billId: bill?.id })
                }
                setShowMore(false)
              }}
            />
          </ListItem>
          <ListItem id="view_bill_between_dates_list_item">
            <CardActionButton
              buttonText="Bill Between Two Dates"
              data={true}
              icon={'dateRange'}
              onClickHandler={() => {
                setShowMore(false)
                setShowMoreAction(
                  CurrentAccountMoreActionsEnum.BILL_BETWEEN_TWO_DATES
                )
              }}
            />
          </ListItem>
          {bill?.status === 'OPEN' && (
            <>
              <ListItem id="create_bill_list_item">
                <CardActionButton
                  buttonText="Bills"
                  data={true}
                  icon={'saveAs'}
                  onClickHandler={() => {
                    setShowMore(false)
                    navigate({
                      pathname: `/slips/view-slips/${bill?.id}/${bill?.customer.id}`,
                      search: '?tab_index=5',
                    })
                    onChangeTab(5)
                  }}
                />
              </ListItem>
              <ListItem id="view_or_add_payment_list_item">
                <CardActionButton
                  buttonText="View or Add Payment"
                  data={true}
                  icon={'quote'}
                  onClickHandler={() => {
                    setShowMore(false)
                    navigate({
                      pathname: `/slips/view-slips/${bill?.id}/${bill?.customer.id}`,
                      search: '?tab_index=4',
                    })
                    onChangeTab(4)
                  }}
                />
              </ListItem>
              <ListItem id="add_other_charges_list_item">
                <CardActionButton
                  buttonText="Add Other Charges"
                  data={true}
                  icon={'other_charge'}
                  onClickHandler={() => {
                    setShowMoreAction(
                      CurrentAccountMoreActionsEnum.ADD_OTHER_CHARGES
                    )
                    setShowMore(false)
                  }}
                  disabled={false}
                />
              </ListItem>

              <ListItem
                sx={{ borderBottom: 1, borderColor: 'lightgray' }}
                id="lost_or_damaged_charged_list_item"
              >
                <CardActionButton
                  buttonText="Lost or Damaged Charges"
                  data={true}
                  icon={'lost'}
                  onClickHandler={() => {
                    setShowMoreAction(
                      CurrentAccountMoreActionsEnum.LOST_OR_DAMAGE_CHARGES
                    )
                    setShowMore(false)
                  }}
                  disabled={false}
                />
              </ListItem>
            </>
          )}
          <ListItem id="create_new_task">
            <CardActionButton
              buttonText="Create New Task"
              data={true}
              icon={'create_new_task'}
              onClickHandler={() => {
                setShowMoreAction(CurrentAccountMoreActionsEnum.CREATE_NEW_TASK)
                setShowMore(false)
              }}
              disabled={false}
            />
          </ListItem>
          {bill?.status != 'CLOSED' && (
            <ListItem id="edit_account_list_item">
              <CardActionButton
                buttonText="Edit Account"
                data={true}
                icon={'edit'}
                onClickHandler={() => {
                  setShowMoreAction(CurrentAccountMoreActionsEnum.EDIT_ACCOUNT)
                  setShowMore(false)
                }}
              />
            </ListItem>
          )}
          {bill?.status != 'OPEN' && (
            <ListItem id="reopen_account_list_item">
              <CardActionButton
                buttonText="Reopen Account"
                data={true}
                icon={'file_open'}
                onClickHandler={() => {
                  setShowMoreAction(
                    CurrentAccountMoreActionsEnum.REOPEN_ACCOUNT
                  )
                  setShowMore(false)
                }}
              />
            </ListItem>
          )}

          {bill?.status != 'CLOSED' && bill?.remainingPieces > 0 && (
            <ListItem id="move_balance_item_list_item">
              <CardActionButton
                buttonText="Move balance items to new account"
                data={true}
                icon={'file_open'}
                onClickHandler={() => {
                  setShowMoreAction(
                    CurrentAccountMoreActionsEnum.MOVE_BALANCE_TO_NEW_ACCOUNT
                  )
                  setShowMore(false)
                }}
              />
            </ListItem>
          )}

          {bill?.status != 'CLOSED' && (
            <ListItem id="close_account_list_item">
              <CardActionButton
                buttonText="Close Account"
                data={true}
                icon={'dnd'}
                onClickHandler={() => {
                  setShowMoreAction(CurrentAccountMoreActionsEnum.CLOSE_ACCOUNT)
                  setShowMore(false)
                }}
              />
            </ListItem>
          )}

          {bill?.status === 'OPEN' && (
            <ListItem id="bill_settings_list_item">
              <CardActionButton
                buttonText="One Day Discount Settings"
                data={true}
                icon={'settings'}
                onClickHandler={() => {
                  setShowMoreAction(
                    CurrentAccountMoreActionsEnum.ONE_DAY_DISCOUNT_SETTING
                  )
                  setShowMore(false)
                }}
              />
            </ListItem>
          )}

          <ListItem id="delete_account_list_item">
            <CardActionButton
              id="id_more_option_delete_account"
              buttonText="Delete"
              data={true}
              icon={'delete'}
              onClickHandler={() => {
                setShowMoreAction(CurrentAccountMoreActionsEnum.DELETE)
                setShowMore(false)
              }}
            />
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
