import { FC, useEffect, Fragment } from 'react'
import {
  Typography,
  List,
  ListSubheader,
  Grid,
  Card,
  CardContent,
  Divider,
} from '@mui/material'
import { grey } from '@mui/material/colors'

import { ListItemComponent } from './ListItemComponent'
import { ItemEntryDetail } from '../../../services/slips'
import { TabsEnums } from '../../../components'
import { LeftListItemComponent } from './LeftListItemComponent'
import { PaymentListItemComponent } from './PaymentListItemComponent'

interface PaymentItemComponentProps {
  data: ItemEntryDetail[]
  separatorHeight: number
  billId: number
}

export const PaymentItemComponent: FC<PaymentItemComponentProps> = ({
  data,
  separatorHeight,
  billId,
}) => {
  return (
    <Fragment>
      {data && data.length > 0 && (
        <Fragment>
          <Divider />
          <Grid
            id="properties_name"
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              minHeight: '4vh',
              pl: 2,
              pr: 2,
              backgroundColor: '#f9f9f9',
              fontWeight: 'bold',
            }}
          >
            <Grid xs={4} sx={{ pl: 4 }}>
              Payment Description
            </Grid>
            <Grid xs={1.5}>Amount</Grid>
            <Grid xs={2.75}>Date</Grid>
            <Grid xs={2.75}>Status</Grid>
            <Grid xs={1} sx={{ paddingLeft: 1 }}>
              Actions
            </Grid>
          </Grid>
          {data &&
            data.map((items, index) => {
              return (
                <List
                  sx={{
                    width: '100%',
                    p: 0,
                    m: 0,
                  }}
                  component="div"
                  aria-labelledby="subheader"
                  key={index}
                >
                  {items.itemEntries &&
                    items.itemEntries.map((item, i) => {
                      return (
                        <Fragment key={i}>
                          <Divider />
                          <PaymentListItemComponent
                            item={item}
                            key={i}
                            componentName={TabsEnums.Payment}
                            billId={billId}
                          />
                        </Fragment>
                      )
                    })}
                </List>
              )
            })}
        </Fragment>
      )}
    </Fragment>
  )
}
