import { useState, Fragment, useContext, useEffect, FC } from 'react'
import {
  Typography,
  Grid,
  TextField,
  CardActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
} from '@mui/material'
import { CreateBillManagerContext } from '../CreateBillManager'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { GenerateBillRequest } from '../../../../services'
import {
  generateBillAction,
  generateBillResetAction,
} from '../../../../store/bills'

import { showAlertAction } from '../../../../store/alerts'
import { currencyFormatter } from '../../../../utils'
import { fetchCustomerAccountAction } from '../../../../store/customerAccounts'
import { fetchMonthlyBillsAction } from '../../../../store/monthlyBills'

import CustomNumberField from '../../Users/profile/CustomNumberField'

export interface GenerateBillProps {
  children?: React.ReactNode
  sqftStartDate?: string
  sqftEndDate?: string
  onClose: () => void
}

const GenerateBill: FC<GenerateBillProps> = ({
  sqftStartDate,
  sqftEndDate,
  onClose,
}) => {
  const dispatchAction = useAppDispatch()
  const { data } = useAppSelector((state) => state.manageBillsBreakup)
  const {
    data: generateBillUrl,
    success: billGenerated,
    message: failMessage,
  } = useAppSelector((state) => state.generateBill)
  const { totalRentCopy, billId, activeStep, setActiveStep } = useContext(
    CreateBillManagerContext
  )

  const [discount, setDiscount] = useState(0)
  const [paidAmount, setPaidAmount] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalDue, setTotalDue] = useState(0)

  useEffect(() => {
    setSubTotal(totalRentCopy + data?.lostItemsCost)
  }, [data?.lostItemsCost, totalRentCopy])

  useEffect(() => {
    setTotal(subTotal - discount)
  }, [subTotal, discount])

  useEffect(() => {
    setTotalDue(total - paidAmount - data.totalPaid)
  }, [total, paidAmount, data?.totalPaid])

  const onInputChange = (e) => {
    const { name, valueAsNumber } = e.target
    if (name == 'discount') {
      setDiscount(valueAsNumber ? valueAsNumber : 0)
    } else if (name == 'paidAmount') {
      setPaidAmount(valueAsNumber ? valueAsNumber : 0)
    }
  }
  const genrateBill = () => {
    const request: GenerateBillRequest = {
      id: billId,
      isBillSettingsSaved: true,
      sqftStartDate: sqftStartDate,
      sqftEndDate: sqftEndDate,
      totalPaid: data?.totalPaid + paidAmount,
      bill_time_paymnt: paidAmount,
      discount: discount,
      ...(data?.lostItemsCost && { lostItemsCost: data?.lostItemsCost }),
      ...(subTotal && { subtotal: subTotal }),
      ...(total && { total: total }),
      ...(totalDue && { totalDue: totalDue }),
      ...(totalRentCopy && { totalRent: totalRentCopy }),
    }
    dispatchAction(generateBillAction(request))
  }

  useEffect(() => {
    if (billGenerated) {
      dispatchAction(fetchCustomerAccountAction('OPEN'))
      if (billId) {
        dispatchAction(fetchMonthlyBillsAction(billId))
      }
      dispatchAction(generateBillResetAction())
      dispatchAction(
        showAlertAction({
          message: 'Bill genereated successfully',
          severity: 'success',
        })
      )
      onClose() // Close the popup
    } else if (failMessage) {
      dispatchAction(
        showAlertAction({ message: failMessage, severity: 'error' })
      )
    }
  }, [generateBillUrl, billGenerated, failMessage])
  return (
    <Grid
      container
      xs={12}
      direction="column"
      spacing={2}
      sx={{
        height: '70vh',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
      }}
    >
      <Grid item xs={11}>
        <Grid container direction="row">
          <Grid item xs={12} md={5} sx={{ pt: 2, pl: 3 }}>
            <Typography sx={{ mb: 3 }}>
              {' '}
              Please enter final discount and paid amount.
            </Typography>
            <Grid
              container
              direction={'column'}
              spacing={2}
              xs={12}
              sx={{
                mb: 4,
              }}
            >
              <Grid item={true} xs={12} md={4} sx={{ pl: 2 }}>
                <CustomNumberField
                  name="discount"
                  value={discount ? discount : ''}
                  label="Discount"
                  onChange={onInputChange}
                  fullWidth
                  maxLength={8}
                ></CustomNumberField>
              </Grid>

              <Grid item={true} xs={12} md={4}>
                <CustomNumberField
                  name="paidAmount"
                  value={paidAmount ? paidAmount : ''}
                  label="Received Amount"
                  onChange={onInputChange}
                  fullWidth
                  maxLength={8}
                ></CustomNumberField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              container
              sx={{ pl: 4, pt: 7, pr: 6, justifyContent: 'flex-end' }}
            >
              <Table sx={{ border: 1, borderColor: 'lightgray' }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#f9f9f9' }}>
                    <TableCell>
                      <Typography color={'black'} sx={{ fontWeight: 'bold' }}>
                        Summary
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        borderLeft: 1,
                        borderLeftColor: 'lightgray',
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <tbody>
                  {data.totalSqftRent > 0 && (
                    <TableRow>
                      <TableCell>
                        Sqft Rent ({currencyFormatter.format(data.ratePerSqft)}{' '}
                        x {data.totalCeilingArea}ft x {data.billAgeInDays}days)
                      </TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {data?.totalSqftRent}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.plateAndWallsRent > 0 && (
                    <TableRow>
                      <TableCell>Plate Area Rent</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {data?.plateAndWallsRent}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.totalPerItemRent > 0 && (
                    <TableRow>
                      <TableCell>Per Item Rent</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {data?.totalPerItemRent}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Total Rent
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        borderLeft: 1,
                        borderLeftColor: 'lightgray',
                      }}
                    >
                      {data?.totalRentWithoutConsumable}
                    </TableCell>
                  </TableRow>
                  {data.lostItemsCost > 0 && (
                    <TableRow>
                      <TableCell>Lost or damaged items charges</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {data?.lostItemsCost}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.consumableTotal > 0 && (
                    <TableRow>
                      <TableCell>Other Charges</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {data?.consumableTotal}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {data.gst > 0 ? 'Taxable Amount' : 'Invoice Total'}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        borderLeft: 1,
                        borderLeftColor: 'lightgray',
                      }}
                    >
                      {subTotal}
                    </TableCell>
                  </TableRow>
                  {/* {discount > 0 && (
                    <TableRow>
                      <TableCell>Discount</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {discount}
                      </TableCell>
                    </TableRow>
                  )} */}
                  {data.sgst > 0 && (
                    <TableRow>
                      <TableCell>SGST({data.sgstRate}%)</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {data?.sgst}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.cgst > 0 && (
                    <TableRow>
                      <TableCell>SGST({data.cgstRate}%)</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {data?.cgst}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.gst > 0 && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Total GST({data.sgstRate + data.cgstRate}%)
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          borderLeft: 1,
                          borderLeftColor: 'lightgray',
                        }}
                      >
                        {data?.gst}
                      </TableCell>
                    </TableRow>
                  )}
                 
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        borderLeft: 1,
                        borderLeftColor: 'lightgray',
                      }}
                    >
                      {currencyFormatter.format(data.total)}
                    </TableCell>
                  </TableRow>
                  {data.totalPaid > 0 && (
                    <TableRow>
                      <TableCell>Total amount received</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {data.totalPaid + paidAmount}
                      </TableCell>
                    </TableRow>
                  )}
                  {paidAmount > 0 && (
                    <TableRow>
                      <TableCell>Bill time amount received</TableCell>
                      <TableCell
                        sx={{ borderLeft: 1, borderLeftColor: 'lightgray' }}
                      >
                        {paidAmount}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Total Due</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        borderLeft: 1,
                        borderLeftColor: 'lightgray',
                      }}
                    >
                      {currencyFormatter.format(data.totalDue - paidAmount)}
                    </TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <CardActions
          sx={{ display: 'flex', flexDirection: 'row', pl: 2, pr: 1 }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setActiveStep(activeStep - 1)
            }}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={genrateBill}
          >
            Generate Bill
          </Button>
        </CardActions>
      </Grid>
    </Grid>
  )
}

export default GenerateBill
