import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response } from '../types'

export interface SupplyItem {
  natureOfSupply: string
  taxableValue: number
  igst: number
  cgst: number
  sgst: number
  cess: number
}

export interface StateSupplyItem {
  placeOfSupply: string
  urgTaxableValue: number
  urgIgst: number
  compoTaxableValue: number
  compoIgst: number
  uinTaxableValue: number
  uinIgst: number
}

export interface GSTR3BReport {
  supplyItems: [SupplyItem]
  stateSupplyItems: [StateSupplyItem]
}

export interface GSTINDetails {
  gstin: string
  tradeName: string
  legalName: string
  address1: string
  address2: string
  stateCode: string
  pinCode: string
  txpType: string
  status: string
}

export interface GSTINDetailsAPIResponse extends Response {
  data: GSTINDetails
}

export interface GSTR3BReportAPIResponse extends Response {
  data: GSTR3BReport
}

export function fetchGSTR3BJson(from: string, to: string) {
  const url = `/gstr/pvt/gstr3b?from=${from}&to=${to}`
  return http.get(url).then((response: AxiosResponse) => response)
}
