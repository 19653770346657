import { GSTR3BReport, GSTR3BReportAPIResponse } from '../../../services/gstr'
import { BaseReducerState } from '../../../services'
import { ActionTypes } from '../actions'

export type FetchGSTR3BReportState = BaseReducerState & {
  data: GSTR3BReport
}

export const initialState: FetchGSTR3BReportState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const fetchGSTR3B = (
  state: FetchGSTR3BReportState = initialState,
  action: { type: string; res: any }
): FetchGSTR3BReportState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_GSTR3B_JSON}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.FETCH_GSTR3B_JSON}_SUCCESS`: {
      const { success, data, message } = <GSTR3BReportAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.FETCH_GSTR3B_JSON}_FAILURE`:
      const res = <GSTR3BReportAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.FETCH_GSTR3B_JSON}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
