import {
  BaseReducerState,
  ItemCategory,
  AddItemCategoryResponse,
} from '../../../services'
import { ActionTypes } from '../actions'

export type AddCategoryState = BaseReducerState & {
  data: ItemCategory
}

const addCategoryInitialState: AddCategoryState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const addCategory = (
  state: AddCategoryState = addCategoryInitialState,
  action: { type: string; res: any }
): AddCategoryState => {
  switch (action.type) {
    case `${ActionTypes.ADD_CATEGORY}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.ADD_CATEGORY}_SUCCESS`: {
      const { success, data, message } = <AddItemCategoryResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.ADD_CATEGORY}_FAILURE`:
      const res = <AddItemCategoryResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.ADD_CATEGORY}_RESET`:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        data: null,
        error: null,
      }
  }
  return state
}
