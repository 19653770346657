import { useState } from 'react'
import { useLocation, Link as ReactRouterLink } from 'react-router-dom'
import {
  Container,
  Link,
  Box,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Hidden,
  IconButton,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import logo from './images/app_logo_blu.png'
const useStyles = makeStyles(() => ({
  link: {
    color: 'white',
    curser: 'pointer',
    '&:hover': {
      borderBottom: '2px solid white',
    },
  },
  redLinks: {
    backgroundColor: '#eb627a',
    height: 48,
    ml: 1,
    color: 'white',
  },
  avatar: {
    marginRight: 'auto',
    color: 'white',
    backgroundColor: 'black',
    borderRadius: 0,
    height: 30,
    border: '2px solid gray',
    borderLeft: '12px solid transparent',
    borderRight: '12px solid transparent',
  },
}))
const navigationLinks = [
  { name: 'HOME', href: '/' },
  { name: 'PRODUCTS', href: '/products' },
  { name: 'ABOUT', href: '/about' },
  { name: 'CONTACT', href: '/contact' },
  { name: 'PRICING', href: '/pricing' },
]
export const menuBarColor = '#31436'
export const HeaderMenuItem = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const styles = useStyles()
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: menuBarColor,
        height: '8vh',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
      }}
    >
      {' '}
      <Container maxWidth="lg">
        <Toolbar sx={{ boxShadow: 'none' }} disableGutters>
          <Box sx={{ marginRight: 'auto', display: 'inline-flex' }}>
            <img
              alt="shuttering"
              src={logo}
              style={{ width: 44, marginBottom: 0 }}
            />
            <Typography variant="h3" color={'white'} ml={1} mb={0}>
              Shutteringapp.Com™
            </Typography>
          </Box>

          <Hidden mdDown>
            {navigationLinks.map((item) => (
              <Link
                className={styles.link}
                color="textPrimary"
                component={ReactRouterLink}
                to={item.href}
                key={item.name}
                sx={{
                  mx: 0.5,
                  borderBottom:
                    item.href == location?.pathname ? '2px solid white' : '',
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: 'white', mt: 0, px: 0.5, py: 0.5 }}
                >
                  {item.name}
                </Typography>
              </Link>
            ))}
            <Box display={'inline-flex'} sx={{ color: 'white' }}>
              <Button
                variant="contained"
                size="medium"
                sx={{
                  backgroundColor: '#eb627a',

                  ml: 4,
                  color: 'white',
                }}
                onClick={() => {
                  window.open('/login')
                }}
              >
                LOGIN
              </Button>
              <Button
                variant="contained"
                size="medium"
                sx={{
                  backgroundColor: '#eb627a',

                  ml: 1,
                  color: 'white',
                }}
                onClick={() => {
                  window.open(
                    'https://web.whatsapp.com/send?phone=917976757979'
                  )
                }}
              >
                CONTACT US
              </Button>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              sx={{
                color: 'white',
              }}
              onClick={() => setOpen(true)}
            >
              <MenuTwoToneIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <div
          onClick={() => setOpen(false)}
          onKeyPress={() => setOpen(false)}
          role="button"
          tabIndex={0}
        >
          <IconButton>
            <ChevronRightTwoToneIcon />
          </IconButton>
        </div>
        <Divider />

        <List>
          {navigationLinks.map((item) => (
            <ListItem
              sx={{
                p: 0,
              }}
              key={item.name}
            >
              <Link
                className={styles.link}
                color="textPrimary"
                component={ReactRouterLink}
                to={item.href}
                underline="none"
                href={item.href}
                key={item.name}
              >
                <Typography variant="h6" component="span" sx={{ ml: 2, mt: 0 }}>
                  {item.name}
                </Typography>
              </Link>
            </ListItem>
          ))}
        </List>

        <Divider />
        <List>
          <ListItem>
            <Button
              variant="contained"
              size="medium"
              sx={{
                backgroundColor: '#eb627a',

                color: 'white',
              }}
              onClick={() => {
                window.open('/login')
              }}
            >
              LOGIN
            </Button>
          </ListItem>{' '}
          <ListItem>
            <Button
              variant="contained"
              size="medium"
              sx={{
                backgroundColor: '#eb627a',

                color: 'white',
              }}
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.pad.rental.shuttering.store.shutteringapp'
                )
              }}
            >
              Contact Us
            </Button>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </AppBar>
  )
}
