import { AxiosResponse } from 'axios'
import { __String } from 'typescript'
import { http } from '../../utils'
import { Bill } from '../items'
import { Response, EntryType, TimeTypeEnum, Challan } from '../types'

export interface ItemEntry {
  id: number
  paymentId: number
  entryType: EntryType
  quantity: number
  entryDate: string
  createDate: string
  itemId: number
  itemName: string
  categoryName: string
  categoryId: number
  rate: number
  receivedAmount: number
  isAllSubmitted: boolean
  inCount: number
  outCount: number
  leftCount: number
  billId: number
  isConsumable: boolean
  timeType: TimeTypeEnum
  lostItemCost: number
  itemArea: number
  minRentalDays: number
  barColorCode: string
  bgColorCode: string
  note: string
  challanNo: string
  isArea?: boolean
  height?: number | string
  width?: number | string
  length?: number | string
  hsnCode?: string
}

export interface ItemEntryDetail {
  title: string
  titleDate?: string
  displayTitle?: string
  itemEntries: ItemEntry[]
  entriesNameSet: string[]
  rentPerDay: number
  customerMobile?: string
  chaalanNumber?: string
  displayCHL?: string
  challanId: number
  vehicleNo?: string
  ewayBillNo?: number
  ewayBillLink?: string
  challanType?: string
  driverName?: string
  driverMobile?: string
  DL?: string
}

export interface SlipData {
  all: ItemEntryDetail[]
  in: ItemEntryDetail[]
  out: ItemEntryDetail[]
  left: ItemEntryDetail[]
  payment: any[]
  accountInfo?: Bill
  totalLeftCount: number
}
export interface FetchSlipsListResponse extends Response {
  data: SlipData[]
}

export function fetchSlipsList(billId, startDate?, endDate?, isQuotation?) {
  let url = `/bill/pvt/entries/v3?id=${billId}`
  if (startDate) {
    url = `/entry/pvt/entries_bw_dates?from=${startDate}&to=${endDate}`
  }
  if (isQuotation) {
    url = `${url}&is_quotation=${isQuotation}`
  }
  return http.get(url).then((response: AxiosResponse) => response)
}

export type UpdateEntryDateRequest = {
  entries: { id: number }[]
  entryDate: string
  vehicleNo: string
  ewayBillNo: number
  challanId: number
  challanNo: string
  driverName?: string
  DL?: string
  driverMobile: string
}

export type InvoiceDetail = {
  docNo: number
  isGSTType: boolean
}
export interface UpdateEntryDateResponse extends Response {
  data: boolean
}
export interface FetchChallansResponse extends Response {
  data: Challan[]
}
export interface InvoiceNumberAPIResponse extends Response {
  data: InvoiceDetail
}
export function updateEntryDate(request: UpdateEntryDateRequest) {
  return http
    .put(`/entry/pvt/update_slip_date`, request)
    .then((response: AxiosResponse) => response)
}

export function fetchChallans(accountId: number, challan_type?: string) {
  let url = `/challan/pvt?account_id=${accountId}`
  if (challan_type) {
    url = `/challan/pvt?account_id=${accountId}&challan_type=${challan_type}`
  }
  return http.get(url).then((response: AxiosResponse) => response)
}

export function getInvoiceNumber(accountId: number) {
  return http
    .get(`/interim_bill/pvt/next_inv_no/?account_id=${accountId}`)
    .then((response: AxiosResponse) => response)
}
