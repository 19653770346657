import { AxiosResponse } from "axios";
import { http } from "../../../utils";

export function fetchSitesPdf(
    startDate: string,
    endDate: string
) {

    let url = `/charts/pvt/all_sites_ledger_pdf?start_date=${startDate}&end_date=${endDate}`
    return http
        .get(
            url
        )
        .then((response: AxiosResponse) => response.data)
}