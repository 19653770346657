import { ItemCategory } from 'src/services'
import { createStock, fetchOnboardingStock } from '../../../services/onboarding'

export const ActionTypes = {
  FETCH_ONBOARDING_STOCK: 'FETCH_ONBOARDING_STOCK',
  CREATE_ONBOARDING_STOCK: 'CREATE_ONBOARDING_STOCK',

}

export const fetchOnboardingStocksAction = () => ({
  type: ActionTypes.FETCH_ONBOARDING_STOCK,
  promise: fetchOnboardingStock(),
})

export const fetchOnboardingStockResetAction = () => ({
  type: `${ActionTypes.FETCH_ONBOARDING_STOCK}_RESET`,
})

export const createOnboardingStocksAction = (categories: ItemCategory[]) => ({
  type: ActionTypes.CREATE_ONBOARDING_STOCK,
  promise: createStock(categories),
})

export const createOnboardingStockResetAction = () => ({
  type: `${ActionTypes.CREATE_ONBOARDING_STOCK}_RESET`,
})
