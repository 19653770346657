import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { BarChart } from '@mui/x-charts/BarChart';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import BillingReportHeader from './PageHeader';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { currencyFormatter } from '../../../../src/utils';

export const tableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    borderRight: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
  },
  tableHead: {
    backgroundColor: '#efeff0 !important',
  },
}));

export interface BillingReportProps {}

export const BillingReport: FC<BillingReportProps> = ({}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [gst, setGst] = useState<boolean>(true);

  const dispatchAction = useAppDispatch();
  const { loading, success, message, data } = useAppSelector(
    (state) => state.billingChartState
  );

  const classes = tableStyles();

  return (
    <>
      <Helmet>
        <title>Billing Report</title>
      </Helmet>
      <PageTitleWrapper>
        <BillingReportHeader />
      </PageTitleWrapper>
      <PageContainerWrapper>
        {data?.billingCharts && (
          <>
            <TableContainer>
              <Table aria-label="summary table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Total Rent(A)</TableCell>
                    <TableCell align="center">Total Consumable(B)</TableCell>
                    <TableCell align="center">Total Lost Items(C)</TableCell>
                    <TableCell align="center">Total(A+B+C)</TableCell>
                    <TableCell align="center">Payment Received(D)</TableCell>
                    <TableCell align="center">Difference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{backgroundColor:'white'}}>
                    <TableCell align="center">
                      <Typography variant='h5'>
                      {currencyFormatter.format(data.rentSum)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                    <Typography variant='h5'>
                      {currencyFormatter.format(data.consumableSum)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                    <Typography variant='h5'>
                      {currencyFormatter.format(data.lostItemsSum)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                    <Typography variant='h5'>
                      {currencyFormatter.format(data.rentSum + data.consumableSum + data.lostItemsSum)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                    <Typography variant='h5'>
                      {currencyFormatter.format(data.paymentSum)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                    <Typography variant='h5'>
                      {currencyFormatter.format(data.rentSum + data.consumableSum + data.lostItemsSum - data.paymentSum)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography m={1} color={'#ff1100'}>Note: Plot area based bills are not included in this report.</Typography>
            <BarChart
             sx={{mt:5}}
              dataset={data.billingCharts}
              series={[
                { dataKey: 'rentSum', label: 'Rent', color: '#ff9800', stack:"rent" }, // Custom color for Rent
                { dataKey: 'consumableSum', label: 'Consumable', color:'#1185E0', stack:"rent" },
                { dataKey: 'discountSum', label: 'Discount', color:'#32CD32',  stack:"rent" },
                { dataKey: 'lostItemsSum', label: 'Lost', color:'#ff0000',  stack:"rent" },
                { dataKey: 'paymentSum', label: 'Payment', color: '#582899' }, // Custom color for Payment
              ]}
              height={450}
              xAxis={[{ dataKey: 'month', scaleType: 'band' }]}
              margin={{ top: 50, bottom: 40, left: 100, right: 100 }}
            />
          </>
        )}
      </PageContainerWrapper>
    </>
  );
};

export default BillingReport;
