export const loggerMiddleware = store =>{
    return next => {
      return action =>{
        console.log("[Middleware] dispatching action", action)
        const result = next(action)
        console.log("[Middleware] next state", store.getState())
        return result;
      }
    }  
  }
