import { FC, useEffect, useState } from 'react'
import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Checkbox,
  ListItem,
  Button,
  IconButton,
  Divider,
} from '@mui/material'
import { StarBorder } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ItemCategory, OnboardingStock } from '../../../services'
import StockItem from './StockItem'

export interface StockCategoryProps {
  category: ItemCategory
}

const StockCategory: FC<StockCategoryProps> = ({ category }) => {
  const [open, setOpen] = useState(category.isExpanded == true)
  const [catChecked, setCatChecked] = useState(category.isSelected == true)
  const [selectAllChilds, setSelectAllChilds] = useState<boolean | null>(null)

  useEffect(() => {
    category.isSelected = catChecked
  }, [catChecked])

  useEffect(() => {
    category.isExpanded = open
  }, [open])

  return (
    <>
      {category && (
        <>
          <ListItem key={category.name}>
            <ListItemIcon>
              <Checkbox
                checked={catChecked}
                key={`cat-checkbox-${category.name}`}
                edge="start"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCatChecked(event.target.checked)
                  setSelectAllChilds(event.target.checked)
                  if (event.target.checked) {
                    setOpen(true)
                  }
                }}
              />
            </ListItemIcon>

            <ListItemText primary={category.name} />
            {category?.items?.length > 1 && (
              <IconButton
                onClick={() => {
                  setOpen(!open)
                }}
                sx={{
                  backgroundColor: '#314d67',
                  color: 'white',
                  width: 24,
                  height: 24,
                }}
              >
                {' '}
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </ListItem>
          {category?.items?.length > 1 && (
            <Collapse
              in={open && category?.items?.length > 0}
              timeout="auto"
              unmountOnExit
              key={`collapse-${category.name}`}
            >
              <List component="div" key={`collapse-list-${category.name}`}>
                {category &&
                  category?.items?.map((item) => {
                    return (
                      <StockItem
                        key={`stock-item-${item.itemName}`}
                        isParentChecked={selectAllChilds}
                        item={item}
                        onChange={(checked) => {
                          if (!checked) {
                            setCatChecked(false)
                            setSelectAllChilds(null)
                          } else {
                            const allSelected =
                              category?.items?.filter(
                                (item) => item.isSelected != true
                              ).length === 0
                            if (allSelected == true) {
                              setCatChecked(true)
                            }
                          }
                        }}
                      />
                    )
                  })}
              </List>
            </Collapse>
          )}{' '}
        </>
      )}
    </>
  )
}

export default StockCategory
