import { Bill, IssueItemResponse } from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState } from '../../../services/types'

export type IssueItemState = BaseReducerState & {
  data: Bill
}

const issueIteminitialState: IssueItemState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const issueItems = (
  state: IssueItemState = issueIteminitialState,
  action: { type: string; res: any }
): IssueItemState => {
  switch (action.type) {
    case `${ActionTypes.ISSUE_ITEMS}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        error: null,
      }

    case `${ActionTypes.ISSUE_ITEMS}_SUCCESS`: {
      const { success, data, message } = <IssueItemResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.ISSUE_ITEMS}_FAILURE`:
      const { message, error } = <IssueItemResponse>action.res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.ISSUE_ITEMS}_RESET`: {
      return {
        ...issueIteminitialState,
      }
    }

    case `${ActionTypes.CLOSE_ALERT}`:
      return {
        ...state,
        error: null,
      }
  }
  return state
}
