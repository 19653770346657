import { FC, useEffect } from 'react'
import {
  deleteMerchantEnteredDaysAction,
  deleteMerchantEnteredDaysResetAction,
} from '../../../../store/bills'

// Components
import { ConfirmationAppDialog } from '../../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { showAlertAction } from '../../../../store/alerts'
import { fetchCustomerAccountAction } from '../../../../store/customerAccounts'

export interface ResetDaysCountProps {
  onClose: (reload?: boolean) => void
  accountId: number
}

export const ResetDaysCount: FC<ResetDaysCountProps> = ({
  onClose,
  accountId,
}) => {
  const dispatchAction = useAppDispatch()
  const { success: result, message } = useAppSelector(
    (state) => state.resetMerchantEnteredDays
  )

  useEffect(() => {
    return () => {
      dispatchAction(deleteMerchantEnteredDaysResetAction())
    }
  }, [])

  useEffect(() => {
    if (result) {
      dispatchAction(
        showAlertAction({
          message: 'Done successfully',
          severity: 'success',
        })
      )
      dispatchAction(fetchCustomerAccountAction('OPEN'))
      onClose(true) // To close the popup
    } else if (message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onClose(false)
    }
  }, [result, message])

  const deleteHandler = () => {
    dispatchAction(deleteMerchantEnteredDaysAction(accountId))
  }

  const onCloseHanlder = () => onClose()

  return (
    <ConfirmationAppDialog
      id="delete-item"
      title={`Reset days count of Account: ${accountId}`}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onCloseHanlder,
        },
        {
          text: 'Reset Now',
          color: 'error',
          variant: 'contained',
          onClickHandler: deleteHandler,
        },
      ]}
    >
      Do you really want to reset days count on this account?
    </ConfirmationAppDialog>
  )
}
