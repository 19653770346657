import firebase from 'firebase/compat/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyD_OOXMTXfpKsVbhUVMb78kq49sgpKZPpY',
  authDomain: 'shuttering-app.firebaseapp.com',
  projectId: 'shuttering-app',
  storageBucket: 'shuttering-app.appspot.com',
  messagingSenderId: '783188573112',
  appId: '1:783188573112:web:6d598d473f8569f07dad4d',
  measurementId: 'G-TR709TC27Q',
}
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const analytics = getAnalytics()
const logShutteringEvent = (eventName, eventParams = {}) =>
  logEvent(analytics, eventName, eventParams)
export { auth, firebase, logShutteringEvent }
