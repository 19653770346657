import { AddStaffAPIResponse } from 'src/services/staff'
import { BaseReducerState, Staff } from '../../../services'
import { ActionTypes } from '../actions'

export type AddStaffState = BaseReducerState & {
  data: Staff
}

export const initialStateAddStaff: AddStaffState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const addStaff = (
  state: AddStaffState = initialStateAddStaff,
  action: { type: string; res: any }
): AddStaffState => {
  switch (action.type) {
    case `${ActionTypes.ADD_STAFF}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.ADD_STAFF}_SUCCESS`: {
      const { success, data, message } = <AddStaffAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.ADD_STAFF}_FAILURE`:
      const res = <AddStaffAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.ADD_STAFF}_RESET`:
      return {
        ...initialStateAddStaff,
      }
  }
  return state
}
