import { FC, useState } from 'react'
import { Box, Checkbox, Grid, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import { Bill, BillPDFRequest } from '../../../services'

// Components
import { AppDialog } from '../../../components'

// Redux
import {
  getFormattedEndDateString,
  getFormattedStartDateString,
} from '../../../utils'

export interface BillBetweenDatesProps {
  onClose?: (request?: BillPDFRequest) => void
  bill: Bill
}

export const BillBetweenDatesItem: FC<BillBetweenDatesProps> = ({
  onClose,
  bill,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)

  const startDateChangeHandler = (value) => {
    setStartDate(value)
  }

  const endDateChangeHandler = (value) => {
    setEndDate(value)
  }

  const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const submitHandle = () => {
    let date1 = null
    let date2 = null
    if (startDate) {
      date1 = getFormattedStartDateString(new Date(startDate))
    }
    if (endDate) {
      date2 = getFormattedEndDateString(new Date(endDate))
    } else {
    }
    onClose({
      billId: bill.id,
      from_date: date1,
      to_date: date2,
      exclude_payment: checked,
    })
  }

  return (
    <AppDialog
      id="edit-item"
      title={'Bill between dates'}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
      fixHeight={false}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} mr={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2} mt={0.5}>
              <Grid item={true} xs={12}>
                <DatePicker
                  label="Start Date (Leave blank to start from first day)"
                  value={startDate}
                  onChange={startDateChangeHandler}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: 'outlined',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item={true} xs={12} mt={2}>
                <DatePicker
                  label="End Date(Leave blank for Today)"
                  value={endDate}
                  onChange={endDateChangeHandler}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: 'outlined',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item={true} xs={12} mt={2}>
                <Checkbox onChange={onCheckBoxChange} /> Exclude Payment
              </Grid>
            </Grid>
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
