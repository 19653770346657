import { Autocomplete, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { Challan } from 'src/services'
import { fetchChallansAction, fetchChallansResetAction } from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'

export interface ChallanDropDownProps {
  accountId: number
  onChallanChange: (challan: any) => void
  challanNo?: string
  error?: boolean
  challan_type?: string
}

export const ChallanDropDown: FC<ChallanDropDownProps> = ({
  accountId,
  onChallanChange,
  challanNo,
  error,
  challan_type,
}) => {
  const dispatchAction = useAppDispatch()
  const [selectedChallan, setSelectedChallan] = useState<any | null>(null)
  const [challans, setChallans] = useState<Challan[]>([])
  
  const {
    data: challansData,
    success: challansSuccess,
    message: challansError,
  } = useAppSelector((state) => state.fetchChallan)

  const defaultProps = {
    options: challans,
    getOptionLabel: (option: Challan) => option.label,
  }

  useEffect(() => {
    dispatchAction(fetchChallansAction(accountId, challan_type))
  }, [accountId])


  useEffect(() => {
    if (challansSuccess && challansData) {
      setChallans(challansData)
     
      let challan = challansData.find(
        (element) => element.slipNo === challanNo
      )
      if (!challanNo && challansData && challansData.length > 0) {
        challan = challansData[0];
      }
      setSelectedChallan(challan)
      onChallanChange(challan)
      dispatchAction(fetchChallansResetAction())

    } else if (challansError && !challansSuccess) {
      dispatchAction(
        showAlertAction({ message: challansError, severity: 'error' })
      )
    }
  }, [challansSuccess, challansError])

  return (
    <Autocomplete
                  size='small'           
      {...defaultProps}
      id="select-challan"
      value={selectedChallan}
      options={challans}
      onChange={(e, newValue) => {
        setSelectedChallan(newValue)
        onChallanChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
                    size='small'
          {...params}
          error={error}
          label={'Select Challan'}
          sx={{ minWidth: '100%' }}
        />
      )}
    />
  )
}
