import { FC, Fragment, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import SitesMonthlyRentReportHeader from './PageHeader';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Paper } from '@mui/material';
import { currencyFormatter } from '../../../utils';
import { tableStyles } from '../Reports';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    borderRight: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
    textAlign: 'left', // Left align content
  },
  tableHead: {
    backgroundColor: '#efeff0 !important',
    textAlign: 'left', // Left align header
  },
  greenRow: {
    backgroundColor: '#e0f7e0', // Light green background for blank row
    height: '20px',
  },
}));

export interface Customer {
  name: string;
  mobile: string;
}

export interface SitesMonthlyRentReportProps {}

export const SitesMonthlyRentReport: FC<SitesMonthlyRentReportProps> = ({}) => {
  const classes = tableStyles();
  const dispatchAction = useAppDispatch();
  const { data: siteReportRows } = useAppSelector(
    (state) => state.sitesMonthlyRentReportState
  );

  let lastSiteNo = '';
  let count = 0;
  return (
    <>
      <Helmet>
        <title>Billing Report</title>
      </Helmet>
      <PageTitleWrapper>
        <SitesMonthlyRentReportHeader />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Table aria-label="billing report">
          <TableHead>
            <TableRow>
              <TableCell width={20}>S.N.</TableCell> {/* Serial Number column */}
              <TableCell className={classes.tableCell}>Party Name</TableCell>
              <TableCell className={classes.tableCell}>Site ID</TableCell>
              <TableCell className={classes.tableCell}>Site</TableCell>
              <TableCell className={classes.tableCell}>Month</TableCell>
              <TableCell className={classes.tableCell}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteReportRows &&
              siteReportRows.map((bill, index) => {
                const isNewGroup = bill.siteNo != 0 && bill.siteNo !== lastSiteNo;
                if(bill.siteNo != 0) {
                  lastSiteNo = bill.siteNo;
                }

                return (
                  <Fragment key={index}>
                    {isNewGroup && (
                      // Insert a green blank row when siteNo changes
                      <TableRow sx={{backgroundColor:'#efeff0'}}>
                        <TableCell colSpan={6}></TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell >{ bill.siteNo != 0 ? ++count : ''}</TableCell> {/* Increment serial number */}
                      <TableCell colSpan={bill.siteNo != 0 ? 0 : 4} className={classes.tableCell}><Typography variant={bill.siteNo != 0 ? 'body1' : 'h5'}>{bill.partyName}</Typography></TableCell>
                      {bill.siteNo != 0 && (<>
                        <TableCell className={classes.tableCell}>{bill.siteNo != 0 ? bill.siteNo : ''}</TableCell>
                      <TableCell className={classes.tableCell}>{bill.siteAddress}</TableCell>
                      <TableCell className={classes.tableCell}>{bill.month}</TableCell>
                      </>)}
                      <TableCell className={classes.tableCell}><Typography variant={bill.siteNo != 0 ? 'body1' : 'h5'}>{currencyFormatter.format(bill.total)}</Typography></TableCell>
                     
                      </TableRow>
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </PageContainerWrapper>
    </>
  );
};

export default SitesMonthlyRentReport;
