import { Box } from '@mui/material'
import { AppAlerts, FullScreenDialog } from '../../../components'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { hidePaymentDuePopupAction } from '../../../store/pendingBills'
import PaymentDuePopupContent from './PaymentDuePopupContent'

const PaymentDuePopup = () => {
  const { show } = useAppSelector((state) => state.managePaymentDuePopup)
  const dispatchAction = useAppDispatch()
  const onclosePopup = () => dispatchAction(hidePaymentDuePopupAction())
  return (
    <>
      {show ? (
        <FullScreenDialog
          title={'Payment due'}
          show={show}
          onclose={onclosePopup}
        >
          <Box
            sx={{
              margin: 'auto',
              width: '50%',
              height: '80vh',
            }}
          >
            <PaymentDuePopupContent />
          </Box>
          <AppAlerts />
        </FullScreenDialog>
      ) : null}
    </>
  )
}
export default PaymentDuePopup
