import { FC, useState, useEffect, Fragment, useContext } from 'react'
import {
  Checkbox,
  ListItemText,
  List,
  ListItem,
  Divider,
  styled,
  Typography,
} from '@mui/material'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
const ListItemWrapper = styled(ListItem)(
  () => `
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 8px;      
`
)

export interface DiscountCategoriesProps {
   onCategoriesChange: (newCategories: any[]) => void
}

const DiscountCategories: FC<DiscountCategoriesProps> = ({onCategoriesChange}) => {
  const {
    data: { categories, selectedCategories },
  } = useAppSelector((state) => state.manageDiscountedCategories)

  useEffect(() => {
    setChecked([...selectedCategories])
    onCategoriesChange([...selectedCategories])
  }, [selectedCategories, categories])

  const [checked, setChecked] = useState([...selectedCategories])

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
    onCategoriesChange(newChecked)
  }

  return (
    <>
      <Typography variant="h6" sx={{ mt: 0 }}>
        {' '}
        One day discount on below seleted items (You may
        select/unselect more)
      </Typography>
      <List sx={{ maxWidth: 400, bgcolor: 'background.paper' }}>
        {categories.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value}`
          return (
            <Fragment key={value}>
              <ListItemWrapper
                key={value}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(value)}
                    checked={checked.indexOf(value) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                }
              >
                <ListItemText id={labelId} primary={`${value}`} />
              </ListItemWrapper>
              <Divider component="li" />
            </Fragment>
          )
        })}
      </List>
    </>
  )
}

export default DiscountCategories
