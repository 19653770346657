import { AxiosResponse } from "axios";
import { http } from "../../../utils";

export function createInvoices(
    startDate: string,
    endDate: string,
    createDate: string,
    billFormat: string
) {

    let url = `/interim_bill/pvt/bulk`
    return http
        .post(
            url,
            {startDate: startDate, endDate: endDate, createDate: createDate, billFormat: billFormat}
        )
        .then((response: AxiosResponse) => response.data)
}

export function getProgressInPercentage() {

    let url = `/interim_bill/pvt/progress`
    return http
        .get(
            url
        )
        .then((response: AxiosResponse) => response.data)
}