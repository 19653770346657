import { UserLoginData, UserLoginResponse } from 'src/services'
import { ActionTypes } from '../actions'

export type UserLoginState = {
  loading: boolean
  success: boolean
  message?: string
  error: string
  data: UserLoginData | null
  selectedStore?: string
}

const initialState: UserLoginState = {
  loading: false,
  success: false,
  error: null,
  data: null,
}

export const userData = (
  state: UserLoginState = initialState,
  action: { type: string; res: UserLoginResponse | any }
): UserLoginState => {
  switch (action.type) {
    case `${ActionTypes.USER_LOGIN}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
      }
    case `${ActionTypes.USER_LOGIN}_SUCCESS`: {
      const { success, data, message } = action.res
      return {
        ...state,
        success,
        message,
        loading: false,
        ...(success && data && { data: data }),
      }
    }

    case `${ActionTypes.USER_LOGIN}_FAILURE`: {
      const { error, message } = action.res
      return {
        ...state,
        message,
        loading: false,
        success: false,
        error,
        data: null,
      }
    }

    case `${ActionTypes.USER_LOGIN}_RESET`: {
      return {
        ...initialState,
      }
    }
    case `${ActionTypes.CHANGE_STORE}`: {
      const { selectedStore } = action.res
      return {
        ...state,
        selectedStore,
      }
    }
  }
  return state
}
