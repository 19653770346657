import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, OnboardingStock, ItemCategory } from '../types'

export interface FetchOnboardingStocksAPIResponse extends Response {
  data: OnboardingStock[]
}

export interface CreateOnboardingStocksAPIResponse extends Response {
  data: boolean | null
}

export function fetchOnboardingStock() {
  return http.get('/category/pvt/onboarding_stock').then((response: AxiosResponse) => response)
}

export function createStock(categories: ItemCategory[]) {
  return http.post('/category/pvt/create_stock', categories).then((response: AxiosResponse) => response)
}
