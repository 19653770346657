import { useState, ChangeEvent, ReactNode, FC, useEffect } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone'

export const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
)

interface SearchProps {
  children?: ReactNode
  placeholder?: string
  onSearch: (searchValue: string) => void
}

export const Search: FC<SearchProps> = ({
  onSearch,
  placeholder = 'Search terms here...',
}) => {
  const [searchValue, setSearchValue] = useState('')
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value)
  }
  useEffect(() => {
    onSearch(searchValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])
  return (
    <SearchInputWrapper
      value={searchValue}
      onChange={handleSearchChange}
      InputProps={{
        ...(searchValue && {
          endAdornment: (
            <InputAdornment position="end" sx={{ minHeight: 10 }}>
              <IconButton
                onClick={() => {
                  setSearchValue('')
                }}
              >
                <ClearTwoToneIcon />
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
      placeholder={placeholder}
      size="small"
      fullWidth
      label="Search"
    />
  )
}
