import { FC, ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
} from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../../hooks'
import Header from '../../../layouts/SidebarLayout/Header'
import Logo from '../../../components/LogoSign'
import StoreProfilePage from '../Users/profile/ProfileCover'
import AccountSettings from '../Users/settings/AccountSettings'
import {
  createOnboardingStocksAction,
  fetchOnboardingStocksAction,
} from '../../../store/onboarding'
import { ItemCategory, UpdateSettingsRequest } from '../../../services'
import { showAlertAction } from '../../../store/alerts'
import {
  getUserProfileDataAction,
  updateSettingsAction,
  updateSettingsResetAction,
  updateUserProfileResetAction,
} from '../../../store/users'
import StockCategoryGrooups from './StockCategoryGroups'
import StockInputItems from './StockInputItems'
import FinalStepItem from './FinalStep'

interface OnboardingScreenProps {
  children?: ReactNode
}

const OnboardingScreen: FC<OnboardingScreenProps> = () => {
  const theme = useTheme()
  const dispatchAction = useAppDispatch()
  const { data: userData, success: userDataSuccess } = useAppSelector(
    (state) => state.userData
  )
  const { data, success, message } = useAppSelector(
    (state) => state.fetchOnboardingStock
  )
  const { success: updateProfileSuccess, message: updateProfileError } =
    useAppSelector((state) => state.updateProfile)
  const { success: updateSettingsSuccess, message: updateSettingsError } =
    useAppSelector((state) => state.updateSettings)
  const { success: createResult, message: createError } = useAppSelector(
    (state) => state.createOnboardingStock
  )
  const [updatedSettings, setUpdatedSettings] =
    useState<UpdateSettingsRequest | null>(null)
  const [backupEmailError, setBackupEmailError] = useState(false)
  const [upiError, setUpiError] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [profileNextClicked, setProfileNextClicked] = useState(false)

  const [selectedStockCategories, setSelectedStockCategories] = useState<
    ItemCategory[]
  >([])
  const navigate = useNavigate()

  const steps = [
    'Company Profile',
    'Settings',
    'Select Stock Items',
    'Create Stock',
  ]

  useEffect(() => {
    dispatchAction(fetchOnboardingStocksAction())
  }, [])

  useEffect(() => {
    if (userData && userDataSuccess) {
      if (userData.onboardingDone != false) {
        if (createResult) {
          navigate('/stock/item-category')
        } else {
          navigate('/dashboard/current-account')
        }
      }
    }
  }, [userData, userDataSuccess])

  useEffect(() => {
    if (!success && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [success, message])

  useEffect(() => {
    if (updateProfileSuccess) {
      setActiveStep(1)
    } else if (updateProfileError) {
      showAlertAction({ message: updateProfileError, severity: 'error' })
      dispatchAction(updateUserProfileResetAction())
    }
  }, [updateProfileSuccess, updateProfileError])

  useEffect(() => {
    if (updateSettingsSuccess) {
      setActiveStep(2)
    } else if (updateSettingsError) {
      dispatchAction(
        showAlertAction({ message: updateSettingsError, severity: 'error' })
      )
      dispatchAction(updateSettingsResetAction())
    }
  }, [updateSettingsSuccess, updateSettingsError])

  useEffect(() => {
    if (createResult) {
      dispatchAction(getUserProfileDataAction())
    } else if (createError) {
      dispatchAction(
        showAlertAction({ message: createError, severity: 'error' })
      )
    }
  }, [createResult, createError])

  useEffect(() => {
    if (profileNextClicked) {
      setProfileNextClicked(false)
    }
  }, [profileNextClicked])

  // Button Click Actions
  function onProfileNextClick() {
    setProfileNextClicked(true)
  }

  function onSettingsNextClick() {
    const rex = /\S+@\S+\.\S+/
    const upirex = /\S+@\S+/
    if (
      updatedSettings.backupEmail &&
      updatedSettings.backupEmail.trim().length > 0 &&
      !rex.test(updatedSettings.backupEmail)
    ) {
      setBackupEmailError(true)
      return
    }
    if (
      updatedSettings.upiId &&
      updatedSettings.upiId.trim().length > 0 &&
      !upirex.test(updatedSettings.upiId)
    ) {
      setUpiError(true)
      return
    }
    dispatchAction(updateSettingsAction(updatedSettings))
  }

  function onStockListNextClick() {
    const selectedCategories = []
    data.map((stockGroup) => {
      stockGroup.itemCategories.map((category) => {
        selectedCategories.push(category)
      })
    })
    setSelectedStockCategories(selectedCategories)

    setActiveStep(3)
  }

  function onCreateStockNextClick() {
    let errorFound = false

    selectedStockCategories.map((category) => {
      category.items.map((item) => {
        const selected = category.isSelected || item.isSelected == true
        if (selected && !item.rate) {
          //this will show validation error
          item.rate = null
          errorFound = true
        }
        if (selected && !item.availableQuantity) {
          //this will show validation error
          item.availableQuantity = null
          errorFound = true
        }
      })
    })
    if (errorFound) {
      dispatchAction(
        showAlertAction({
          message:
            'Validation errors, Please scroll up/down to see all the errors.',
          severity: 'error',
        })
      )
      setSelectedStockCategories([...selectedStockCategories])
    } else {
      const requestCats = []
      selectedStockCategories.map((category) => {
        const categoryCopy = { ...category }
        delete categoryCopy.isExpanded
        delete categoryCopy.isSelected
        categoryCopy.items = []
        category.items.map((item) => {
          if (category.isSelected || item.isSelected) {
            const itemCopy = { ...item }
            categoryCopy.items.push(itemCopy)
            //set null, to avoid sending it to api
            delete itemCopy.isSelected
            delete itemCopy.error
          }
        })

        if (categoryCopy?.items?.length > 0) {
          requestCats.push(categoryCopy)
        }
      })
      dispatchAction(createOnboardingStocksAction(requestCats))
    }
  }

  function onFinalStepClick() {
    navigate('/dashboard/current-account')
  }

  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundColor: 'white',
          width: '30%',
          zIndex: 1000,
          position: 'fixed',
          pt: theme.spacing(2.4),
          pb: theme.spacing(2.2),
          borderBottom: 1,
          borderBottomColor: '#E6E3E3',
        }}
      >
        <Box
          mx={2}
          sx={{
            width: 52,
          }}
        >
          <Logo />
        </Box>
      </Box>
      <Box sx={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
        <Grid container flexDirection={'column'}>
          <Grid item>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                mt: 9,
                pt: 4,
                backgroundColor: '#ffffff',
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                  {
                    color: 'green', // Just text label (COMPLETED)
                  },
                '& .MuiStepLabel-root .Mui-active': {
                  color: '#eb627a', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                  {
                    color: '#eb627a', // Just text label (ACTIVE)
                  },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                style={{
                  width: 800,
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {activeStep === 0 && (
                  <StoreProfilePage
                    hideSaveButton={true}
                    nextClicked={profileNextClicked && activeStep === 0}
                  />
                )}
                {activeStep === 1 && (
                  <AccountSettings
                    isOnboarding={true}
                    invalidEmail={backupEmailError}
                    invalidUpiId={upiError}
                    onChange={(newSettings) => {
                      setUpdatedSettings(newSettings)
                      setBackupEmailError(false)
                      setUpiError(false)
                    }}
                  />
                )}
                {activeStep === 2 && (
                  <StockCategoryGrooups stockGroups={data} />
                )}
                {activeStep === 3 && (
                  <StockInputItems categories={selectedStockCategories} />
                )}
                {activeStep === 4 && <FinalStepItem />}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                pb: 2,
                width: '100%',
                backgroundColor: 'white',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                paddingTop: 2,
              }}
            >
              <Button
                disabled={activeStep === 4}
                sx={{ width: 100 }}
                variant="contained"
                onClick={() => {
                  if (activeStep > 0) {
                    setActiveStep(activeStep - 1)
                  }
                }}
              >
                BACK
              </Button>

              <Button
                variant="contained"
                sx={{ ml: 2, width: 100 }}
                onClick={() => {
                  if (activeStep === 0) {
                    onProfileNextClick()
                  } else if (activeStep === 1) {
                    onSettingsNextClick()
                  } else if (activeStep === 2) {
                    onStockListNextClick()
                  } else if (activeStep === 3) {
                    onCreateStockNextClick()
                  } else if (activeStep === 4) {
                    onFinalStepClick()
                  }
                }}
              >
                {activeStep === 4 ? 'START' : 'NEXT'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default OnboardingScreen
