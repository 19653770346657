import { AccountDetailsResponse, Bill } from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState } from '../../../services/types'

type AccountDetailsState = BaseReducerState & { data: Bill }

const initialState: AccountDetailsState = {
  loading: false,
  success: false,
  message: '',
  data: null,
}

export const accountDetails = (
  state: AccountDetailsState = initialState,
  action: { type: string; res: AccountDetailsResponse }
): AccountDetailsState => {
  switch (action.type) {
    case `${ActionTypes.GET_ACCOUNT_DETAILS}_REQUEST`:
      return {
        ...initialState,
        loading: true,
      }

    case `${ActionTypes.GET_ACCOUNT_DETAILS}_SUCCESS`:
      const { success, data, message } = <AccountDetailsResponse>action.res

      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    case `${ActionTypes.GET_ACCOUNT_DETAILS}_FAILURE`:
      return {
        ...state,
        loading: false,
        success: false,
        message: message,
      }
    case `${ActionTypes.GET_ACCOUNT_DETAILS}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
