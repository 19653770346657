import { Box, Grid, TextField, Autocomplete, Typography, Button, CircularProgress } from '@mui/material';
import { FC, useState, useReducer, useEffect } from 'react';
import { AppDialog } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { addStaffAction, addStaffResetAction, fetchStaffsAction } from '../../../store/staff';
import { showAlertAction } from '../../../store/alerts';
import CustomNumberField from '../Users/profile/CustomNumberField';
import { http } from '../../../../src/utils';
import { Staff } from 'src/services';

type AddStaffFields = 'name' | 'mobile' | 'userLevel';
type AddStaffError = Record<AddStaffFields, Record<string, string>>;
const addStaffErrors: AddStaffError = {
  name: {
    empty: '',
  },
  mobile: {
    empty: '',
  },
  userLevel: {
    empty: 'Please select a user level',
  },
};

type InputBox = {
  value: string;
  error: boolean;
  helperText: string;
};

type AddStaffFieldsState = Record<AddStaffFields, InputBox>;

const defaultObj: InputBox = {
  value: '',
  error: false,
  helperText: '',
};

const initialAddStaffState: AddStaffFieldsState = {
  name: { ...defaultObj },
  mobile: { ...defaultObj },
  userLevel: { ...defaultObj },
};

type Action = {
  type: 'SET' | 'ERROR';
  payload: {
    name: AddStaffFields;
    value: string;
  };
};
const addStaffReducer = (state, action) => {
  const {
    type,
    payload: { name, value },
  } = action;
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: { ...state[name], error: false, value: value, helperText: '' },
      };
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: addStaffErrors[name]['empty'],
        },
      };
    default:
      return state;
  }
};

export interface AddStaffProps {
  children?: React.ReactNode;
  staff?: Staff;
  onClose?: () => void;
}

const userLevels = [
  { label: 'VIEWER', description: 'Read-only access to all resources' },
  { label: 'EDITOR', description: 'Can create and edit resources but cannot delete' },
  { label: 'ADMIN', description: 'Full access to all features' },
];

const AddStaff: FC<AddStaffProps> = ({ onClose, children, staff }) => {
  const { data, success: addStaffSuccess, error: addStaffError, message } = useAppSelector((state) => state.addStaff);
  const dispatchAction = useAppDispatch();
  const [addStaffData, dispatch] = useReducer(addStaffReducer, initialAddStaffState);
  const { name, mobile, userLevel } = addStaffData;
  const [isClose, setClose] = useState<boolean>(false);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [loadingQr, setLoadingQr] = useState<boolean>(false);

  const onChangeHandler = (e) => {
    const {
      target: { name, value },
    } = e;
    if (value.length > 0) {
      dispatch({ type: 'SET', payload: { name, value } });
    } else {
      dispatch({ type: 'ERROR', payload: { name, value } });
    }
  };

  const onUserLevelChange = (event, value) => {
    dispatch({ type: 'SET', payload: { name: 'userLevel', value: value ? value.label : '' } });
  };

  const submitHandle = () => {
    if (name.value.length && mobile.value.length && userLevel.value.length) {
      dispatchAction(
        addStaffAction({
          name: name.value,
          mobile: mobile.value,
          userLevel: userLevel.value.toUpperCase(),
        })
      );
    } else {
      if (!name.value.length) {
        dispatch({ type: 'ERROR', payload: { name: 'name' } });
      }
      if (!mobile.value.length) {
        dispatch({ type: 'ERROR', payload: { name: 'mobile' } });
      }
      if (!userLevel.value.length) {
        dispatch({ type: 'ERROR', payload: { name: 'userLevel', value: '' } });
      }
    }
  };

  const fetchQrCode = () => {
    if (staff || data) {
      setLoadingQr(true);

      http
        .get(`/user/pvt/staff/qr_code?id=${staff?.id ?? data?.id}`)
        .then((response) => {
          if (response.data.success) {
            setQrCodeUrl(response.data.data);
          }
        })
        .catch(() => {
          setQrCodeUrl(null);
        })
        .finally(() => {
          setLoadingQr(false);
        });
    }
  };

  useEffect(() => {
    if (staff) {
      dispatch({
        type: 'SET',
        payload: { name: 'name', value: staff.userName || '' },
      });
      dispatch({
        type: 'SET',
        payload: { name: 'mobile', value: (staff.mobile || '').replace('+91', '') },
      });
      dispatch({
        type: 'SET',
        payload: { name: 'userLevel', value: staff.userLevel || 'Admin' },
      });
    }
  }, [staff, dispatch]);

  useEffect(() => {
    if (addStaffSuccess) {
      dispatchAction(fetchStaffsAction());
      dispatchAction(addStaffResetAction());
      if(staff) {
        onClose();
      } else {
        fetchQrCode()
      }
     
    } else if (addStaffError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }));
      dispatchAction(addStaffResetAction());
    }
  }, [addStaffSuccess, addStaffError, message]);

  return (
    <AppDialog
      id="add-staff"
      title={staff ? 'Edit User' : 'Add User'}
      submitButtonText={staff ? 'UPDATE USER' : 'ADD USER'}
      onClose={onClose}
      close={isClose}
      onSubmit={submitHandle}
      maxWidth="sm"
    >
      <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off">
        <Grid container>
          <Grid item xs>
            <TextField
              size="small"
              name="name"
              value={name.value}
              label="Name"
              error={name.error}
              helperText={name.helperText}
              onChange={onChangeHandler}
              sx={{ minWidth: 545 }}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <CustomNumberField
              name="mobile"
              value={mobile.value}
              label="Mobile"
              maxLength={13}
              error={mobile.error}
              required
              helperText={mobile.helperText}
              onChange={onChangeHandler}
              sx={{ minWidth: 545 }}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <Autocomplete
              options={userLevels}
              getOptionLabel={(option) => option.label}
              value={userLevels.find((level) => level.label === userLevel.value) || null}
              onChange={onUserLevelChange}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Typography variant="body1" fontWeight="bold">
                      {option.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {option.description}
                    </Typography>
                  </Box>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  name="userLevel"
                  value={userLevel.value}
                  error={userLevel.error}
                  helperText={userLevel.helperText}
                  label="User Level"
                  placeholder="Select user level"
                  sx={{ minWidth: 545 }}
                />
              )}
              sx={{ minWidth: 545 }}
            />
          </Grid>
          {staff && (
            <Grid item xs={12} mt={2} ml={1} >
                <Grid item xs={6} display="flex">
              <Button variant="contained" onClick={fetchQrCode}>
                Show Login QR Code
              </Button>
              </Grid>
            </Grid>
          )}
          {loadingQr && (
            <Grid item xs={12} mt={4} textAlign={'center'}>
              <CircularProgress sx={{mt:5}}/>
            </Grid>
          )}
          {qrCodeUrl && (
            <Grid container spacing={0} mt={4}>
              <Grid item xs={6} >
                <img src={qrCodeUrl} alt="QR Code" style={{ width: '200px', height: '200px', marginLeft:10 }} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Steps to Use QR Code:</Typography>
                <Typography variant="body1">1. Open Login Screen in mobile App </Typography>
                <Typography variant="body1">2. Tap QR Code icon (Top-right corner)</Typography>
                <Typography variant="body1">3. Scan this QR code</Typography>
                <Typography variant="body1">4. The account is logged in on other device.</Typography>
                </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </AppDialog>
  );
};

export default AddStaff;

