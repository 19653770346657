import { AxiosResponse } from "axios";
import { http } from "../../../src/utils";

export function getNextChallanNo(
    accountId: number,
    challanType: string,
    challanDate: string

  ) {
    let url = `/challan/pvt/challan_no_by_date?account_id=${accountId}&challan_date=${challanDate}`
    if(challanType) {
      url = `/challan/pvt/challan_no_by_date?account_id=${accountId}&challan_type=${challanType}&challan_date=${challanDate}`
    }
    return http
      .get(
        url
      )
      .then((response: AxiosResponse) => response.data)
  }