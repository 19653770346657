import { BaseReducerState} from '../../../services'
import { ActionTypes } from './actions'
import {CreateInvoicesAPIResponse } from './types'

export type CreateInvoicesState = BaseReducerState & {
  data: [boolean]
}

export const initialState: CreateInvoicesState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const createInvoicesState = (
  state: CreateInvoicesState = initialState,
  action: { type: string; res: any }
): CreateInvoicesState => {
  switch (action.type) {
    case `${ActionTypes.CREATE_INVOICES}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.CREATE_INVOICES}_SUCCESS`: {
      const { success, data, message } = <CreateInvoicesAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.CREATE_INVOICES}_FAILURE`:
      const res = <CreateInvoicesAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.CREATE_INVOICES}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
