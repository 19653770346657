import { ActionTypes } from '../actions'

export interface AlertState {
  show: boolean
  message: string
  severity: string
}

const initialState: AlertState = {
  show: false,
  message: '',
  severity: 'success',
}

export const manageAlerts = (
  state: AlertState = initialState,
  action: { type: string; payload: any }
): AlertState => {
  switch (action.type) {
    case `${ActionTypes.SHOW_ALERT}`:
      return {
        ...state,
        show: true,
        message: action.payload?.message,
        severity: action.payload?.severity,
      }

    case `${ActionTypes.HIDE_ALERT}`:
      return {
        ...state,
        show: false,
        message: '',
        severity: 'success',
      }
  }
  return state
}
