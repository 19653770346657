import { Typography, Grid, Tooltip, IconButton } from '@mui/material'
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { Bill } from '../../../services'
export interface CreateBillHeaderProps {
  pageTitle: string
}
const PageHeader: FC<CreateBillHeaderProps> = ({pageTitle}) => {
  return (
    <Grid container sx={{ p: 0 }} alignItems="center">
      <Grid item>
        {/* <Tooltip arrow placement="top" title="Go to accounts">
          <IconButton
            color="primary"
            sx={{ p: 0, mr: 0}}
            onClick={() => {
              navigate('/dashboard/current-account')
            }}
          >
            <ArrowBackTwoToneIcon />
          </IconButton>
        </Tooltip> */}
      </Grid>
      <Grid item>
        <Typography sx={{pl:2.5}} variant="h3" component="h3" gutterBottom>
          Bills
        </Typography>
        <Typography sx={{pl:2.5}} variant="subtitle1" component="h3" gutterBottom>
          {pageTitle}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PageHeader
