import React, { FC, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  infer as Infer,
  number,
  object,
  string,
  date,
  ZodIssueCode,
  literal,
} from 'zod'
import { Box, Checkbox, Grid, Typography } from '@mui/material'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import {
  ItemEntry,
  EntryType,
  ItemEntryDetail,
  createEwayBill,
  getGSTNDetails,
} from '../../../../services'

import ItemListBox, {
  sumCgstValue,
  sumIgstValue,
  sumSgstValue,
  sumTaxableAmount,
  sumTotalValue,
  totalValue,
} from './ItemListBox'

import {
  addLeadingZero,
  getFormattedDate,
  gstinRegex,
  noEmptyRegex,
  pincodeRegex,
  vehicleNumberRegex,
} from '../../../../utils'

import { states } from '../../../../utils/appUtils'

// Components
import {
  AppDialog,
  FormInputAutosuggest,
  FormInputDateField,
  FormInputTextField,
  Loader,
} from '../../../../components'
import { showAlertAction } from '../../../../store/alerts'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { fetchSlipsListAction } from '../../../../store/slips'
import { EwayItem } from './Services'

const preFillEWayBillStoreData = (gstinData, setValue) => {
  if (gstinData) {
    setValue('storeTradeName', gstinData.tradeName)
    setValue('storeGSTN', gstinData.gstin)
    if (gstinData.stateCode) {
      setValue('storeState', addLeadingZero(gstinData.stateCode))
      setValue('storeActualState', addLeadingZero(gstinData.stateCode))
    }
    setValue('storeAddress1', gstinData.address1)
    setValue('storeAddress2', gstinData.address2)
    setValue('storePlace', gstinData.place)
    setValue('storePincode', gstinData.pinCode)
  }
}

const preFillEWayBillCustomerData = (slipsData, gstinData, setValue) => {
  // Check if userData is available and contains the relevant data
  if (gstinData) {
    // Extract relevant data from slipsData and dispatch an action to update eWayBillData
    if (slipsData.accountInfo.customer) {
      setValue('partyTradeName', gstinData.tradeName)
      setValue('partyGSTN', gstinData.gstin)

      if (gstinData.stateCode) {
        setValue('partyState', addLeadingZero(gstinData.stateCode))
        setValue('siteState', addLeadingZero(gstinData.stateCode))
      }
    }
    setValue('siteAddress1', gstinData.address1)
    setValue('siteAddress2', gstinData.address2)
    setValue('sitePlace', gstinData.place)
    setValue('sitePincode', gstinData.pinCode)
  }
}
const StoreBox = ({ entryType }) => {
  const { control } = useFormContext()
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                mt: 4,
                fontWeight: 'bold',
              }}
            >
              {entryType === EntryType.OUT ? 'Bill From' : 'Bill To'}
            </Typography>
          </Grid>
          <Grid item>
            {' '}
            <FormInputTextField
              control={control}
              name={'storeTradeName'}
              label={'Name'}
              required
            />
          </Grid>
          <Grid item>
            {' '}
            <FormInputTextField
              control={control}
              name={'storeGSTN'}
              label={'GSTIN'}
              InputProps={{
                readOnly: true,
              }}
              required
            />
          </Grid>
          <Grid item>
            {' '}
            <FormInputAutosuggest
              control={control}
              name={'storeState'}
              label="Select State"
              readOnly={true}
              options={states.map((s) => {
                return {
                  label: s.label,
                  id: s.code,
                }
              })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                mt: 4,
                fontWeight: 'bold', // Make the text bold
              }}
            >
              {entryType === EntryType.OUT ? 'Dispatch From' : 'Ship To'}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <FormInputTextField
                control={control}
                name={'storeAddress1'}
                label={'Address 1'}
              />
              <FormInputTextField
                control={control}
                name={'storeAddress2'}
                label={'Address 2'}
              />
            </Box>
          </Grid>
          <Grid item>
            <FormInputTextField
              control={control}
              name={'storePlace'}
              label={'Place'}
            />
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <FormInputTextField
                control={control}
                name={'storePincode'}
                label={'Pincode'}
                required
              />

              <Grid item xs={12}>
                <FormInputAutosuggest
                  control={control}
                  name={'storeActualState'}
                  label="State"
                  options={states.map((s) => {
                    return {
                      label: s.label,
                      id: s.code,
                    }
                  })}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const CustomerBox = ({ entryType }) => {
  const { control } = useFormContext()
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                mt: 4,
                fontWeight: 'bold', // Make the text bold
              }}
            >
              {entryType === EntryType.OUT ? 'Bill To' : 'Bill From'}
            </Typography>
          </Grid>
          <Grid item>
            <FormInputTextField
              control={control}
              name={'partyTradeName'}
              label={'Name'}
              required
            />
          </Grid>
          <Grid item>
            {' '}
            <FormInputTextField
              control={control}
              name={'partyGSTN'}
              label={'GSTIN'}
              InputProps={{
                readOnly: true,
              }}
              required
            />
          </Grid>
          <Grid item>
            {' '}
            <FormInputAutosuggest
              control={control}
              name={'partyState'}
              label="State"
              readOnly={true}
              options={states.map((s) => {
                return {
                  label: s.label,
                  id: s.code,
                }
              })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                mt: 4,
                fontWeight: 'bold', // Make the text bold
              }}
            >
              {entryType === EntryType.OUT ? 'Ship To' : 'Dispatch From'}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <FormInputTextField
                control={control}
                name={'siteAddress1'}
                label={'Address 1'}
              />
              <FormInputTextField
                control={control}
                name={'siteAddress2'}
                label={'Address 2'}
              />
            </Box>
          </Grid>
          <Grid item>
            {' '}
            <FormInputTextField
              control={control}
              name={'sitePlace'}
              label={'Place'}
            />
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <FormInputTextField
                control={control}
                name={'sitePincode'}
                label={'Pincode'}
                required
              />

              <Grid item xs={12}>
                <FormInputAutosuggest
                  control={control}
                  name={'siteState'}
                  label="State"
                  options={states.map((s) => {
                    return {
                      label: s.label,
                      id: s.code,
                    }
                  })}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

// Transaction Type Array
const transactionTypes = [
  { id: 1, label: 'Regular' },
  { id: 2, label: 'Bill To - Ship To' },
  { id: 3, label: 'Bill from - Dispatch From' },
  { id: 4, label: 'Combination' },
]

// Formschema
const schema = object({
  transactionType: number({
    required_error: 'Please select a transaction type',
  }),
  storeTradeName: string({ required_error: 'Please enter trade name' }),
  docNo: string({ required_error: 'Please enter document number' }).regex(
    noEmptyRegex,
    'Please enter document number'
  ),
  docDate: date({ required_error: 'Please enter document date' }),
  storeGSTN: string({ required_error: 'Please enter GST number' }).regex(
    gstinRegex,
    'Please provide valid GSTIN'
  ),
  storeState: string({ required_error: 'Please select a store state' }).regex(
    noEmptyRegex,
    'Please select store state'
  ),
  storeAddress1: string().optional(),
  storeAddress2: string().optional(),
  storePlace: string({ required_error: 'Please enter store place' }).regex(
    noEmptyRegex,
    'Please enter store state'
  ),
  storePincode: string({ required_error: 'Please enter store pincode' }).regex(
    pincodeRegex,
    'Please provide valid pincode'
  ),
  storeActualState: string({ required_error: 'Please select a state ' }).regex(
    noEmptyRegex,
    'Please select state'
  ),
  partyTradeName: string({ required_error: 'Please enter trade name' }).regex(
    noEmptyRegex,
    'Please enter trade name'
  ),
  partyGSTN: string({ required_error: 'Please enter GSTIN number' }).regex(
    gstinRegex,
    'Please provide valid GSTIN'
  ),
  partyState: string({ required_error: 'Please select state ' }).regex(
    noEmptyRegex,
    'Please select state'
  ),
  siteAddress1: string().optional(),
  siteAddress2: string().optional(),
  sitePlace: string({ required_error: 'Please enter place name' }).regex(
    noEmptyRegex,
    'Please enter place name'
  ),
  sitePincode: string({ required_error: 'Please enter pincode' }).regex(
    pincodeRegex,
    'Please provide valide pincode'
  ),
  siteState: string({ required_error: 'Please select state ' }).regex(
    noEmptyRegex,
    'Please select state'
  ),
  transporterId: string()
    .regex(gstinRegex, 'Please provide valid transporter Id')
    .optional(),
  transporterName: string().optional(),
  distance: string().optional(),
  vehicleNumber: string()
    .regex(vehicleNumberRegex, 'Please provide valid vehicle number')
    .optional(),
  transporterDoc: string().optional(),
  transportDate: date().optional(),
}).superRefine((values, ctx) => {
  if (!values.transporterId && !values.vehicleNumber) {
    ctx.addIssue({
      message: 'Please provide transporter Id',
      code: ZodIssueCode.custom,
      path: ['transporterId'],
    })
  }
})

type Schema = Infer<typeof schema>

export interface CreateEwayBillProps {
  children?: React.ReactNode
  onClose?: (string) => void
  slip: ItemEntryDetail
  accountId: number
  entryType: string
}

export const CreateEwayBill: FC<CreateEwayBillProps> = ({
  onClose,
  slip,
  accountId,
  entryType,
}) => {
  const { data: userData } = useAppSelector((state) => state.userData)
  const { data: slipsData } = useAppSelector((state) => state.manageSlips)
  const reachHookForm = useForm<Schema>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {
      transactionType: transactionTypes[0].id,
      storeState: states[0].code,
      storeActualState: states[0].code,
      partyState: states[0].code,
      siteState: states[0].code,
    },
  })

  const { control, handleSubmit, watch, setValue } = reachHookForm
  const [loadingStore, setLoadingStore] = useState(false)
  const [loadingCustomer, setLoadingCustomer] = useState(false)
  const [creatingEWayBill, setCreatingEWayBill] = useState(false)
  const [ewayItemList, setEwayItemList] = useState<EwayItem[]>([])
  const [gstinData, setGstinData] = useState(null)
  const [custGstinData, setCustGstinData] = useState(null)

  //  console.log(watch())

  useEffect(() => {
    setValue('docNo', slip.chaalanNumber + '')
    setValue('vehicleNumber', slip.vehicleNo)
    setValue('docDate', new Date(slip.titleDate ?? new Date()))
  }, [slip])

  useEffect(() => {
    if (userData?.store?.gstin) {
      setLoadingStore(true)
      getGSTNDetails(userData.store.gstin).then((response) => {
        setLoadingStore(false)
        if (response.data.success) {
          setGstinData(response.data.data)
          preFillEWayBillStoreData(response.data.data, setValue)
        } else {
          dispatchAction(
            showAlertAction({
              message: response.data?.message,
              severity: 'error',
            })
          )
        }
      })
    }
    if (slipsData.accountInfo?.customer?.gstin) {
      setLoadingCustomer(true)
      getGSTNDetails(slipsData.accountInfo.customer.gstin).then((response) => {
        setLoadingCustomer(false)
        if (response.data.success) {
          setCustGstinData(response.data.data)
          preFillEWayBillCustomerData(slipsData, response.data.data, setValue)
        } else {
          dispatchAction(
            showAlertAction({
              message: response.data?.message,
              severity: 'error',
            })
          )
        }
      })
    }
  }, [])

  const dispatchAction = useAppDispatch()

  const validateEwayItems = () => {
    if (ewayItemList.length == 0) {
      return false
    }
    for (let i = 0; i < ewayItemList.length; i++) {
      const item = ewayItemList[i]
      if (
        !item.productName ||
        !item.hsnCode ||
        item.quantity <= 0 ||
        item.taxableAmount <= 0
      ) {
        return false
      }
    }
    return true
  }

  const submitHandle = handleSubmit((data: Schema) => {
    if (!validateEwayItems()) {
      dispatchAction(
        showAlertAction({
          message:
            'Data validation errors, Please scroll and check ITEM LIST section.',
          severity: 'error',
        })
      )
      return
    }

    // Submit the form or perform the desired action

    const updatedStore = {
      ...userData.store,
      name: data.storeTradeName,
      gstin: data.storeGSTN,
      state: data.storeState,
      stateCode: data.storeState,
      address1: data.storeAddress1,
      address2: data.storeAddress2,
      place: data.storePlace,
      pincode: data.storePincode,
      actualStateCode: data.storeActualState,
      eWayCredentials: null,
    }

    const updatedCustomer = {
      ...slipsData.accountInfo.customer,
      name: data.partyTradeName,
      gstin: data.partyGSTN,
      state: data.partyState,
      stateCode: data.partyState,
      pincode: data.sitePincode,
    }

    const distance: number = parseInt(data.distance) || 0

    const formattedDate = getFormattedDate(data.transportDate)
    const transDocDate: string | null =
      formattedDate !== '' ? formattedDate : null

    const docDate = getFormattedDate(data.docDate)

    const request = {
      accountId: accountId,
      challanId: slip.challanId,
      challanNumber: slip.chaalanNumber.toString(),
      entryType: entryType,
      transactionType: data.transactionType,
      distance: distance,
      vehicleNo: data.vehicleNumber,
      siteAddress1: data.siteAddress1,
      siteAddress2: data.siteAddress2,
      sitePlace: data.sitePlace,
      sitePincode: data.sitePincode,
      siteState: data.siteState,
      siteStateCode: data.siteState,
      store: updatedStore,
      customer: updatedCustomer,
      transporterId: data.transporterId,
      transporterName: data.transporterName,
      transDocNo: data.transporterDoc,
      transDocDate: transDocDate,
      itemList: ewayItemList,
      totalValue: sumTaxableAmount(ewayItemList),
      totInvoiceValue: sumTotalValue(ewayItemList),
      cgstValue: sumCgstValue(ewayItemList),
      sgstValue: sumSgstValue(ewayItemList),
      igstValue: sumIgstValue(ewayItemList),
      docDate: docDate,
    }

    console.log(request)
    setCreatingEWayBill(true)
    createEwayBill(request).then((response) => {
      setCreatingEWayBill(false)
      if (response.data.success) {
        showAlertAction({
          message: 'EWay bill created successfully',
          severity: 'success',
        })
        dispatchAction(fetchSlipsListAction(accountId))
        onClose(response.data.data.ewayBillNo)
      } else if (response.data.message) {
        dispatchAction(
          showAlertAction({
            message: response.data?.message,
            severity: 'error',
          })
        )
      }
    })
  })

  // This function will be called whenever the value of the watched input changes
  const handleTrsanporterIDChange = async (e) => {
    const value = e.target.value
    // Clear error for transporterName field
    setValue('transporterName', '') // Clear the value first
    const upperCaseValue = value.toUpperCase()
    setValue('transporterId', upperCaseValue)
    const isValidInput = /^([0-9]{2}[A-Z0-9]{13})|URP$/.test(value)
    if (!isValidInput) {
      return
    }
    getGSTNDetails(value).then((response) => {
      if (response.data.success && response.data.data.tradeName) {
        // fill transporter name
        setValue('transporterName', response.data.data.tradeName)
      }
    })
  }

  const handleVehicleNumberChange = (newValue) => {
    // Convert the vehicle number to uppercase
    const upperCaseValue = newValue.target.value.toUpperCase()
    setValue('vehicleNumber', upperCaseValue)
  }

  const onSelectTrasactionType = (option) => {
    preFillEWayBillStoreData(gstinData, setValue)
    preFillEWayBillCustomerData(slipsData, custGstinData, setValue)
    if (entryType === EntryType.OUT) {
      if (transactionTypes[1].id === option.id) {
        resetSiteFields()
      } else if (transactionTypes[2].id === option.id) {
        resetStoreFields()
      } else if (transactionTypes[3].id === option.id) {
        resetSiteFields()
        resetStoreFields()
      }
    } else {
      if (transactionTypes[1].id === option.id) {
        resetStoreFields()
      } else if (transactionTypes[2].id === option.id) {
        resetSiteFields()
      } else if (transactionTypes[3].id === option.id) {
        resetSiteFields()
        resetStoreFields()
      }
    }
  }

  function resetStoreFields() {
    setValue('storeAddress1', '')
    setValue('storeAddress2', '')
    setValue('storePlace', '')
    setValue('storePincode', '')
    setValue('storeActualState', '')
  }

  function resetSiteFields() {
    setValue('siteAddress1', '')
    setValue('siteAddress2', '')
    setValue('sitePlace', '')
    setValue('sitePincode', '')
    setValue('siteState', '')
  }

  return (
    <AppDialog
      id="edit-item"
      title={`eWay Bill Form: ${
        entryType === EntryType.IN ? 'Inward' : 'Outward'
      }`}
      submitButtonText="Submit"
      onClose={() => {
        onClose(null)
      }}
      onSubmit={submitHandle}
      close={false}
      maxWidth="lg"
    >
      {/* Loader overlay */}
      <FormProvider {...reachHookForm}>
        <form>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontWeight: 'bold', // Make the text bold
            }}
          >
            TRASACTION DETAILS
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormInputAutosuggest
                control={control}
                name={'transactionType'}
                label="Select transaction type"
                options={transactionTypes}
                onSelect={onSelectTrasactionType}
              />
            </Grid>
            <Grid item xs={3}>
              <FormInputTextField
                control={control}
                name={'docNo'}
                value={slip.chaalanNumber}
                label={'Document No'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              {' '}
              <FormInputDateField
                format="dd/MM/yyyy"
                control={control}
                name={'docDate'}
                label="Document Date"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {entryType == EntryType.OUT ? (
              <div key={1}>
                <StoreBox entryType={entryType} />
                <CustomerBox entryType={entryType} />
              </div>
            ) : (
              <div key={2}>
                <CustomerBox entryType={entryType} />
                <StoreBox entryType={entryType} />
              </div>
            )}

            <ItemListBox
              challanId={slip.challanId}
              setEwayItemList={setEwayItemList}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: 'bold', // Make the text bold
              }}
            >
              TRANSPORTATION DETAILS
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                width: '100%',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormInputTextField
                  control={control}
                  name={'transporterId'}
                  label={'Transporter ID'}
                  onChange={handleTrsanporterIDChange}
                  InputProps={{ inputProps: { maxLength: 15 } }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <FormInputTextField
                  control={control}
                  name={'transporterName'}
                  label={'Transporter Name'}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <FormInputTextField
                  control={control}
                  name={'distance'}
                  label={'Approximate Distance (in KM)'}
                />
              </Box>
            </Box>

            <Typography
              variant="h6"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: 'bold', // Make the text bold
              }}
            >
              PART-B
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormInputTextField
                  control={control}
                  name={'vehicleNumber'}
                  label={'Vehicle No'}
                  onChange={handleVehicleNumberChange}
                  InputProps={{ inputProps: { maxLength: 15, minLength: 7 } }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {' '}
                <FormInputTextField
                  control={control}
                  name={'transporterDoc'}
                  label={'Transporter Doc'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {' '}
                <FormInputDateField
                  control={control}
                  name={'transportDate'}
                  label="Date"
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
      {(loadingStore || loadingCustomer || creatingEWayBill) && <Loader />}
    </AppDialog>
  )
}
