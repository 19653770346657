import { FC } from 'react'
import { Grid, TextField, Button, Box, IconButton } from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import { PrepareIssueItem, EntryType, Bill } from '../../../../services'

type AddQuotationPreviewItemProps = {
  account: Bill
  item: PrepareIssueItem
  entryType?: EntryType
  deleteItem: (id: number) => void
  submission_type?: 'LOST' | 'SUBMIT'
}
const AddQuotationPreviewItem: FC<AddQuotationPreviewItemProps> = ({
  account,
  item,
  entryType = EntryType.OUT,
  deleteItem,
  submission_type = 'SUBMIT',
}) => {
  const fieldWidth = account?.status === 'QUOTATION' ? 240 : 372
  const mdValue = account?.status === 'QUOTATION' ? 3.5 : 5.25
  return (
    <Grid container spacing={0} xs={12}>
      <Grid item={true} xs={12} md={mdValue} sx={{display:'flex', alignItems:'center'}}>
      <TextField
                    size='small'
          size='small'
          value={item?.item?.label}
          sx={{ minWidth: fieldWidth, "& fieldset": { border: 'none' } }}
        />
      </Grid>
      {account?.status === 'QUOTATION' && (<Grid item={true} xs={12} md={mdValue} sx={{display:'flex', alignItems:'center', pl:3}}>
      {item?.quantity}
      </Grid>)}

      {entryType === EntryType.OUT && (
        <Grid item={true} xs={12} md={mdValue} sx={{display:'flex', alignItems:'center', pl:3}}>
         {item?.rate}
        </Grid>
      )}

      <Grid item xs={12} md={1.5} textAlign='right' pr={2}>
        <IconButton
          size='small'
          sx={{
            mt: {
              xs: 2,
              md: 1,
              color: 'crimson',
              borderRadius: 4,
            },
          }}
          onClick={() => {
            deleteItem(item?.item?.id)
          }}

        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default AddQuotationPreviewItem
