import { http } from '../../../utils'

import { AxiosResponse } from 'axios'

export const ActionTypes = {
  GET_LEDGER_SUMMARY_DATA: 'GET_LEDGER_SUMMARY_DATA'
}

export const getLedgerSummaryData = () => {
  let url = '/charts/pvt/ledger_summary'
 
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export const getLedgerSummaryDataAction = () => ({
  type: ActionTypes.GET_LEDGER_SUMMARY_DATA,
  promise: getLedgerSummaryData(),
})
