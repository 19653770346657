import { FC, useEffect } from 'react'

import { ItemEntry } from '../../../services'

// Components
import { ConfirmationAppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  deletePaymentAction,
  deletePaymentResetAction,
} from '../../../store/items'
import { refreshFetchSlipsAction } from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'

export interface DeletePaymentrops {
  onClose?: () => void
  onSubmitSuccess?: () => void
  item: ItemEntry
}

export const DeletePayment: FC<DeletePaymentrops> = ({
  onClose,
  onSubmitSuccess,
  item,
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success: deletePaymentSuccess,
    error: deletePaymentError,
    message,
  } = useAppSelector((state) => state.deletePayment)

  useEffect(() => {
    if (deletePaymentSuccess) {
      dispatchAction(refreshFetchSlipsAction())
      dispatchAction(
        showAlertAction({
          message: 'Item Deleted Successfully',
          severity: 'success',
        })
      )
      onSubmitSuccess() // To close the popup
    } else if (deletePaymentError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onSubmitSuccess() // To close the popup
    }
  }, [deletePaymentSuccess, deletePaymentError, message])

  const deleteHandler = () => {
    if (item.paymentId > 0) {
      dispatchAction(deletePaymentAction(item.paymentId))
    }
  }

  useEffect(() => {
    return () => {
      dispatchAction(deletePaymentResetAction()) // To manage the Alert
    }
  }, [])

  return (
    <ConfirmationAppDialog
      id="delete-item"
      title={`Payment amount: ${item.receivedAmount}`}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onClose,
        },
        {
          text: 'Delete',
          color: 'error',
          variant: 'contained',
          onClickHandler: deleteHandler,
        },
      ]}
    >
      Do you really want to delete this item?
    </ConfirmationAppDialog>
  )
}
