import { http } from '../../../utils'

import { AxiosResponse } from 'axios'

export const ActionTypes = {
  GET_SITES_STOCK_SUMMARY_DATA: 'GET_SITES_STOCK_SUMMARY_DATA'
}

export const getSitesStockSummaryData = () => {
  let url = '/charts/pvt/sites_stock_summary'
 
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export const getSitesStockSummaryDataAction = () => ({
  type: ActionTypes.GET_SITES_STOCK_SUMMARY_DATA,
  promise: getSitesStockSummaryData(),
})

export const getSitesStockSummaryResetAction = () => ({
  type: `${ActionTypes.GET_SITES_STOCK_SUMMARY_DATA}_RESET`
})
