import {
  AddStockItemAPIResponse,
  BaseReducerState,
} from '../../../services'
import { ActionTypes } from '../actions'

export type AddStockItemState = BaseReducerState & {
  data: null
}

export const initialState: AddStockItemState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const addStockItem = (
  state: AddStockItemState = initialState,
  action: { type: string; res: any }
): AddStockItemState => {
  switch (action.type) {
    case `${ActionTypes.ADD_STOCK_ITEM}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.ADD_STOCK_ITEM}_SUCCESS`: {
      const { success, data, message } = <AddStockItemAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        data,
      }
    }
    case `${ActionTypes.ADD_STOCK_ITEM}_FAILURE`:
      const res = <AddStockItemAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.ADD_STOCK_ITEM}_RESET`:
      return {
        ...initialState
      }
  }
  return state
}
