import { FC, useState, useReducer, useRef, useEffect } from 'react'
import { Grid, TextField, Autocomplete, Button, Box } from '@mui/material'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'

import {
  Item,
  PrepareIssueItem,
  SelectOptions,
  EntryType,
  Bill,
} from '../../../../services'

import { useAppSelector, useAppDispatch } from '../../../../hooks'
import CustomNumberField from '../../Users/profile/CustomNumberField'

type IssueItemFields = 'item' | 'quantity' | 'rate' | 'total_cost'
type IssueItemFieldsState = Record<
  IssueItemFields,
  Partial<{
    value: string
    error: boolean
    helperText: string
  }>
>

type IssueItemErrors = Record<IssueItemFields, Record<string, string>>

const issueItemErrors: IssueItemErrors = {
  item: {
    empty: 'Select item',
  },
  quantity: {
    empty: 'Enter quantity',
    maxLimit: 'Only <QUANTITY> <ITEM> <TYPE>',
  },
  rate: {
    empty: 'Enter rate',
  },

  total_cost: {
    empty: 'Enter total cost',
  },
}

const initialItemIssueState: IssueItemFieldsState = {
  item: {
    error: false,
    helperText: ' ',
  },
  quantity: {
    error: false,
    helperText: ' ',
  },
  rate: {
    error: false,
    helperText: ' ',
  },
  total_cost: {
    error: false,
    helperText: ' ',
  },
}
const itemReducer = (state: Partial<IssueItemFieldsState>, action) => {
  const {
    type,
    payload: { name, value },
    helperText,
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: helperText
            ? helperText
            : initialItemIssueState[name]?.helperText,
        },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: issueItemErrors[name]['empty'],
        },
      }
    case 'ERROR_MAX_LIMIT':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: helperText,
        },
      }
    case 'RESET':
      return {
        ...initialItemIssueState,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: initialItemIssueState[name]?.helperText,
        },
      }
    default:
      return state
  }
}

type AddQuotationItemProps = {
  account: Bill,
  selectOptions: SelectOptions[]
  entryType?: EntryType
  addItem: (item: PrepareIssueItem) => void
  submission_type?: 'LOST' | 'SUBMIT'
}

const AddQuotationItem: FC<AddQuotationItemProps> = ({
  account,
  selectOptions,
  entryType = EntryType.OUT,
  addItem,
  submission_type = 'SUBMIT',
}) => {
  const {
    list: { data: itemOptions },
  } = useAppSelector((state) => state.manageItems)
  const dispatchAction = useAppDispatch()
  const [item, setItem] = useState<PrepareIssueItem | null>()
  const [selectedItem, setSelectedItem] = useState<SelectOptions | null>()
  const [itemData, dispatch] = useReducer(itemReducer, initialItemIssueState)
  const [value, setValue] = useState<SelectOptions | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [entryCategroy, setEntryCategory] = useState<string | null>(null)
  const [rateDict, setRateDict] = useState({})
  const autocompleteRef = useRef(null)

  const addItemHandler = () => {
    let error = false
    if (!item?.item?.id) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'item', value: null } })
    }
    if (account?.status === 'QUOTATION' && (!item?.quantity || item.quantity <= 0)) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'quantity', value: null } })
    }
    if (
      !item?.rate &&
      (entryType === EntryType.OUT || submission_type === 'LOST')
    ) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'rate', value: null } })
      dispatch({ type: 'ERROR', payload: { name: 'total_cost', value: null } })
    }
    if (!error) {
      if(account?.status !== 'QUOTATION') {
        item.quantity = 1
      }
      addItem(item)
      setItem((item) => {
        return {
          entryType,
          rate: null,
          item: null,
          quantity: null,
        }
      })
      const category = value.category
      setValue(null)
      setEntryCategory(category)
    }
  }

  useEffect(() => {
    if (entryCategroy) {
      let hasMoreItems = false
      for (let i = 0; i < selectOptions.length; i++) {
        const option = selectOptions[i].label?.toLowerCase()
        if (option.startsWith(entryCategroy?.toLowerCase() + ' ')) {
          setInputValue(entryCategroy + ' ')
          hasMoreItems = true
          break
        }
      }
      if (!hasMoreItems) {
        setInputValue('')
      }
      autocompleteRef?.current?.focus()
      setEntryCategory(null)
    }
  }, [selectOptions])

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Enter key
      addItemHandler()
    }
  }

  const onInputChange = (e) => {
    const { name, value } = e.target
    setItem((item) => {
      return {
        ...item,
        [name]: value,
      }
    })
    if (value && name) {
      // Validataion for quantity check
      if (name == 'rate' && entryType == EntryType.OUT && selectedItem) {
        setRateDict((prevState) => ({
          ...prevState,
          [selectedItem.category]: value,
        }))
      }

      dispatch({ type: 'SET', payload: { name: name, value: value } })
    } else {
      dispatch({ type: 'ERROR', payload: { name: name, value: value } })
    }
  }

  const onAutocompleteInputChange = (e) => {
    const { name, value } = e.target
    setInputValue(value)
  }

  const quantityTextFieldRef = useRef(null)

  const onSelectChange = (
    e: any,
    newValue: {
      label: string
      id: number
      rate: number
      availableQuantity: number
      category: string
    }
  ) => {
    setValue(newValue)
    setInputValue(newValue ? newValue.label : '')

    const updatedItem = newValue
    setSelectedItem(updatedItem)
    setItem((item) => {
      return {
        ...item,
        entryType,
        quantity: 0,
        rate:
          submission_type === 'LOST'
            ? null
            : rateDict[updatedItem?.category]
            ? rateDict[updatedItem?.category]
            : updatedItem?.rate,
        item: {
          ...item?.item,
          id: updatedItem?.id,
          label: updatedItem?.label,
          category: updatedItem?.category,
        },
      }
    })
    if (newValue?.id) {
      dispatch({ type: 'SET', payload: { name: 'item', value: newValue?.id } })
      dispatch({
        type: 'RESET',
        payload: { name: 'item', value: newValue?.id },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'quantity', value: 0 },
        helperText: `${updatedItem.availableQuantity} ${
          entryType == EntryType.OUT ? 'available' : 'remaining'
        }`,
      })
    } else {
      dispatch({ type: 'ERROR', payload: { name: 'item', value: null } })
    }
    quantityTextFieldRef?.current?.focus()
  }
  const mdValue =
    account?.status === 'QUOTATION'  ? 3.5 : 5.25
  const feildWidth =
  account?.status === 'QUOTATION' ? 240 : 370

  return (
    <Grid
      container
      spacing={0}
      xs={12}
      sx={{ backgroundColor: 'white', paddingBottom: 2 }}
    >
      <Grid
        item={true}
        spacing={0}
        xs={12}
        md={mdValue}
        sx={{ backgroundColor: 'white', padding: 0, alignItems: 'left' }}
      >
        <Autocomplete
                  size='small'           
          sx={{ backgroundColor: 'white', padding: 0, margin: 0 }}
          disablePortal
          id="combo-box-demo"
          value={value}
          inputValue={inputValue}
          options={selectOptions}
          onChange={onSelectChange}
          openOnFocus={true}
          renderInput={(params) => (
            <TextField
                    size='small'
              {...params}
              label="Select Item"
              inputRef={autocompleteRef}
              required
              onChange={onAutocompleteInputChange}
              error={itemData['item']['error']}
              helperText={itemData['item']['helperText']}
              sx={{ minWidth: feildWidth, margin: 0 }}
            />
          )}
        />
      </Grid>
     {account?.status === 'QUOTATION' && (<Grid item={true} xs={12} md={mdValue} sx={{ backgroundColor: 'white' }}>
        <CustomNumberField
          name="quantity"
          InputLabelProps={{ shrink: !!item?.quantity }}
          value={item?.quantity ? item?.quantity : ''}
          label="Quantity"
          onChange={onInputChange}
          onKeyDown={handleKeyDown}
          inputRef={quantityTextFieldRef}
          error={itemData['quantity']['error']}
          helperText={itemData['quantity']['helperText']}
          sx={{ minWidth: feildWidth }}
          maxLength={6}
        ></CustomNumberField>
      </Grid>)}

        <Grid item={true} xs={12} md={mdValue} sx={{ backgroundColor: 'white' }}>
          <CustomNumberField
            name="rate"
            InputLabelProps={{ shrink: !!item?.rate }}
            value={item?.rate ? item?.rate : ''}
            label={'Rate'}
            onKeyDown={handleKeyDown}
            onChange={onInputChange}
            error={
              itemData['rate'][
                'error'
              ]
            }
            helperText={itemData['rate']['helperText']}
            sx={{ minWidth: feildWidth }}
            maxLength={8}
          ></CustomNumberField>
        </Grid>

      <Grid item={true} md={1.5} xs={12} sx={{ backgroundColor: 'white' }}>
        <Box sx={{ paddingLeft: { xs: 2, md: 1.1 } }}>
          <Button
            sx={{
              mt: {
                xs: 2,
                md: 1,
                height: 38,
                width: 90,
                backgroundColor: 'rgb(0, 128, 0, 0.5)',
                color: 'green',
                borderRadius: 4,
              },
            }}
            size="large"
            onClick={addItemHandler}
            startIcon={<AddTwoToneIcon />}
          >
            Add
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
export default AddQuotationItem
