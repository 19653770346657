import { Typography, Grid, Button, Box, } from '@mui/material'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Search, SlipsScreenType } from '../../../components'
import { useAppDispatch } from '../../../hooks'
import { searchSlipsAction } from '../../../store/slips'
import { Bill } from '../../../services'
import { DatePicker } from '@mui/x-date-pickers'
import EditIcon from '@mui/icons-material/Edit'
import { AddAccountItem } from '../CurrentAccounts/AddNewAccountItem'

export interface SlipPageHeaderProps {
  screenType: SlipsScreenType
  bill: Bill
  onDateSelect: (date: Date) => void
  subheaderInfo: ReactNode
}
export let currentSiteId = 0
export const SlipPageHeader: FC<SlipPageHeaderProps> = ({
  screenType,
  onDateSelect,
  subheaderInfo,
  bill,
}) => {

  const dispatchAction = useAppDispatch()
  const [showEditBill, setShowEditBill] = useState<boolean>(false)
  const [date, setDate] = useState<Date | null>(() => new Date())
  const screenTitle =
    screenType === SlipsScreenType.Account
      ? 'Site Details'
      : screenType === SlipsScreenType.Date
        ? `Entries by Date`
        : 'Parties'


  const handleSearch = (searchTerm) => {
    dispatchAction(searchSlipsAction(searchTerm))
  }

  useEffect(() => {
    if(bill) {
      currentSiteId = bill.id
    }
    return () => {
      currentSiteId = 0
    }
  }, [bill])

  return (
    <>
      <Grid container alignItems="flex-start">
        
        <Grid item xs>
        <Box display={'flex'} alignItems="end">
            <Typography variant="h3" component="h3" pl={2.5}>
            {screenTitle}
            </Typography>
            {subheaderInfo && (
            <Typography
              noWrap
              sx={{maxWidth: 250}}
              component="p"
              gutterBottom
              pl={2.5}
              data-cy="id_typo_page_subtitle"
            >
              {subheaderInfo}
            </Typography>
          )}
          </Box>
         
        </Grid>

        <Grid item sx={{ width: '30%' }}>
          <Search onSearch={handleSearch} />
        </Grid>
        <Grid item sx={{ width: 190, textAlign: 'right' }}>
          {screenType === SlipsScreenType.Date && (
            <div
              style={{
                borderRadius: 4,
                height: 41.5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DatePicker
                sx={{ ml: 2 }}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue)
                  onDateSelect(newValue)
                }}
                slotProps={{ textField: { size: 'small' } }}
                format="dd/MM/yyyy"
              />
            </div>
          )}
          {screenType === SlipsScreenType.Account && (
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              sx={{ backgroundColor: '#eb627a', height: 41.5, width: 180 }}
              onClick={() => {
                setShowEditBill(true)
              }}
            >
              EDIT ACCOUNT
            </Button>
          )}
        </Grid>
      </Grid>
      {showEditBill && (
        <AddAccountItem
          onClose={() => {
            setShowEditBill(false)
          }}
          partialBill={bill}
        />
      )}
    </>
  )
}

export default SlipPageHeader
