import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, ItemCategory } from '../types'

export type AddItemCategoryRequest = {
  id: number
  name: string
  isPlate: boolean
  defaultDiscountInDays: number
  isActive: boolean
}

export interface AddItemCategoryResponse extends Response {
  data: ItemCategory
}

export interface UpdateOneDayDiscountResponse extends Response {
  data: null
}

export const addItemCategory = (request: AddItemCategoryRequest) => {
  return http
    .post<AddItemCategoryRequest>('/category/pvt', request)
    .then((response: AxiosResponse) => response)
}

export interface FetchItemCategoryResponse extends Response {
  data: ItemCategory[]
}

export function fetchItemCategories() {
  return http
    .get('/category/pvt/v2')
    .then((response: AxiosResponse) => response)
}

export interface DeleteCategoryAPIResponse extends Response {
  data: ItemCategory | null
}

export function deleteCategory(id: number) {
  return http
    .delete(`/category/pvt/${id}`)
    .then((response: AxiosResponse) => response)
}

export interface DiscountedCategories {
  selectedCategories: string[]
  categories: string[]
}
export interface FetchDiscountedCategoriesResponse extends Response {
  data: DiscountedCategories
}

export function fetchDiscountedCategoriesById(bill_id: number) {
  return http
    .get(`/bill/pvt/categories/${bill_id}`)
    .then((response: AxiosResponse) => response)
}

export function updateDiscountedCategories(bill_id: number, request: string[]) {
  return http
    .put(`/bill/pvt/one_day_discount?days=1&bill_id=${bill_id}`, request)
    .then((response: AxiosResponse) => response)
}
