import {
  FC,
  useState,
  useEffect,
} from 'react'

import {
  Bill,
} from '../../../services'

// Components
import { AppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import DiscountCategories from '../Bill/OneDayDiscount/DiscountCategories'
import { fetchDiscountedCategoriesAction, fetchDiscountedCategoriesResetAction, updateDiscountedCategoriesAction, updateDiscountedCategoriesResetAction } from '../../../store/itemCategory'
import { showAlertAction } from '../../../store/alerts'
import { fetchCustomerAccountAction } from '../../../store/customerAccounts'


export interface BillSettingsProps {
  onClose?: () => void
  account: Bill
}

export const BillSettings: FC<BillSettingsProps> = ({
  onClose,
  account
}) => {
  const dispatchAction = useAppDispatch()
  const [checked, setChecked] = useState([])
  const { success, message } = useAppSelector((state) => state.updateOneDayDiscount)
  const [isClose, setClosePopup] = useState<boolean>(false)
  

  useEffect(() => {
    if(account) {
      dispatchAction(fetchDiscountedCategoriesAction(account.id))
    }
  }, [account])

  useEffect(() => {
       dispatchAction(fetchDiscountedCategoriesResetAction())
       dispatchAction(updateDiscountedCategoriesResetAction())
  }, [])

  useEffect(() => {
    if(success) {
      dispatchAction(
        showAlertAction({
          message: 'Successfully done',
          severity: 'success',
        })
      )
      dispatchAction(fetchCustomerAccountAction(account?.status))
      dispatchAction(fetchDiscountedCategoriesResetAction())
      dispatchAction(updateDiscountedCategoriesResetAction())
      onClose()
     
    } else if(message) {
      dispatchAction(
        showAlertAction({
          message: message,
          severity: 'error',
        })
      )
      dispatchAction(fetchDiscountedCategoriesResetAction())
      dispatchAction(updateDiscountedCategoriesResetAction())

    }
  }, [success, message])

  return (
    <AppDialog
      id="edit-item"
      title={'Bill Settings'}
      submitButtonText="Submit"
      onClose={onClose}
      close={isClose}
      onSubmit={() => {
        dispatchAction(updateDiscountedCategoriesAction(account.id, checked))
      }}
      maxWidth="sm"
    >
      <DiscountCategories onCategoriesChange={(newCats) => {
        setChecked(newCats)
      }}/>

    </AppDialog>
  )
}
