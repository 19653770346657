import { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Container, CssBaseline } from '@mui/material'

interface PageContainerWrapperProps {
  children?: ReactNode
  border?: number
}

export const pageLeftMargin = 40
export const pageRightMargin = 40

const PageContainerWrapper: FC<PageContainerWrapperProps> = ({ children, border }) => {
  return (
    <div style={{marginLeft: pageLeftMargin, marginRight: pageRightMargin, minHeight: 400}}>
    <Container disableGutters maxWidth={false} sx={{border: border != null ? border : 0.8, borderRadius:0.5, borderColor: 'lightgray', backgroundColor:'white'}}>
      {children}
    </Container>
    </div>
  )
}

PageContainerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageContainerWrapper
