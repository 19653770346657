import {
  Customer,
  FetchCustomersResponse,
  AddCustomersResponse,
} from '../../../services'
import { ActionTypes, searchCustomer } from '../actions'
import { BaseReducerState } from '../../../services/types'

export interface CustomerList {
  data: Customer[]
  searchResults: Customer[]
  searchTerm: string
}

export interface CustomerAdd {
  data: Customer | null
}

export type CustomerListState = BaseReducerState & CustomerList
export type CustomerAddState = BaseReducerState & CustomerAdd
export type CustomerManageState = {
  list: CustomerListState
  add: CustomerAddState
}

type SearchUpdate = {
  data: Customer[]
  searchTerm: string
}

const initialState: CustomerManageState = {
  list: {
    loading: false,
    success: false,
    message: '',
    data: [],
    searchResults: [],
    searchTerm: '',
  },
  add: {
    loading: false,
    success: false,
    message: '',
    data: null,
  },
}

export const manageCustomers = (
  state: CustomerManageState = initialState,
  action: {
    type: string
    res: FetchCustomersResponse | AddCustomersResponse | SearchUpdate
  }
): CustomerManageState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_CUSTOMERS}_REQUEST`:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          success: false,
          message: null,
        },
      }

    case `${ActionTypes.FETCH_CUSTOMERS}_SUCCESS`: {
      const { success, data, message } = <FetchCustomersResponse>action.res
      const searchResults = searchCustomer(state.list.searchTerm, data)
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success,
          message,
          searchResults: searchResults,
          ...(success && data && { data }),
        },
      }
    }

    case `${ActionTypes.CUTOMER_SEARCH_RESULTS}`: {
      const { data, searchTerm } = <SearchUpdate>action.res
      return {
        ...state,
        list: {
          ...state.list,
          searchResults: data,
          searchTerm,
        },
      }
    }

    case `${ActionTypes.FETCH_CUSTOMERS}_FAILURE`: {
      const { message } = <FetchCustomersResponse>action.res
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success: false,
          message: message,
        },
      }
    }
  }
  return state
}
