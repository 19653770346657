import {
  UserLoginData,
  createUserLogin,
  UserLoginRequest,
  UserLoginWithFirebaseRequest,
  createUserFirebaseLogin,
  createGetUserProfileData,
  UpdateProfileRequest,
  updateUserProfile,
  UpdateSettingsRequest,
  updateSettings,
  VerifyOtpRequest,
  createVerifyOtp,
} from '../../../services'
type UserAction = {
  type: string
  userData: UserLoginData
}
export const ActionTypes = {
  SAVE_USER_DATA: 'SAVE_USER_DATA',
  USER_LOGIN: 'USER_LOGIN',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  CHANGE_STORE: 'CHANGE_STORE',
}

type DispatchType = (args: UserAction) => UserAction
export function saveUserData(userData: UserLoginData) {
  const action: UserAction = {
    type: ActionTypes.SAVE_USER_DATA,
    userData,
  }
  return (dispatch: DispatchType) => dispatch(action)
}

export const getUserLoginAction = (loginRequest: UserLoginRequest) => ({
  type: ActionTypes.USER_LOGIN,
  promise: createUserLogin(loginRequest),
})

export const getUserLoginWithPhoneNumberAction = (
  loginRequest: UserLoginWithFirebaseRequest
) => ({
  type: ActionTypes.USER_LOGIN,
  promise: createUserFirebaseLogin(loginRequest),
})

export const getUserProfileDataAction = () => ({
  type: ActionTypes.USER_LOGIN,
  promise: createGetUserProfileData(),
})

export const getUserProfileDataResetAction = () => ({
  type: `${ActionTypes.USER_LOGIN}_RESET`,
})

export const updateUserProfileActionB = (request: UpdateProfileRequest) => ({
  type: ActionTypes.UPDATE_USER_PROFILE,
  promise: updateUserProfile(request),
})

export const updateUserProfileResetAction = () => ({
  type: `${ActionTypes.UPDATE_USER_PROFILE}_RESET`,
})

export const updateSettingsAction = (request: UpdateSettingsRequest) => ({
  type: ActionTypes.UPDATE_SETTINGS,
  promise: updateSettings(request),
})

export const updateSettingsResetAction = () => ({
  type: `${ActionTypes.UPDATE_SETTINGS}_RESET`,
})

export const ChangeStoreAction = (selectedStore: string) => ({
  type: `${ActionTypes.CHANGE_STORE}`,
  res: { selectedStore },
})

export const verfifyOTPAction = (loginRequest: VerifyOtpRequest) => ({
  type: ActionTypes.USER_LOGIN,
  promise: createVerifyOtp(loginRequest),
})
