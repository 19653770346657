import { PendingBills, FetchPendingBillsResponse } from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState } from '../../../services/types'

export interface PendingBillsList {
  data: PendingBills[]
}

export type PendingBillsListState = BaseReducerState & PendingBillsList

const initialState: PendingBillsListState = {
  loading: false,
  success: false,
  message: '',
  data: [],
}

export const pendingBills = (
  state: PendingBillsListState = initialState,
  action: {
    type: string
    res: FetchPendingBillsResponse
  }
): PendingBillsListState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_PENDING_BILLS}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
      }

    case `${ActionTypes.FETCH_PENDING_BILLS}_SUCCESS`: {
      const { success, data, message } = <FetchPendingBillsResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }

    case `${ActionTypes.FETCH_PENDING_BILLS}_FAILURE`: {
      const { message } = <FetchPendingBillsResponse>action.res
      return {
        ...state,
        loading: false,
        success: false,
        message: message,
      }
    }
  }
  return state
}

/**
 * Payment due Popup related
 */
export interface PaymentDuePopupState {
  show: boolean
}

const paymentDuePopup: PaymentDuePopupState = {
  show: false,
}

export const managePaymentDuePopup = (
  state: PaymentDuePopupState = paymentDuePopup,
  action: { type: string; payload: any }
): PaymentDuePopupState => {
  switch (action.type) {
    case `${ActionTypes.SHOW_PAYMENT_DUE_POPUP}`:
      return {
        ...state,
        show: true,
      }
    case `${ActionTypes.HIDE_PAYMENT_DUE_POPUP}`:
      return {
        ...state,
        show: false,
      }
  }
  return state
}
