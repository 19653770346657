import { FetchTasksResponse, BaseReducerState, Task } from '../../../services'
import { ActionTypes, searchTask } from '../actions'

export interface TaskList {
  data: Task[]
  searchResults: Task[]
  searchTerm: string
}

export type TaskListState = BaseReducerState & TaskList

type SearchUpdate = {
  data: Task[]
  searchTerm: string
}

const initialState: TaskListState = {
  loading: false,
  success: false,
  message: '',
  data: [],
  searchResults: [],
  searchTerm: '',
}

export const fetchTasks = (
  state: TaskListState = initialState,
  action: {
    type: string
    res: FetchTasksResponse | SearchUpdate
  }
): TaskListState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_TASKS}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
      }

    case `${ActionTypes.FETCH_TASKS}_SUCCESS`: {
      const { success, data, message } = <FetchTasksResponse>action.res
      const filteredData = data?.filter((d) => !d.isDeleted)
      const searchResults = searchTask(state.searchTerm, filteredData)
      return {
        ...state,
        loading: false,
        success,
        message,
        searchResults: searchResults,
        ...(success && data && { data: filteredData }),
      }
    }

    case `${ActionTypes.TASK_SEARCH_RESULTS}`: {
      const { data, searchTerm } = <SearchUpdate>action.res
      return {
        ...state,
        searchResults: data,
        searchTerm,
      }
    }

    case `${ActionTypes.FETCH_TASKS}_FAILURE`: {
      const { message } = <FetchTasksResponse>action.res
      return {
        ...state,
        loading: false,
        success: false,
        message: message,
      }
    }
  }
  return state
}
