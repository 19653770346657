import { createGetSubscriptionPlans } from '../../../services'

export const ActionTypes = {
  FETCH_SUBSCRIPTION_PLAN: 'FETCH_SUBSCRIPTION_PLAN',
  SHOW_SUBCRIBE_POPUP: 'SHOW_SUBCRIBE_POPUP',
  HIDE_SUBCRIBE_POPUP: 'HIDE_SUBCRIBE_POPUP',
}

export const fetchSubscriptionPlanAction = (monthly_billing: number) => ({
  type: ActionTypes.FETCH_SUBSCRIPTION_PLAN,
  promise: createGetSubscriptionPlans(monthly_billing),
  monthly_billing: monthly_billing,
})

export const fetchSubscriptionPlanResetAction = () => ({
  type: `${ActionTypes.FETCH_SUBSCRIPTION_PLAN}_RESET`,
})

export const showSubscribePopupAction = () => ({
  type: ActionTypes.SHOW_SUBCRIBE_POPUP,
})

export const hideSubscribePopupAction = () => ({
  type: ActionTypes.HIDE_SUBCRIBE_POPUP,
})
