import {
  AddPaymentAPIResponse,
  AddPaymentResponse,
  BaseReducerState,
} from '../../../services'
import { ActionTypes } from '../actions'

export type AddPaymentState = BaseReducerState & {
  data: AddPaymentResponse
}

export const addPaymentInitialState: AddPaymentState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const addPayment = (
  state: AddPaymentState = addPaymentInitialState,
  action: { type: string; res: any }
): AddPaymentState => {
  switch (action.type) {
    case `${ActionTypes.ADD_PAYMENT}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.ADD_PAYMENT}_SUCCESS`: {
      const { success, data, message } = <AddPaymentAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.ADD_PAYMENT}_FAILURE`:
      const res = <AddPaymentAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.ADD_PAYMENT}_RESET`:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        data: null,
        error: null,
      }
  }
  return state
}
