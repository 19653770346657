import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import 'nprogress/nprogress.css'
import App from './App'
import ErrorBoundary from './components/ErrorBoundary'
import { SidebarProvider } from './contexts/SidebarContext'
import * as serviceWorker from './serviceWorker'
import './index.css'

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
