import { Autocomplete, Grid, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { states } from '../../../utils/appUtils'

export interface SelectStateItemProps {
  children?: React.ReactNode
  selectedStateOption?: StateOption
  onChange: (option: StateOption) => void
  label?: string
  error?: boolean // Add error prop
  helperText?: string // Add helperText prop
  required?: boolean
  disabled?: boolean
  minWidth?: number
  statesData?: StateOption[]
}

export interface StateOption {
  code: string
  label: string
}

export function getState(code: string) {
  let stateOption = null
  states.map((state) => {
    if (state.code === code) {
      stateOption = state
    }
  })
  return stateOption
}

const SelectStateItem: FC<SelectStateItemProps> = ({
  onChange,
  children,
  selectedStateOption,
  label,
  error,
  helperText,
  required,
  disabled,
  statesData = states,
  minWidth,
}) => {
  const [stateOption, setStateOption] = useState<StateOption | null>(null)

  const defaultProps = {
    options: statesData,
    getOptionLabel: (option: StateOption) => `${option.label} (${option.code})`,
  }

  const onStateChange = (e, newValue) => {
    setStateOption(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    setStateOption(selectedStateOption)
  }, [selectedStateOption])

  return (
    <Autocomplete
      size="small"
      {...defaultProps}
      id="id_select_state"
      value={stateOption}
      options={statesData}
      onChange={onStateChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          label={label ? label : 'Select State'}
          sx={{ minWidth: minWidth === null ? '100%' : minWidth }}
          error={error} // Pass the error prop to the TextField component
          helperText={helperText} // Pass the helperText prop to the TextField component
          required={required}
        />
      )}
      renderOption={(props, option) => (
        <Grid component={'li'} container {...props}>
          <Grid
            id={option.label}
            data-cy={`${option.label} (${option.code})`}
            item
            xs
            pl={1}
            pr={1}
            pt={0.7}
            pb={0.7}
          >
            {`${option.label} (${option.code})`}
          </Grid>
        </Grid>
      )}
    />
  )
}

export default SelectStateItem
