import { FC, useState } from 'react'
import {
  Grid,
  Typography,
  ListItem,
  Tooltip,
  IconButton,
  useTheme,
} from '@mui/material'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import PrintIcon from '@mui/icons-material/Print'
import ShareIcon from '@mui/icons-material/Share'
import DeleteIcon from '@mui/icons-material/Delete'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'

import { EditItem } from './EditItem'
import { DeleteItem } from './DeleteItem'
import BorderColorIcon from '@mui/icons-material/BorderColor'

import { TabsEnums } from '../../../components'
import { ItemEntry, EntryType } from '../../../services'
import './slips_style.css'
import { getShortFormattedDate } from 'src/utils'

const ItemName: FC<{ name: string; id?: string }> = ({ name, id }) => {
  return (
    <Typography
      id="item_name"
      data-cy={id}
      variant="body2"
      sx={{
        py: 0,
        pl: 3,
        m: 1.2,
      }}
    >
      {name}
    </Typography>
  )
}

interface LeftListItemComponentProps {
  item: ItemEntry
  componentName: TabsEnums
}

export const LeftListItemComponent: FC<LeftListItemComponentProps> = ({
  item,
  componentName,
}) => {
  const theme = useTheme()
  const issuedRecievedText = item.quantity === 0 ? 'RECV' : 'LEFT'

  const [editItem, openEditItem] = useState<boolean>(false)
  const [deleteItem, openDeleteItem] = useState<boolean>(false)
  const testIdPrefix = `id_${item.itemName}`
  return (
    <ListItem
      id="slip_row"
      sx={{
        pt: 0.5,
        pb: 0.5,
      }}
      key={item.id}
    >
      <Grid container sx={{ p: 0 }} alignItems="center" justifyContent="center">
        <Grid item xs={2.75} sx={{ alignItems: 'center' }}>
          <div
            style={{
              height: 10,
              width: 10,
              borderRadius: 5,
              backgroundColor: item.barColorCode,
              marginLeft: 20,
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
          <ItemName name={item.itemName} id={`${testIdPrefix}_name`} />
        </Grid>

        <Grid item xs={2.75} sx={{ alignItems: 'center' }}>
          <Typography
            id="item_out_count"
            data-cy={`${testIdPrefix}_out`}
            variant="body2"
            sx={{
              m: 0.5,
            }}
          >
            {item.outCount}
          </Typography>
        </Grid>

        <Grid
          item
          xs={2.75}
          sx={{ alignItems: 'center', alignContent: 'center' }}
        >
          <Typography
            data-cy={`${testIdPrefix}_in`}
            id="item_in_count"
            variant="body2"
            textAlign={'right'}
            sx={{
              width: 70,
            }}
          >
            {item.inCount}
          </Typography>
        </Grid>

        <Grid item xs={2.75} sx={{ alignItems: 'center' }}>
          <div className={`entryType${issuedRecievedText}`}>
            {issuedRecievedText}
          </div>
        </Grid>

        <Grid item xs={1}>
          <Typography
            data-cy={`${testIdPrefix}_left`}
            variant="body2"
            textAlign={'right'}
            sx={{
              width: 70,
            }}
          >
            {item.quantity}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  )
}
