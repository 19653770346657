import { FC, Fragment, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import LedgerSummaryHeader from './PageHeader';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Paper, Link, Tooltip } from '@mui/material';
import { currencyFormatter } from '../../../utils';
import { tableStyles } from '../Reports';


export interface LedgerSummaryProps {}

export const LedgerSummary: FC<LedgerSummaryProps> = ({}) => {
  const classes = tableStyles();
  const { data: siteReportRows } = useAppSelector(
    (state) => state.ledgerSummaryState
  );

  let count = 0;
  return (
    <>
      <Helmet>
        <title>Ledger Summary</title>
      </Helmet>
      <PageTitleWrapper>
        <LedgerSummaryHeader />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Table aria-label="billing report">
          <TableHead>
            <TableRow>
              <TableCell width={20}>S.N.</TableCell> {/* Serial Number column */}
              <TableCell className={classes.tableCell}>Party Name</TableCell>
              <TableCell className={classes.tableCell}>Site</TableCell>
              <TableCell className={classes.tableCell}>Site ID</TableCell>
              <TableCell className={classes.tableCell}>Mobile</TableCell>
              <TableCell className={classes.tableCell}>Total Billing</TableCell>
              <TableCell className={classes.tableCell}>Total Payment</TableCell>
              <TableCell className={classes.tableCell}>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteReportRows &&
              siteReportRows.map((bill, index) => {
                const isNotSum = !(bill.party === "SUM")
                
                return (
                  <Fragment key={index}>
                    <TableRow>
                      <TableCell >{ isNotSum? ++count : ''}</TableCell> 
                      <TableCell colSpan={isNotSum ? 0 : 4} className={classes.tableCell}><Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.party}</Typography></TableCell>
                      {isNotSum && (<>
                        <TableCell className={classes.tableCell}>{bill.siteAddress}</TableCell>
                        <TableCell className={classes.tableCell}>
                        <Tooltip arrow title="Go to site details">
                              <Link
                               color={'#0000ff'}
                                underline="always"
                                href={`/slips/view-slips/${bill.siteNo}/${bill.partyId}?tab_index=6`}
                              >
                                {isNotSum ? bill.siteNo : ''}
                              </Link>
                            </Tooltip>
                          </TableCell>
                        <TableCell className={classes.tableCell}>{isNotSum ? bill.mobile : ''}</TableCell>
                      </>)}
                      <TableCell className={classes.tableCell}><Typography variant={isNotSum ? 'body1' : 'h5'}>{currencyFormatter.format(bill.invoiceSum)}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant={isNotSum ? 'body1' : 'h5'}>{currencyFormatter.format(bill.paymentSum)}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant={isNotSum ? 'body1' : 'h5'}>{currencyFormatter.format(bill.due)}</Typography></TableCell>
                      </TableRow>
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </PageContainerWrapper>
    </>
  );
};

export default LedgerSummary;
