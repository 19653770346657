import { FC, forwardRef, useEffect } from 'react'
import Alert from '@mui/material/Alert'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Breakpoint,
  Typography,
  Slide,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { API_ERRORS } from '../../services/types'
import { TransitionProps } from '@mui/material/transitions'
// Redux related
import hotkeys from 'hotkeys-js';

export const THEME_COLOR = '#314d67'

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    display: 'flex', // add this
    flexDirection: 'column', // add this
    height: '100%', // add this
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
  bgColor?: string
  textColor?: string
}

function CustomDialogTitle(props: DialogTitleProps) {
  const { children, onClose, bgColor, textColor, ...other } = props

  return (
    <DialogTitle
      sx={{
        m: 0,
        pl: 2,
        py: 1.4,
        color: textColor,
        bgcolor: bgColor ? bgColor : THEME_COLOR,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
export interface AppDialogProps {
  id: string
  title: string | React.ReactNode
  children: React.ReactNode
  onClose?: () => void
  onSubmit?: () => void
  submitButtonText?: string
  close: boolean
  dialogActions?: boolean
  maxWidth?: false | Breakpoint
  fixHeight?: boolean
  showErrorMessage?: string | boolean | API_ERRORS
  dialogActionsButtons?: Array<{
    id?: string
    variant: 'text' | 'outlined' | 'contained'
    text: string
    color:
      | 'inherit'
      | 'primary'
      | 'secondary'
      | 'success'
      | 'error'
      | 'info'
      | 'warning'
    onClickHandler: () => void
  }>
}

export const AppDialog: FC<AppDialogProps> = ({
  id,
  title,
  children,
  onClose,
  onSubmit,
  submitButtonText = 'Save Changes',
  close = false,
  dialogActions = true,
  maxWidth = 'md',
  fixHeight = true,
  showErrorMessage = false,
}) => {
  const handleClose = () => {
    onClose()
  }
  const handleSubmit = () => {
    onSubmit()
  }

  hotkeys('ctrl+x', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    onClose()
  });

  return (
    <CustomDialog
    onKeyUp={(e) => {
      const ENTER = 13;
      console.log(e.keyCode)
  
      if (e.keyCode === ENTER) {
        handleSubmit()
        e.preventDefault()
      }
    }}
      onClose={handleClose}
      fullWidth={true}
      PaperProps={{
        sx: { maxHeight: '90vh', ...(fixHeight && { minHeight: '90vh' }) },
      }}
      maxWidth={maxWidth}
      aria-labelledby={id}
      open={!close}
    >
      <CustomDialogTitle id={id} onClose={handleClose}>
        <Typography sx={{ fontSize: 18, color: 'white', mr: 4 }}>
          {title}
        </Typography>
      </CustomDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {dialogActions && (
        <DialogActions
          sx={{
            my: 0.4,
            mr: 3,
            justifyContent: showErrorMessage ? 'space-between' : 'flex-end',
          }}
        >
          {showErrorMessage && (
            <Alert severity="error">{showErrorMessage}</Alert>
          )}
          <Button
            id="id_dialog_submit_button"
            variant="contained"
            size="medium"
            onClick={handleSubmit}
          >
            {submitButtonText}
          </Button>
        </DialogActions>
      )}
    </CustomDialog>
  )
}

export const ConfirmationAppDialog: FC<AppDialogProps> = ({
  id,
  children,
  title,
  onClose,
  close = false,
  dialogActions = true,
  dialogActionsButtons,
}) => {
  return (
    <CustomDialog
      onClose={onClose}
      aria-labelledby={id}
      open={!close}
      maxWidth={'sm'}
    >
      {title && (
        <CustomDialogTitle id={id} onClose={onClose}>
          <Typography color={'white'}> {title}</Typography>
        </CustomDialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="confirm-dialog-description" p={2}>
          {children}
        </DialogContentText>
      </DialogContent>
      {dialogActions && dialogActionsButtons.length > 0 && (
        <DialogActions sx={{ mr: 2, mb: 2 }}>
          {dialogActionsButtons.map((btn, index) => {
            return (
              <Button
                id={btn.id}
                data-cy={`id_button_${index === 0 ? 'no' : 'yes'}`}
                onClick={btn.onClickHandler}
                variant={btn.variant}
                color={btn.color}
                key={index}
              >
                {btn.text}
              </Button>
            )
          })}
        </DialogActions>
      )}
    </CustomDialog>
  )
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const FullScreenDialog = ({ children, title, show, onclose }) => {
  const handleClose = (event?: React.SyntheticEvent | Event) => {
    onclose()
  }

  return (
    <Dialog
      fullScreen
      open={show}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <CustomDialogTitle
        id={'id'}
        bgColor="#314d67"
        textColor="white"
        onClose={handleClose}
      >
        <Typography
          sx={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold', mr: 4 }}
        >
          {title}
        </Typography>
      </CustomDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}
