import { FC, useState, useReducer, useEffect } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

// Components
import { AppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { editBillResetAction, updateBillAction } from '../../../store/items'
import { showAlertAction } from '../../../store/alerts'
import { fetchCustomerAccountAction } from '../../../store/customerAccounts'
import { fetchCustomersActionV2 } from '../../../store/customers'
import {
  getFormattedDateTimeIST,
  isValidMobileNumber,
  indianMobileNumberRegex,
} from '../../../utils'
import { Bill } from '../../../services'
import CustomNumberField from '../Users/profile/CustomNumberField'

type AddAccountFields =
  | 'agreementTitle'
  | 'customer'
  | 'customerName'
  | 'customerMobile'
  | 'customerAddress'

type AddAccountFieldsState = Record<
  AddAccountFields,
  Partial<{
    value: string
    error: boolean
    helperText: string
  }>
>

type IssueItemErrors = Record<AddAccountFields, Record<string, string>>

const issueItemErrors: IssueItemErrors = {
  agreementTitle: {
    empty: 'Please enter site address',
  },
  customerAddress: {
    empty: 'Please enter party address',
  },
  customer: {
    empty: 'Please select party',
  },
  customerName: {
    empty: 'Please enter party name',
  },
  customerMobile: {
    empty: 'Please enter party mobile',
  },
}

const initialAddAccountFieldsState: AddAccountFieldsState = {
  agreementTitle: {
    error: false,
    helperText: '',
    value: '',
  },
  customer: {
    error: false,
    helperText: '',
    value: '',
  },
  customerName: {
    error: false,
    helperText: '',
    value: '',
  },
  customerMobile: {
    error: false,
    helperText: '',
    value: '',
  },
  customerAddress: {
    error: false,
    helperText: '',
    value: '',
  },
}
const itemReducer = (state: Partial<AddAccountFieldsState>, action) => {
  const {
    type,
    payload: { name, value },
    helperText,
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: helperText
            ? helperText
            : initialAddAccountFieldsState[name]?.helperText,
        },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: issueItemErrors[name]['empty'],
        },
      }
    case 'RESET':
      return {
        ...initialAddAccountFieldsState,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: initialAddAccountFieldsState[name]?.helperText,
        },
      }
    default:
      return state
  }
}

export interface NewQuotationFormProps {
  onClose?: (newAccount?: Bill) => void
  billStatus: string
}

export const NewQuotationForm: FC<NewQuotationFormProps> = ({
  onClose,
  billStatus,
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success: addAccountSuccess,
    error: addAccountError,
    data: newAccount,
    message,
  } = useAppSelector((state) => state.editBill)
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [customerOptions, setCustomerOption] = useState(null)
  const [showCustomerName, setShowCustomerName] = useState<boolean>(false)
  const [showCustomerMobile, setShowCustomerMobile] = useState<boolean>(false)
  const [showCustomerAddress, setShowCustomerAddress] = useState<boolean>(false)
  const [itemData, dispatch] = useReducer(
    itemReducer,
    initialAddAccountFieldsState
  )
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const {
    list: { data },
  } = useAppSelector((state) => state.manageCustomers)

  useEffect(() => {
    dispatchAction(fetchCustomersActionV2())
  }, [])

  const ADD_NEW_CUSTOMER = 'New Customer'

  // Customer Select
  const onCustomerChange = (e, newValue: { id; label }) => {
    setSelectedCustomer(newValue)
    if (!newValue) {
      setShowCustomerName(false)
      setShowCustomerMobile(false)
      setShowCustomerAddress(false)
    } else if (newValue.label && newValue.label === ADD_NEW_CUSTOMER) {
      setShowCustomerName(true)
      setShowCustomerMobile(true)
      setShowCustomerAddress(true)
    } else {
      setShowCustomerName(false)
      setShowCustomerMobile(false)
      setShowCustomerAddress(false)
    }
  }

  const onChangeHandler = (e) => {
    const {
      target: { name, value },
    } = e
    if (value.length > 0) {
      dispatch({ type: 'SET', payload: { name, value } })
    } else {
      dispatch({ type: 'ERROR', payload: { name, value } })
    }
  }

  useEffect(() => {
    let customer = []
    if (data.length > 0) {
      customer = data.map((customer) => {
        return {
          id: customer.id,
          label: `${customer.name} (${customer.mobile}) - ${customer.address}`,
        }
      })
      customer = insert(customer, 0, { id: 0, label: ADD_NEW_CUSTOMER })
      setCustomerOption(customer)
    } else {
      //navigate('/management/customers')
    }
  }, [data])

  function insert(array, index, newItem) {
    return [...array.slice(0, index), newItem, ...array.slice(index)]
  }

  useEffect(() => {
    if (addAccountSuccess) {
      dispatchAction(fetchCustomerAccountAction(billStatus))
      dispatchAction(
        showAlertAction({
          message: 'Successfully done',
          severity: 'success',
        })
      )
      onClose(newAccount)
    } else if (addAccountError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [addAccountSuccess, addAccountError, message])

  const submitHandle = () => {
    let error = false
    if (!selectedCustomer) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'customer', value: null } })
    }
    if (showCustomerName && !itemData?.customerName?.value) {
      error = true
      dispatch({
        type: 'ERROR',
        payload: { name: 'customerName', value: null },
      })
    }
    if (showCustomerMobile) {
      if (!itemData?.customerMobile?.value) {
        error = true
        dispatch({
          type: 'ERROR',
          payload: { name: 'customerMobile', value: null },
        })
      } else if (isValidMobileNumber(itemData?.customerMobile?.value)) {
        dispatch({
          type: 'ERROR',
          payload: {
            name: 'customerMobile',
            value: itemData?.customerMobile?.value,
          },
        })
      }
    }
    if (showCustomerAddress && !itemData?.customerAddress?.value) {
      error = true
      dispatch({
        type: 'ERROR',
        payload: { name: 'customerAddress', value: null },
      })
    }

    if (!itemData?.agreementTitle?.value) {
      error = true
      dispatch({
        type: 'ERROR',
        payload: { name: 'agreementTitle', value: null },
      })
    }

    if (!error) {
      const apiRequest = {
        date: getFormattedDateTimeIST(new Date()),
        customerId: selectedCustomer.id,
        agreementTitle: itemData?.agreementTitle?.value,
        billType: 'PER_ITEM',
        ratePerSqft: 0,
        ceilingArea: 0,
        wallPercent: 0,
        isSqftFlatRate: false,
        isGSTEnabled: false,
        enteries: [],
        customerName: itemData?.customerName.value,
        customerMobile: itemData?.customerMobile.value,
        customerAddress: itemData?.customerAddress.value,
        billStatus: billStatus,
        isRecurringEnabled: false,
      }
      dispatchAction(updateBillAction(apiRequest))
    }
  }
  useEffect(() => {
    return () => {
      dispatchAction(editBillResetAction()) // To manage the Alert
    }
  }, [])
  return (
    <AppDialog
      id="edit-item"
      title={`NEW ${billStatus}`}
      submitButtonText="CONTINUE"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} m={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 0 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container>
              <Grid item={true} xs>
                <Autocomplete
                  size='small'           
                  id="select-customer"
                  value={selectedCustomer}
                  options={customerOptions}
                  onChange={onCustomerChange}
                  renderInput={(params) => (
                    <TextField
                    size='small'
                      {...params}
                      label="Select Customer"
                      required
                      error={itemData['customer']['error']}
                    />
                  )}
                  renderOption={(props, option) => {
                    const textColor =
                      option.label === ADD_NEW_CUSTOMER ? 'green' : 'black'
                    return (
                      <Typography
                        {...props}
                        noWrap
                        style={{ color: textColor, height: 40, marginRight:10 }}
                      >
                        {option.label}
                      </Typography>
                    )
                  }}
                />
              </Grid>
            </Grid>
            {showCustomerName && (
              <Grid container spacing={2} mt={0.5}>
                <Grid item={true} xs={12}>
                  <TextField
                    size='small'
                    name="customerName"
                    onChange={onChangeHandler}
                    value={itemData.customerName.value}
                    label="Party Name"
                    type="string"
                    fullWidth
                    error={itemData['customerName']['error']}
                  />
                </Grid>
              </Grid>
            )}
            {showCustomerMobile && (
              <Grid container spacing={2} mt={0.5}>
                <Grid item={true} xs={12}>
                  <CustomNumberField
                    name="customerMobile"
                    onChange={onChangeHandler}
                    value={itemData.customerMobile.value}
                    label="Customer Mobile"
                    fullWidth
                    error={itemData['customerMobile']['error']}
                    inputProps={{
                      maxLength: 13,
                      pattern: indianMobileNumberRegex,
                    }}
                    maxLength={13}
                  />
                </Grid>
              </Grid>
            )}
            {showCustomerAddress && (
              <Grid container spacing={2} mt={0.5}>
                <Grid item={true} xs={12}>
                  <TextField
                    size='small'
                    name="customerAddress"
                    onChange={onChangeHandler}
                    value={itemData.customerAddress.value}
                    label="Customer Address"
                    fullWidth
                    error={itemData['customerAddress']['error']}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} mt={0.5}>
              <Grid item={true} xs={12}>
                <TextField
                    size='small'
                  name="agreementTitle"
                  onChange={onChangeHandler}
                  value={itemData.agreementTitle.value}
                  label="Title"
                  type="string"
                  fullWidth
                  error={itemData['agreementTitle']['error']}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
