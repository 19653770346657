import { fetchAllChallans } from '../../../services/outwardChallans'

export const ActionTypes = {
  FETCH_ALL_CHALLANS: 'FETCH_ALL_CHALLANS',
}

export const fetchAllChallansAction = (isGst: boolean, challanType: string, finYear: string) => ({
  type: ActionTypes.FETCH_ALL_CHALLANS,
  promise: fetchAllChallans(isGst, challanType, finYear),
})

export const fetchAllChallansResetAction = () => ({
  type: `${ActionTypes.FETCH_ALL_CHALLANS}_RESET`,
})
