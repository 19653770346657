import { FC, useEffect } from 'react'

// Components

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'

import { showAlertAction } from '../../../store/alerts'
import { Staff } from '../../../services'
import {
  deleteStaffResetAction,
  deleteStaffsAction,
  fetchStaffsAction,
} from '../../../store/staff'
import { ConfirmationAppDialog } from '../../../components'

export interface DeleteStaffProps {
  onClose?: () => void
  onSubmitSuccess?: () => void
  staff: Staff
}

export const DeleteStaff: FC<DeleteStaffProps> = ({ onClose, staff }) => {
  const dispatchAction = useAppDispatch()
  const {
    success: deleteSuccess,
    error: deleteFail,
    message,
  } = useAppSelector((state) => state.deleteStaff)

  useEffect(() => {
    if (deleteSuccess) {
      dispatchAction(fetchStaffsAction())
      dispatchAction(
        showAlertAction({
          message: 'Staff deleted successfully',
          severity: 'success',
        })
      )
      onClose() // To close the popup
    } else if (deleteFail && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onClose() // To close the popup
    }
  }, [deleteSuccess, deleteFail, message])

  const deleteHandler = () => {
    dispatchAction(deleteStaffsAction(staff.mobile))
  }

  useEffect(() => {
    return () => {
      dispatchAction(deleteStaffResetAction()) // To manage the Alert
    }
  }, [])

  return (
    <ConfirmationAppDialog
      id="delete-item"
      title={staff?.userName}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onClose,
        },
        {
          text: 'Delete',
          color: 'error',
          variant: 'contained',
          onClickHandler: deleteHandler,
        },
      ]}
    >
      Do you really want to delete this staff?
    </ConfirmationAppDialog>
  )
}
