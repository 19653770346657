import { FC, useEffect, useMemo } from 'react'
import { Bill } from 'src/services'

// Components
import { ConfirmationAppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'
import {
  deleteAccountAction,
  deleteAccountResetAction,
  fetchCustomerAccountAction,
} from '../../../store/customerAccounts'

export interface DeleteAccountProps {
  onClose: () => void
  account: Bill
}

export const DeleteAccount: FC<DeleteAccountProps> = ({ onClose, account }) => {
  console.log(account)

  const dispatchAction = useAppDispatch()
  const {
    success: deleteAccountSuccess,
    error: deleteAccountError,
    message,
  } = useAppSelector((state) => state.deleteAccount)
  const status = useMemo(
    () => (account.status === 'QUOTATION' ?  'Quotation' : account.status === 'AGREEMENT' ? 'Agreement' : 'Account'),
    [account.status]
  )
  useEffect(() => {
    if (deleteAccountSuccess) {
      dispatchAction(
        showAlertAction({
          message: `${status} deleted successfully`,
          severity: 'success',
        })
      )
      dispatchAction(fetchCustomerAccountAction(account.status))
      onClose() // To close the popup
    } else if (deleteAccountError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onClose()
    }
  }, [deleteAccountSuccess, deleteAccountError, message])

  const deleteHandler = () => {
    dispatchAction(deleteAccountAction(account.id))
  }

  useEffect(() => {
    return () => {
      dispatchAction(deleteAccountResetAction()) // To manage the Alert
    }
  }, [])

  return (
    <ConfirmationAppDialog
      id="delete-item"
      title={`Delete ${status}: ${account.id}`}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onClose,
        },
        {
          text: 'Delete',
          color: 'error',
          variant: 'contained',
          onClickHandler: deleteHandler,
        },
      ]}
    >
      Do you really want to delete this {status}?
    </ConfirmationAppDialog>
  )
}
