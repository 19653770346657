import {
  Config,
  UserLoginData,
  UpdateSettingsAPIResponse,
} from '../../../services'
import { ActionTypes } from '../actions'

export type UpdateSettingsState = {
  loading: boolean
  success: boolean
  message?: string
  data?: Config | null
}

const initialState: UpdateSettingsState = {
  loading: false,
  success: false,
  message: null,
  data: null,
}

export const updateSettings = (
  state: UpdateSettingsState = initialState,
  action: { type: string; res: UpdateSettingsAPIResponse }
): UpdateSettingsState => {
  switch (action.type) {
    case `${ActionTypes.UPDATE_SETTINGS}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
      }
    case `${ActionTypes.UPDATE_SETTINGS}_SUCCESS`: {
      const { success, data, message } = action.res
      return {
        ...state,
        success,
        message,
        loading: false,
        ...(success && data && { data: data }),
      }
    }

    case `${ActionTypes.UPDATE_SETTINGS}_FAILURE`: {
      const { message } = action.res
      return {
        ...state,
        message,
        loading: false,
        success: false,
        data: null,
      }
    }

    case `${ActionTypes.UPDATE_SETTINGS}_RESET`: {
      return {
        ...state,
        message: null,
        loading: false,
        success: false,
        data: null,
      }
    }
  }
  return state
}
