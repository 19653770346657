import {
  Alert,
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { FC, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TaskRepeat, Task, Bill } from '../../../services'
import {
  AppDialog,
  FormInputTextField,
  FormInputDateField,
  FormInputDropdown,
  FormInputTextArea,
} from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  createNewTaskAction,
  resetCreateNewTaskAction,
  fetchTaskAction,
} from '../../../store/tasks'
import { showAlertAction } from '../../../store/alerts'

interface FormInputs {
  accountId?: number
  title: string
  date: Date
  description: string
  taskRepeat: TaskRepeat
}

const defaultValues = {
  accountId: null,
  title: '',
  date: new Date(),
  description: '',
  taskRepeat: TaskRepeat.NONE,
}

export interface CreateNewTaskProps {
  children?: React.ReactNode
  onClose?: () => void
  task?: Task
  account?: Bill
}

const options = [
  {
    label: 'NONE',
    value: 'NONE',
  },
  {
    label: 'DAILY',
    value: 'DAILY',
  },
  {
    label: 'WEEKLY',
    value: 'WEEKLY',
  },
  {
    label: 'MONTHLY',
    value: 'MONTHLY',
  },
  ,
  {
    label: 'YEARLY',
    value: 'YEARLY',
  }
]
const CreateNewTask: FC<CreateNewTaskProps> = ({ onClose, task, account }) => {
  const {
    success: createNewTaskSuccess,
    error: createNewTaskError,
    message,
  } = useAppSelector((state) => state.createNewTask)

  const dispatchAction = useAppDispatch()
   
  const { handleSubmit, reset, watch, control } = useForm<FormInputs>({
    defaultValues: {
      ...defaultValues,
      ...(account?.id && { accountId: account?.id }),
    },
    mode: 'all',
  })

  const [isClose, setClose] = useState<boolean>(false)
  const submitHandle = handleSubmit((data: FormInputs) => {
    const request = { ...data, date: data.date.toISOString() }
    dispatchAction(createNewTaskAction(request))
  })

  useEffect(() => {
    if (task) {
      const data = { ...task, date: new Date(task.date) }
      reset(data)
    }
  }, [task])

  useEffect(() => {
    if (createNewTaskSuccess) {
      dispatchAction(
        showAlertAction({
          message: `Task ${task?.id ? 'updated' : 'created'} successfully`,
          severity: 'success',
        })
      )
      dispatchAction(fetchTaskAction({}))
      setClose(true)
      onClose()
    }
    if (createNewTaskError) {
      dispatchAction(
        showAlertAction({
          message,
          severity: 'error',
        })
      )
    }
    dispatchAction(resetCreateNewTaskAction())
  }, [createNewTaskSuccess])

  return (
    <AppDialog
      id="create-task"
      title={!task ? 'Create Task' : 'Edit Task'}
      submitButtonText={!task ? 'Create New Task' : 'Edit'}
      onClose={onClose}
      close={isClose}
      onSubmit={submitHandle}
      maxWidth="sm"
    >
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={2}>
          {account?.id && (
            <Grid item xs={12}>
              <Alert icon={false} severity="info">
                You are creating the task for party name
                <Typography
                  sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
                  component={'span'}
                >
                  {' '}
                  {account?.customer?.name}{' '}
                </Typography>
                for site address{' '}
                <Typography
                  sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
                  component={'span'}
                >
                  {account?.siteAddress}
                </Typography>
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormInputTextField
              control={control}
              name={'title'}
              label={'Title'}
              rules={{ required: 'This is required' }}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <FormInputTextArea
              control={control}
              name={'description'}
              label={'Description'}
            />
          </Grid>

          <Grid item xs={12} mt={1}>
            <FormInputDateField
              control={control}
              name={'date'}
              label="Task Date"
              rules={{ required: 'This is required' }}
            />
          </Grid>
          <Grid item xs mt={2}>
            <FormInputDropdown
              control={control}
              name={'taskRepeat'}
              label="Task Repeat"
              options={options}
            />
          </Grid>
          {
            watch("taskRepeat") && (console.log(watch("taskRepeat")))

            
          }
          {watch("taskRepeat") !== "NONE" && (<Grid item xs={12} mt={1}>
            <FormInputDateField
              control={control}
              name={'endDate'}
              label="Repeat End Date"
              rules={{ required: 'This is required' }}
            />
          </Grid>)}
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
    </AppDialog>
  )
}

export default CreateNewTask
