import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, AccountTnc } from '../types'

export interface AccountTncAPIResponse extends Response {
  data: AccountTnc
}

export interface UpdateAccountTncAPIResponse extends Response {
  data: AccountTnc
}

export const updateAccountTnc = (accountTnc: AccountTnc) => {
  return http
    .post('/account_tnc/pvt', accountTnc)
    .then((response: AxiosResponse) => response)
}

export function getAccountTnc(accountId: number) {
  return http.get(`/account_tnc/pvt?account_id=${accountId}`).then((response: AxiosResponse) => response)
}

