import { FC, useEffect, Fragment, ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import PageHeader from './PageHeader'
import PageTitleWrapper from '../../../components/PageTitleWrapper'
import PageContainerWrapper from '../../../components/PageContainerWrapper'
import { Grid } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'

import Footer from '../../../components/Footer'
import RenderSelectedComponent from './RenderSelectedComponent'
import { useAppDispatch } from '../../../hooks'
import { fetchDiscountedCategoriesAction } from '../../../store/itemCategory'
import { CreateBillManager } from './CreateBillManager'
import CustomStepper from './CustomStepper'
export interface BillPros {
  children?: ReactNode
}
export let showOneDayDiscount = true
export let showDaysCount = true
const Bills: FC<BillPros> = () => {
  const dispatchAction = useAppDispatch()
  const { bill_id } = useParams()
  const { search } = useLocation()

  const query = new URLSearchParams(search)
  const plotArea = query ? Number(query.get('plot_area')) : 0
  const startDate = query.get('start_date')
  const endDate = query.get('end_date')
  const billType = query.get('bill_type')
  const page_title = query.get('page_title')
  const isFlateRate = query.get('is_flat_rate') === 'true'
  showOneDayDiscount =
    billType === 'MIX' || billType === 'PER_ITEM' || !isFlateRate
  showDaysCount =
    billType === 'MIX' ||
    billType === 'PER_ITEM' ||
    (!isFlateRate && plotArea == 0)
  useEffect(() => {
    if (bill_id) {
      dispatchAction(fetchDiscountedCategoriesAction(bill_id))
    }
  }, [bill_id, dispatchAction])

  return (
    <Fragment>
      <Helmet>
        <title>Bills</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader pageTitle={page_title} />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <CreateBillManager bill_id={+bill_id}>
              <CustomStepper />
              <RenderSelectedComponent
                plotArea={plotArea}
                startDate={startDate}
                endDate={endDate}
                billType={billType}
                isFlateRate={isFlateRate}
              />
            </CreateBillManager>
          </Grid>
        </Grid>
      </PageContainerWrapper>
      <Footer />
    </Fragment>
  )
}

export default Bills
