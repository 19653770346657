import { FC, useEffect, useRef, useState } from 'react';
import { Box, Grid, LinearProgress, Stack, Typography, Autocomplete, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AppDialog, Loader } from '../../../components';
import { getFormattedStartDateString, getFormattedEndDateString, http, setBillFormat, getBillFormat } from '../../../utils';
import { useAppDispatch } from '../../../../src/hooks';
import { createInvoices } from './Services';
import { showAlertAction } from '../../../../src/store/alerts';
import { InvoicesProgress } from './types';

export interface CreateInvoicesDialogProps {
  onClose?: (success?: boolean) => void;
}

const billFormatOptions = ["FORMAT_3", "FORMAT_2", "FORMAT_1"];

export const CreateInvoicesDialog: FC<CreateInvoicesDialogProps> = ({ onClose }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [format, setFormat] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<InvoicesProgress | null>(null);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [isClose, setClosePopup] = useState<boolean>(false);

  const dispatchAction = useAppDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    const today = new Date();
    setInvoiceDate(today);
    const currentDay = today.getDate();
    let prefillStartDate: Date;
    let prefillEndDate: Date;

    if (currentDay > 20) {
      prefillStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      prefillEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else {
      prefillStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      prefillEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
    }

    setStartDate(prefillStartDate);
    setEndDate(prefillEndDate);
    setFormat(getBillFormat())
  }, []);

  const handleStartDateChange = (value: Date | null) => {
    setStartDate(value);
    setError(null);
  };

  const handleInvoiceDateChange = (value: Date | null) => {
    setInvoiceDate(value);
    setError(null);
  };

  const handleEndDateChange = (value: Date | null) => {
    setEndDate(value);
    setError(null);
  };

  const handleBillFormatChange = (event: any, value: string | null) => {
    setFormat(value);
  };

  const stopPolling = () => {
    setLoading(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      startTimeRef.current = null;
    }
  };

  const handleSubmit = async () => {
    if (!startDate || !endDate || !invoiceDate || !format) {
      setError('Start date, end date, invoice date, and bill format are required.');
      return;
    }

    const startDateString = getFormattedStartDateString(new Date(startDate), false);
    const endDateString = getFormattedEndDateString(new Date(endDate), false);
    const invoiceDateString = getFormattedEndDateString(new Date(invoiceDate), false);
    try {
      startPolling();
      setShowProgress(true);
      setBillFormat(format)
      createInvoices(startDateString, endDateString, invoiceDateString, format);
    } catch (error) {
      setLoading(false);
      dispatchAction(showAlertAction({
        message: 'Error occurred while creating invoices',
        severity: 'error',
      }));
    }
  };

  const startPolling = () => {
    setLoading(true);
    const fetchProgress = async () => {
      try {
        const response = await getProgressInPercentage();
        const progressData = response.data;
        setProgress(progressData);
        if(progressData && progressData.progress === 100) {
            stopPolling()
            onClose(true)
        }
      } catch (error) {
        stopPolling()
        console.error('Error fetching progress:', error);
      }
    };

    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchProgress, 1000);
      startTimeRef.current = Date.now(); // Save the start time
    }

    // Stop polling after 10 minutes
    const checkTimeLimit = () => {
      const currentTime = Date.now();
      if (startTimeRef.current && currentTime - startTimeRef.current >= 10 * 60 * 1000) {
        stopPolling();
      }
    };

    const timeLimitChecker = setInterval(checkTimeLimit, 1000);

    // Cleanup polling on component unmount or dialog close
    return () => {
      stopPolling();
      clearInterval(timeLimitChecker);
    };
  };

  // Stop polling when the dialog is closed
  useEffect(() => {
    return () => stopPolling();
  }, [isClose, onClose]);

  return (
    <AppDialog
      close={isClose}
      id="create-invoices-dialog"
      title={'Create Bulk Invoices'}
      submitButtonText="Create"
      onClose={() => {
        stopPolling();  // Stop polling when dialog closes
        if (onClose) onClose();  // Trigger external onClose if provided
      }}
      onSubmit={handleSubmit}
      maxWidth="sm"
    >
      {loading && <Loader />}
      <Grid container spacing={3}>
        {showProgress && (  // Show progress bar only after polling starts
          <Grid item xs={12}>
            <Stack direction="row" alignItems="start" spacing={2}>
              <Box display="flex" flexDirection="column" alignItems="center" width={'100%'}>
                <Typography variant="body2" mb={1}>
                  Creating invoices {(progress != null && progress != undefined) ? `(${progress.complete}/${progress.total})` : ''}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={progress ? progress.progress : 0}
                  sx={{ height: 8, borderRadius: 5, width:'100%' }}
                  
                />
              </Box>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1">
            Please select start and end date to create invoices. This will create invoices for all open sites if an invoice does not already exist for that period.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label="Invoice Date"
            value={invoiceDate}
            onChange={handleInvoiceDateChange}
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                variant: 'outlined',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                variant: 'outlined',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                variant: 'outlined',
              },
            }}
          />
        </Grid>
       
        <Grid item xs={12}>
          <Autocomplete
            size='small'
            options={billFormatOptions}
            getOptionLabel={(option) => option}
            value={format}
            onChange={handleBillFormatChange}
            renderInput={(params) => <TextField {...params} label="Bill Format" fullWidth />}
          />
          {format === 'FORMAT_3' && (          
          <Typography sx={{ mt:1, fontWeight:'bold', color:'#edb95e'}}>FORMAT_3 is only suitable for PER ITEM per day rent type of bills, Please do not use this format with PLATE AREA and SQFT type bills.</Typography>
          )}

        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
      </Grid>
    </AppDialog>
  );
};

export default CreateInvoicesDialog;

export function getProgressInPercentage() {
  return http.get(`/interim_bill/pvt/progress`).then((response) => response.data);
}
