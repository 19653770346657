import { getGSTNDetails } from '../../../services'
import { fetchGSTR3BJson } from '../../../services/gstr'

export const ActionTypes = {
  FETCH_GSTR3B_JSON: 'FETCH_GSTR3B_JSON',
  FETCH_GSTIN_DETAILS: 'FETCH_GSTIN_DETAILS',
}

export const fetchGSTR3bReportAction = (from: string, to: string) => ({
  type: ActionTypes.FETCH_GSTR3B_JSON,
  promise: fetchGSTR3BJson(from, to),
})

export const fetchGSTR3bReportResetAction = () => ({
  type: `${ActionTypes.FETCH_GSTR3B_JSON}_RESET`,
})

export const fetchGSTINAction = (gstin: string) => ({
  type: ActionTypes.FETCH_GSTIN_DETAILS,
  promise: getGSTNDetails(gstin),
})

export const fetchGSTINResetAction = () => ({
  type: `${ActionTypes.FETCH_GSTIN_DETAILS}_RESET`,
})
