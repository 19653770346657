import {
  DiscountedCategories,
  FetchDiscountedCategoriesResponse,
} from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState } from '../../../services/types'

export interface DiscountedCategoriesState {
  data: DiscountedCategories
}

export type ManageDiscountedCategoresState = BaseReducerState &
  DiscountedCategoriesState

const initialState: ManageDiscountedCategoresState = {
  loading: false,
  success: false,
  message: '',
  data: {
    selectedCategories: [],
    categories: [],
  },
}

export const manageDiscountedCategories = (
  state: ManageDiscountedCategoresState = initialState,
  action: { type: string; res: FetchDiscountedCategoriesResponse }
): ManageDiscountedCategoresState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_CATEGORIES}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
      }

    case `${ActionTypes.FETCH_CATEGORIES}_SUCCESS`:
      const { success, data, message } = <FetchDiscountedCategoriesResponse>(
        action.res
      )
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    case `${ActionTypes.FETCH_CATEGORIES}_FAILURE`:
      return {
        ...initialState,
        loading: false,
        success: false,
        message: message,
      }
      
      case `${ActionTypes.FETCH_CATEGORIES}_RESET`:
        return {
          ...initialState
        }
  }
  return state
}
