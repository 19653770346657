import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response } from '../types'

export enum TaskRepeat {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export type Task = {
  id: number
  date: string
  isDeleted: boolean
  title: string
  description: string
  accountId: number
  acDisplayNo: number
  siteAddress: string
  taskRepeat: TaskRepeat
}

export type CreateNewTaskRequest = {
  id?: number
  accountId?: number
  date: string
  title: string
  description: string
  taskRepeat: TaskRepeat
}

export type FetchTasksRequest = {
  account_id?: number
  date?: string
}

export interface CreateNewTaskResponse extends Response {
  data: Task
}

export interface FetchTasksResponse extends Response {
  data: Task[]
}

export type DeleteTaskResponse = Response

export const createNewTask = (request: CreateNewTaskRequest) => {
  return http
    .post<CreateNewTaskRequest>('/task/pvt', request)
    .then((response: AxiosResponse) => response)
}

export function fetchTasks(request: FetchTasksRequest) {
  const { date, account_id } = request
  let queryString = '?'
  if (account_id) {
    queryString += '&account_id=' + account_id
  }
  if (date) {
    queryString += '&date=' + date
  }
  return http
    .get(`/task/pvt${queryString}`)
    .then((response: AxiosResponse) => response)
}

export const deleteTask = (id: number) => {
  return http
    .delete(`/task/pvt?id=${id}`)
    .then((response: AxiosResponse) => response)
}

export const updateTaskStatus = (id: number, status: string) => {
  return http
    .get(`/task/pvt?id=${id}&status=${status}`)
    .then((response: AxiosResponse) => response)
}
