export const ActionTypes = {
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',
}

export const showAlertAction = (payload) => ({
  type: ActionTypes.SHOW_ALERT,
  payload,
})

export const hideAlertAction = () => ({
  type: ActionTypes.HIDE_ALERT,
})
