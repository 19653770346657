import { AxiosResponse } from "axios";
import { getMinDuePcs, getMinTotalDue, getNoActivityDays, getNoPaymentDays, getSortBy, http } from "../../../../src/utils";

export function fetchAccountsPdf(
    status: string
) {

    let url = `/bill/pvt/accounts_pdf/${status}`
    const params = new URLSearchParams();
    const noPaymentDays = getNoPaymentDays()
    const noActivity = getNoActivityDays()
    const minTotalDue = getMinTotalDue()
    const minDuePcs = getMinDuePcs()
    const sortBy = getSortBy()

    if (sortBy != null && sortBy != '') {
        params.append('sort_type', sortBy.toString());
    }
    if (noPaymentDays != null) {
        params.append('no_payment_days', noPaymentDays.toString());
    }
    if (noActivity != null) {
        params.append('no_activity_days', noActivity.toString());
    }
    if (minTotalDue != null) {
        params.append('min_total_due', minTotalDue.toString());
    }
    if (minDuePcs != null) {
        params.append('min_due_pcs', minDuePcs.toString());
    }

    // If there are params, append them to the URL
    if (params.toString()) {
        url += `?${params.toString()}`;
    }
    return http
        .get(
            url
        )
        .then((response: AxiosResponse) => response.data)
}