import { Typography, Grid, Button, TextField } from '@mui/material'
import { FC, ReactNode, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Search, SlipsScreenType } from '../../../../components'
import { useAppDispatch } from '../../../../hooks'
import { searchSlipsAction } from '../../../../store/slips'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

export interface QuotationHeaderProps {
  refr: any
  onExportPdf: () => void
  pageTitle: string
}

export const PageHeader: FC<QuotationHeaderProps> = ({
  refr,
  onExportPdf,
  pageTitle
}) => {
  const dispatchAction = useAppDispatch()
  const [date, setDate] = useState<Date | null>(() => new Date())
  const handleSearch = (searchTerm) => {
    dispatchAction(searchSlipsAction(searchTerm))
  }

  return (
    <Grid container alignItems="flex-start">
      <Grid item xs>
        <Typography variant="h3" component="h3" pl={2.5}>
          {pageTitle}
        </Typography>
      </Grid>
      <Grid item sx={{ width: 500 }}></Grid>
      <Grid item sx={{ width: 190, textAlign: 'right' }}>
       
          <Button
            ref={refr}
            startIcon={<PictureAsPdfIcon />}
            variant="contained"
            sx={{ backgroundColor: '#eb627a', height: 41.5, width: 180 }}
            onClick={onExportPdf}
          >
            EXPORT PDF
          </Button>
      </Grid>
    </Grid>
  )
}

export default PageHeader
