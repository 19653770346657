import { FC, useEffect, useState } from 'react'

// Components
import { AppDialog } from '../../../components'
import CreateBillPopup from '../Bill/CreateBillPopup'
import { Bill } from '../../../services'
// import { useAppDispatch, useAppSelector } from '../../../hooks'

export interface CreateMonthlyBillProps {
  onClose: () => void
  account: Bill
}

export const CreateBill: FC<CreateMonthlyBillProps> = ({
  onClose,
  account,
}) => {
  // const dispatchAction = useAppDispatch()
  // const { success, message } = useAppSelector(
  //   (state) => state.createMonthlyBill
  // )

  const [isClose] = useState<boolean>(false)

  const submitHandle = () => {}
  return (
    <AppDialog
      id="edit-item"
      title={'Create Bill'}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="md"
      dialogActions={false}
    >
      <CreateBillPopup bill={account} onClose={onClose} />
    </AppDialog>
  )
}
