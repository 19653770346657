import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response } from '../types'

export interface SubscriptionPlan {
  id: number
  title: string
  description: string
  price: number
  creditPoints: number
  discountedPrice: number
  duration: number
  percentageDiscount: number
  durationDiscount: number
  isActive: true
}

export interface SubscriptionPlanAPIResponse extends Response {
  data: Array<SubscriptionPlan>
  monthly_billing: number
}

export function createGetSubscriptionPlans(monthly_billing: number) {
  let endpoint = `/subscription/pvt/plans?monthly_billing=${monthly_billing}`
  if (process.env.OFFLINE) {
    endpoint =
      process.env.PAYMENT_API_BASE_URL +
      `subscription/pbc/plans?monthly_billing=${monthly_billing}`
  }
  return http.get(endpoint).then((response: AxiosResponse) => response)
}

export type SubscribePlanRequest = {
  monthly_billing: number
  plan_id: number
  email?: string
  hardware_id?: string
}
export interface SubscribePlanResponse extends Response {
  data: {
    id: number
    planPrice: number
    planDiscountedPrice: number
    planId: number
    orderAmount: number
    userId: number
    userName: string
    userPhone: string
    storeId: number
    storeName: string
    currency: string
    orderStatus: string
    paymentStatus: string
    orderToken: string
    cfOrderId: number
    gatewayOrderId: string
    cfOrderStatus: string
    appId: string
    creditPoints: number
  }
  debugData: {
    orderToken: string
    gatewayOrderId: string
    orderStatus: string
    createdAt: string
  }
}

export function createSubscribePlan(request: SubscribePlanRequest) {
  const { plan_id, monthly_billing, email, hardware_id } = request
  let endpoint = `/subscription/pvt/subscribe?plan_id=${plan_id}&monthly_billing=${monthly_billing}`
  if (process.env.OFFLINE) {
    endpoint =
      process.env.PAYMENT_API_BASE_URL +
      `subscription/pbc/subscribe?plan_id=${request.plan_id}&monthly_billing=${request.monthly_billing}&email=${email}&hardware_id=${hardware_id}`
  }
  return http
    .get<SubscribePlanResponse>(endpoint)
    .then((response: AxiosResponse) => response.data)
}

export type VerifyPlanRequest = {
  payment_id: number
  signature: number
}

export function createVerifySubscribedPlan(request: VerifyPlanRequest) {
  let endpoint = `/subscription/pvt/verify?payment_id=${request.payment_id}&signature=${request.signature}`
  if (process.env.OFFLINE) {
    endpoint =
      process.env.PAYMENT_API_BASE_URL +
      `subscription/pbc/verify?payment_id=${request.payment_id}&signature=${request.signature}`
  }
  return http.get(endpoint).then((response: AxiosResponse) => response.data)
}

export type ActivateActivationKeyRequest = {
  activation_key: string
}

export function createActiveActivationKey(
  request: ActivateActivationKeyRequest
) {
  return http
    .get(
      `/subscription/pvt/offline_activation?activation_key=${request.activation_key}`
    )
    .then((response: AxiosResponse) => response.data)
}
