import {
  fetchBillById,
  fetchBillbBreakup,
  generateBill,
  GenerateBillRequest,
  postMerchantEnteredDays,
  deleteMerchantEnteredDays,
  MerchantEnteredDays,
  SelectOptions,
  getAccountDetails,
} from '../../../services'

export const ActionTypes = {
  FETCH_BILL_BY_ID: 'FETCH_BILL_BY_ID',
  FETCH_LEFT_ITEMS: 'FETCH_LEFT_ITEMS',
  FETCH_BILL_BREAKUP_BY_ID: 'FETCH_BILL_BREAKUP_BY_ID',
  GENERATE_BILL: 'GENERATE_BILL',
  GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
  POST_MERCHANT_ENTERED_DAYS: 'POST_MERCHANT_ENTERED_DAYS',
  DELETE_MERCHANT_ENTERED_DAYS: 'DELETE_MERCHANT_ENTERED_DAYS',
  UPDATE_ITEM_SUBMIT_SELECT_OPTIONS: 'UPDATE_ITEM_SUBMIT_SELECT_OPTIONS',
}

export const fetchBillbyIdAction = (bill_id: number) => ({
  type: ActionTypes.FETCH_BILL_BY_ID,
  promise: fetchBillById(bill_id),
})

export const resetBillDataAction = () => ({
  type: `${ActionTypes.FETCH_BILL_BY_ID}_RESET`,
})

export const getAccountDetailsAction = (bill_id: number) => ({
  type: ActionTypes.GET_ACCOUNT_DETAILS,
  promise: getAccountDetails(bill_id),
})

export const getAccountDetailsResetAction = () => ({
  type: `${ActionTypes.GET_ACCOUNT_DETAILS}_RESET`,
})

export const fetchBillBreakupByIdAction = (
  bill_id: number,
  sqftStartDate: string,
  sqftEndDate: string
) => ({
  type: ActionTypes.FETCH_BILL_BREAKUP_BY_ID,
  promise: fetchBillbBreakup(bill_id, sqftStartDate, sqftEndDate),
})

export const generateBillAction = (request: GenerateBillRequest) => ({
  type: ActionTypes.GENERATE_BILL,
  promise: generateBill(request),
})

export const generateBillResetAction = () => ({
  type: `${ActionTypes.GENERATE_BILL}_RESET`,
})

export const postMerchantEnteredDaysAction = (
  request: Array<MerchantEnteredDays>
) => ({
  type: ActionTypes.POST_MERCHANT_ENTERED_DAYS,
  promise: postMerchantEnteredDays(request),
})

export const postMerchantEnteredDaysResetAction = () => ({
  type: `${ActionTypes.POST_MERCHANT_ENTERED_DAYS}_RESET`,
})

export const deleteMerchantEnteredDaysAction = (bill_id: number) => ({
  type: ActionTypes.DELETE_MERCHANT_ENTERED_DAYS,
  promise: deleteMerchantEnteredDays(bill_id),
})

export const deleteMerchantEnteredDaysResetAction = () => ({
  type: `${ActionTypes.DELETE_MERCHANT_ENTERED_DAYS}_RESET`,
})

/**
 * This Action will be dispatched to update the dropdown when item is being submitted
 * @param options
 * @returns
 */
export const updateSelectOptionsAction = (options) => ({
  type: ActionTypes.UPDATE_ITEM_SUBMIT_SELECT_OPTIONS,
  res: options,
})

export const prepareSelectOptions = (data) => {
  const selectOptions = data?.enteries?.map((item): SelectOptions => {
    return {
      label: item.itemName,
      id: item.id,
      rate: item.rate,
      availableQuantity: item.availableQuantity,
      category: item.category.name,
      outAreaEntryId: item.outAreaEntryId,
      height: item.height,
      width: item.width,
      length: item.length,
      isArea: item.isArea,
    }
  })
  return selectOptions?.length > 0 ? selectOptions : []
}

const uniqeStringFromItem = (item) =>
  item.item.id +
  '_' +
  item.rate +
  (item.outAreaEntryId ? '_' + item.outAreaEntryId : '')
const uniqeStringFromOption = (item) =>
  item.id +
  '_' +
  item.rate +
  (item.outAreaEntryId ? '_' + item.outAreaEntryId : '')
export const syncSelectOptionsAction =
  (items = []) =>
  (dispatch, getState) => {
    const { data } = getState().manageBills
    let selectOptions = prepareSelectOptions(data)
    if (items?.length) {
      const itemIds = items.map((item) => uniqeStringFromItem(item))
      selectOptions = selectOptions.reduce((accumulator, currentValue) => {
        if (itemIds.includes(uniqeStringFromOption(currentValue))) {
          return accumulator
        } else {
          return [...accumulator, currentValue]
        }
      }, [])
    }
    dispatch({
      type: ActionTypes.UPDATE_ITEM_SUBMIT_SELECT_OPTIONS,
      res: selectOptions,
    })
  }
