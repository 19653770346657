export const getAccessToken = (): string => window.localStorage.getItem('token')
export const getBillFormat = (): string => window.localStorage.getItem('bill_format')

export const setBillFormat = async (format: string) => window.localStorage.setItem('bill_format', format)
export const setAccessToken = async (token: string) =>
  window.localStorage.setItem('token', token)
export const removeToken = () => window.localStorage.removeItem('token')

export const isLoggedIn = (): boolean => !!getAccessToken()

export const getSlipsCheckBoxValue = (): boolean =>
  window.localStorage.getItem('slips_checkbox') !== 'no'
export const setSlipsCheckboxValue = async (checked: boolean) =>
  window.localStorage.setItem('slips_checkbox', checked ? 'yes' : 'no')

export const getDefaultGstType = (): string =>
  window.localStorage.getItem('gst_type') ?? '0'
export const setDefaultGSTType = async (checked: string) =>
  window.localStorage.setItem('gst_type', checked)

export const getBalanceItemsCheckBoxValue = (): boolean =>
  window.localStorage.getItem('balance_items_checkbox') !== 'no'
export const setBalanceItemsCheckBoxValue = async (checked: boolean) =>
  window.localStorage.setItem('balance_items_checkbox', checked ? 'yes' : 'no')

export const getSelectedStoreId = (): string =>
  window.localStorage.getItem('selectedStore')
export const setSelectedStoreId = (selectedStoreId: string) =>
  window.localStorage.setItem('selectedStore', selectedStoreId)
export const removeSelectedStoreId = () =>
  window.localStorage.removeItem('selectedStore')

export const getNoPaymentDays = (): string => window.sessionStorage.getItem('noPaymentDays')
export const setNoPaymentDays =  (noPaymentDays: string) =>  window.sessionStorage.setItem('noPaymentDays', noPaymentDays)

export const getNoActivityDays = (): string => window.sessionStorage.getItem('noActivityDays')
export const setNoActivityDays =  (noActivityDays: string) =>  window.sessionStorage.setItem('noActivityDays', noActivityDays)

export const getMinTotalDue = (): string => window.sessionStorage.getItem('minTotalDue')
export const setMinTotalDue =  (minTotalDue: string) =>  window.sessionStorage.setItem('minTotalDue', minTotalDue)

export const getMinDuePcs = (): string => window.sessionStorage.getItem('minDuePcs')
export const setMinDuePcs =  (minDuePcs: string) =>  window.sessionStorage.setItem('minDuePcs', minDuePcs)

export const getSortBy = (): string => window.sessionStorage.getItem('sortBy')
export const setSortBy =  (sortBy: string) =>  window.sessionStorage.setItem('sortBy', sortBy)
