import { useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'

import SubscribePlan from './SubscribePlan'
import ActivateKey from './ActivateKey'
import FormLabel from '@mui/material/FormLabel'
enum ActivationKeyStatus {
  Yes = 'yes',
  No = 'no',
}
const ActivateAndSuscription = () => {
  const [activationKeyStatus, setActivationKeyStatus] =
    useState<ActivationKeyStatus>(ActivationKeyStatus.No)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivationKeyStatus(event.target.value as unknown as ActivationKeyStatus)
  }

  return (
    <>
      <Box pl={3} pr={3} pb={10} pt={3}>
        <FormControl variant="standard">
          <FormLabel id="demo-controlled-radio-buttons-group">
            You must need to buy activation key.
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={activationKeyStatus}
            onChange={handleChange}
          >
            <FormControlLabel
              value={ActivationKeyStatus.No}
              control={<Radio />}
              label="Buy new activation key"
            />
            <FormControlLabel
              value={ActivationKeyStatus.Yes}
              control={<Radio />}
              label="I have activation key"
            />
          </RadioGroup>
        </FormControl>
        {activationKeyStatus === ActivationKeyStatus.No && <SubscribePlan />}
        {activationKeyStatus === ActivationKeyStatus.Yes && <ActivateKey />}
      </Box>
    </>
  )
}
export default ActivateAndSuscription
