import { FC } from 'react'
import {
  Grid,
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import { Charges } from '../../../services'

type PreviewAddOtherChargesProps = {
  charge: Charges
  deleteCharge: () => void
}

const PreviewAddOtherCharges: FC<PreviewAddOtherChargesProps> = ({
  charge,
  deleteCharge,
}) => {
  function getId(field: string) {
    return `id_${charge?.chargeTitle}_${charge.amount}_${field}`
  }
  return (
    <Grid container spacing={2} xs={12}>
      <Grid
        item={true}
        xs={12}
        md={4.9}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography data-cy={getId('title')} ml={1}>
          {charge?.chargeTitle}
        </Typography>
      </Grid>
      <Grid
        item={true}
        xs={12}
        md={4.9}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography ml={1.5} data-cy={getId('amount')}>
          {charge?.amount}
        </Typography>
      </Grid>

      <Grid item xs={12} md={2.2} textAlign="right">
        <Box>
          <IconButton
            data-cy={getId('button_delete')}
            sx={{
              backgroundColor: 'rgb(255, 0, 0, 0.2)',
              color: 'crimson',
              mt: 0.7,
              mb: 0.7,
            }}
            onClick={deleteCharge}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PreviewAddOtherCharges
