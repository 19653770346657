import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import SitesRentReportHeader from './PageHeader';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper } from '@mui/material';
import { currencyFormatter } from '../../../utils';
import { tableStyles } from '../Reports';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    borderRight: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
    textAlign: 'left', // Left align content
  },
  tableHead: {
    backgroundColor: '#efeff0 !important',
    textAlign: 'left', // Left align header
  },
  sumRow: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
  },
}));

export interface Bill {
  id: number;
  total: number;
  lostItemsCost: number;
  otherCharges: number;
  customer: Customer;
  siteAddress: string;
}

export interface Customer {
  name: string;
  mobile: string;
}

export interface SitesRentReportProps {}

export const SitesRentReport: FC<SitesRentReportProps> = ({}) => {
  const classes = tableStyles();
  const dispatchAction = useAppDispatch();

  const { data: bills } = useAppSelector(
    (state) => state.sitesRentReportState
  );

  // Calculate sum totals

  return (
    <>
      <Helmet>
        <title>Billing Report</title>
      </Helmet>
      <PageTitleWrapper>
        <SitesRentReportHeader />
      </PageTitleWrapper>
      <PageContainerWrapper>
        
        <Table aria-label="billing report">
          <TableHead>
            <TableRow>
              <TableCell>S.N.</TableCell> {/* Serial Number column */}
              <TableCell className={classes.tableCell}>Party Name</TableCell>
              <TableCell className={classes.tableCell}>Site Address</TableCell>
              <TableCell className={classes.tableCell}>AC No</TableCell>
              <TableCell className={classes.tableCell}>Mobile</TableCell>
              <TableCell className={classes.tableCell}>Rent</TableCell>
              <TableCell className={classes.tableCell}>Lost/Damaged</TableCell>
              <TableCell className={classes.tableCell}>Other</TableCell>
              <TableCell className={classes.tableCell}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bills && bills.map((bill, index) => (
              <TableRow key={bill.id}>
                <TableCell>{index + 1}</TableCell> {/* Increment serial number */}
                <TableCell className={classes.tableCell}>{bill.customer.name}</TableCell>
                <TableCell className={classes.tableCell}>{bill.siteAddress}</TableCell>
                <TableCell className={classes.tableCell}>{bill.id}</TableCell>
                <TableCell className={classes.tableCell}>{bill.customer.mobile}</TableCell>
                <TableCell className={classes.tableCell}>
                  {currencyFormatter.format(bill.totalRentWithoutConsumable)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {currencyFormatter.format(bill.lostItemsCost)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {currencyFormatter.format(bill.consumableTotal)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {currencyFormatter.format(bill.total)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PageContainerWrapper>
    </>
  );
};

export default SitesRentReport;
