import {
  addCustomer,
  fetchCustomers,
  AddCustomerRequest,
  deleteCustomer,
  fetchCustomersV2,
} from '../../../services'

export const ActionTypes = {
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  FETCH_CUSTOMERS: 'FETCH_CUSTOMERS',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  CUTOMER_SEARCH_RESULTS: 'CUTOMER_SEARCH_RESULTS',
}

export const addCustomerAction = (customer: AddCustomerRequest) => ({
  type: ActionTypes.ADD_CUSTOMER,
  promise: addCustomer(customer),
})

export const fetchCustomersAction = () => ({
  type: ActionTypes.FETCH_CUSTOMERS,
  promise: fetchCustomers(),
})

export const fetchCustomersActionV2 = () => ({
  type: ActionTypes.FETCH_CUSTOMERS,
  promise: fetchCustomersV2(),
})

export const addCustomerResetAction = () => ({
  type: `${ActionTypes.ADD_CUSTOMER}_RESET`,
})

export const deleteCustomerAction = (id: number) => ({
  type: ActionTypes.DELETE_CUSTOMER,
  promise: deleteCustomer(id),
})

export const deleteCustomerResetAction = () => ({
  type: `${ActionTypes.DELETE_CUSTOMER}_RESET`,
})

// Search fucntion
/**
 *
 * @param searchTerm
 * @param data
 * @returns
 */
export const searchCustomer = (searchTerm, data) => {
  let searchResults = []
  if (data?.length) {
    searchResults = data.filter((customer) => {
      const { address, mobile, name } = customer
      if (
        address?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        mobile?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        name?.toLowerCase().includes(searchTerm?.toLowerCase())
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return searchResults
}

/**
 *
 * @param searchTerm
 * @returns
 */
export const searchCustomesAction = (searchTerm) => {
  return (dispatch, getState) => {
    const { list } = getState().manageCustomers
    const { data } = list
    const searchResults = searchCustomer(searchTerm, data)
    dispatch({
      type: ActionTypes.CUTOMER_SEARCH_RESULTS,
      res: { data: searchResults, searchTerm },
    })
  }
}
