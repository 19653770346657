import { Box } from '@mui/material'
import { AppAlerts, FullScreenDialog } from '../../../../components'
import { useAppSelector, useAppDispatch } from '../../../../hooks'
import ActivateAndSuscription from './ActivateAndSuscription'
import SubscribePlan from './SubscribePlan'
import { hideSubscribePopupAction } from '../../../../store/subscriptionPlan'

const SubscriptionPopup = () => {
  const { show } = useAppSelector((state) => state.manageSubscribePopup)
  const dispatchAction = useAppDispatch()
  const onclosePopup = () => dispatchAction(hideSubscribePopupAction())
  return (
    <>
      {show ? (
        <FullScreenDialog
          title={'Buy a Plan'}
          show={show}
          onclose={onclosePopup}
        >
          <Box
            sx={{
              margin: 'auto',
              width: '50%',
              height: 'auto',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            {process.env.OFFLINE ? (
              <ActivateAndSuscription />
            ) : (
              <SubscribePlan />
            )}
          </Box>
          <AppAlerts />
        </FullScreenDialog>
      ) : null}
    </>
  )
}
export default SubscriptionPopup
