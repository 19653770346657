import { FC, Fragment, useState } from 'react'
import {
  Autocomplete,
  Divider,
  Grid,
  List,
  TextField,
  Typography,
} from '@mui/material'
import { Item, ItemCategory } from '../../../services'
import { Box } from '@mui/system'
import SelectedStockItem from './StockInputItem'
import { Empty } from '../../../components'

export interface StockInputItemsProps {
  categories: ItemCategory[]
}

const StockInputItems: FC<StockInputItemsProps> = ({ categories }) => {
  const rentTypes = [
    { id: 0, label: 'Per Pc' },
    { id: 1, label: 'Running Meter' },
  ]
  const [rentType, setRentType] = useState<null | any>(rentTypes[0])
  let hasItems = false
  return (
    <>
      <Box
        sx={{
          width: 800,
          overflowY: 'auto',
          maxWidth: 800,
          height: '100%',
          bgcolor: 'background.paper',
          border: 0.5,
          borderColor: 'lightgray',
        }}
      >
        <List>
          {categories?.map((category) => {
            return (
              <>
                {category.items?.map((item) => {
                  hasItems =
                    hasItems || item.isSelected == true || category.isSelected
                  return (
                    <>
                      {(item.isSelected == true || category.isSelected) && (
                        <Fragment key={item.itemName}>
                          <SelectedStockItem item={item} />{' '}
                        </Fragment>
                      )}
                    </>
                  )
                })}
              </>
            )
          })}
          {!hasItems && (
            <Empty>
              <Grid container xs={12} direction={'column'}>
                <Grid
                  xs={1}
                  item
                  sx={{
                    display: 'flex',
                    flex: 1,
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    height: 60,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 24,
                      pl: 20,
                      pr: 20,
                      pt: 0,
                      fontWeight: 'bold',
                    }}
                  >
                    No Item Selected
                  </Typography>
                </Grid>
                <Grid xs={2} item sx={{ height: 60 }}>
                  <Typography
                    sx={{
                      fontSize: 20,
                      pl: 10,
                      pr: 10,
                      textAlign: 'center',
                      pt: 2,
                    }}
                  >
                    {' '}
                    No worries you can create your stock later on. To continue,
                    press Next or back to select stock items.{' '}
                  </Typography>
                </Grid>
              </Grid>
            </Empty>
          )}
        </List>
      </Box>
    </>
  )
}

export default StockInputItems
