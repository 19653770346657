import {
  CreateNewTaskRequest,
  createNewTask,
  fetchTasks,
  FetchTasksRequest,
  deleteTask,
} from '../../../services'

export const ActionTypes = {
  CREATE_NEW_TASK: 'CREATE_NEW_TASK',
  FETCH_TASKS: 'FETCH_TASKS',
  DELETE_TASK: 'DELETE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  TASK_SEARCH_RESULTS: 'TASK_SEARCH_RESULTS',
}

export const createNewTaskAction = (task: CreateNewTaskRequest) => ({
  type: ActionTypes.CREATE_NEW_TASK,
  promise: createNewTask(task),
})

export const resetCreateNewTaskAction = () => ({
  type: `${ActionTypes.CREATE_NEW_TASK}_RESET`,
})

export const fetchTaskAction = (request: FetchTasksRequest) => ({
  type: ActionTypes.FETCH_TASKS,
  promise: fetchTasks(request),
})

export const deleteTaskAction = (id: number) => ({
  type: ActionTypes.DELETE_TASK,
  promise: deleteTask(id),
})

export const deleteTaskResetAction = () => ({
  type: `${ActionTypes.DELETE_TASK}_RESET`,
})

// Search fucntion
/**
 *
 * @param searchTerm
 * @param data
 * @returns
 */
export const searchTask = (searchTerm, data) => {
  let searchResults = []
  if (data?.length) {
    searchResults = data.filter((task) => {
      const { title, description } = task
      if (
        title?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        description?.toLowerCase().includes(searchTerm?.toLowerCase())
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return searchResults
}

/**
 *
 * @param searchTerm
 * @returns
 */
export const searchTaskAction = (searchTerm) => {
  return (dispatch, getState) => {
    const { data } = getState().fetchTasks
    const searchResults = searchTask(searchTerm, data)
    dispatch({
      type: ActionTypes.TASK_SEARCH_RESULTS,
      res: { data: searchResults, searchTerm },
    })
  }
}
