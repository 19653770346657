import { FC, useState, useReducer, useEffect, useRef } from 'react'
import { Grid, TextField, Button, Box } from '@mui/material'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'

import { Charges } from '../../../services'
import CustomNumberField from '../Users/profile/CustomNumberField'

type AddOtherChargesFields = 'amount' | 'chargeTitle'
type AddOtherChargesState = Record<
  AddOtherChargesFields,
  Partial<{
    value: string
    error: boolean
    helperText: string
  }>
>

type AddOtherChargesError = Record<
  AddOtherChargesFields,
  Record<string, string>
>

const addOtherChargesError: AddOtherChargesError = {
  amount: {
    empty: 'Please some amount',
  },
  chargeTitle: {
    empty: 'Please enter charge title',
  },
}

const initialAddOtherChargesState: AddOtherChargesState = {
  amount: {
    error: false,
    helperText: '',
  },
  chargeTitle: {
    error: false,
    helperText: '',
  },
}
const addOtherChargesReducers = (
  state: Partial<AddOtherChargesState>,
  action
) => {
  const {
    type,
    payload: { name, value },
    helperText,
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: helperText
            ? helperText
            : initialAddOtherChargesState[name]?.helperText,
        },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: addOtherChargesError[name]['empty'],
        },
      }

    case 'RESET':
      return {
        ...initialAddOtherChargesState,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: initialAddOtherChargesState[name]?.helperText,
        },
      }
    default:
      return state
  }
}

type AddOtherChargesProps = {
  dummyCharge?: Charges
  addOtherCharge: (item: Charges) => void
}


const AddOtherCharges: FC<AddOtherChargesProps> = ({
  dummyCharge,
  addOtherCharge,
}) => {
  const [charge, setCharge] = useState<Charges | null>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [addOtherChargesData, dispatch] = useReducer(
    addOtherChargesReducers,
    initialAddOtherChargesState
  )


const handleKeyUp = (event) => {
  if (event.keyCode === 13) {
    // Enter key
    adOtherChargesHandler()
    event.preventDefault()
    event.stopPropagation()
  }
}

  useEffect(() => {
    setCharge(dummyCharge)
  }, [dummyCharge])

  const adOtherChargesHandler = () => {
    let error = false

    if (!charge?.chargeTitle) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'chargeTitle', value: null } })
    }
    if (!charge?.amount) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'amount', value: null } })
    }
    if (!error) {
      if (inputRef.current) {
        inputRef.current.focus() // Set focus to the text field
      }
      addOtherCharge(charge)
      setCharge((charge) => {
        return {
          amount: null,
          chargeTitle: null,
        }
      })
    }
  }
  const onInputChange = (e) => {
    const { name, value } = e.target
    setCharge((charge) => {
      return {
        ...charge,
        [name]: value,
      }
    })
    if (value && name) {
      dispatch({ type: 'SET', payload: { name: name, value: value } })
    } else {
      dispatch({ type: 'ERROR', payload: { name: name, value: value } })
    }
  }

  return (
    <Grid container spacing={0} sx={{ mb: 4 }}>
      <Grid item={true} md={5}>
        <TextField
          size="small"
          inputRef={inputRef}
          name="chargeTitle"
          value={charge?.chargeTitle ? charge?.chargeTitle : ''}
          label="Charge Tite"
          type="text"
          onChange={onInputChange}
          error={addOtherChargesData['chargeTitle']['error']}
          helperText={addOtherChargesData['chargeTitle']['helperText']}
          required
        />
      </Grid>

      <Grid item={true} md={5}>
        <CustomNumberField
          name="amount"
          InputLabelProps={{ shrink: !!charge?.amount }}
          value={charge?.amount ? charge?.amount : ''}
          label="Amount"
          onKeyDown={handleKeyUp}
          onChange={onInputChange}
          error={addOtherChargesData['amount']['error']}
          helperText={addOtherChargesData['amount']['helperText']}
          required
          maxLength={8}
        />
      </Grid>

      <Grid item={true} md={2}>
        <Box sx={{ paddingLeft: 0 }}>
          <Button
            id="id_button_add"
            sx={{
              height: 37,
              width: 80,
              backgroundColor: 'rgb(0, 128, 0, 0.5)',
              color: 'green',
              borderRadius: 1.2,
            }}
            size="large"
            onClick={adOtherChargesHandler}
            startIcon={<AddTwoToneIcon />}
          >
            Add
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
export default AddOtherCharges
