import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FormInputProps } from './../FormInputProps'

type Option = {
  label: string
  value: number | string
}
export const FormInputDropdown: React.FC<
  FormInputProps & { options: Array<Option> }
> = ({ name, control, label, options }) => {
  const generateSingleOptions = () => {
    return (
      options &&
      options.map((option: Option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )
      })
    )
  }

  return (
    <FormControl size={'medium'} fullWidth>
      <InputLabel id="FormInputDropdown-label">{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            size='small'
            labelId="FormInputDropdown-label"
            id="FormInputDropdown"
            onChange={onChange}
            value={value}
            label={label}
          >
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  )
}
