import { FC, useState, useReducer, useEffect } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'

// Components
import { AppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { editBillResetAction, updateBillAction } from '../../../store/items'
import { showAlertAction } from '../../../store/alerts'
import {
  fetchCustomerAccountAction,
  getAgreementsAction,
} from '../../../store/customerAccounts'
import {
  fetchCustomersAction,
  fetchCustomersActionV2,
} from '../../../store/customers'
import {
  getFormattedDateTimeIST,
  isValidMobileNumber,
  indianMobileNumberRegex,
  isValidGST,
} from '../../../utils'
import { Agreement, Bill, Customer } from 'src/services'
import CustomNumberField from '../Users/profile/CustomNumberField'
import SelectStateItem, { StateOption } from '../Customers/SelectStateItem'
import { BillType, getBillTypes } from '../../../utils/appUtils'
import {
  getAccountDetailsAction,
  getAccountDetailsResetAction,
} from '../../../store/bills'

type AddAccountFields =
  | 'siteAddress'
  | 'billType'
  | 'ceilingArea'
  | 'ratePerSqft'
  | 'wallPercent'
  | 'openingBalance'
  | 'customer'
  | 'sitePlace'
  | 'sitePincode'
  | 'sqftMinDays'
  | 'overDaysRate'
  | 'gstRate'
type AddAccountFieldsState = Record<
  AddAccountFields,
  Partial<{
    value: string
    error: boolean
    helperText: string
  }>
>

type IssueItemErrors = Record<AddAccountFields, Record<string, string>>

const issueItemErrors: IssueItemErrors = {
  siteAddress: {
    empty: 'Please enter site address',
  },
  billType: {
    empty: 'Please select bill type',
  },
  ceilingArea: {
    empty: 'Please enter plot area',
  },
  ratePerSqft: {
    empty: 'Please enter rate',
  },
  wallPercent: {
    empty: 'Please enter wall percentage',
  },
  openingBalance: {
    empty: 'Please enter opening balance',
  },
  customer: {
    empty: 'Please select party',
  },
  sitePlace: {
    empty: 'Please enter site place',
  },
  sitePincode: {
    empty: 'Please enter site pincode',
  },
  sqftMinDays: {
    empty: 'Please enter min days',
  },
  overDaysRate: {
    empty: 'Please enter over days rate',
  },
  gstRate: {
    empty: 'Please enter over days rate',
  },
}

const initialAddAccountFieldsState: AddAccountFieldsState = {
  siteAddress: {
    error: false,
    helperText: '',
    value: '',
  },
  billType: {
    error: false,
    helperText: '',
    value: '',
  },
  ceilingArea: {
    error: false,
    helperText: '',
    value: null,
  },
  ratePerSqft: {
    error: false,
    helperText: '',
    value: null,
  },
  wallPercent: {
    error: false,
    helperText: '',
    value: null,
  },
  openingBalance: {
    error: false,
    helperText: '',
    value: null,
  },
  customer: {
    error: false,
    helperText: '',
    value: '',
  },
  sitePlace: {
    error: false,
    helperText: '',
    value: '',
  },
  sitePincode: {
    error: false,
    helperText: '',
    value: '',
  },
  overDaysRate: {
    error: false,
    helperText: '',
    value: '',
  },
  sqftMinDays: {
    error: false,
    helperText: '',
    value: '',
  },
  gstRate: {
    error: false,
    helperText: '',
    value: '',
  },
}

const itemReducer = (state: Partial<AddAccountFieldsState>, action) => {
  const {
    type,
    payload: { name, value },
    helperText,
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: helperText
            ? helperText
            : initialAddAccountFieldsState[name]?.helperText,
        },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: issueItemErrors[name]['empty'],
        },
      }
    case 'RESET':
      return {
        ...initialAddAccountFieldsState,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: initialAddAccountFieldsState[name]?.helperText,
        },
      }
    default:
      return state
  }
}

export interface AddAccountProps {
  onClose?: (newAccount?: Bill, addCustomer?: boolean) => void
  partialBill?: Bill
  customer?: Customer
}

export const AddAccountItem: FC<AddAccountProps> = ({
  onClose,
  partialBill,
  customer
}) => {
  const dispatchAction = useAppDispatch()
  const { data: userData } = useAppSelector((state) => state.userData)

  const {
    success: addAccountSuccess,
    error: addAccountError,
    data: billData,
    message,
  } = useAppSelector((state) => state.editBill)
  const ADD_NEW_CUSTOMER = 'New Party'
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [customerOptions, setCustomerOption] = useState(null)
  const [billTypes, setBillTypes] = useState<BillType[]>(
    partialBill ? getBillTypes(userData, partialBill) : []
  )
  const [billTypeIndex, setBillTypeIndex] = useState<number>(0)
  const [stateOption, setStateOption] = useState<StateOption | null>(null)

  const [showPlotArea, setShowPlotArea] = useState<boolean>(false)
  const [showRecurringCheckbox, setShowRecurringCheckbox] =
    useState<boolean>(false)
  const [show30DayMonth, setShow30DayMonth] = useState<boolean>(false)

  const [showWallPercent, setShowWallPercent] = useState<boolean>(false)
  const [showRate, setShowRate] = useState<boolean>(false)
  const [bill, setBill] = useState<Bill | null>(partialBill)

  const [itemData, dispatch] = useReducer(
    itemReducer,
    initialAddAccountFieldsState
  )
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedAgreement, setSelectedAgreement] = useState<Agreement | null>(
    null
  )
  const [selectedBillType, setSelectedBillType] = useState<BillType | null>(
    null
  )
  const [gstEnabled, setGSTEnabled] = useState('no')
  const [checked, setChecked] = useState<boolean>(false)
  const [thirtyDaysMonthChecked, setThirtyDaysMonthChecked] =
    useState<boolean>(false)

  const [storecName, setStoreName] = useState(null)
  const isEditMode = partialBill
  const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const on30DaysCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setThirtyDaysMonthChecked(event.target.checked)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGSTEnabled((event.target as HTMLInputElement).value)
    if (userData?.store?.nonGSTName) {
      const storeName =
        event.target.value == 'no'
          ? userData?.store?.nonGSTName
          : userData?.store?.name
      setStoreName(storeName)
    }
    if(!bill) {
      if (event.target.value === 'yes' && userData?.store.stateCode && userData?.store.state ) {
        setStateOption({ code: userData?.store.stateCode, label: userData?.store.state })
      } else if(event.target.value === 'yes-igst') {
        setStateOption(null)
      }
    }
   
    if (event.target.value == 'no') {
      dispatch({
        type: 'SET',
        payload: { name: 'gstRate', value: '' },
      })
    } else {
      dispatch({
        type: 'SET',
        payload: { name: 'gstRate', value: userData?.gstRate?.value },
      })
    }
  }

  const {
    list: { data },
  } = useAppSelector((state) => state.manageCustomers)
  const {
    data: agreements,
    message: agreementsError,
    success,
  } = useAppSelector((state) => state.getAgreements)
  const { data: accountDetails, message: accountError } = useAppSelector(
    (state) => state.accountDetails
  )
 
  useEffect(() => {
    dispatchAction(fetchCustomersActionV2())
  }, [])

  useEffect(() => {
    setChecked(showRecurringCheckbox)
  }, [showRecurringCheckbox])

  useEffect(() => {
    let gst = gstEnabled ? getGstRate() : 0
    dispatch({
      type: 'SET',
      payload: { name: 'gstRate', value: gst },
    })
  }, [gstEnabled])

  useEffect(() => {
    if (userData) {
      setShowRecurringCheckbox(userData.config.isRecurringBillsEnabled)
      setShow30DayMonth(userData.config.is30DaysMonthEnabled)
    }
    if (userData) {
      setBillTypes(getBillTypes(userData, billData))
    }
  }, [userData, billData])

  useEffect(() => {
    if (!success && agreementsError) {
      dispatchAction(
        showAlertAction({
          message: agreementsError,
          severity: 'error',
        })
      )
    }
  }, [agreementsError])

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.label !== ADD_NEW_CUSTOMER) {
      dispatchAction(getAgreementsAction(selectedCustomer.id))
    } else {
      setSelectedAgreement(null)
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (partialBill) {
      dispatchAction(getAccountDetailsAction(partialBill.id))
      dispatchAction(getAgreementsAction(partialBill.customer.id))
    }
  }, [partialBill])

  useEffect(() => {
    return () => {
      dispatchAction(getAccountDetailsResetAction())
      dispatchAction(editBillResetAction()) // To manage the Alert
    }
  }, [])

  useEffect(() => {
    if (accountDetails) {
      setBill(accountDetails)
    } else if (accountError) {
      dispatchAction(
        showAlertAction({ message: accountError, severity: 'error' })
      )
    }
  }, [accountDetails, accountError])

  useEffect(() => {
    if (bill?.state && bill?.stateCode) {
      setStateOption({ code: bill?.stateCode, label: bill?.state })
    }
    let index = 0
    if (bill?.billType === 'PER_ITEM') {
      index = 0
    } else if (bill?.totalCeilingArea > 0) {
      index = bill?.isSqftFlatRate === true ? 2 : 1
    } else if (bill?.ratePerSqft > 0) {
      index = bill?.isSqftFlatRate === true ? 4 : 3
    }
    if (bill?.agreementId) {
      let agreement = {
        id: bill?.agreementId,
        agreementTitle: bill?.agreementTitle,
      }
      if (agreements && agreements.length > 0) {
        agreements.map((agr) => {
          if (agr.id === bill?.agreementId) {
            agreement = agr
          }
        })
      }
      setSelectedAgreement(agreement)
    }
    setBillTypeIndex(index)
    onSelectChange(null, billTypes[index])
    setChecked(bill?.isRecurringEnabled === true)
    setThirtyDaysMonthChecked(bill?.is30DaysMonthEnabled === true)

    dispatch({
      type: 'SET',
      payload: { name: 'siteAddress', value: bill?.siteAddress },
    }),
      dispatch({
        type: 'SET',
        payload: { name: 'wallPercent', value: bill?.wallAreaPercentage },
      }),
      dispatch({
        type: 'SET',
        payload: { name: 'openingBalance', value: bill?.openingBalance },
      }),
      dispatch({
        type: 'SET',
        payload: { name: 'billType', value: billTypes[billTypeIndex] },
      }),
      dispatch({
        type: 'SET',
        payload: { name: 'ratePerSqft', value: bill?.ratePerSqft },
      }),
      dispatch({
        type: 'SET',
        payload: { name: 'overDaysRate', value: bill?.overDaysRate },
      }),
      dispatch({
        type: 'SET',
        payload: { name: 'sqftMinDays', value: bill?.sqftMinDays },
      }),
      dispatch({
        type: 'SET',
        payload: { name: 'ceilingArea', value: bill?.totalCeilingArea },
      })
    dispatch({
      type: 'SET',
      payload: { name: 'sitePlace', value: bill?.sitePlace },
    })
    dispatch({
      type: 'SET',
      payload: { name: 'sitePincode', value: bill?.sitePincode },
    })
    dispatch({
      type: 'SET',
      payload: { name: 'discount', value: bill?.discount },
    })
    if (bill?.isGSTEnabled) {
      setGSTEnabled(bill?.gstType === 'IGST' ? 'yes-igst' : 'yes')
      dispatch({
        type: 'SET',
        payload: { name: 'gstRate', value: getGstRate() },
      })
    } else {
      setGSTEnabled('no')
    }
  }, [bill, dispatch])

  function getGstRate() {
    let rate = (bill?.sgstRate ?? 0) + (bill?.cgstRate ?? 0) 
    if(bill?.gstType === 'IGST') {
      rate = (bill?.igstRate ?? 0)
    }
    if(!rate || rate === 0) {
      rate = (userData?.config?.sgst ?? 0) + (userData?.config?.cgst ?? 0)
    }
    return rate
  }

  // Customer Select
  const onCustomerChange = (e, newValue: { id; label }) => {
    setSelectedCustomer(newValue)
    if (!newValue) {
      setSelectedAgreement(null)
    } else if (newValue.label && newValue.label === ADD_NEW_CUSTOMER) {
      
      onClose(null, true)
      setSelectedAgreement(null)
    } else {
      dispatch({
        type: 'SET',
        payload: { name: 'customer', value: newValue.label },
      })
    }
  }

  const onChangeHandler = (e) => {
    const {
      target: { name, value },
    } = e
    if (
      name === 'customerMobile' ||
      name === 'customerAddress' ||
      name === 'customerGSTIN'
    ) {
      dispatch({ type: 'SET', payload: { name, value } })
    } else if (value.length > 0) {
      dispatch({ type: 'SET', payload: { name, value } })
    } else {
      dispatch({ type: 'ERROR', payload: { name, value } })
    }
  }

  useEffect(() => {
    const addNewCustomer = { id: 0, label: ADD_NEW_CUSTOMER }
    const customerlist = data.map((customer) => {
      return {
        id: customer.id,
        label: `${customer.name} (${customer.mobile}) - ${customer.address}`,
      }
    })
    setCustomerOption([addNewCustomer, ...customerlist])
  }, [data])
  useEffect(() => {
      if (customer) {
       const option = { id: customer.id, label: `${customer.name} (${customer.mobile}) - ${customer.address}` }
       setSelectedCustomer(option)
      }
  }, [customer])

  useEffect(() => {
    setBillTypeIndex(0)
    onSelectChange(null, billTypes[0])
    dispatch({
      type: 'SET',
      payload: { name: 'billType', value: billTypes[billTypeIndex] },
    })
  }, [dispatch])

  useEffect(() => {
    if (addAccountSuccess) {
      dispatchAction(fetchCustomerAccountAction('OPEN'))
      dispatchAction(
        showAlertAction({
          message: 'Account added successfully',
          severity: 'success',
        })
      )
      onClose(billData)
    } else if (addAccountError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [addAccountSuccess, addAccountError, message])

  const onAgreementChange = (e: any, newValue: Agreement) => {
    setSelectedAgreement(newValue)
  }

  const onSelectChange = (e: any, newValue: BillType) => {
    setSelectedBillType(newValue)
    if (newValue) {
      dispatch({ type: 'SET', payload: { name: 'billType', value: newValue } })
      setShowRecurringCheckbox(
        userData.config.isRecurringBillsEnabled &&
          (newValue.index === 0 || newValue.index === 3)
      )

      setShow30DayMonth(
        userData.config.is30DaysMonthEnabled &&
          (newValue.index === 0 || newValue.index === 3)
      )

      if (newValue.index !== 2 && newValue.index !== 4) {
        dispatch({ type: 'SET', payload: { name: 'minDaysRent', value: '' } })
        dispatch({ type: 'SET', payload: { name: 'overDaysRate', value: '' } })
      }

      if (newValue.index === 0) {
        dispatch({ type: 'SET', payload: { name: 'ceilingArea', value: '' } })
        dispatch({ type: 'SET', payload: { name: 'wallPercent', value: '' } })
        dispatch({ type: 'SET', payload: { name: 'ratePerSqft', value: '' } })
        setShowPlotArea(false)
        setShowRate(false)
        setShowWallPercent(false)
      } else if (newValue.index < 3) {
        dispatch({ type: 'SET', payload: { name: 'wallPercent', value: '' } })
        setShowPlotArea(true)
        setShowRate(true)
        setShowWallPercent(false)
      } else {
        dispatch({ type: 'SET', payload: { name: 'ceilingArea', value: '' } })
        setShowPlotArea(false)
        setShowRate(true)
        setShowWallPercent(true)
      }
    }
  }

  const submitHandle = () => {
    let error = false
    if (!isEditMode && !selectedCustomer) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'customer', value: null } })
    }
   
    if (!itemData?.siteAddress?.value) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'siteAddress', value: null } })
    }

    if (!selectedBillType) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'billType', value: null } })
    }

    const rate = itemData?.ratePerSqft?.value
      ? Number(itemData?.ratePerSqft?.value)
      : 0

    const overDaysRate =
      itemData?.overDaysRate?.value || itemData?.overDaysRate?.value === '0'
        ? Number(itemData?.overDaysRate?.value)
        : null

    const sqftMinDays =
      itemData?.sqftMinDays?.value || itemData?.sqftMinDays?.value === '0'
        ? Number(itemData?.sqftMinDays?.value)
        : null

    const plotArea = itemData?.ceilingArea?.value
      ? Number(itemData?.ceilingArea?.value)
      : 0
    const wallPercent = itemData?.wallPercent?.value
      ? Number(itemData?.wallPercent?.value)
      : 0
    const openingBalance = itemData?.openingBalance?.value
      ? Number(itemData?.openingBalance?.value)
      : 0
    if (showPlotArea && plotArea === 0) {
      error = true
      dispatch({ type: 'SET', payload: { name: 'ceilingArea', value: '' } })
      dispatch({ type: 'ERROR', payload: { name: 'ceilingArea', value: '' } })
    }

    if (showRate && rate === 0) {
      error = true
      dispatch({ type: 'SET', payload: { name: 'ratePerSqft', value: '' } })
      dispatch({ type: 'ERROR', payload: { name: 'ratePerSqft', value: '' } })
    }

    if (showRate && isFlateRate && !sqftMinDays && sqftMinDays !== 0) {
      error = true
      dispatch({ type: 'SET', payload: { name: 'sqftMinDays', value: '' } })
      dispatch({ type: 'ERROR', payload: { name: 'sqftMinDays', value: '' } })
    }

    if (showRate && isFlateRate && !overDaysRate && overDaysRate !== 0) {
      error = true
      dispatch({ type: 'SET', payload: { name: 'overDaysRate', value: '' } })
      dispatch({ type: 'ERROR', payload: { name: 'overDaysRate', value: '' } })
    }

    if (gstEnabled && (itemData?.gstRate?.value === undefined || itemData?.gstRate?.value === '') ) {
      error = true
      dispatch({ type: 'SET', payload: { name: 'gstRate', value: '' } })
      dispatch({ type: 'ERROR', payload: { name: 'gstRate', value: '' } })
    }

    if (!error) {
      const billType =
        userData.config?.isMixTypeAllowed || selectedBillType.index == 0
          ? 'PER_ITEM'
          : 'PER_SQFT'
      const isFlatRate =
        selectedBillType.index == 2 || selectedBillType.index == 4
      const sitePlace =
        gstEnabled && itemData?.sitePlace?.value
          ? itemData?.sitePlace?.value
          : null
      const sitePincode =
        gstEnabled && itemData?.sitePincode?.value
          ? itemData?.sitePincode?.value
          : null

      const apiRequest = {
        billId: partialBill?.id,
        date: isEditMode ? null : getFormattedDateTimeIST(new Date()),
        customerId: isEditMode ? bill?.customer.id : selectedCustomer.id,
        siteAddress: itemData?.siteAddress?.value,
        billType: billType,
        ratePerSqft: rate,
        ceilingArea: plotArea,
        wallPercent: wallPercent,
        isSqftFlatRate: isFlatRate,
        isGSTEnabled: gstEnabled !== 'no',
        enteries: [],
        isRecurringEnabled: showRecurringCheckbox && checked,
        agreementId: selectedAgreement?.id,
        stateCode: stateOption?.code,
        state: stateOption?.label,
        sitePlace: sitePlace,
        sitePincode: sitePincode ? Number(sitePincode) : null,
        is30DaysMonthEnabled: show30DayMonth && thirtyDaysMonthChecked,
        sqftMinDays: sqftMinDays,
        overDaysRate: overDaysRate,
        gstType:
          gstEnabled === 'yes'
            ? 'CGST_SGST'
            : gstEnabled === 'yes-igst'
            ? 'IGST'
            : 'NONE',
        gstRate: itemData?.gstRate?.value,
        openingBalance: openingBalance
      }
      dispatchAction(updateBillAction(apiRequest))
    }
  }

  useEffect(() => {
    setStoreName(userData?.store?.nonGSTName)
  }, [userData?.store])

  const handleChangeStoreName = (e) => {
    const gstEnabled =
      e.target.value == userData?.store?.nonGSTName ? 'no' : 'yes'
    setStoreName(e.target.value)
    setGSTEnabled(gstEnabled)
  }
  useEffect(() => {
    return () => {
      dispatchAction(editBillResetAction()) // To manage the Alert
    }
  }, [customerOptions])

  const defaultProps = {
    options: agreements ? agreements : [],
    getOptionLabel: (option: Agreement) =>
      option.agreementTitle
        ? `AG:${option.id} ${option.agreementTitle}`
        : `AGREEMENT: ${option.id}`,
  }

  const isFlateRate =
    selectedBillType?.index === 2 || selectedBillType?.index === 4

  return (
    <AppDialog
      id="edit-item"
      title={isEditMode ? 'Edit Site' : 'Open New Site'}
      submitButtonText={isEditMode ? 'SAVE CHANGES' : 'OPEN SITE'}
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} m={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 0 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container>
              {!isEditMode && (
                <Grid item={true} xs>
                  <Autocomplete
                  size='small'           
                    id="select-customer"
                    value={selectedCustomer}
                    options={customerOptions}
                    onChange={onCustomerChange}
                    renderInput={(params) => (
                      <TextField
                    size='small'
                        {...params}
                        label="Select Party"
                        required
                        error={itemData['customer']['error']}
                      />
                    )}
                    renderOption={(props, option) => {
                      const textColor =
                        option.label === ADD_NEW_CUSTOMER ? 'green' : 'black'
                      return (
                        <Typography
                          {...props}
                          noWrap
                          style={{
                            color: textColor,
                            height: 40,
                            marginRight: 10,
                          }}
                        >
                          {option.label}
                        </Typography>
                      )
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid item={true} xs={12}>
                <TextField
                    size='small'
                  name="siteAddress"
                  onChange={onChangeHandler}
                  value={itemData.siteAddress.value}
                  label="Site/Shipping Address"
                  type="string"
                  fullWidth
                  required
                  error={itemData['siteAddress']['error']}
                />
              </Grid>
            </Grid>
            {storecName && (
              <Grid container spacing={2} mt={0.5}>
                <Grid item={true} xs={12}>
                  {' '}
                  <FormControl fullWidth>
                    <InputLabel id="store-name-label">Store Name</InputLabel>
                    <Select
                      size='small'
                      labelId="store-name-label"
                      id="store-name"
                      value={storecName}
                      name={'store-name'}
                      label="Business Name"
                      onChange={handleChangeStoreName}
                    >
                      <MenuItem value={userData?.store?.nonGSTName}>
                        {userData?.store?.nonGSTName}
                      </MenuItem>
                      <MenuItem value={userData?.store?.name}>
                        {userData?.store?.name}
                      </MenuItem>
                    </Select>
                  </FormControl>{' '}
                </Grid>
              </Grid>
            )}
           
             
           <Grid container spacing={2} mt={1.2}>
            <Grid item={true} xs={12}>
                  <CustomNumberField
                    name="openingBalance"
                    onChange={onChangeHandler}
                    value={itemData.openingBalance?.value}
                    label="Opening Balance(Optional)"
                    fullWidth
                    maxLength={10}
                    InputLabelProps={{
                      shrink: itemData.openingBalance?.value,
                    }}
                  />
                </Grid>
                </Grid>
            <Grid container spacing={2}>
            
              <Grid item={true} xs={12} mt={3}>
                <Autocomplete
                  size='small'
                  disablePortal
                  id="bill_type_options"
                  value={selectedBillType}
                  options={billTypes}
                  onChange={onSelectChange}
                  renderInput={(params) => (
                    <TextField
                    size='small'
                      {...params}
                      label="Select bill type"
                      required
                      error={itemData['billType']['error']}
                      sx={{ minWidth: '100%', margin: 0 }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Grid component={'li'} container {...props}>
                      <Grid
                        id={option.label}
                        data-cy={option.label}
                        item
                        xs
                        pl={1}
                        pr={1}
                        pt={0.7}
                        pb={0.7}
                      >
                        {option.label}
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
            </Grid>

            {showPlotArea && (
              <Grid container spacing={2} mt={1.2}>
                <Grid item={true} xs={12}>
                  <CustomNumberField
                    onChange={onChangeHandler}
                    name="ceilingArea"
                    value={itemData.ceilingArea.value}
                    label="Sqft Area"
                    error={itemData['ceilingArea']['error']}
                    fullWidth
                    maxLength={8}
                  />
                </Grid>
              </Grid>
            )}
            {showWallPercent && (
              <Grid container spacing={2} mt={1.2}>
                <Grid item={true} xs={12}>
                  <CustomNumberField
                    name="wallPercent"
                    onChange={onChangeHandler}
                    value={itemData.wallPercent.value}
                    label="Wall Percent(Optional)"
                    fullWidth
                    maxLength={2}
                  />
                </Grid>
              </Grid>
            )}

            {showRate && (
              <Grid container spacing={2} mt={1.2}>
                <Grid item={true} xs={isFlateRate ? 4 : 12}>
                  <CustomNumberField
                    name="ratePerSqft"
                    onChange={onChangeHandler}
                    value={itemData.ratePerSqft?.value}
                    label="Rate Per Sqft"
                    error={itemData['ratePerSqft']['error']}
                    maxLength={4}
                    fullWidth
                  />
                </Grid>

                {isFlateRate && (
                  <Grid item={true} xs={4}>
                    <CustomNumberField
                      name="sqftMinDays"
                      onChange={onChangeHandler}
                      value={itemData.sqftMinDays?.value}
                      label="For days"
                      error={itemData['sqftMinDays']['error']}
                      maxLength={4}
                      fullWidth
                    />
                  </Grid>
                )}

                {isFlateRate && (
                  <Grid item={true} xs={4}>
                    <CustomNumberField
                      name="overDaysRate"
                      onChange={onChangeHandler}
                      value={itemData.overDaysRate?.value}
                      label="Over Days Rate"
                      error={itemData['overDaysRate']['error']}
                      maxLength={4}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            )}

            <Grid container spacing={2}>
              <Grid item={true} xs={12} mt={3}>
                <Autocomplete
                  size='small'           
                  {...defaultProps}
                  disablePortal
                  id="combo-box-demo"
                  value={selectedAgreement}
                  onChange={onAgreementChange}
                  renderInput={(params) => (
                    <TextField
                    size='small'
                      {...params}
                      label="Select Agreement"
                      sx={{ minWidth: '100%', margin: 0 }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {showRecurringCheckbox && (
              <Grid container spacing={2} mt={1.2}>
                <Grid item={true} xs={12}>
                  <Checkbox
                    checked={checked}
                    onChange={onCheckBoxChange}
                    sx={{ ml: -1 }}
                  />{' '}
                  Recurring Bills (Monthly/Fortnightly/Weekly)
                </Grid>
              </Grid>
            )}

            {show30DayMonth && (
              <Grid container spacing={2} mt={1.2}>
                <Grid item={true} xs={12}>
                  <Checkbox
                    checked={thirtyDaysMonthChecked}
                    onChange={on30DaysCheckBoxChange}
                    sx={{ ml: -1 }}
                  />{' '}
                  Enable 30 days month
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2} mt={1.2}>
              <Grid item={true} xs={12}>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Apply GST on this account?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={gstEnabled}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes (SGST+CGST)"
                    />
                    <FormControlLabel
                      value="yes-igst"
                      control={<Radio />}
                      label="Yes (IGST)"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No GST"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {gstEnabled !== 'no' && (
                <Grid item xs={12}>
                  <CustomNumberField
                    name="gstRate"
                    onChange={onChangeHandler}
                    value={
                      itemData.gstRate?.value ?? ''
                    }
                    label="GST Rate(%)"
                    fullWidth
                    maxLength={6}
                  />
                </Grid>
              )}
              {gstEnabled !== 'no' && (
                <Grid item xs={12}>
                  <SelectStateItem
                    selectedStateOption={stateOption}
                    label={'Construction site state'}
                    onChange={(option) => {
                      setStateOption(option)
                    }}
                  />
                </Grid>
              )}
              {/* {gstEnabled !== 'no' && (
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    name="sitePlace"
                    onChange={onChangeHandler}
                    value={
                      itemData.sitePlace?.value ? itemData.sitePlace?.value : ''
                    }
                    label="Place of Supply"
                    type="string"
                    fullWidth
                  />
                </Grid>
              )} */}
              {gstEnabled !== 'no' && (
                <Grid item xs={12}>
                  <CustomNumberField
                    name="sitePincode"
                    onChange={onChangeHandler}
                    value={
                      itemData.sitePincode?.value
                        ? itemData.sitePincode?.value
                        : ''
                    }
                    label="Site pincode"
                    fullWidth
                    maxLength={6}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
