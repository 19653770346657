import { MerchantEnteredDaysResponse } from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState } from '../../../services/types'

type MerchantEnteredDaysState = BaseReducerState & { data: string }

const initialState: MerchantEnteredDaysState = {
  loading: false,
  success: false,
  message: '',
  data: null,
}

export const merchantEnteredDays = (
  state: MerchantEnteredDaysState = initialState,
  action: { type: string; res: MerchantEnteredDaysResponse }
): MerchantEnteredDaysState => {
  switch (action.type) {
    case `${ActionTypes.POST_MERCHANT_ENTERED_DAYS}_REQUEST`:
      return {
        ...initialState,
        loading: true,
      }

    case `${ActionTypes.POST_MERCHANT_ENTERED_DAYS}_SUCCESS`:
      const { success, data, message } = <MerchantEnteredDaysResponse>action.res

      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    case `${ActionTypes.POST_MERCHANT_ENTERED_DAYS}_FAILURE`:
      return {
        ...state,
        loading: false,
        success: false,
        message: message,
      }
    case `${ActionTypes.POST_MERCHANT_ENTERED_DAYS}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
