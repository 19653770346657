import { FC, useState } from 'react'
import {
  Grid,
  Typography,
  ListItem,
  Tooltip,
  IconButton,
  useTheme,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'

import { EditItem } from './EditItem'
import { DeleteItem } from './DeleteItem'

import { TabsEnums } from '../../../../components'
import { ItemEntry, EntryType, Bill } from '../../../../services'
import './quotation.css'
import { currencyFormatter } from '../../../../utils'

const ItemName: FC<{ name: string }> = ({ name }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        py: 0,
        pl: 3,
        m: 1.2,
      }}
    >
      {name}
    </Typography>
  )
}

interface ListItemComponentProps {
  account: Bill
  item: ItemEntry
  componentName: TabsEnums
  billId: number
}

export const ListItemComponent: FC<ListItemComponentProps> = ({
  account,
  item,
  componentName,
  billId,
}) => {
  const theme = useTheme()
  const issuedRecievedText =
    item.entryType === EntryType.IN && item.receivedAmount > 0
      ? 'CRDT'
      : item.entryType === EntryType.OUT && item.isConsumable
      ? 'OTHR'
      : item.entryType === EntryType.OUT
      ? 'ISSU'
      : item.entryType === EntryType.IN && item.lostItemCost > 0
      ? 'LOST'
      : item.entryType === EntryType.IN
      ? 'RECV'
      : ' '

  const [editItem, openEditItem] = useState<boolean>(false)
  const [deleteItem, openDeleteItem] = useState<boolean>(false)
  const xsValue = account?.status === 'QUOTATION' ? 2.75 : 3.666
  return (
    <ListItem
      sx={{
        height: 50,
      }}
      disableGutters
      key={item.id}
    >
      <Grid container sx={{ p: 0 }} alignItems="center" justifyContent="center">
        <Grid item xs={xsValue} sx={{ pl: 3.1 }}>
          {item.itemName}
        </Grid>

        <Grid item xs={2.75} sx={{ alignItems: 'center' }}>
          {item.rate > 0 && (
            <Typography
              variant="body2"
              sx={{
                m: 0.5,
              }}
            >
              {currencyFormatter.format(item.rate)}
            </Typography>
          )}

          {item.lostItemCost > 0 && (
            <Typography
              variant="body2"
              sx={{
                m: 0.5,
              }}
            >
              {currencyFormatter.format(item.lostItemCost)}
            </Typography>
          )}
        </Grid>

        {account?.status === 'QUOTATION' && (<Grid
          item
          xs={xsValue}
          sx={{ alignItems: 'center', alignContent: 'center' }}
        >
          <Typography
            variant="body2"
            sx={{
              width: 70,
            }}
          >
            {item.quantity}
          </Typography>
        </Grid>)}

        <Grid item xs={xsValue} sx={{ alignItems: 'center' }}>
          {currencyFormatter.format(item.rate * item.quantity)}
        </Grid>

        <Grid item xs={1}>
            <>
              <Tooltip title="Edit" arrow>
                <IconButton
                  sx={{
                    '&:hover': {
                      background: theme.colors.primary.lighter,
                    },
                  }}
                  color="inherit"
                  onClick={() => {
                    openEditItem(true)
                  }}
                >
                  <EditCalendarIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <IconButton
                  sx={{
                    '&:hover': { background: theme.colors.error.lighter },
                    color: 'crimson',
                  }}
                  color="inherit"
                  onClick={() => {
                    openDeleteItem(true)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
        </Grid>
      </Grid>

      {editItem && (
        <EditItem
          item={item}
          onClose={() => {
            openEditItem(false)
          }}
          onSubmitSuccess={() => {
            openEditItem(false)
          }}
          accountId={billId}
        />
      )}

      {deleteItem && (
        <DeleteItem
          item={item}
          onClose={() => {
            openDeleteItem(false)
          }}
          onSubmitSuccess={() => {
            openDeleteItem(false)
          }}
          accountId={billId}
        />
      )}
    </ListItem>
  )
}
