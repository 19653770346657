// import { useEffect } from 'react'
import { Box, Typography, Grid, Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { createActiveActivationKey } from '../../../../services'
import { useAppDispatch } from '../../../../../src/hooks'
import { hideSubscribePopupAction } from '../../../../../src/store/subscriptionPlan'
import { showAlertAction } from '../../../../../src/store/alerts'
// import { useAppDispatch, useAppSelector } from '../../../../hooks'
// import {
//   fetchSubscriptionPlanAction,
//   fetchSubscriptionPlanResetAction,
// } from '../../../../store/subscriptionPlan/actions'

const ActivateKey = () => {
  const dispatchAction = useAppDispatch()
  const { handleSubmit, control } = useForm<{
    activation_key: string
  }>()
  // const dispatchAction = useAppDispatch()
  const hanldeActivateKey = handleSubmit((data: { activation_key: string }) => {
    if (data?.activation_key) {
      createActiveActivationKey({ activation_key: data?.activation_key }).then(
        (response) => {
          if (response.success) {
            dispatchAction(
              showAlertAction({
                message: response.message,
                severity: 'success',
              })
            )
            dispatchAction(hideSubscribePopupAction())
          } else {
            dispatchAction(
              showAlertAction({
                message: response.message,
                severity: 'error',
              })
            )
          }
        }
      )
    }
  })

  return (
    <>
      <Box component="form" noValidate autoComplete="off">
        <Typography component="h1" sx={{ fontSize: 16 }}>
          If you have a key, please enter
        </Typography>
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
            <Controller
              name={'activation_key'}
              control={control}
              rules={{
                required: 'Please enter your activation key to active the plan',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    helperText={error ? error.message : null}
                    size={'small'}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    sx={{ width: 200 }}
                    label={'Activation Key'}
                    variant="outlined"
                    required={true}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'left', pl: 1 }}>
            <Button
              id="view_plan_button"
              variant="contained"
              sx={{ backgroundColor: '#314d67', height: 38, width: 140 }}
              onClick={hanldeActivateKey}
            >
              {' '}
                Activate key
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ActivateKey
