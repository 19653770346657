import { FC, useEffect, useState } from 'react'
import {
  Typography,
  Radio,
  CardHeader,
  Card,
  Box,
  useTheme,
  Button,
} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import {
  SubscribePlanRequest,
  SubscriptionPlan,
  createSubscribePlan,
  createVerifySubscribedPlan,
  createActiveActivationKey,
} from '../../../../services'
import { currencyFormatter, loadScript } from '../../../../utils'
import { showAlertAction } from '../../../../store/alerts'
import { useAppDispatch, usePayRazorpay } from '../../../../hooks'
import { hideSubscribePopupAction } from '../../../../store/subscriptionPlan'
import OfflineMachineInfo from './OfflineMachineInfo'

type PlanListProps = {
  plans: Array<SubscriptionPlan>
  monthly_billing: number
  setShowLoader: (boolean) => void
}

const PlanList: FC<PlanListProps> = ({
  plans,
  monthly_billing,
  setShowLoader,
}) => {
  const theme = useTheme()
  const dispatchAction = useAppDispatch()
  const [makeRazorPayment] = usePayRazorpay()
  const [selectedPlan, setSelectedPlan] = useState(plans[0].id)
  const [description, setDescription] = useState('')
  const [jsLoadStatus, setJsLoadStatus] = useState(false)
  const formMethod = useForm()
  useEffect(() => {
    setDescription(plans[selectedPlan]?.title)
  }, [plans, selectedPlan])

  // Load the javascript
  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
      .then((success) => {
        setJsLoadStatus(true)
      })
      .catch((errr) => {
        console.error('Razorpay SDK failed to load. Are you online?', errr)
        setJsLoadStatus(false)
      })
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const plan = +event.target.value
    setSelectedPlan(plan)
    setDescription(plans[plan]?.title)
  }

  const paymentSuccess = async (response) => {
    const verifyRequest = {
      signature: response.razorpay_signature,
      payment_id: response.razorpay_payment_id,
    }
    const verifyResponse = await createVerifySubscribedPlan(verifyRequest)
    if (verifyResponse?.data || verifyResponse?.success) {
      if (process.env.OFFLINE) {
        await createActiveActivationKey({
          activation_key: verifyResponse?.data,
        })
      }
      dispatchAction(hideSubscribePopupAction())
    } else {
      dispatchAction(
        showAlertAction({
          message: 'Opps something went wrong',
          severity: 'error',
        })
      )
    }
    setShowLoader(false)
  }
  const paymentFailed = function (response) {
    setShowLoader(false)
    alert('failed')
    dispatchAction(
      showAlertAction({ message: 'Payment failed', severity: 'error' })
    )
  }
  const checkIfOrderSelected = () => {
    if (!selectedPlan || !monthly_billing) {
      // show our alert
      dispatchAction(
        showAlertAction({ message: 'Select a plan', severity: 'error' })
      )
      return false
    } else {
      return true
    }
  }

  const displayRazorpay = formMethod.handleSubmit(
    async (data: SubscribePlanRequest) => {
      const { email, hardware_id } = data
      const orderSelected = checkIfOrderSelected()
      if (!orderSelected) {
        console.error('Select an order')
        return false
      }

      const subscribePlanResponse = await createSubscribePlan({
        monthly_billing: monthly_billing,
        plan_id: selectedPlan,
        email,
        hardware_id,
      })

      if (!subscribePlanResponse?.success || !subscribePlanResponse?.data) {
        dispatchAction(
          showAlertAction({
            message:
              subscribePlanResponse?.message || 'Opps something went wrong',
            severity: 'error',
          })
        )
        return false
      }

      if (!jsLoadStatus) {
        return false
      }
      setShowLoader(true)
      const subscribePlanData = { ...subscribePlanResponse?.data, description }
      makeRazorPayment(subscribePlanData, paymentSuccess, paymentFailed)
    }
  )

  return (
    <Box sx={{ mt: '20px' }}>
      <Typography component="h1" variant="h5" sx={{ mt: '20px' }}>
        Please select a plan:
      </Typography>
      <FormProvider {...formMethod}>
        <form>
          <Box sx={{ mt: '20px' }}>
            {plans.map((plan) => {
              return (
                <Card
                  sx={{ mb: '20px', cursor: 'pointer' }}
                  key={plan.id}
                  onClick={() => {
                    setSelectedPlan(plan.id)
                  }}
                >
                  <CardHeader
                    avatar={
                      <Radio
                        checked={selectedPlan == plan.id}
                        onChange={handleChange}
                        value={plan.id}
                        name="plan"
                        inputProps={{ 'aria-label': plan.title }}
                      />
                    }
                    action={
                      <>
                        <Typography
                          component="span"
                          variant="h4"
                          color={`${theme.palette.error.dark}`}
                          sx={{
                            textDecoration: 'line-through',
                            marginRight: '10px',
                          }}
                        >
                          {currencyFormatter.format(plan.price)}
                        </Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ pr: 2 }}
                          color={`${theme.palette.success.dark}`}
                        >
                          {currencyFormatter.format(plan.discountedPrice)}
                        </Typography>
                      </>
                    }
                    title={
                      <Typography component="span" variant="h5">
                        {plan.title}
                      </Typography>
                    }
                    subheader={`You get all features and unlimited billing.`}
                  />
                </Card>
              )
            })}
          </Box>
          {process.env.OFFLINE && <OfflineMachineInfo />}
          <Box sx={{ textAlign: 'center' }}>
            <Button
              id="buy_plan_button"
              variant="contained"
              sx={{ mt: 3, backgroundColor: 'green', width: 300 }}
              onClick={displayRazorpay}
            >
              {' '}
                Buy Plan
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

export default PlanList
