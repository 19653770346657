import { FC, Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import RecentBillsHeader from './PageHeader';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { Button, IconButton, Link, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { currencyFormatter, getDisplayDate, getFormattedDate } from '../../../utils';
import { tableStyles } from '../Reports';
import { getRecentBillsDataAction, getRecentBillsDataResetAction } from './actions';
import { CreateMonthlyBill } from '../MonthlyBills/CreateMonthlyBill';
import { SiteDto } from 'src/components/SitesAutoComplete/Services';
import { set } from 'date-fns';
import QueueIcon from '@mui/icons-material/Queue';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { DeleteMonthlyBill } from '../MonthlyBills/DeleteMonthlyBill';
import { SiteRecentBill } from './types';
import DeleteIcon from '@mui/icons-material/Delete'

export interface RecentBillsProps { }

export const RecentBills: FC<RecentBillsProps> = ({ }) => {
  const [siteId, setSiteId] = useState<number|null>(null)
  const [deleteInvoice, setDeleteInvoice] = useState<SiteRecentBill|null>(null)

  const classes = tableStyles();
  const dispatchAction = useAppDispatch()
  const { data: sites } = useAppSelector(
    (state) => state.recentBillsState
  );

  useEffect(() => {
    dispatchAction(getRecentBillsDataResetAction())
    dispatchAction(getRecentBillsDataAction('END_DATE'))
  }, [])

  let count = 0;
  return (
    <>
      <Helmet>
        <title>Last invoices</title>
      </Helmet>
      <PageTitleWrapper>
        <RecentBillsHeader />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Table aria-label="billing report">
          <TableHead>
            <TableRow>
              <TableCell width={20}>S.N.</TableCell> {/* Serial Number column */}
              <TableCell className={classes.tableCell}>Party Name</TableCell>
              <TableCell className={classes.tableCell}>Site</TableCell>
              <TableCell className={classes.tableCell}>Site ID</TableCell>
              <TableCell className={classes.tableCell} sx={{ width: 190 }}>Last Invoice Period</TableCell>
              <TableCell className={classes.tableCell} sx={{ width: 100 }}>Inv. Date</TableCell>
              <TableCell className={classes.tableCell} sx={{ width: 110 }}>Create Date</TableCell>
              <TableCell className={classes.tableCell} sx={{ width: 110 }}>Amount</TableCell>
              <TableCell className={classes.tableCell} sx={{ width: 100 }}>Unbilled</TableCell>
              <TableCell className={classes.tableCell} sx={{ width: 80 }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sites &&
              sites.map((site, index) => {


                return (
                  <Fragment key={site.billId}>
                    <TableRow>
                      <TableCell>{++count}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant={'body1'}>{site.customerName}</Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>{site.siteAddress}</TableCell>
                      <TableCell className={classes.tableCell}>
                        
                        <Tooltip arrow title="Go to account details">
              <Link
                underline="always"
                href={`/slips/view-slips/${site.billId}/${site.customerId}?tab_index=5`}
              >
               {site.billId}
              </Link>
            </Tooltip>
                        </TableCell>
                      <TableCell className={classes.tableCell}>{site.period}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {getDisplayDate(site.invoiceDate)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getDisplayDate(site.actualCreateDate)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {currencyFormatter.format(site.invoiceTotal)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant={'body1'} sx={{color:site.bgColor}}>{site.unbilledDays} days</Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                      <Stack direction="row" alignItems="right">
                        {site.pdf && (
                <Tooltip title="View last invoice" arrow>
                  <IconButton
                    sx={{height: 25, width:30}}
                    onClick={() => {
                      window.open(site.pdf, '_blank')
                    }}
                  >
                    {' '}
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>
                )}
                <Tooltip title="Create new invoice" arrow>
                  <IconButton
                    sx={{height: 25, width:30}}
                    onClick={() => {
                      setSiteId(site.id)
                    }}
                  >
                    {' '}
                    <QueueIcon sx={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>
                {site.interimBillId && site.interimBillId > 0 &&(
                <Tooltip title="Delete Bill" arrow>
                  <IconButton
                    sx={{height: 25, width:30}}
                    onClick={() => {
                      setDeleteInvoice(site)
                    }}
                  >
                    {' '}
                    <DeleteIcon sx={{ color: 'crimson' }} />
                  </IconButton>
                </Tooltip>
                )}
              </Stack>
                       
                      </TableCell>
                    </TableRow>


                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </PageContainerWrapper>
      {siteId && (
        <CreateMonthlyBill
          onClose={(site?: SiteDto) => {
            setSiteId(null)
            if (site) {
              dispatchAction(getRecentBillsDataAction('END_DATE'))
            }
          }}
          selectedSiteId={siteId}
          account={null}
        />
      )}
      {deleteInvoice && (
              <DeleteMonthlyBill
                bill={null}
                recentInvoice={deleteInvoice}
                onClose={(result) => {
                  if(result === true) {
                    dispatchAction(getRecentBillsDataAction('END_DATE'))
                  }
                  setDeleteInvoice(null)
                }}
              />
            )}
    </>
  );
};

export default RecentBills;
