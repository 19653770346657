import {
  FetchActivityResponse,
  BaseReducerState,
  Activity,
} from '../../../services'
import { ActionTypes } from '../actions'

export interface AcitivityState {
  data: Activity[]
}

export type ManageAcitivityState = BaseReducerState & AcitivityState

const initialState: ManageAcitivityState = {
  loading: false,
  success: false,
  message: '',
  data: [],
}

export const fetchActivities = (
  state: ManageAcitivityState = initialState,
  action: {
    type: string
    res: FetchActivityResponse
  }
): ManageAcitivityState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_ACTIVITIES}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
      }

    case `${ActionTypes.FETCH_ACTIVITIES}_SUCCESS`: {
      console.log('jjjjj', action.res)
      const { success, data, message } = <FetchActivityResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data: data }),
      }
    }
    case `${ActionTypes.FETCH_ACTIVITIES}_FAILURE`: {
      const { message } = <FetchActivityResponse>action.res
      return {
        ...state,
        loading: false,
        success: false,
        message: message,
      }
    }
  }
  return state
}
