import { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'
import { HeaderMenuItem } from './HeaderMenuItem'
import { WebsiteFooter } from './WebsiteFooter'

interface BaseLayoutProps {
  children?: ReactNode
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
      }}
    >
      {children || (
        <>
          <HeaderMenuItem />
          <Box
            sx={{
              minHeight: '100vh'
            }}
          >
            <Outlet />
          </Box>

          <WebsiteFooter />
        </>
      )}
    </Box>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node,
}

export default BaseLayout
