import { FC, useState, useReducer, useEffect } from 'react'
import { Box, Grid, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

import { AddPaymentRequest } from '../../../services'

// Components
import { AppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { addPaymentAction, addPaymentResetAction } from '../../../store/items'
import { refreshFetchSlipsAction } from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'
import { getFormattedDate, replace12AmWithCurrentTime } from '../../../utils'
import CustomNumberField from '../Users/profile/CustomNumberField'
import SiteAutocomplete from '../../../../src/components/SitesAutoComplete/SiteAutocomplete'
import { SiteDto } from '../../../../src/components/SitesAutoComplete/Services'

type IssueItemFields = 'amount' | 'note' | 'refNo'
type IssueItemFieldsState = Record<
  IssueItemFields,
  Partial<{
    value: string
    error: boolean
    helperText: string
  }>
>

type IssueItemErrors = Record<IssueItemFields, Record<string, string>>

const issueItemErrors: IssueItemErrors = {
  amount: {
    empty: 'Please enter amount',
  },
  note: {
    empty: 'Please enter note',
  },

  refNo: {
    empty: 'Please enter refNo',
  },
}

const initialItemIssueState: IssueItemFieldsState = {
  amount: {
    error: false,
    helperText: 'Please enter amount',
  },
  note: {
    error: false,
    helperText: 'Please enter note',
  },
  refNo: {
    error: false,
    helperText: 'Please enter ref no',
  },
}
const itemReducer = (state: Partial<IssueItemFieldsState>, action) => {
  const {
    type,
    payload: { name, value },
    helperText,
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: helperText
            ? helperText
            : initialItemIssueState[name]?.helperText,
        },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: issueItemErrors[name]['empty'],
        },
      }
    case 'RESET':
      return {
        ...initialItemIssueState,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: initialItemIssueState[name]?.helperText,
        },
      }
    default:
      return state
  }
}

export interface AddEditPayementProps {
  children?: React.ReactNode
  onClose?: () => void
  onSubmitSuccess?: (site?: SiteDto) => void
  item?: AddPaymentRequest
}

export const AddEditPaymentItem: FC<AddEditPayementProps> = ({
  onClose,
  onSubmitSuccess,
  item,
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success: addPaymentSuccess,
    error: addPaymentError,
    message,
  } = useAppSelector((state) => state.addPayment)
  const [date, setDate] = useState<Date | null>(
    () => new Date(item?.paymentDate != null ? item?.paymentDate : Date())
  )
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [paymentRequest, setPaymentRequest] = useState<AddPaymentRequest | null>(null)
  const [itemData, dispatch] = useReducer(itemReducer, initialItemIssueState)
  const [request, setRequest] = useState<AddPaymentRequest | null>(null)
  const [site, setSite] = useState<SiteDto | null>(null)

  useEffect(() => {
    if(item) {
      setPaymentRequest(item)
    }
  }, [item])

  useEffect(() => {
    if (paymentRequest) {
      dispatch({
        type: 'SET',
        payload: { name: 'amount', value: paymentRequest.amount },
      }),
        dispatch({
          type: 'SET',
          payload: { name: 'note', value: paymentRequest.note },
        }),
        dispatch({
          type: 'SET',
          payload: { name: 'refNo', value: paymentRequest.refNo },
        }),
        setRequest({
          ...request,
          ...(paymentRequest.amount && { amount: paymentRequest.amount }),
          ...(paymentRequest.note && { note: paymentRequest.note }),
          ...(paymentRequest.refNo && { refNo: paymentRequest.refNo }),
          paymentId: paymentRequest.paymentId,
          billId: paymentRequest.billId,
          paymentDate: getFormattedDate(date),
        })
    }
  }, [paymentRequest, setRequest, dispatch])

  useEffect(() => {
    if (addPaymentSuccess) {
      dispatchAction(refreshFetchSlipsAction())
      dispatchAction(
        showAlertAction({
          message: 'Item Updated Successfully',
          severity: 'success',
        })
      )
      onSubmitSuccess(site) // To close the popup
    } else if (addPaymentError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onSubmitSuccess() // To close the popup
    }
  }, [addPaymentSuccess, addPaymentError, message])

  const onChangeHandler = (e) => {
    const { name, valueAsNumber, value } = e.target
    if (valueAsNumber) {
      dispatch({ type: 'SET', payload: { name, value: valueAsNumber } })
      setRequest({
        ...request,
        [name]: valueAsNumber,
      })
    } else {
      dispatch({ type: 'ERROR', payload: { name, value: value } })
      setRequest({
        ...request,
        [name]: value,
      })
    }
  }

  const dateChangeHandler = (value) => {
    const newDate = replace12AmWithCurrentTime(value)
    setRequest({
      ...request,
      paymentDate: getFormattedDate(newDate),
    })
    setDate(newDate)
  }

  const submitHandle = () => {
    const checkRequest = Object.keys(request)?.every((key) => {
      if (key === 'note' || key == 'paymentId' || key == 'refNo') {
        return true
      }
      return !!request[key]
    })
    if (checkRequest) {
      dispatchAction(addPaymentAction(request))
    }
  }
  useEffect(() => {
    return () => {
      dispatchAction(addPaymentResetAction()) // To manage the Alert
    }
  }, [])
  return (
    <AppDialog
      id="edit-item"
      title={'Add Payment'}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} mr={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={0} mt={0.5}>
              <Grid item xs={12}>
            {!item && (
             <SiteAutocomplete  small onSiteSelected={(site?: SiteDto) => {
              
              const addPaymentRequest = {
                billId: Number(site ? site.id : 0),
                paymentId: null,
                amount: null,
                paymentDate: null,
                note: null,
              }
              setSite(site)
              setPaymentRequest(addPaymentRequest)
             
             }}/>)}
             </Grid>
              <Grid item={true} xs={12}>
                <CustomNumberField
                  maxLength={8}
                  name="amount"
                  value={itemData.amount.value}
                  label="amount"
                  onChange={onChangeHandler}
                  fullWidth
                  error={itemData['amount']['error']}
                  helperText={
                    itemData['amount']['error']
                      ? itemData.amount.helperText
                      : ''
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item={true} xs={12} mt={2}>
                <DateTimePicker
                  label="Select Date"
                  value={date}
                  ampmInClock={false}
                  onChange={dateChangeHandler}
                  format="dd/MM/yyyy hh:mm a"
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      variant: 'outlined',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1.2}>
              <Grid item={true} xs={12}>
                <TextField
                    size='small'
                  id="id_text_field_refNo"
                  name="refNo"
                  value={itemData.refNo.value}
                  label="Check no, Transaction no etc"
                  type="string"
                  onChange={onChangeHandler}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1.2}>
              <Grid item={true} xs={12}>
                <TextField
                    size='small'
                  id="id_text_field_note"
                  multiline
                  rows={4}
                  name="note"
                  value={itemData.note.value}
                  label="Note"
                  type="string"
                  onChange={onChangeHandler}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
