import { AxiosResponse } from "axios";
import { http } from "../../../utils";

export function fetchLedgerSummaryPdf(
) {

    let url = `/charts/pvt/ledger_summary_pdf`
    return http
        .get(
            url
        )
        .then((response: AxiosResponse) => response.data)
}