import { FC, useState } from 'react';
import { Typography, Grid, IconButton, Tooltip, TextField } from '@mui/material';
import { useAppDispatch } from '../../../hooks';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { fetchSitesStockPdf } from './Services';
import { showAlertAction } from '../../../store/alerts';
import { Search } from '../../../../src/components';
export const circleButtonSize = '32px';
export const circleIconSize = '18px';

interface SitesStockSummaryHeaderProps {
  searchString: string;
  setSearchString: (value: string) => void;
  onPdfClick: () => void
}

const SitesStockSummaryHeader: FC<SitesStockSummaryHeaderProps> = ({
  searchString,
  setSearchString,
  onPdfClick
}) => {
  const [isEnabled, setIsEnabled] = useState(true);

  const dispatchAction = useAppDispatch();
  const fetchPDF = () => {
    setIsEnabled(false);
    fetchSitesStockPdf().then((response) => {
      setIsEnabled(true);
      if (response.success) {
        window.open(response.data, '_blank');
      } else {
        dispatchAction(
          showAlertAction({
            message: response.message,
            severity: 'error',
          })
        );
      }
    });
  };

  return (
    <>
      <Grid container alignItems="stretch">
        <Grid item md={5}>
          <Typography variant="h3" component="h3" gutterBottom pl={2.5}>
            Balance Items
          </Typography>
        </Grid>

       

        <Grid
          md={3}
          item
          sx={{ textAlign: 'right', display: 'flex', justifyContent: 'right', alignItems: 'center' }}
        >
          <Tooltip title="Export PDF">
            <IconButton
              disabled={!isEnabled}
              onClick={onPdfClick}
              sx={{
                mr: 2,
                backgroundColor: '#1976d2',
                '&:hover': {
                  backgroundColor: '#1565c0',
                },
                color: 'white',
                width: { circleButtonSize },
                height: { circleButtonSize },
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PictureAsPdfIcon sx={{ width: circleIconSize, height: circleIconSize }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          md={4}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        >
           <Search onSearch={setSearchString} />
        
        </Grid>
      </Grid>
    </>
  );
};

export default SitesStockSummaryHeader;
