import { FetchBillBreakupResponse, BillIntervalData } from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState } from '../../../services/types'

export interface BillBreakupDetail {
  data: BillIntervalData
}

type ManageBillBreakupState = BaseReducerState & BillBreakupDetail

const initialState: ManageBillBreakupState = {
  loading: false,
  success: false,
  message: '',
  data: null,
}

export const manageBillsBreakup = (
  state: ManageBillBreakupState = initialState,
  action: { type: string; res: FetchBillBreakupResponse }
): ManageBillBreakupState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_BILL_BREAKUP_BY_ID}_REQUEST`:
      return {
        ...initialState,
        loading: true,
      }

    case `${ActionTypes.FETCH_BILL_BREAKUP_BY_ID}_SUCCESS`:
      const { success, data, message } = <FetchBillBreakupResponse>action.res

      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    case `${ActionTypes.FETCH_BILL_BREAKUP_BY_ID}_FAILURE`:
      return {
        ...state,
        loading: false,
        success: false,
        message: message,
      }
    case `${ActionTypes.FETCH_BILL_BREAKUP_BY_ID}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
