import { FC, useEffect } from 'react'

import {
  ItemEntryDetail,
} from '../../../services'

// Components
import { ConfirmationAppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  deleteChallanAction,
  resetDeleteChallanAction,
} from '../../../store/items'
import { refreshFetchSlipsAction } from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'

export interface DeleteChallanProps {
  onClose?: () => void
  onSubmitSuccess?: () => void
  item: ItemEntryDetail
}

export const DeleteChallan: FC<DeleteChallanProps> = ({
  onClose,
  onSubmitSuccess,
  item,
}) => {
  const dispatchAction = useAppDispatch()
  const {
    data,
    message,
  } = useAppSelector((state) => state.deleteChallan)

  useEffect(() => {
    if (data) {
      dispatchAction(refreshFetchSlipsAction())
      dispatchAction(
        showAlertAction({
          message: 'Challan Deleted Successfully',
          severity: 'success',
        })
      )
      dispatchAction(resetDeleteChallanAction())
      onSubmitSuccess() // To close the popup
    } else if (message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      dispatchAction(resetDeleteChallanAction())
      onSubmitSuccess() // To close the popup
    }
  }, [data])

  const deleteHandler = () => {
    if (item.challanId) {
      dispatchAction(deleteChallanAction(item.challanId))
    }
  }

  useEffect(() => {
      dispatchAction(resetDeleteChallanAction()) // To manage the Alert
  }, [])

  return (
    <ConfirmationAppDialog
      id="delete-item"
      title={`Delete challan number: ${item?.displayCHL}`}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onClose,
        },
        {
          text: 'Delete',
          color: 'error',
          variant: 'contained',
          onClickHandler: deleteHandler,
        },
      ]}
    >
      Do you really want to delete this Challan?
    </ConfirmationAppDialog>
  )
}
