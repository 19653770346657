import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller } from 'react-hook-form'
import { FormInputProps } from './../FormInputProps'

export const FormInputDateField = ({
  name,
  control,
  label,
  rules,
  format = 'dd/MM/yyyy hh:mm a',
  disabled = false,
  size = 'small',
}: FormInputProps & { format?: string }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState, formState }) => {
          const { error } = fieldState
          const isError = error || field.value == 'Invalid Date'
          return (
            <DatePicker
              label={label}
              value={field.value}
              onChange={(date) => field.onChange(date)}
              format={format}
              disabled={disabled}
              slotProps={{
                textField: {
                  size: size,
                  fullWidth: true,
                  variant: 'outlined',
                  helperText: isError ? error?.message || 'Invalid Date' : null,
                  error: !!isError,
                },
              }}
            />
          )
        }}
      />
    </LocalizationProvider>
  )
}
