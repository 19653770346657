import { FC, Fragment, useEffect } from 'react'
import { Typography, TableCell, TableRow } from '@mui/material'

import { BillInterval } from '../../../../services'
import BillRow from './BillRow'

export interface BillTypeSectionProps {
  children?: React.ReactNode
  billInterval: BillInterval
  rowHeight: number
}

const BillTypeSection: FC<BillTypeSectionProps> = ({
  billInterval,
  rowHeight,
}) => {
  const { title, billEntries } = billInterval
  const isLost = title.includes('damaged')
  const isNotReceived = title.includes('जमा नहीं')
  const bgColor = isNotReceived ? '#eeee00' : (isLost ? '#eb627a' : '#f9f9f9')

  return (
    <Fragment>
      <TableRow sx={{ backgroundColor: bgColor }}>
        <TableCell >
          <Typography
            variant="body1"
            fontWeight="bold"
            color="text.primary"
            noWrap
          >
            {title.replace('को गया -','  To  ').replace('को जमा', '').replace('तक जमा नहीं', '(NOT RECEIVED)').replace('Sell Items', 'Other Charges')}
          </Typography>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
      {billEntries &&
        billEntries.map((billEntry, index) => (
          <BillRow
            key={index}
            billEntry={billEntry}
            billTitle={title}
            rowHeight={rowHeight}
          />
        ))}
    </Fragment>
  )
}

export default BillTypeSection
