import { FC, useState, Fragment, useEffect, useContext } from 'react'
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  CardActions,
  Button,
  styled,
  Grid,
  Box,
} from '@mui/material'

import { currencyFormatter } from '../../../../utils'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  fetchBillBreakupByIdAction,
  postMerchantEnteredDaysAction,
  postMerchantEnteredDaysResetAction,
} from '../../../../store/bills'
import BillTypeSection from './BillTypeSection'
import { CreateBillManagerContext } from '../CreateBillManager'
import { fetchCustomerAccountAction } from '../../../../store/customerAccounts'
import { showAlertAction } from '../../../../store/alerts'
import { ResetDaysCount } from './ResetDaysCount'
import { showOneDayDiscount } from '..'

const CustomCardActions = styled(CardActions)(
  () => `
    justify-content: center;
     align-items: center  
`
)
export interface DaysCountingProps {
  children?: React.ReactNode
  sqftStartDate?: string
  sqftEndDate?: string
}

const DaysCounting: FC<DaysCountingProps> = ({
  sqftStartDate,
  sqftEndDate,
}) => {
  const {
    billId,
    totalRentCopy,
    setTotalRent,
    activeStep,
    setActiveStep,
    merchantEnteredDataArray,
  } = useContext(CreateBillManagerContext)

  const dispatchAction = useAppDispatch()
  const { data } = useAppSelector((state) => state.manageBillsBreakup)
  const { success, message } = useAppSelector(
    (state) => state.merchantEnteredDays
  )
  const [showResetDailog, setShowResetDailog] = useState<boolean>(false)

  useEffect(() => {
    if (billId) {
      dispatchAction(
        fetchBillBreakupByIdAction(billId, sqftStartDate, sqftEndDate)
      )
    }
  }, [billId, dispatchAction])

  useEffect(() => {
    if (success) {
      dispatchAction(fetchCustomerAccountAction('OPEN'))
      setActiveStep(activeStep + 1)
      dispatchAction(postMerchantEnteredDaysResetAction())
    } else if (message) {
      dispatchAction(postMerchantEnteredDaysResetAction())
      dispatchAction(
        showAlertAction({
          message: message,
          severity: 'error',
        })
      )
    }
  }, [success, message])

  useEffect(() => {
    if (data?.totalRent) {
      setTotalRent(data.totalRent)
    }
  }, [data?.totalRent, setTotalRent])

  const handleReset = () => {
    setShowResetDailog(true)
  }

  const handleSubmit = () => {
    if (merchantEnteredDataArray.length > 0) {
      // Make API call to update the data
      dispatchAction(postMerchantEnteredDaysAction(merchantEnteredDataArray))
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  if (data == null) {
    return null
  }
  const rowHeight = 52

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{
        height: '70vh',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
      }}
    >
      <Grid item xs={11} sx={{ overflow: 'auto' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ height: rowHeight - 10 }}>
                <TableCell width={'20%'}>Item Name</TableCell>
                <TableCell width={'15%'}> Rate </TableCell>
                <TableCell width={'15%'}> Quantity </TableCell>
                <TableCell width={'15%'}> Rent/Day </TableCell>
                <TableCell align="center" width={'20%'}>
                  Days
                </TableCell>
                <TableCell width={'15%'} align="right">
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.billIntervals &&
                data.billIntervals.map((billInterval, index) => {
                  return (
                    <BillTypeSection
                      key={index}
                      billInterval={billInterval}
                      rowHeight={rowHeight}
                    />
                  )
                })}

              <TableRow sx={{ height: rowHeight }}>
                <TableCell size="small">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    Total Rent + Other Charges
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    {' '}
                    {currencyFormatter.format(totalRentCopy)}
                  </Typography>
                </TableCell>
              </TableRow>
              {data != null && data.lostItemsCost > 0 && (
                <TableRow sx={{ height: rowHeight }}>
                  <TableCell size="small">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      Lost or damaged items charges
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {' '}
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {' '}
                      {data?.lostItemsCost}{' '}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={1}>
        <CardActions
          sx={{ display: 'flex', flexDirection: 'row', pl: 1, pr: 2 }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={activeStep === 0}
            onClick={() => {
              setActiveStep(activeStep - 1)
            }}
            sx={{
              mr: 1,
              visibility: showOneDayDiscount ? 'visible' : 'hidden',
            }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            size="medium"
            color="error"
            variant="contained"
            onClick={handleReset}
          >
            Reset Days Count
          </Button>

          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Next
          </Button>
        </CardActions>
        {showResetDailog && (
          <ResetDaysCount
            onClose={(reload: boolean) => {
              setShowResetDailog(false)
              console.log({ reload })
              if (reload) {
                dispatchAction(
                  fetchBillBreakupByIdAction(billId, sqftStartDate, sqftEndDate)
                )
              }
            }}
            accountId={billId}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default DaysCounting
