import { FC, useEffect, useState } from 'react'
import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Checkbox,
  ListItem,
  Button,
  IconButton,
  Divider,
} from '@mui/material'
import { StarBorder } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Item, ItemCategory, OnboardingStock } from '../../../services'

export interface ItemProps {
  item: Item
  isParentChecked: boolean | null
  onChange:(checked: boolean) => void
}

const StockItem: FC<ItemProps> = ({ item, isParentChecked, onChange}) => {
  const [checked, setChecked] = useState(item.isSelected)

  useEffect(() => {
    if(isParentChecked !== null) {
      setChecked(isParentChecked)
      item.isSelected = isParentChecked
    }
  }, [isParentChecked])

 
  return (
    <ListItemButton sx={{ pl: 4 }} key={item.itemName}>
      <ListItemIcon>
        <Checkbox
          checked={checked || isParentChecked}
          edge="start"
          tabIndex={-1}
          disableRipple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(event.target.checked) 
            item.isSelected = event.target.checked
            onChange(event.target.checked)
          }}
        />
      </ListItemIcon>
      <ListItemText primary={item.size} />
    </ListItemButton>)

}

export default StockItem
