import { FC, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Empty } from '../../../components'

const FinalStepItem: FC = ({}) => {
  return (
    <>
      <Box
        sx={{
          width: 800,
          overflowY: 'auto',
          maxWidth: 800,
          height: '100%',
          bgcolor: 'background.paper',
          border: 0.5,
          borderColor: 'lightgray',
        }}
      >
        <Empty>
          <Grid container xs={12} direction={'column'}>
            <Grid
              xs={1}
              item
              sx={{
                display: 'flex',
                flex: 1,
                textAlign: 'center',
                alignItems: 'center',
                justifyItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                height: 60,
              }}
            >
              <Typography
                sx={{ fontSize: 24, pl: 20, pr: 20, pt: 0, fontWeight: 'bold' }}
              >
                Congratulations
              </Typography>
            </Grid>
            <Grid xs={2} item sx={{ height: 60 }}>
              <Typography
                sx={{
                  fontSize: 20,
                  pl: 10,
                  pr: 10,
                  textAlign: 'center',
                  pt: 2,
                }}
              >
                Setup has been completed successfully. Now, sit back, relax, and
                let the software take care of everything for you
              </Typography>
            </Grid>
          </Grid>
        </Empty>
      </Box>
    </>
  )
}

export default FinalStepItem
