import { SlipData, FetchSlipsListResponse } from '../../../services'
import { ActionTypes, searchSlips } from '../actions'
import { BaseReducerState } from '../../../services/types'

export type SlipsListResponse = BaseReducerState & {
  billId: number
  data: SlipData[]
  searchResults: SlipData[]
  searchTerm: string
}

type SearchUpdate = {
  data: SlipData[]
  searchTerm: string
}
const initialState: SlipsListResponse = {
  loading: false,
  success: false,
  message: '',
  error: null,
  billId: null,
  data: [],
  searchResults: [],
  searchTerm: '',
}

export const manageSlips = (
  state: SlipsListResponse = initialState,
  action: { type: string; res: any; data: { billId: number } }
): SlipsListResponse => {
  switch (action.type) {
    case `${ActionTypes.FETCH_SLIPS}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
      }

    case `${ActionTypes.FETCH_SLIPS}_SUCCESS`: {
      const {
        data: { billId },
        res,
      } = action
      const { success, data, message } = <FetchSlipsListResponse>res
      const searchResults = searchSlips(state.searchTerm, data)

      return {
        ...state,
        loading: false,
        success,
        message,
        billId,
        searchResults: searchResults,
        ...(success && data && { data }),
      }
    }

    case `${ActionTypes.SLIPS_SEARCH_RESULTS}`: {
      const { data, searchTerm } = <SearchUpdate>action.res
      return {
        ...state,
        searchResults: data,
        searchTerm,
      }
    }
    case `${ActionTypes.FETCH_SLIPS}_FAILURE`:
      const { message, error } = <FetchSlipsListResponse>action.res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }
    case `${ActionTypes.FETCH_SLIPS}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
