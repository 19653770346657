import { FC, ReactNode } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import { Box, useTheme, Backdrop, CircularProgress } from '@mui/material'

import Sidebar from './Sidebar'
import Header from './Header'
import { AppAlerts } from '../../components'
import { useAppSelector } from '../../hooks'
import SubscriptionPopup from '../../content/applications/Users/subscription-plan/SubscriptionPopup'
import { searchProperty } from '../../utils'
import PaymentDuePopup from '../../content/applications/PendingBills/PaymentDuePopup'

interface SidebarLayoutProps {
  children?: ReactNode
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme()
  const location = useLocation()

  console.log({ location })
  const loading = useAppSelector((state) => searchProperty(state, 'loading'))
  return (
    <>
      <SubscriptionPopup />
      <PaymentDuePopup />
      <Header />
      <Sidebar />
      <Backdrop
        sx={{
          color: 'transparent',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'transparent',
        }}
        open={loading && !process.env.OFFLINE}
        onClick={() => {}}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          display: 'block',
          flex: 1,
          backgroundColor: 'white',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            ml: `${theme.sidebar.width}`,
          },
        }}
      >
        <Box display="block">
          <AppAlerts />
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

export default SidebarLayout
