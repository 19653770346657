import {
  fetchLedger, FetchLedgerRequest,
} from '../../../services/ledger'

export const ActionTypes = {
  FETCH_LEDGER: 'FETCH_LEDGER',
}

export const fetchLedgerAction = (request: FetchLedgerRequest) => ({
  type: ActionTypes.FETCH_LEDGER,
  promise: fetchLedger(request),
})

export const fetchLedgerResetAction = () => ({
  type: `${ActionTypes.FETCH_LEDGER}_RESET`,
})

