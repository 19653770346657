import {
  addItemCategory,
  fetchItemCategories,
  AddItemCategoryRequest,
  ItemCategory,
  deleteCategory,
  fetchDiscountedCategoriesById,
  updateDiscountedCategories,
} from '../../../services'

export const ActionTypes = {
  ADD_ITEM_CATEGORY: 'ADD_ITEM_CATEGORY',
  ADD_CATEGORY: 'ADD_CATEGORY',
  FETCH_ITEM_CATEGORY: 'FETCH_ITEM_CATEGORY',
  UPDATE_SEARCH_CATEGORIES_RESULTS: 'UPDATE_SEARCH_CATEGORIES_RESULTS',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  UPDATE_ONE_DAY_DISCOUNT_CATEGORIES: 'UPDATE_ONE_DAY_DISCOUNT_CATEGORIES',
}

export const addCategoryAction = (itemCategory: AddItemCategoryRequest) => ({
  type: ActionTypes.ADD_CATEGORY,
  promise: addItemCategory(itemCategory),
})

export const fetchItemCategoryAction = () => ({
  type: ActionTypes.FETCH_ITEM_CATEGORY,
  promise: fetchItemCategories(),
})

export const addCategoryResetAction = () => ({
  type: `${ActionTypes.ADD_CATEGORY}_RESET`,
})

// Search fucntion
export const searchItemCategory = (searchTerm, data) => {
  let searchResults: ItemCategory[] = []
  if (data?.length) {
    searchResults = data.filter((category) => {
      const { name } = category
      if (name?.toLowerCase().includes(searchTerm?.toLowerCase())) {
        return true
      } else {
        return false
      }
    })
  }
  return searchResults
}

export const searchItemCategoryAction = (searchTerm) => {
  return (dispatch, getState) => {
    const { list } = getState().manageItemCategories
    const { data } = list
    const searchResults = searchItemCategory(searchTerm, data)
    dispatch({
      type: ActionTypes.UPDATE_SEARCH_CATEGORIES_RESULTS,
      res: { data: searchResults, searchTerm },
    })
  }
}

export const deleteCategoryAction = (id: number) => ({
  type: ActionTypes.DELETE_CATEGORY,
  promise: deleteCategory(id),
})

export const deleteCategoryResetAction = () => ({
  type: `${ActionTypes.DELETE_CATEGORY}_RESET`,
})

export const fetchDiscountedCategoriesAction = (bill_id) => ({
  type: ActionTypes.FETCH_CATEGORIES,
  promise: fetchDiscountedCategoriesById(bill_id),
})

export const fetchDiscountedCategoriesResetAction = () => ({
  type: `${ActionTypes.FETCH_CATEGORIES}_RESET`,
})

export const updateDiscountedCategoriesAction = (bill_id, request) => ({
  type: ActionTypes.UPDATE_ONE_DAY_DISCOUNT_CATEGORIES,
  promise: updateDiscountedCategories(bill_id, request),
})

export const updateDiscountedCategoriesResetAction = () => ({
  type: `${ActionTypes.UPDATE_ONE_DAY_DISCOUNT_CATEGORIES}_RESET`,
})