import { FC, useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  ListItem,
  Tooltip,
  IconButton,
  useTheme,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'

import { EditItem } from './EditItem'
import { DeleteItem } from './DeleteItem'

import { SlipsScreenType, TabsEnums } from '../../../components'
import { ItemEntry, EntryType } from '../../../services'
import './slips_style.css'
import { AddEditPaymentItem } from './AddEditPayment'
import { DeletePayment } from './DeletePayment'
import {
  currencyFormatter,
  getShortFormattedDate,
  getTimeOnly,
} from '../../../utils'
import { Box } from '@mui/system'

const ItemName: FC<{ name: string; time: string; id?: string }> = ({
  name,
  time,
  id,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        data-cy={`${id}_name`}
        variant="body2"
        id={id}
        sx={{
          py: 0,
          pl: 3,
          m: 1.2,
        }}
      >
        {name}
      </Typography>
      <Typography
        data-cy={`${id}_time_type`}
        variant="body2"
        sx={{
          py: 0,
          fontSize: 12,
          color: 'crimson',
        }}
      >
        {time ? time : ''}
      </Typography>
    </Box>
  )
}

interface ListItemComponentProps {
  item: ItemEntry
  componentName: TabsEnums
  billId: number
  screenType: SlipsScreenType
}

export const ListItemComponent: FC<ListItemComponentProps> = ({
  item,
  componentName,
  billId,
  screenType
}) => {
  const theme = useTheme()
  const issuedRecievedText =
    item.entryType === EntryType.IN && item.receivedAmount > 0
      ? 'CRDT'
      : item.entryType === EntryType.OUT && item.isConsumable
      ? 'OTHR'
      : item.entryType === EntryType.OUT
      ? 'ISSU'
      : item.entryType === EntryType.IN && item.lostItemCost > 0
      ? 'LOST'
      : item.entryType === EntryType.IN
      ? 'RECV'
      : ' '

  // submission_type={'LOST'}

  const [editItem, openEditItem] = useState<boolean>(false)
  const [deleteItem, openDeleteItem] = useState<boolean>(false)
  const addPaymentRequest = {
    billId: billId,
    paymentId: item.paymentId,
    amount: item.receivedAmount,
    paymentDate: item.entryDate,
    note: item.note,
  }

  const showTimeType =
    (item.entryType === 'IN' && item.timeType === 'MORNING') ||
    (item.entryType === 'OUT' && item.timeType === 'EVENING')
  const slipDate = getShortFormattedDate(new Date(item.entryDate))
  const testIdPrefix = `id_typo_${slipDate}_${item.itemName}`

  return (
    <ListItem
      id="slip_row"
      sx={{
        pt: 0.5,
        pb: 0.5,
      }}
      key={item.id}
    >
      <Grid container sx={{ p: 0 }} alignItems="center" justifyContent="center">
        <Grid item xs={3} sx={{ alignItems: 'center' }}>
          <div
            style={{
              height: 10,
              width: 10,
              display: 'flex',
              borderRadius: 5,
              backgroundColor: item.barColorCode,
              marginLeft: 20,
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
          <ItemName
            name={item.itemName}
            time={showTimeType ? item.timeType : ''}
            id={testIdPrefix}
          />
        </Grid>

        <Grid item xs={2} sx={{ alignItems: 'center' }}>
          <Typography data-cy={`${testIdPrefix}_time`}>
            {item.id >= 0 ? getTimeOnly(item.entryDate) : ''}
          </Typography>
        </Grid>

        {(componentName === TabsEnums.All ||
          componentName === TabsEnums.Out ||
          componentName === TabsEnums.In) && (
          <>
            <Grid item xs={2} sx={{ alignItems: 'center' }}>
              {item.entryType === EntryType.OUT &&
                item.id > 0 &&
                (item.rate || item.rate === 0) && (
                  <Typography
                    id="item_rate"
                    variant="body2"
                    sx={{
                      m: 0.5,
                    }}
                    data-cy={`${testIdPrefix}_rate`}
                  >
                    {currencyFormatter.format(item.rate)}
                  </Typography>
                )}

              {item.receivedAmount > 0 && (
                <Typography
                  id="received_amount"
                  variant="body2"
                  sx={{
                    m: 0.5,
                  }}
                  data-cy={`${testIdPrefix}_received_amount`}
                >
                  {currencyFormatter.format(item.receivedAmount)}
                </Typography>
              )}
              {item.lostItemCost > 0 && (
                <Typography
                  id="lost_item_cost"
                  variant="body2"
                  sx={{
                    m: 0.5,
                  }}
                  data-cy={`${testIdPrefix}_lost_item_cost`}
                >
                  {currencyFormatter.format(item.lostItemCost)}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={2}
              sx={{ alignItems: 'center', alignContent: 'center' }}
            >
              <Typography
                id="item_quantity"
                variant="body2"
                textAlign={'right'}
                sx={{
                  width: 70,
                }}
                data-cy={`${testIdPrefix}_quantity`}
              >
                {item?.categoryName !== 'OtherCharges' && item.paymentId == 0
                  ? item.quantity
                  : '-'}
              </Typography>
            </Grid>

            <Grid
              id="issued_recieved_text"
              item
              xs={2}
              sx={{ alignItems: 'center' }}
            >
              <div
                className={`entryType${issuedRecievedText}`}
                data-cy={`${testIdPrefix}_entry_type`}
              >
                {issuedRecievedText}
              </div>
            </Grid>

            <Grid item xs={1}>
              {(screenType === SlipsScreenType.Account && (item.itemId > 0 || item.paymentId > 0)) && (
                <>
                  <Tooltip title="Edit" arrow>
                    <IconButton
                      id="edit_entry"
                      data-cy={`${testIdPrefix}_button_edit`}
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter,
                        },
                      }}
                      color="inherit"
                      onClick={() => {
                        openEditItem(true)
                      }}
                    >
                      <EditCalendarIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      id="delete_entry"
                      data-cy={`${testIdPrefix}_button_delete`}
                      sx={{
                        '&:hover': { background: theme.colors.error.lighter },
                        color: 'crimson',
                      }}
                      color="inherit"
                      onClick={() => {
                        openDeleteItem(true)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
      {editItem && item.paymentId > 0 && (
        <AddEditPaymentItem
          item={addPaymentRequest}
          onClose={() => {
            openEditItem(false)
          }}
          onSubmitSuccess={() => {
            openEditItem(false)
          }}
        />
      )}
      {editItem && item.paymentId == 0 && (
        <EditItem
          item={item}
          onClose={() => {
            openEditItem(false)
          }}
          onSubmitSuccess={() => {
            openEditItem(false)
          }}
        />
      )}
      {deleteItem && item.paymentId > 0 && (
        <DeletePayment
          item={item}
          onClose={() => {
            openDeleteItem(false)
          }}
          onSubmitSuccess={() => {
            openDeleteItem(false)
          }}
        />
      )}
      {deleteItem && item.paymentId == 0 && (
        <DeleteItem
          item={item}
          onClose={() => {
            openDeleteItem(false)
          }}
          onSubmitSuccess={() => {
            openDeleteItem(false)
          }}
        />
      )}
    </ListItem>
  )
}
