import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response } from '../types'

export interface PendingBills {
  id: number
  month: string
  dueDate: string
  amount: number
  consumption: number
  status: string
  createDate: string
  amountAfterDiscount: number
  updateDate: string
}
export interface FetchPendingBillsResponse extends Response {
  data: PendingBills[]
}

export function fetchPendingBills(onlyRecentDue: boolean) {
  return http
    .get(`/software_invoice/pvt/due_invoices?only_recent_due=${onlyRecentDue}`)
    .then((response: AxiosResponse) => response)
}

export function createPendingBills() {
  return http
    .get('/software_invoice/pvt/create')
    .then((response: AxiosResponse) => response)
}

export function createPaynow(invoice_id: number) {
  return http
    .get(`/software_invoice/pvt/paynow?invoice_id=${invoice_id}`)
    .then((response: AxiosResponse) => response.data)
}
