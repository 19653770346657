import { http } from '../../../utils'

import { AxiosResponse } from 'axios'
import { CreateInvoicesRequest } from './types'

export const ActionTypes = {
  GET_RECENT_BILLS_DATA: 'GET_RECENT_BILLS_DATA',
  CREATE_INVOICES: 'CREATE_INVOICES'

}

export const getRecentBillsData = (sortType: string) => {
  let url = `/bill/pvt/recent_bills?sort_type=${sortType}`
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export const createInvoices = (request: CreateInvoicesRequest) => {
  let url = '/interim_bill/pvt/bulk'
  return http
    .post(url, request)
    .then((response: AxiosResponse) => response)
}

export const getRecentBillsDataAction = (sortType: string) => ({
  type: ActionTypes.GET_RECENT_BILLS_DATA,
  promise: getRecentBillsData(sortType),
})

export const getRecentBillsDataResetAction = () => ({
  type: ActionTypes.GET_RECENT_BILLS_DATA+"_RESET",
})

export const createInvoiceAction = (request: CreateInvoicesRequest) => ({
  type: ActionTypes.CREATE_INVOICES,
  promise: createInvoices(request),
})

export const createInvoiceResetAction = () => ({
  type: ActionTypes.CREATE_INVOICES+"_RESET",
})
