import { CreateMonthlyBillAPIResponse } from 'src/services/monthlyBills'
import { BaseReducerState, MonthlyBill } from '../../../services'
import { ActionTypes } from '../actions'

export type CreateMonthlyBillState = BaseReducerState & {
  data: MonthlyBill
}

export const initialState: CreateMonthlyBillState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const createMonthlyBill = (
  state: CreateMonthlyBillState = initialState,
  action: { type: string; res: any }
): CreateMonthlyBillState => {
  switch (action.type) {
    case `${ActionTypes.CREATE_MONTHLY_BILL}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.CREATE_MONTHLY_BILL}_SUCCESS`: {
      const { success, data, message } = <CreateMonthlyBillAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.CREATE_MONTHLY_BILL}_FAILURE`:
      const res = <CreateMonthlyBillAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.CREATE_MONTHLY_BILL}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
