import ErrorIcon from '@mui/icons-material/Error'
import { Avatar } from '@mui/material'
import React, { useState } from 'react'

interface AvatarWithErrorHandlingProps {
  src: string
  alt: string
  avatarStyle?: React.CSSProperties
  errorIcon?: React.ReactElement
}

const AvatarWithErrorHandling: React.FC<AvatarWithErrorHandlingProps> = ({
  src,
  alt,
  avatarStyle,
  errorIcon = <ErrorIcon />,
}) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src)
  const [imgError, setImgError] = useState<boolean>(false)

  const handleImageError = () => {
    setImgError(true)
  }

  return (
    <>
      {imgError ? (
        errorIcon
      ) : (
        <Avatar
          src={imgSrc}
          alt={alt}
          style={avatarStyle}
          onError={handleImageError}
        />
      )}
    </>
  )
}

export default AvatarWithErrorHandling
