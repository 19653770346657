import { FC } from 'react'
import { List, ListSubheader } from '@mui/material'
import { OnboardingStock } from '../../../services'
import { Box } from '@mui/system'
import StockCategory from './StockCategory'

export interface StockCategoryGrooupsProps {
  stockGroups: OnboardingStock[]
}

const StockCategoryGrooups: FC<StockCategoryGrooupsProps> = ({
  stockGroups,
}) => {
  return (
    <>
      {stockGroups &&
        stockGroups.map((stockGroup, index) => {
          return (
            <Box
              sx={{
                width: 400,
                overflowY: 'auto',
                maxWidth: 400,
                height: '100%',
                bgcolor: 'background.paper',
                border: 0.5,
                borderColor: 'lightgray',
              }}
              key={index}
            >
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {stockGroup.title}
                  </ListSubheader>
                }
              >
                {stockGroup.itemCategories?.map((category) => {
                  return (
                    <>
                      <StockCategory
                        category={category}
                        key={`stock-cat-${category.name}`}
                      />
                    </>
                  )
                })}
              </List>
            </Box>
          )
        })}
    </>
  )
}

export default StockCategoryGrooups
