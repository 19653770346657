import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Bill } from '../items'
import { Response, Customer, Item } from '../types'
export interface MerchantEnteredDays {
  billId: number
  enteredDays: number
  inEntryId: number
  outEntryId: number
  id?: number
}
export interface BillEntry {
  itemName: string
  outTime: string
  inTime: string
  categoryName: string
  categoryId: number
  itemId: number
  itemEnteryId: number
  billId: number
  issueDate: string
  createDate: string
  submitDate: string
  days: number
  rate: number
  area: number
  areaBasedPerSqFtRate: number
  billAmount: number
  rentPerDay: number
  displayText: string
  quantity: number
  isConsumable: boolean
  isRemaining: boolean
  isCeilingItem: boolean
  defaultDiscountInDays: number
  inEntryId: number
  outEntryId: number
  lostItemCost: number
  isInDateRane: boolean
  barColorCode: string
  isDaysCountEditable: boolean
  merchantEnteredDays?: MerchantEnteredDays
}

export interface BillInterval {
  isConsumable: boolean
  isDaysDifferent: boolean
  title: string
  startDate: string
  endDate: string
  rentPerDay: number
  plateArea: number
  daysCount: number
  intervalTotalRent: number
  billEntries: BillEntry[]
}

export interface BillEntryCategory {
  name: string
  accountNumber: number
  billEntries: BillEntry[]
}

export interface Data {
  id: number
  discount: number
  daysDiscount: number
  total: number
  subtotal: number
  lostItemsCost: number
  otherCharges: number
  status: string
  accountType: string
  dailyBillAmount: number
  totalPaid: number
  totalRent: number
  totalRentWithoutConsumable: number
  consumableTotal: number
  wallsAreaRent: number
  sellItemsTotalCost: number
  totalCeilingArea: number
  totalPlateArea: number
  wallAreaPercentage: number
  totalDue: number
  outstandingTillLastEntry: number
  customer: Customer
  siteAddress: string
  openDate: string
  createDate: string
  updateDate: string
  closeDate: string
  ratePerSqft: number
  billAgeInDays: number
  totalSqftRent: number
  totalPerItemRent: number
  totalPlateAreaRent: number
  isRecurringEnabled: boolean
}
export interface BillIntervalData extends Data {
  billType: string
  billIntervals: BillInterval[]
  merchantEnteredDays: Record<string, unknown>
  hasBorrowedItems: boolean
  prevBalance: number
}
export interface LeftItemsResponse extends Data {
  enteries: [Item]
}
export interface FetchBillResponse extends Response {
  data: LeftItemsResponse
}

export interface AccountDetailsResponse extends Response {
  data: Bill
}

export function fetchBillById(bill_id: number) {
  return http
    .get(`/item/pvt/left_items?bill_id=${bill_id}`)
    .then((response: AxiosResponse) => response)
}

export function getAccountDetails(bill_id: number) {
  return http
    .get(`/bill/pvt/details?id=${bill_id}`)
    .then((response: AxiosResponse) => response)
}

export interface FetchBillBreakupResponse extends Response {
  data: BillIntervalData
}

export function fetchBillbBreakup(
  bill_id: number,
  sqftStartDate?: string,
  sqftEndDate?: string
) {
  let url = `/bill/pvt/v1?day_discount_enabled=true&id=${bill_id}`
  if (sqftStartDate) {
    url = `${url}&sqft_start_date=${sqftStartDate}`
  }
  if (sqftEndDate) {
    url = `${url}&sqft_end_date=${sqftEndDate}`
  }
  return http.get(url).then((response: AxiosResponse) => response)
}

/**-----------------------------------------------
 *  POST MerchantEnteredDays
 *-----------------------------------------------*/
export interface MerchantEnteredDaysResponse extends Response {
  data: string
}
export function postMerchantEnteredDays(request: Array<MerchantEnteredDays>) {
  return http
    .post(`/merchant_entered_days/pvt`, request)
    .then((response: AxiosResponse) => response)
}

/**-----------------------------------------------
 * DELETE MerchantEnteredDays
 *-----------------------------------------------*/

export function deleteMerchantEnteredDays(bill_id: number) {
  return http
    .delete(`/merchant_entered_days/pvt?bill_id=${bill_id}`)
    .then((response: AxiosResponse) => response)
}

/**-----------------------------------------------
 * Generate Bill
 *-----------------------------------------------*/
export interface GenerateBillRequest {
  id: number
  isBillSettingsSaved?: boolean
  lostItemsCost?: number
  subtotal?: number
  total?: number
  totalDue?: number
  totalPaid?: number
  totalRent?: number
  bill_time_paymnt?: number
  sqftStartDate?: string
  sqftEndDate?: string
  discount?: number
}
export interface GenerateBillResponse extends Response {
  data: string
}
export function generateBill(request: GenerateBillRequest) {
  const bill_time_paymnt = request.bill_time_paymnt
    ? request.bill_time_paymnt
    : 0
  delete request.bill_time_paymnt
  return http
    .put(`/bill/pvt/create_bill?bill_time_paymnt=${bill_time_paymnt}`, request)
    .then((response: AxiosResponse) => response)
}
