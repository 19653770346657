import {
  PendingItemsData,
  FetchPendingItemsAPIResponse,
} from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState } from '../../../services/types'

export type PendingItemState = BaseReducerState & {
  data: PendingItemsData[]
}

export const pendingItemsInitialState: PendingItemState = {
  loading: false,
  success: false,
  message: '',
  data: [],
  error: null,
}

export const fetchPendingItems = (
  state: PendingItemState = pendingItemsInitialState,
  action: { type: string; res: any }
): PendingItemState => {
  switch (action.type) {
    case `${ActionTypes.FTECH_PENDING_ITEMS}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.FTECH_PENDING_ITEMS}_SUCCESS`: {
      const { success, data, message } = <FetchPendingItemsAPIResponse>(
        action.res
      )
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.FTECH_PENDING_ITEMS}_FAILURE`:
      const res = <FetchPendingItemsAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.FTECH_PENDING_ITEMS}_RESET`:
      return {
        ...pendingItemsInitialState,
      }
  }
  return state
}
