import { FC, useState, useReducer, useEffect } from 'react'
import { Box, Grid, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

import { AccountTnc, AddPaymentRequest, Bill } from '../../../../services'

// Components
import { AppDialog } from '../../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { showAlertAction } from '../../../../store/alerts'
import { getTncAction, updateTncAction, updateTncResetAction } from '../../../../store/tnc'

export interface AddEditTncProps {
  children?: React.ReactNode
  onClose?: () => void
  accountTnc: AccountTnc
}

export const AddEditTncItem: FC<AddEditTncProps> = ({
  onClose,
  accountTnc,
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success,
    message,
  } = useAppSelector((state) => state.updateAccountTncState)
 
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [tnc, setTnc] = useState<string>(null)
  const [tncError, setTncError] = useState(false)

  
  useEffect(() => {
    if (success) {
      dispatchAction(getTncAction(accountTnc.account.id))
      dispatchAction(
        showAlertAction({
          message: 'Item Updated Successfully',
          severity: 'success',
        })
      )
      onClose() // To close the popup
    } else if (message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [success, message])

  const onChangeHandler = (e) => {
     setTncError(false)
     setTnc(e.target.value)
  }

  const submitHandle = () => {
     if(!tnc || !tnc.trim()) {
        setTncError(true)
        return
     } 
     accountTnc.tnc = tnc
     dispatchAction(updateTncAction(accountTnc)) 
  }

  useEffect(() => {
    if(accountTnc) {
      setTnc(accountTnc.tnc)
    }
    return () => {
      dispatchAction(updateTncResetAction()) 
    }
  }, [])
  return (
    <AppDialog
      id="edit-item"
      title={'Terms and Conditions'}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="md"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} mr={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2} mt={0.5}>
              <Grid item={true} xs={12}>
                <TextField
                    size='small'
                  name="tnc"
                  value={tnc}
                  label="Terms and Conditions"
                  onChange={onChangeHandler}
                  fullWidth
                  multiline
                  inputProps={{ maxLength: 8000}}
                  error={tncError}
                
                />
              </Grid>
            </Grid>
           
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
