import { FC, useState, useEffect } from 'react'

import {
  TimeTypeEnum,
  ItemEntry,
  EditItemRequest,
  EntryType,
} from '../../../services'

// Components
import { ConfirmationAppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  updateIssuedItemAction,
  updateIssuedItemResetAction,
} from '../../../store/items'
import { refreshFetchSlipsAction } from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'

export interface DeleteItemProps {
  onClose?: () => void
  onSubmitSuccess?: () => void
  item: ItemEntry
}

export const DeleteItem: FC<DeleteItemProps> = ({
  onClose,
  onSubmitSuccess,
  item,
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success: updateIssuedItemSuccess,
    error: updateIssuedItemError,
    message,
  } = useAppSelector((state) => state.updateIssuedItems)

  useEffect(() => {
    if (updateIssuedItemSuccess) {
      dispatchAction(refreshFetchSlipsAction())
      dispatchAction(
        showAlertAction({
          message: 'Item Deleted Successfully',
          severity: 'success',
        })
      )
      onSubmitSuccess() // To close the popup
    } else if (updateIssuedItemError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onSubmitSuccess() // To close the popup
    }
  }, [updateIssuedItemSuccess, updateIssuedItemError, message])

  const deleteHandler = () => {
    if (item.itemId) {
      const request = { id: item.id, quantity: 0, rate: item.rate }
      dispatchAction(updateIssuedItemAction(request))
    }
  }

  useEffect(() => {
    return () => {
      dispatchAction(updateIssuedItemResetAction()) // To manage the Alert
    }
  }, [])

  return (
    <ConfirmationAppDialog
      id="delete-item"
      title={item?.itemName}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onClose,
        },
        {
          text: 'Delete',
          color: 'error',
          variant: 'contained',
          onClickHandler: deleteHandler,
        },
      ]}
    >
      Do you really want to delete this item?
    </ConfirmationAppDialog>
  )
}
