import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, Customer, Staff } from '../types'

export interface AddStaffAPIResponse extends Response {
  data: Staff
}

export interface DeleteStaffAPIResponse extends Response {
  data: null
}

export interface FetchStaffAPIResponse extends Response {
  data: Staff[]
}

export type AddStaffRequest = {
  name: string
  mobile: string
  userLevel: string
}

export const addStaff = (request: AddStaffRequest) => {
  return http
    .put('/user/pvt/v2/add_staff', request)
    .then((response: AxiosResponse) => response)
}

export function fetchStaff() {
  return http.get('/user/pvt/staff').then((response: AxiosResponse) => response)
}

export function deleteStaff(mobile: string) {
  return http
    .delete(`/user/pvt/delete_staff?mobile=${mobile}`)
    .then((response: AxiosResponse) => response)
}
