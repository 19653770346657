import { http } from '../../../utils'

import { AxiosResponse } from 'axios'

export const ActionTypes = {
  GET_STORE_PAYMENT_DATA: 'GET_STORE_PAYMENT_DATA'
}

export const getStorePaymentData = (startDate?: string, endDate?: string) => {
  let url = '/store-payment/pvt/summary'
  if(startDate && endDate) {
    url = `/store-payment/pvt/summary?start_date=${startDate}&end_date=${endDate}`
  }
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export const getStorePaymentDataAction = (startDate?: string, endDate?: string) => ({
  type: ActionTypes.GET_STORE_PAYMENT_DATA,
  promise: getStorePaymentData(startDate, endDate),
})
