import { AxiosResponse } from 'axios'
import { getMinDuePcs, getMinTotalDue, getNoActivityDays, getNoPaymentDays, getSortBy, http } from '../../utils'
import { Response, Customer } from '../types'

export interface Account {
  id: number
  status: string
  accountType: string
  totalCeilingArea: number
  wallAreaPercentage: number
  totalDue: number
  customer: Customer
  siteAddress: string
  openDate: string
  updateDate: string
  ratePerSqft: number
  remainingPieces: number
}

export interface CustomerAccount {
  id: number
  name: string
  address: string
  mobile: string
  accounts: Account[]
}

export type RenewAccountRequest = {
  billId: number
  fromDate: string
  customerId: number
  shouldShiftDueAmount: boolean
}

export interface RenewAccountAPIResponse extends Response {
  data: boolean
}

export interface FetchCustomerAccountsResponse extends Response {
  data: CustomerAccount[]
}

export interface Agreement {
  id: number
  agreementTitle: string
}

export interface GetAgreementsAPIResponse extends Response {
  data: Agreement[]
}

export function fetchCustomerAccounts(status: string) {
  let url = `/bill/pvt/v6/${status}`
  const params = new URLSearchParams();
  const noPaymentDays = getNoPaymentDays()
  const noActivity = getNoActivityDays()
  const minTotalDue = getMinTotalDue()
  const minDuePcs = getMinDuePcs()

  const sortBy = getSortBy()

    if (sortBy != null && sortBy != '') {
        params.append('sort_type', sortBy.toString());
    }
  if (noPaymentDays != null) {
    params.append('no_payment_days', noPaymentDays.toString());
  }
  if (noActivity != null) {
    params.append('no_activity_days', noActivity.toString());
  }
  if (minTotalDue != null) {
    params.append('min_total_due', minTotalDue.toString());
  }
  if (minDuePcs != null) {
    params.append('min_due_pcs', minDuePcs.toString());
  }

  // If there are params, append them to the URL
  if (params.toString()) {
    url += `?${params.toString()}`;
  }
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export function closeAccount(accountId: number) {
  return http
    .put(`/bill/pvt/close_account/${accountId}`)
    .then((response: AxiosResponse) => response)
}

export function reopenAccount(accountId: number) {
  return http
    .get(`/bill/pvt/mark_unbilled/${accountId}`)
    .then((response: AxiosResponse) => response)
}

export function deleteAccount(accountId: number) {
  return http
    .delete(`/bill/pvt/delete/${accountId}`)
    .then((response: AxiosResponse) => response)
}

export function renewAccount(request: RenewAccountRequest) {
  return http
    .put('/bill/pvt/renew', request)
    .then((response: AxiosResponse) => response)
}

export function getAgreements(customerId: number) {
  return http
    .get(`/bill/pvt/agreements?customer_id=${customerId}`)
    .then((response: AxiosResponse) => response)
}
