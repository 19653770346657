import { fetchPendingBills } from '../../../services'

export const ActionTypes = {
  FETCH_PENDING_BILLS: 'FETCH_PENDING_BILLS',
  PENDING_BILLS_SEARCH_RESULTS: 'PENDING_BILLS_SEARCH_RESULTS',
  SHOW_PAYMENT_DUE_POPUP: 'SHOW_PAYMENT_DUE_POPUP',
  HIDE_PAYMENT_DUE_POPUP: 'HIDE_PAYMENT_DUE_POPUP',
}

export const fetchPendingBillsAction = (onlyRecentDue: boolean) => ({
  type: ActionTypes.FETCH_PENDING_BILLS,
  promise: fetchPendingBills(onlyRecentDue),
})

export const showPaymentDuePopupAction = () => ({
  type: ActionTypes.SHOW_PAYMENT_DUE_POPUP,
})

export const hidePaymentDuePopupAction = () => ({
  type: ActionTypes.HIDE_PAYMENT_DUE_POPUP,
})
