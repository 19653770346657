import { FC, useEffect, ReactNode } from 'react'
import { Grid } from '@mui/material'
import RenderSelectedComponent from './RenderSelectedComponent'
import { useAppDispatch } from '../../../hooks'
import { fetchDiscountedCategoriesAction } from '../../../store/itemCategory'
import { CreateBillManager } from './CreateBillManager'
import CustomStepper from './CustomStepper'
import { Bill } from '../../../services'
export interface BillPros {
  children?: ReactNode
  bill: Bill
  onClose: () => void
}
export let showOneDayDiscount = true
export let showDaysCount = true
const CreateBillPopup: FC<BillPros> = ({ bill, onClose }) => {
  const dispatchAction = useAppDispatch()
  const bill_id = bill?.id
  const plotArea = bill?.totalCeilingArea ? bill?.totalCeilingArea : 0
  const startDate = bill?.sqftStartDate ? bill?.sqftStartDate : undefined
  const endDate = bill?.sqftEndDate ? bill?.sqftEndDate : undefined
  const billType = bill?.billType
  const isFlateRate = bill?.isSqftFlatRate ? bill?.isSqftFlatRate : false
  showOneDayDiscount =
    billType === 'MIX' || billType === 'PER_ITEM' || !isFlateRate
  showDaysCount =
    billType === 'MIX' ||
    billType === 'PER_ITEM' ||
    (!isFlateRate && plotArea == 0)
  useEffect(() => {
    if (bill?.id) {
      dispatchAction(fetchDiscountedCategoriesAction(bill?.id))
    }
  }, [bill, dispatchAction])

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <CreateBillManager bill_id={+bill_id}>
          <CustomStepper />
          <RenderSelectedComponent
            plotArea={plotArea}
            startDate={startDate}
            endDate={endDate}
            billType={billType}
            isFlateRate={isFlateRate}
            onClose={onClose}
          />
        </CreateBillManager>
      </Grid>
    </Grid>
  )
}

export default CreateBillPopup
