import { Box, Button, Typography } from '@mui/material'
import { FC } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'

export interface EmptyWithAddButtonProps {
  buttonTitle: string
  message?: string
  onClickAdd:(buttonTitle: string) => void
}

export const EmptyWithAddButton: FC<EmptyWithAddButtonProps> = ({ onClickAdd, buttonTitle, message }) => {
  return (
    <Box
      sx={{
        height: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{textAlign:'center'}}>
      <Typography mb={2} sx={{color:'#314d67', fontSize:16}}>{message}</Typography>
      <Button
        startIcon={<AddCircleIcon />}
        variant="contained"
        sx={{ backgroundColor: '#314d67', width: 240, height: 41.5 }}
        onClick={() => {onClickAdd(buttonTitle)}}
      >
       {buttonTitle.toUpperCase()}
        <div style={{ width: 9 }} />
      </Button>
      </div>
    </Box>
  )
}
