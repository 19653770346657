import { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Box, styled, Container } from '@mui/material'

import PageContainerWrapper, { pageRightMargin } from '../PageContainerWrapper'
const PageTitle = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(2)};
`
)

interface PageTitleWrapperProps {
  children?: ReactNode
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  return <PageTitle sx={{marginRight:pageRightMargin / 16}}>{children}</PageTitle>
}

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageTitleWrapper
