import React, { FC, useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem, RadioGroup, FormControlLabel, Radio, TextField, Typography, Box, Grid, Divider, Badge, Tooltip, Button, styled } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { getNoPaymentDays, setNoPaymentDays, getNoActivityDays, setNoActivityDays, getMinTotalDue, setMinTotalDue, getMinDuePcs, setMinDuePcs, setSortBy, getSortBy } from '../../../../src/utils';
import { circleButtonSize, circleIconSize } from '../SiteRentReport/PageHeader';

interface FiltersProps {
    onApplyFilters: () => void;
}

const Filters: FC<FiltersProps> = ({ onApplyFilters }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Applied filters (persistent state)
  const [appliedPaymentActivity, setAppliedPaymentActivity] = useState<number>(0);
  const [appliedIssueActivity, setAppliedIssueActivity] = useState<number>(0);
  const [appliedMinDueAmount, setAppliedMinDueAmount] = useState<string>('');
  const [appliedMinBalanceItems, setAppliedMinBalanceItems] = useState<string>('');
  const [appliedSortBy, setAppliedSortBy] = useState<string>('UPDATE_DATE_DSC');

  // Temporary filter states (for when the popup is open)
  const [tempPaymentActivity, setTempPaymentActivity] = useState<number>(0);
  const [tempIssueActivity, setTempIssueActivity] = useState<number>(0);
  const [tempMinDueAmount, setTempMinDueAmount] = useState<string>('');
  const [tempMinBalanceItems, setTempMinBalanceItems] = useState<string>('');
  const [tempSortBy, setTempSortBy] = useState<string>('UPDATE_DATE_DSC');


  // Count active filters based on applied filters
  const activeFiltersCount = [
    appliedPaymentActivity,
    appliedIssueActivity,
    parseInt(appliedMinDueAmount) || 0,
    parseInt(appliedMinBalanceItems) || 0
  ].filter(val => val > 0).length;

  // Load the applied filter values when the user opens the popup
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    loadTempFilters();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setTempPaymentActivity(0);
    setTempIssueActivity(0);
    setTempMinDueAmount('');
    setTempMinBalanceItems('');
    setTempSortBy('UPDATE_DATE_DSC');

    // Reset sessionStorage values
    setNoPaymentDays('0');
    setNoActivityDays('0');
    setMinTotalDue('');
    setMinDuePcs('');
    setSortBy('');

    // Also reset applied filters
    setAppliedPaymentActivity(0);
    setAppliedIssueActivity(0);
    setAppliedMinDueAmount('');
    setAppliedMinBalanceItems('');
  };

  useEffect(() => {
    // Load applied filters from sessionStorage when the component mounts
    loadAppliedFilters();
  }, []);

  function loadAppliedFilters() {
    setAppliedPaymentActivity(parseInt(getNoPaymentDays() || '0', 10));
    setAppliedIssueActivity(parseInt(getNoActivityDays() || '0', 10));
    setAppliedMinDueAmount(getMinTotalDue() || '');
    setAppliedMinBalanceItems(getMinDuePcs() || '');
    setAppliedSortBy(getSortBy() || '');

  }

  function loadTempFilters() {
    // Load the temporary filters based on the currently applied filters
    setTempPaymentActivity(appliedPaymentActivity);
    setTempIssueActivity(appliedIssueActivity);
    setTempMinDueAmount(appliedMinDueAmount);
    setTempMinBalanceItems(appliedMinBalanceItems);
    setTempSortBy(appliedSortBy);
  }

  const handleApplyFilters = () => {
    // Update the applied filters
    setAppliedPaymentActivity(tempPaymentActivity);
    setAppliedIssueActivity(tempIssueActivity);
    setAppliedMinDueAmount(tempMinDueAmount);
    setAppliedMinBalanceItems(tempMinBalanceItems);
    setAppliedSortBy(tempSortBy);

    // Save the applied filters to sessionStorage
    setNoPaymentDays(tempPaymentActivity.toString());
    setNoActivityDays(tempIssueActivity.toString());
    setMinTotalDue(tempMinDueAmount);
    setMinDuePcs(tempMinBalanceItems);
    setSortBy(tempSortBy);
    // Apply the filters
    onApplyFilters();

    handleClose();
  };

  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
      backgroundColor: '#ffffff !important', // Disable hover background
    },
    '&.Mui-selected': {
      backgroundColor: '#ffffff !important', // Disable selected background
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#ffffff !important', // Disable focus background
    },
  }));

  return (
    <>
      <Badge badgeContent={activeFiltersCount > 0 ? activeFiltersCount : null} color="primary">
        <Tooltip title="Filters">
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              backgroundColor: activeFiltersCount > 0 ? '#f44336' : 'black', // Red if filters are applied
              width: {circleButtonSize},
              height: {circleButtonSize},
              borderRadius: '50%', // Circular button
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            <FilterListIcon sx={{width: circleIconSize, height: circleIconSize}}/>
          </IconButton>
        </Tooltip>
      </Badge>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{ style: { width: 800 } }}
        
      >
        <MenuItem>
          <Grid container spacing={2}>
          <Grid item xs={4}>
              <Typography gutterBottom variant="h5">Sort by</Typography>
              <RadioGroup
                value={tempSortBy}
                onChange={(e) => setTempSortBy(e.target.value)}
              >
                <FormControlLabel value={'UPDATE_DATE_DSC'} control={<Radio />} label="Update Date (Default)" />
                <FormControlLabel value={'ACCOUNT_NUMBER_DESC'} control={<Radio />} label="Site(Account) Number" />
                <FormControlLabel value={'CUSTOMER_NAME_ASC'} control={<Radio />} label="Customer Name" />

             </RadioGroup>
            </Grid>
            {/* Payment Activity and Issue Activity side by side */}
            <Grid item xs={4}>
              <Typography gutterBottom variant="h5">Filter by Payment Activity</Typography>
              <RadioGroup
                value={tempPaymentActivity}
                onChange={(e) => setTempPaymentActivity(Number(e.target.value))}
              >
                <FormControlLabel value={0} control={<Radio />} label="All (Default)" />
                <FormControlLabel value={15} control={<Radio />} label="No payment in last 15 days" />
                <FormControlLabel value={30} control={<Radio />} label="No payment in last 30 days" />
                <FormControlLabel value={45} control={<Radio />} label="No Payment in Last 45 days" />
                <FormControlLabel value={60} control={<Radio />} label="No payment in last 2 Months" />
                <FormControlLabel value={90} control={<Radio />} label="No Payment in Last 3 Months" />
              </RadioGroup>
            </Grid>

            <Grid item xs={4}>
              <Typography gutterBottom variant="h5">Filter by Issue/Receive Activity</Typography>
              <RadioGroup
                value={tempIssueActivity}
                onChange={(e) => setTempIssueActivity(Number(e.target.value))}
              >
                <FormControlLabel value={0} control={<Radio />} label="All Accounts (Default)" />
                <FormControlLabel value={15} control={<Radio />} label="No activity in last 15 days" />
                <FormControlLabel value={30} control={<Radio />} label="No activity in last 30 days" />
                <FormControlLabel value={45} control={<Radio />} label="No activity in last 45 days" />
                <FormControlLabel value={60} control={<Radio />} label="No activity in last 2 Months" />
                <FormControlLabel value={90} control={<Radio />} label="No activity in last 3 Months" />
              </RadioGroup>
            </Grid>
          </Grid>
        </MenuItem>

        <Divider />

        {/* Minimum Due Amount */}
        <MenuItem>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ mr: 2 }}>Minimum Due Amount (INR)</Typography>
            <TextField
              size="small"
              type="number"
              value={tempMinDueAmount}
              onChange={(e) => setTempMinDueAmount(e.target.value)}
              inputProps={{ min: 0, max: 500000 }}
              sx={{ width: '150px' }}
            />
          </Box>
        </MenuItem>

        <Divider />

        {/* Minimum balance items */}
        <MenuItem>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
            <Typography gutterBottom variant="h5">Minimum balance items</Typography>
            <TextField
              size="small"
              type="number"
              value={tempMinBalanceItems}
              onChange={(e) => setTempMinBalanceItems(e.target.value)}
              inputProps={{ min: 0, max: 15000000 }}
              sx={{ width: '150px' }}
            />
          </Box>
        </MenuItem>

        <Divider />

        {/* Close, Reset, and Apply Buttons */}
        <MenuItem>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: '100%', pt: 3 }}>
            <Button onClick={handleClose} variant="contained" color="error">Close</Button>
            <Box>
              <Button onClick={handleReset} variant="contained" color="warning" sx={{ mr: 1 }}>Reset</Button>
              <Button onClick={handleApplyFilters} sx={{ backgroundColor: '#4caf50' }} variant="contained" color="primary">Apply</Button>
            </Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Filters;
