import { GenerateBillResponse } from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState } from '../../../services/types'

type GenerateBillState = BaseReducerState & { data: string }

const initialState: GenerateBillState = {
  loading: false,
  success: false,
  message: '',
  data: null,
}

export const generateBill = (
  state: GenerateBillState = initialState,
  action: { type: string; res: GenerateBillResponse }
): GenerateBillState => {
  switch (action.type) {
    case `${ActionTypes.GENERATE_BILL}_REQUEST`:
      return {
        ...initialState,
        loading: true,
      }

    case `${ActionTypes.GENERATE_BILL}_SUCCESS`:
      const { success, data, message } = <GenerateBillResponse>action.res

      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    case `${ActionTypes.GENERATE_BILL}_FAILURE`:
      const { message: errorMessage } = <GenerateBillResponse>action.res

      return {
        loading: false,
        success: false,
        message: errorMessage,
        data: null,
      }
    case `${ActionTypes.GENERATE_BILL}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
