import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response } from '../types'

export interface Activity {
  id: number
  activityByName: string
  activityTitle: string
  accountDetails: string
  activityDescription: string
  createDate: string
  isHeader: boolean
}

export interface FetchActivityResponse extends Response {
  data: Activity[]
}

export function fetchActivities(date: string) {
  let queryString = '?'
  if (date) {
    queryString += 'date=' + date
  }

  return http
    .get(`/activity/pvt${queryString}`)
    .then((response: AxiosResponse) => response)
}
