import { useEffect, FC } from 'react'
import { useGoogleAnalytics } from './../../hooks'

type LogEvent = {
  event: {
    eventName: string
    eventParams?: {
      [key: string]: any
    }
  }
}
export const LogEventOnPageLoad: FC<LogEvent> = ({ event }) => {
  const { eventName, eventParams } = event
  const analytis = useGoogleAnalytics()
  useEffect(() => {
    analytis(eventName, eventParams)
  }, [])
  return null
}
