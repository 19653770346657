import { Item, FetchItemResponse } from '../../../services'
import { ActionTypes, prepareSelectOptions } from '../actions'
import { BaseReducerState, SelectOptions } from '../../../services/types'

export type SelectOtionItemManageState = BaseReducerState & {
  data: Item[]
  selectOptions: SelectOptions[]
}

const initialState: SelectOtionItemManageState = {
  loading: false,
  success: false,
  message: '',
  error: null,
  data: [],
  selectOptions: [],
}
export const manageOptionsItems = (
  state: SelectOtionItemManageState = initialState,
  action: { type: string; res: any }
): SelectOtionItemManageState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_ITEMS_OPTIONS}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
      }

    case `${ActionTypes.FETCH_ITEMS_OPTIONS}_SUCCESS`: {
      const { success, data, message } = <FetchItemResponse>action.res
      const selectOptions = prepareSelectOptions(data)
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
        selectOptions: selectOptions,
      }
    }

    case `${ActionTypes.FETCH_ITEMS_OPTIONS}_FAILURE`:
      const { message, error } = <FetchItemResponse>action.res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.UPDATE_SELECT_OPTIONS}`:
      return {
        ...state,
        selectOptions: action.res,
      }
  }
  return state
}
