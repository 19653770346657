import { Autocomplete, Grid, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import SelectStateItem, {
  StateOption,
  getState,
} from '../content/applications/Customers/SelectStateItem'
import { useAppDispatch, useAppSelector } from '../hooks'
import { fetchGSTINAction, fetchGSTINResetAction } from '../store/gstr'
import { GSTINDetails } from 'src/services/gstr'
import { da } from 'date-fns/locale'

export interface GSTINDetailsItemPropsV2 {
  children?: React.ReactNode
  initialGstin?: string
  required?: boolean
  gstinError: boolean
  onUpdate: (gstin?: string, gstinObj?: GSTINDetails) => void
  minWidth?: number
}

const GSTINDetailsItemV2: FC<GSTINDetailsItemPropsV2> = ({
  initialGstin,
  required,
  onUpdate,
  minWidth,
  gstinError,
}) => {
  const [gstin, setGstin] = useState<string>(initialGstin)
  const dispatchAction = useAppDispatch()
  const { success, data } = useAppSelector((state) => state.fetchGSTINDetails)

  const onChangeHandler = (e) => {
    const {
      target: { name, value },
    } = e
    onUpdate(value, null)
    if (value != gstin && value.length === 15) {
      dispatchAction(fetchGSTINAction(value))
    }
    setGstin(value)
  }

  useEffect(() => {
    if (gstin) {
      onUpdate(gstin, data)
    }
    dispatchAction(fetchGSTINResetAction())
  }, [success])

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          size="small"
          required={required}
          name="gstin"
          value={gstin ?? ''}
          label="GSTIN"
          error={gstinError}
          onChange={onChangeHandler}
          fullWidth
          sx={{ minWidth: minWidth ? minWidth : 150 }}
        ></TextField>
      </Grid>
    </Grid>
  )
}

export default GSTINDetailsItemV2
