import {
  BaseReducerState,
  Bill,
  RenewAccountAPIResponse,
} from '../../../services'
import { ActionTypes } from '../actions'

export type RenewAccountState = BaseReducerState & {
  data: boolean
}

const renewAccountInitialState: RenewAccountState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const renewAccount = (
  state: RenewAccountState = renewAccountInitialState,
  action: { type: string; res: any }
): RenewAccountState => {
  switch (action.type) {
    case `${ActionTypes.RENEW_ACCOUNT}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.RENEW_ACCOUNT}_SUCCESS`: {
      const { success, data, message } = <RenewAccountAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }

    case `${ActionTypes.RENEW_ACCOUNT}_FAILURE`:
      const res = <RenewAccountAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.RENEW_ACCOUNT}_RESET`:
      return { ...renewAccountInitialState }
  }
  return state
}
