import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { FC, useState, useEffect, useRef, ReactNode } from 'react'
import { useNavigate, useNavigation, useParams } from 'react-router'
import { Empty } from '../../../components'
import { setAccessToken } from '../../../utils'

export interface StartTestingProps {
  children?: React.ReactNode
}

const StartTesting: FC<StartTestingProps> = ({}) => {
  const { token } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      setAccessToken(token)
    }
  }, [token])
  return (
    <Empty>
      <Grid
        container
        direction={'column'}
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Grid item xs>
          {token} <br /> <br />
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            onClick={() => {
              setAccessToken(token)
              navigate('/dashboard/current-account')
            }}
          >
            START TESTING
          </Button>
        </Grid>
      </Grid>
    </Empty>
  )
}

export default StartTesting
