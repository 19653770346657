import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, LedgerResponse } from '../types'


export interface FetchLedgersAPIResponse extends Response {
  data: LedgerResponse
}

export type FetchLedgerRequest = {
  accountId: number
  fromDate?: string
  toDate?: string
}

export const fetchLedger = (request: FetchLedgerRequest) => {
  return http
    .post('/ledger/pvt', request)
    .then((response: AxiosResponse) => response)
}

