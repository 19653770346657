export const usePayRazorpay = () => {
  const makeRazorPayment = (
    subscribePlanData,
    successCallback,
    failureCallback
  ) => {
    const {
      orderAmount,
      orderToken,
      currency,
      appId,
      storeName,
      email,
      userPhone,
      address,
      description,
    } = subscribePlanData

    const options = {
      key: appId,
      amount: (orderAmount * 100).toString(),
      currency: currency,
      name: 'ANJNEETA',
      description: description,
      order_id: orderToken,
      prefill: {
        name: storeName,
        email: email,
        contact: userPhone,
      },
      notes: {
        address: address,
      },
      theme: {
        color: '#3399cc',
      },
      handler: successCallback,
    }
    const paymentObject = new (window as any).Razorpay(options)
    paymentObject.open()
    paymentObject.on('payment.failed', failureCallback)
  }
  return [makeRazorPayment]
}
