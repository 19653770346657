import { FC, useEffect } from 'react'
import { Bill } from 'src/services'

// Components
import { ConfirmationAppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'
import {
  closeAccountAction,
  closeAccountResetAction,
  fetchCustomerAccountAction,
} from '../../../store/customerAccounts'

export interface CloseAccountProps {
  onClose: () => void
  account: Bill
}

export const CloseAccount: FC<CloseAccountProps> = ({ onClose, account }) => {
  const dispatchAction = useAppDispatch()
  const {
    success: closeAccountSuccess,
    error: closeAccountError,
    message,
  } = useAppSelector((state) => state.closeAccount)

  useEffect(() => {
    if (closeAccountSuccess) {
      dispatchAction(
        showAlertAction({
          message: 'Account closed successfully',
          severity: 'success',
        })
      )
      dispatchAction(fetchCustomerAccountAction(account.status))
      onClose() // To close the popup
    } else if (closeAccountError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onClose()
    }
  }, [closeAccountSuccess, closeAccountError, message])

  const deleteHandler = () => {
    dispatchAction(closeAccountAction(account.id))
  }

  useEffect(() => {
    return () => {
      dispatchAction(closeAccountResetAction()) // To manage the Alert
    }
  }, [])

  return (
    <ConfirmationAppDialog
      id="delete-item"
      title={`Close Account: ${account.id}`}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onClose,
        },
        {
          text: 'Close',
          color: 'error',
          variant: 'contained',
          onClickHandler: deleteHandler,
        },
      ]}
    >
      Do you really want to close this account?
    </ConfirmationAppDialog>
  )
}
