import { FC, useState } from 'react'
import {
  Grid,
  Typography,
  ListItem,
  Tooltip,
  IconButton,
  useTheme,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'

import { TabsEnums } from '../../../components'
import { ItemEntry, EntryType } from '../../../services'
import './slips_style.css'
import { AddEditPaymentItem } from './AddEditPayment'
import { DeletePayment } from './DeletePayment'
import {
  currencyFormatter,
  getDisplayDateTime,
  getShortFormattedDate,
} from '../../../utils'

const ItemName: FC<{ name: string; id?: string }> = ({ name, id }) => {
  return (
    <Typography
      id="item_name"
      data-cy={id}
      variant="body2"
      sx={{
        py: 0,
        pl: 3,
        m: 1.2,
      }}
    >
      {name}
    </Typography>
  )
}

interface PaymentListItemComponentProps {
  item: ItemEntry
  componentName: TabsEnums
  billId: number
}

export const PaymentListItemComponent: FC<PaymentListItemComponentProps> = ({
  item,
  componentName,
  billId,
}) => {
  const theme = useTheme()
  const issuedRecievedText = item.entryType === EntryType.IN ? 'CRDT' : 'DEBT'

  const [editItem, openEditItem] = useState<boolean>(false)
  const [deleteItem, openDeleteItem] = useState<boolean>(false)
  const addPaymentRequest = {
    billId: billId,
    paymentId: item.paymentId,
    amount: item.receivedAmount,
    paymentDate: item.entryDate,
    note: item.note,
  }
  const slipDate = getShortFormattedDate(new Date(item.entryDate))
  const testIdPrefix = `id_typo_${slipDate}_${item.itemName}`
  return (
    <ListItem
      id="slip_row"
      sx={{
        pt: 0.5,
        pb: 0.5,
      }}
      key={item.id}
    >
      <Grid container sx={{ p: 0 }} alignItems="center" justifyContent="center">
        <Grid item xs={4} sx={{ alignItems: 'center', pr: 2 }}>
          <div
            style={{
              height: 10,
              width: 10,
              borderRadius: 5,
              backgroundColor: item.barColorCode,
              marginLeft: 20,
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
          <ItemName
            name={
              !item.note || item.note.trim().length === 0
                ? item.itemName
                : item.note
            }
            id={`${testIdPrefix}_name`}
          />
        </Grid>

        <Grid item xs={1.5} sx={{ alignItems: 'center' }}>
          <Typography
            id="received_amount"
            data-cy={`${testIdPrefix}_received_amount`}
            variant="body2"
            sx={{
              m: 0,
            }}
          >
            {currencyFormatter.format(item.receivedAmount)}
          </Typography>
        </Grid>

        <Grid item xs={2.75} sx={{ alignItems: 'center' }}>
          <Typography
            id="entry_date"
            variant="body2"
            data-cy={`${testIdPrefix}_entry_date`}
            sx={{
              m: 0,
            }}
          >
            {getDisplayDateTime(item.entryDate)}
          </Typography>
        </Grid>

        <Grid
          item
          xs={2.75}
          sx={{ alignItems: 'center', alignContent: 'center' }}
        >
          <div
            className={`payment${issuedRecievedText}`}
            data-cy={`${testIdPrefix}_entry_type`}
          >
            {issuedRecievedText}
          </div>
        </Grid>

        <Grid item xs={1}>
          <>
            <Tooltip title="Edit" arrow>
              <IconButton
                id="edit_entry"
                data-cy={`${testIdPrefix}_button_edit`}
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter,
                  },
                }}
                color="inherit"
                onClick={() => {
                  openEditItem(true)
                }}
              >
                <EditCalendarIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <IconButton
                data-cy={`${testIdPrefix}_button_delete`}
                id="delete_entry"
                sx={{
                  '&:hover': { background: theme.colors.error.lighter },
                  color: 'crimson',
                }}
                color="inherit"
                onClick={() => {
                  openDeleteItem(true)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        </Grid>
      </Grid>
      {editItem && (
        <AddEditPaymentItem
          item={addPaymentRequest}
          onClose={() => {
            openEditItem(false)
          }}
          onSubmitSuccess={() => {
            openEditItem(false)
          }}
        />
      )}
      {deleteItem && (
        <DeletePayment
          item={item}
          onClose={() => {
            openDeleteItem(false)
          }}
          onSubmitSuccess={() => {
            openDeleteItem(false)
          }}
        />
      )}
    </ListItem>
  )
}
