import {
  AddCustomersResponse,
  AddPaymentAPIResponse,
  AddPaymentResponse,
  BaseReducerState,
  Customer,
} from '../../../services'
import { ActionTypes } from '../actions'

export type AddCustomerState = BaseReducerState & {
  data: Customer
}

export const initialState: AddCustomerState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const addCustomer = (
  state: AddCustomerState = initialState,
  action: { type: string; res: any }
): AddCustomerState => {
  switch (action.type) {
    case `${ActionTypes.ADD_CUSTOMER}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.ADD_CUSTOMER}_SUCCESS`: {
      const { success, data, message } = <AddCustomersResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.ADD_CUSTOMER}_FAILURE`:
      const res = <AddCustomersResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.ADD_CUSTOMER}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
