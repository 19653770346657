import React from 'react'
import { Autocomplete, TextField, FormHelperText } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FormInputProps } from '../FormInputProps'

type FormInputAutosuggestProps = FormInputProps & {
  options: Array<{
    label: string
    id?: number | string
  }>
  readOnly?: boolean
  onSelect?: (option) => void
}

export const FormInputAutosuggest: React.FC<FormInputAutosuggestProps> = ({
  name,
  control,
  label,
  options,
  readOnly = false,
  onSelect,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field
        return (
          <>
            <Autocomplete
              size="small"
              id={name}
              options={options}
              value={
                value
                  ? options.find((option) => {
                      return value === option?.id
                    }) ?? null
                  : null
              }
              onChange={(event: any, newValue) => {
                onChange(newValue ? newValue?.id : null)
                if (onSelect) {
                  onSelect(newValue)
                }
              }}
              readOnly={readOnly}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  inputRef={ref}
                  fullWidth
                  error={!!error}
                  sx={{ backgroundColor: readOnly ? '#efefef' : 'white' }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'disabled',
                    // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <FormHelperText style={{ color: 'red' }}>
              {error?.message}
            </FormHelperText>
          </>
        )
      }}
    />
  )
}
