import { AccountTnc } from '../../../services'
import { getAccountTnc, updateAccountTnc } from '../../../services/tnc'

export const ActionTypes = {
  ADD_OR_UPDATE_TNC: 'ADD_OR_UPDATE_TNC',
  GET_TNC: 'GET_TNC',
}

export const updateTncAction = (request: AccountTnc) => ({
  type: ActionTypes.ADD_OR_UPDATE_TNC,
  promise: updateAccountTnc(request),
})

export const updateTncResetAction = () => ({
  type: `${ActionTypes.ADD_OR_UPDATE_TNC}_RESET`,
})

export const getTncAction = (accountId: number) => ({
  type: ActionTypes.GET_TNC,
  promise: getAccountTnc(accountId),
})

export const getTncResetAction = () => ({
  type: `${ActionTypes.GET_TNC}_RESET`,
})