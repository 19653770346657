import { FC, Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import SitesStockSummaryHeader from './PageHeader';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import { tableStyles } from '../Reports';
import { getSitesStockSummaryDataAction, getSitesStockSummaryResetAction } from './actions';
import { showAlertAction } from '../../../store/alerts';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getDisplayDateTime } from '../../../../src/utils';


export interface SitesStockSummaryProps {}

export const SitesStockSummary: FC<SitesStockSummaryProps> = ({}) => {
  const classes = tableStyles();
  const { message, success, data: siteReportRows } = useAppSelector(
    (state) => state.sitesStockSummaryState
  );
  const dispatchAction = useAppDispatch();
  const [searchString, setSearchString] = useState('');
  const [filteredRows, setFilteredRows] = useState(siteReportRows);

  useEffect(() => {
    dispatchAction(getSitesStockSummaryResetAction());
    dispatchAction(getSitesStockSummaryDataAction());
  }, [dispatchAction]);

  useEffect(() => {
    if (!success && message) {
      dispatchAction(
        showAlertAction({
          message: message,
          severity: 'error',
        })
      );
    }
  }, [siteReportRows, message, success]);

  useEffect(() => {
    const normalizedSearch = searchString.trim().toLowerCase();
    const isExactMatch = searchString.endsWith('  ');

    if (normalizedSearch.length > 0) {
      const filtered = siteReportRows.filter((bill) => {
        const searchTarget = `${bill.party} ${bill.siteAddress} ${bill.siteNo} ${bill.itemName}`.toLowerCase();
        return isExactMatch
          ? ( bill.party?.toLowerCase() === normalizedSearch || bill.siteAddress?.toLowerCase() === normalizedSearch || `${bill.siteNo}`.toLowerCase() === normalizedSearch || bill.itemName?.toLowerCase() === normalizedSearch)
          : searchTarget.includes(normalizedSearch) && bill.party !== 'SUM';
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(siteReportRows);
    }
  }, [searchString, siteReportRows]);

  const exportTableToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ['S.N.', 'Site', 'Item', 'Issued', 'Received', 'Balance'];
    const tableRows: any[] = [];
    let issued = 0;
    let received = 0;
    let balance = 0;
    let count = 0;
  
    filteredRows.forEach((bill) => {
      const isNotSum = bill.party !== 'SUM';
      issued += isNotSum ? bill.issuedQuantity : 0;
      received += isNotSum ? bill.receivedQuantity : 0;
      balance += isNotSum ? bill.balanceQuantity : 0;
  
      const rowData = [
        isNotSum ? ++count : '',
        isNotSum ? bill.siteAddress : 'Sum',
        isNotSum ? bill.itemName : '',
        bill.issuedQuantity,
        bill.receivedQuantity,
        bill.balanceQuantity,
      ];
      tableRows.push(rowData);
    });
  
    if (tableRows.length > 0) {
      const grandSumRow = [
        '',
        'Grand Sum',
        '',
        issued,
        received,
        balance,
      ];
      tableRows.push(grandSumRow);
    }

    doc.text('Balance Items '+(getDisplayDateTime(new Date())), 14, 14);
    

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: 'grid',
      didParseCell: (data) => {
        const isGrandSumRow = data.row.raw[1] === 'Grand Sum' || data.row.raw[1] === 'Sum';
        if (isGrandSumRow) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = '#000000'; // Optional: Change text color
        }
      },
    });
  
    doc.save('site-stock-summary.pdf');
  };
  
  

  let count = 0;
  return (
    <>
      <Helmet>
        <title>Billing Report</title>
      </Helmet>
      <PageTitleWrapper>
        <SitesStockSummaryHeader
          searchString={searchString}
          setSearchString={setSearchString}
          onPdfClick={exportTableToPDF}
        />
      </PageTitleWrapper>
      <PageContainerWrapper>
       
        <Table aria-label="billing report">
          <TableHead>
            <TableRow>
              <TableCell width={20}>S.N.</TableCell> {/* Serial Number column */}
              <TableCell className={classes.tableCell}>Party Name</TableCell>
              <TableCell className={classes.tableCell}>Site</TableCell>
              <TableCell className={classes.tableCell}>Site ID</TableCell>
              <TableCell className={classes.tableCell} sx={{width: 200}}>Item</TableCell>
              <TableCell className={classes.tableCell}>Issued</TableCell>
              <TableCell className={classes.tableCell}>Received</TableCell>
              <TableCell className={classes.tableCell}>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows &&
              filteredRows.map((bill, index) => {
                const isNotSum = !(bill.party === 'SUM');
                return (
                  <Fragment key={index}>
                    <TableRow>
                      <TableCell>{isNotSum ? ++count : ''}</TableCell>
                      <TableCell colSpan={isNotSum ? 0 : 4} className={classes.tableCell}>
                        <Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.party}</Typography>
                      </TableCell>
                      {isNotSum && (
                        <>
                          <TableCell className={classes.tableCell}>{bill.siteAddress}</TableCell>
                          <TableCell className={classes.tableCell}>{bill.siteNo}</TableCell>
                          <TableCell className={classes.tableCell}>{bill.itemName}</TableCell>
                        </>
                      )}
                      <TableCell className={classes.tableCell}>
                        <Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.issuedQuantity}</Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.receivedQuantity}</Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.balanceQuantity}</Typography>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </PageContainerWrapper>
    </>
  );
};

export default SitesStockSummary;
