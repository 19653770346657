import { useState, FC, useEffect } from 'react'
import {
  Grid,
  Table,
  TableCell,
  TableRow,
  Switch,
  Typography,
  TextField,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getUserProfileDataAction } from '../../../../store/users'
import { UpdateSettingsRequest } from 'src/services'
import CustomNumberField from '../profile/CustomNumberField'

export interface AccountSettingsProps {
  onChange: (newSettings: UpdateSettingsRequest) => void
  invalidEmail: boolean
  isOnboarding?: boolean
  invalidUpiId: boolean
}

const AccountSettings: FC<AccountSettingsProps> = ({
  isOnboarding,
  onChange,
  invalidEmail,
  invalidUpiId
}) => {
  // Existing State Variables
  const [isDriveBackUpEnabled, setDriveBackupEnabled] = useState<boolean>(false)
  const [is30DaysMonthEnabled, set30DaysMonthEnabled] = useState<boolean>(false)
  const [enabledChallanBilling, setEnabledChallanBilling] = useState<boolean>(false)
  const [isOtherStoreEnabled, setOtherStoreEnabled] = useState<boolean>(false)
  const [isGSTEnabled, setGSTEnabled] = useState<boolean>(false)
  const [isRemainingDayDiscountEnabled, setRemainingDayDiscountEnabled] =
    useState<boolean>(false)
  const [is3InchPrinter, set3InchPrinter] = useState<boolean>(false)
  const [isMixTypeAllowed, setMixTypeAllowed] = useState<boolean>(true)
  const [isReoccuringBillsOn, setReoccuringBillsOn] = useState<boolean>(false)
  const [isMinDaysEnabled, setMinDaysEnabled] = useState<boolean>(false)
  const [billFormat, setBillFormat] = useState('FORMAT_1')
  const [backupEmail, setBackupEmail] = useState<string | null>(null)
  const [upiId, setUPIId] = useState<string | null>(null)
  const [upiId2, setUPIId2] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<boolean | null>(null)
  const [upiError, setUPIError] = useState<boolean | null>(null)

  const [sgstRate, setSgstRate] = useState<number>(9)
  const [cgstRate, setCgstRate] = useState<number>(9)
  
  // **New State Variables for Challan Prefixes**
  const [outwardChallanPrefix, setOutwardChallanPrefix] = useState<string>('')
  const [inwardChallanPrefix, setInwardChallanPrefix] = useState<string>('')
  const [invoiceNoPrefix, setInvoiceNoPrefix] = useState<string>('')


  const { data } = useAppSelector((state) => state.userData)
  const dispatchAction = useAppDispatch()
  const tabs = [
    { value: 'edit_profile', label: 'Edit Profile' },
    { value: 'security', label: 'Passwords/Security' },
  ]

  useEffect(() => {
    dispatchAction(getUserProfileDataAction())
  }, [dispatchAction])

  useEffect(() => {
    setEmailError(invalidEmail)
  }, [invalidEmail])

  useEffect(() => {
    setUPIError(invalidUpiId)
  }, [invalidUpiId])

  useEffect(() => {
    const request: UpdateSettingsRequest = {
      id: data?.config?.id,
      isOtherStoreEnabled: isOtherStoreEnabled,
      isGSTEnabled: isGSTEnabled,
      isRemainingDayDiscountEnabled: isRemainingDayDiscountEnabled,
      is3InchPrinter: is3InchPrinter,
      isMinDaysEnabled: isMinDaysEnabled,
      isMixTypeAllowed: isOnboarding ? true : isMixTypeAllowed,
      billFormat: billFormat,
      isRecurringBillsEnabled: isReoccuringBillsOn,
      backupEmail: backupEmail,
      is30DaysMonthEnabled: is30DaysMonthEnabled,
      sgst: sgstRate,
      cgst: cgstRate,
      upiId: upiId,
      enabledChallanBilling: enabledChallanBilling,
      outwardChallanPrefix: outwardChallanPrefix, // New request param
      inwardChallanPrefix: inwardChallanPrefix,    // New request param
      upiId2: upiId2,
      invoiceNoPrefix: invoiceNoPrefix
    }
    onChange(request)
  }, [
    data,
    isDriveBackUpEnabled,
    isOtherStoreEnabled,
    isGSTEnabled,
    isRemainingDayDiscountEnabled,
    is3InchPrinter,
    isMinDaysEnabled,
    isMixTypeAllowed,
    billFormat,
    isReoccuringBillsOn,
    backupEmail,
    is30DaysMonthEnabled,
    sgstRate,
    cgstRate,
    upiId,
    enabledChallanBilling,
    outwardChallanPrefix, // Added to dependencies
    inwardChallanPrefix,   // Added to dependencies
    upiId2,
    invoiceNoPrefix
  ])

  useEffect(() => {
    if (data && data.config) {
      setOtherStoreEnabled(data.config.isOtherStoreEnabled)
      setGSTEnabled(data.config.isGSTEnabled)
      setRemainingDayDiscountEnabled(data.config.isRemainingDayDiscountEnabled)
      set3InchPrinter(data.config.is3InchPrinter)
      setMinDaysEnabled(data.config.isMinDaysEnabled)
      setMixTypeAllowed(isOnboarding ? true : data.config.isMixTypeAllowed)
      setReoccuringBillsOn(data.config.isRecurringBillsEnabled)
      set30DaysMonthEnabled(data.config.is30DaysMonthEnabled)
      setEnabledChallanBilling(data.config.enabledChallanBilling)
      setSgstRate(data.config.sgst)
      setCgstRate(data.config.cgst)
      setBillFormat(data.config.billFormat ? data.config.billFormat : 'FORMAT_1')
      setBackupEmail(data.config.backupEmail)
      setUPIId(data.config.upiId)
      setUPIId2(data.config.upiId2)
      // **Set Initial Values for Challan Prefixes**
      setOutwardChallanPrefix(data.config.outwardChallanPrefix || '')
      setInwardChallanPrefix(data.config.inwardChallanPrefix || '')
      setInvoiceNoPrefix(data.config.invoiceNoPrefix || '')

    }
  }, [data, isOnboarding])

  return (
    <>
      <Table>
        {/* Existing TableRow for Backup Email and UPI ID */}
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">
                  Enter daily backup email address, and UPI id for payment requests
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  size='small'
                  value={backupEmail ?? ""}
                  onChange={(e) => {
                    setEmailError(false)
                    setBackupEmail(e.target.value)
                  }}
                  label="Backup email"
                  sx={{ width: 300, mt: 1 }}
                  error={!!emailError}
                  helperText={emailError ? "Invalid email address" : ""}
                />
                <TextField
                  size='small'
                  value={upiId ?? ""}
                  onChange={(e) => {
                    setUPIError(false)
                    setUPIId(e.target.value)
                  }}
                  label="Your UPI ID"
                  sx={{ width: 150, mt: 1, ml:1 }}
                  error={!!upiError}
                  helperText={upiError ? "Invalid UPI ID" : ""}
                />
                 <TextField
                  size='small'
                  value={upiId2 ?? ""}
                  onChange={(e) => {
                    setUPIId2(e.target.value)
                  }}
                  label="Your UPI ID (Non GST)"
                  sx={{ width: 150, mt: 1, ml:1 }}
                />
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>

        {/* **New TableRow for Challan Number Prefixes** */}
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">
                  Enter challan/invoice number prefixes
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  size='small'
                  value={outwardChallanPrefix}
                  onChange={(e) => setOutwardChallanPrefix(e.target.value)}
                  label="Outward Challan Prefix"
                  sx={{ width: 200, mt: 1 }}
                />
                <TextField
                  size='small'
                  value={inwardChallanPrefix}
                  onChange={(e) => setInwardChallanPrefix(e.target.value)}
                  label="Inward Challan Prefix"
                  sx={{ width: 200, mt: 1, ml:1 }}
                />
                 <TextField
                  size='small'
                  value={invoiceNoPrefix}
                  onChange={(e) => setInvoiceNoPrefix(e.target.value)}
                  label="Invoice No Prefix"
                  sx={{ width: 200, mt: 1, ml:1 }}
                />
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>

        {/* Existing TableRows */}
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">
                  Set your GST Rate
                </Typography>
              </Grid>
              <Grid item xs>
                <CustomNumberField
                  name='sgstRate'
                  maxLength={4}
                  value={sgstRate}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSgstRate(event.target.valueAsNumber)
                  }}
                  size="small"
                  label="SGST Rate(%)"
                  sx={{ width: 300, mt: 1 }}
                />
                <CustomNumberField
                  name='cgstRate'
                  maxLength={4}
                  value={cgstRate}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCgstRate(event.target.valueAsNumber)
                  }}
                  size="small"
                  label="CGST Rate(%)"
                  sx={{ width: 300, mt: 1, ml:1 }}
                />
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>

        {/* Other Existing Rows (Commented Out Row Removed for Clarity) */}
        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">
                  Enable Monthly/Fortnightly/Weekly Bills
                </Typography>
              </Grid>
              <Grid item xs>
                Please turn on this switch if you generate bill on same account
                after regular interval, such as on every month, every week etc.
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={isReoccuringBillsOn}
              onChange={() => {
                setReoccuringBillsOn(!isReoccuringBillsOn)
              }}
            />
          </TableCell>
        </TableRow>

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">
                  Enable new bill format (Simpler Format)
                </Typography>
              </Grid>
              <Grid item xs>
                You can select from OLD or NEW bill format, you should try both formats to know which is better for you.
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={billFormat === "FORMAT_2"}
              onChange={() => {
                let format = billFormat !== "FORMAT_2" ? "FORMAT_2" : "FORMAT_1"
                setBillFormat(format)
              }}
            />
          </TableCell>
        </TableRow>

        {!isOnboarding && (
          <TableRow sx={{ height: 72 }}>
            <TableCell>
              <Grid container direction='column'>
                <Grid item xs>
                  <Typography variant="h5">Enable MIX type billing</Typography>
                </Grid>
                <Grid item xs>
                  Enable MIX type billing (SQFT and PER ITEM billing in same
                  account)
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="right">
              <Switch
                id="mix_bill_switch"
                checked={isMixTypeAllowed}
                onChange={() => {
                  setMixTypeAllowed(!isMixTypeAllowed)
                }}
              />
            </TableCell>
          </TableRow>
        )}

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">
                  3 inch thermal printer(For mobile)
                </Typography>
              </Grid>
              <Grid item xs>
                Turn on if you use 3 inch thermal printer
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={is3InchPrinter}
              onChange={() => {
                set3InchPrinter(!is3InchPrinter)
              }}
            />
          </TableCell>
        </TableRow>

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">Day discount on left items</Typography>
              </Grid>
              <Grid item xs>
                Enable one day discount on left items{' '}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={isRemainingDayDiscountEnabled}
              onChange={() => {
                setRemainingDayDiscountEnabled(!isRemainingDayDiscountEnabled)
              }}
            />
          </TableCell>
        </TableRow>

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">Other store feature</Typography>
              </Grid>
              <Grid item xs>
                Enable feature to borrow items from other stores
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={isOtherStoreEnabled}
              onChange={() => {
                setOtherStoreEnabled(!isOtherStoreEnabled)
              }}
            />
          </TableCell>
        </TableRow>

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">Minimum days feature</Typography>
              </Grid>
              <Grid item xs>
                Enable minimum days feature (example rent for min 10 days, 15
                days)
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={isMinDaysEnabled}
              onChange={() => {
                setMinDaysEnabled(!isMinDaysEnabled)
              }}
            />
          </TableCell>
        </TableRow>

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">Enable 30 days month feature</Typography>
              </Grid>
              <Grid item xs>
                If you turn this on then while counting the days every month will be considered as 30 days month and there will 360 days in a year.
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={is30DaysMonthEnabled}
              onChange={() => {
                set30DaysMonthEnabled(!is30DaysMonthEnabled)
              }}
            />
          </TableCell>
        </TableRow>

        <TableRow sx={{ height: 72 }}>
          <TableCell>
            <Grid container direction='column'>
              <Grid item xs>
                <Typography variant="h5">Enable advance invoicing</Typography>
              </Grid>
              <Grid item xs>
                If generate invoices while issuing the items, then turn this on.
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Switch
              checked={enabledChallanBilling}
              onChange={() => {
                setEnabledChallanBilling(!enabledChallanBilling)
              }}
            />
          </TableCell>
        </TableRow>
      </Table>
    </>
  )
}

export default AccountSettings
