import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

// Redux related
import { useAppDispatch, useAppSelector } from './../../hooks'
import { hideAlertAction } from '../../store/alerts'
import { Portal } from '@mui/material'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const AppAlerts = () => {
  const {
    show,
    message,
    severity = 'success',
  } = useAppSelector((state) => state.manageAlerts)
  const dispatchAction = useAppDispatch()

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatchAction(hideAlertAction())
  }

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={show}
        autoHideDuration={severity === 'error' ? 5000 : 2000}
        onClose={handleClose}
        sx={{ zIndex: 13001 }}
      >
        {message && (
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        )}
      </Snackbar>
    </Portal>
  )
}
