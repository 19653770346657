import { FC, useState, useReducer, useEffect } from 'react'
import {
  Box,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Autocomplete,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import {
  TimeTypeEnum,
  ItemEntry,
  EditItemRequest,
  EntryType,
  Challan,
} from '../../../services'

// Components
import { AppDialog, ChallanSection } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  updateIssuedItemAction,
  updateIssuedItemResetAction,
} from '../../../store/items'
import {
  fetchChallansAction,
  fetchChallansResetAction,
  refreshFetchSlipsAction,
} from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'
import { getFormattedDate, replace12AmWithCurrentTime } from '../../../utils'
import { fetchCustomerAccountAction } from '../../../store/customerAccounts'
import CustomNumberField from '../Users/profile/CustomNumberField'

type IssueItemFields =
  | 'quantity'
  | 'rate'
  | 'lostItemCost'
  | 'minRentalDays'
  | 'height'
  | 'width'
  | 'length'
type IssueItemFieldsState = Record<
  IssueItemFields,
  Partial<{
    value: string
    error: boolean
    helperText: string
  }>
>

type IssueItemErrors = Record<IssueItemFields, Record<string, string>>

const issueItemErrors: IssueItemErrors = {
  quantity: {
    empty: 'Please enter quantity',
  },
  rate: {
    empty: 'Please enter rate',
  },
  height: {
    empty: 'Please enter height',
  },
  width: {
    empty: 'Please enter width',
  },
  length: {
    empty: 'Please enter length',
  },
  lostItemCost: {
    empty: 'Enter total cost',
  },
  minRentalDays: {
    empty: 'Enter total cost',
  },
}

const initialItemIssueState: IssueItemFieldsState = {
  quantity: {
    error: false,
    helperText: 'Please enter quantity',
  },
  rate: {
    error: false,
    helperText: 'Please enter rate',
  },
  height: {
    error: false,
    helperText: 'Please enter height',
  },
  width: {
    error: false,
    helperText: 'Please enter width',
  },
  length: {
    error: false,
    helperText: 'Please enter length',
  },
  lostItemCost: {
    error: false,
    helperText: 'Enter total cost',
  },
  minRentalDays: {
    error: false,
    helperText: 'Enter total cost',
  },
}
const itemReducer = (state: Partial<IssueItemFieldsState>, action) => {
  const {
    type,
    payload: { name, value },
    helperText,
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: helperText
            ? helperText
            : initialItemIssueState[name]?.helperText,
        },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: issueItemErrors[name]['empty'],
        },
      }
    case 'RESET':
      return {
        ...initialItemIssueState,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: initialItemIssueState[name]?.helperText,
        },
      }
    default:
      return state
  }
}

export interface EditItemProps {
  children?: React.ReactNode
  onClose?: () => void
  onSubmitSuccess?: () => void
  item: ItemEntry
}

export const EditItem: FC<EditItemProps> = ({
  onClose,
  onSubmitSuccess,
  item,
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success: updateIssuedItemSuccess,
    error: updateIssuedItemError,
    message,
  } = useAppSelector((state) => state.updateIssuedItems)

  const {
    list: { selectedTab },
  } = useAppSelector((state) => state.manageCustomerAccounts)

  const {
    data: challans,
    success: challansSuccess,
    message: challansError,
  } = useAppSelector((state) => state.fetchChallan)

  const [date, setDate] = useState<Date | null>(null)
  const [timeType, setTimeType] = useState<TimeTypeEnum>(item?.timeType)
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [itemData, dispatch] = useReducer(itemReducer, initialItemIssueState)
  const [request, setRequest] = useState<EditItemRequest | null>(null)
  const { data: userData } = useAppSelector((state) => state.userData)
  const [submissionType, setSubmissionType] = useState<string>()
  const [selectedChallan, setSelectedChallan] = useState<Challan | null>(null)
  const [userDefinedChallanNumber, setDefinedChallanNumber] = useState('')
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)

  useEffect(() => {
    const submissionType =
      item?.entryType === EntryType.IN && item?.lostItemCost > 0 ? 'LOST' : ''
    setSubmissionType(submissionType)
    dispatchAction(
      fetchChallansAction(
        item.billId,
        item?.entryType === EntryType.IN ? 'IN' : 'OUT'
      )
    )
  }, [item])

  useEffect(() => {
    if (challansSuccess) {
      const challan = challans.find(
        (element) => element.slipNo === item.challanNo
      )
      setSelectedChallan(challan)
    } else if (challansError && !challansSuccess) {
      dispatchAction(
        showAlertAction({ message: challansError, severity: 'error' })
      )
    }
  }, [challansSuccess, challansError])

  useEffect(() => {
    if (item) {
      dispatch({
        type: 'SET',
        payload: { name: 'quantity', value: item.quantity },
      })
      dispatch({ type: 'SET', payload: { name: 'rate', value: item.rate } })

      dispatch({
        type: 'SET',
        payload: { name: 'lostItemCost', value: item.lostItemCost },
      })

      dispatch({ type: 'SET', payload: { name: 'height', value: item.height } })
      dispatch({ type: 'SET', payload: { name: 'width', value: item.width } })
      dispatch({ type: 'SET', payload: { name: 'length', value: item.length } })

      if (userData.config.isMinDaysEnabled || item.minRentalDays > 1) {
        dispatch({
          type: 'SET',
          payload: { name: 'minRentalDays', value: item.minRentalDays },
        })
      }

      setRequest({
        ...request,
        ...(item.entryDate && {
          entryDate: getFormattedDate(new Date(item.entryDate)),
        }),
        ...(item.height && { height: item.height }),
        ...(item.width && { width: item.width }),
        ...(item.length && { length: item.length }),
        ...(item.quantity && { quantity: item.quantity }),
        ...(item.rate &&
          item?.entryType == EntryType.OUT && { rate: item.rate }),
        ...(item.timeType && { timeType: item.timeType }),
        ...(item.lostItemCost &&
          submissionType == 'LOST' && { lostItemCost: item.lostItemCost }),
        id: item.id,
        challanNo: selectedChallan?.slipNo
          ? selectedChallan?.slipNo
          : item.challanNo,
        challanId: selectedChallan?.id as unknown as string,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, submissionType, selectedChallan])

  useEffect(() => {
    if (updateIssuedItemSuccess) {
      dispatchAction(refreshFetchSlipsAction())
      dispatchAction(fetchCustomerAccountAction(selectedTab))

      dispatchAction(
        showAlertAction({
          message: 'Item Updated Successfully',
          severity: 'success',
        })
      )
      onSubmitSuccess() // To close the popup
    } else if (updateIssuedItemError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onSubmitSuccess() // To close the popup
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIssuedItemSuccess, updateIssuedItemError, message])

  const onChangeHandler = (e) => {
    const { name, valueAsNumber, value } = e.target
    if (valueAsNumber) {
      dispatch({ type: 'SET', payload: { name, value: valueAsNumber } })
      setRequest({
        ...request,
        [name]: valueAsNumber,
      })
    } else {
      dispatch({ type: 'ERROR', payload: { name, value: value } })
      setRequest({
        ...request,
        [name]: value,
      })
    }
  }

  const dateChangeHandler = (value) => {
    const newDate = replace12AmWithCurrentTime(value)
    setRequest({
      ...request,
      entryDate: getFormattedDate(newDate),
    })
    setDate(newDate)
  }

  const timeTypeChangeHandler = (event: SelectChangeEvent) => {
    setRequest({
      ...request,
      timeType: event.target.value as TimeTypeEnum,
    })
    setTimeType(event.target.value as TimeTypeEnum)
  }

  const submitHandle = () => {
    request.challanNo = userDefinedChallanNumber
      ? userDefinedChallanNumber
      : selectedChallan?.slipNo
    request.challanId = selectedChallan?.id as unknown as string

    if (selectedChallan?.id !== 0) {
      delete request.entryDate
    }

    if (selectedChallan === null || selectedChallan === undefined) {
      return
    }

    const checkRequest = Object.keys(request)?.every((key) => {
      return key === 'challanNo' || key === 'challanId' || !!request[key]
    })

    if (checkRequest) {
      dispatchAction(updateIssuedItemAction(request))
    }
  }

  useEffect(() => {
    return () => {
      dispatchAction(fetchChallansResetAction())
      dispatchAction(updateIssuedItemResetAction()) // To manage the Alert
    }
  }, [])

  return (
    <AppDialog
      id="edit-item"
      title={item?.itemName}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} sx={{ pb: 2, mr: 2 }}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            {item?.categoryName !== 'OtherCharges' && (
              <Grid container spacing={2}>
                <Grid item={true} xs={12} mt={2}>
                  <FormControl sx={{ m: 1 }} fullWidth>
                    <InputLabel id="select-time-helper-label">
                      Select Time
                    </InputLabel>
                    {item.entryType === 'IN' && (
                      <Select
                        size="small"
                        labelId="select-time-helper-label"
                        id="select-time"
                        value={timeType}
                        name="timeType"
                        label="Select Time"
                        onChange={timeTypeChangeHandler}
                      >
                        <MenuItem value={TimeTypeEnum.MORNING}>
                          Morning (Day will not be counted)
                        </MenuItem>
                        <MenuItem value={TimeTypeEnum.EVENING}>
                          Evening
                        </MenuItem>
                      </Select>
                    )}

                    {item.entryType === 'OUT' && (
                      <Select
                        size="small"
                        labelId="select-time-helper-label"
                        id="select-time"
                        value={timeType}
                        name="timeType"
                        label="Select Time"
                        onChange={timeTypeChangeHandler}
                      >
                        <MenuItem value={TimeTypeEnum.MORNING}>
                          Morning
                        </MenuItem>
                        <MenuItem value={TimeTypeEnum.EVENING}>
                          Evening (Day will not be counted)
                        </MenuItem>
                      </Select>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {item.isArea && (
              <>
                <Grid container spacing={2}>
                  <Grid item={true} xs={12} mt={2}>
                    <CustomNumberField
                      maxLength={8}
                      name="height"
                      value={itemData.height.value}
                      label="Height"
                      onChange={onChangeHandler}
                      fullWidth
                      helperText={
                        itemData['height']['error']
                          ? itemData.height.helperText
                          : ''
                      }
                      error={itemData['height']['error']}
                    />{' '}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item={true} xs={12} mt={2}>
                    <CustomNumberField
                      maxLength={8}
                      name="width"
                      value={itemData.width.value}
                      label="Width"
                      onChange={onChangeHandler}
                      fullWidth
                      helperText={
                        itemData['width']['error']
                          ? itemData.width.helperText
                          : ''
                      }
                      error={itemData['width']['error']}
                    />{' '}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item={true} xs={12} mt={2}>
                    <CustomNumberField
                      maxLength={8}
                      name="length"
                      value={itemData.length.value}
                      label="Length"
                      onChange={onChangeHandler}
                      fullWidth
                      helperText={
                        itemData['length']['error']
                          ? itemData.length.helperText
                          : ''
                      }
                      error={itemData['length']['error']}
                    />{' '}
                  </Grid>
                </Grid>
              </>
            )}

            {item?.categoryName !== 'OtherCharges' && !item?.isArea && (
              <Grid container spacing={2}>
                <Grid item={true} xs={12} mt={2}>
                  <CustomNumberField
                    maxLength={8}
                    name="quantity"
                    value={itemData.quantity.value}
                    label="Quantity"
                    onChange={onChangeHandler}
                    fullWidth
                    helperText={
                      itemData['quantity']['error']
                        ? itemData.quantity.helperText
                        : ''
                    }
                    error={itemData['quantity']['error']}
                  />{' '}
                </Grid>
              </Grid>
            )}
            {item?.entryType === EntryType.OUT && (
              <Grid container spacing={2} mt={2}>
                <Grid item={true} xs={12}>
                  <CustomNumberField
                    maxLength={8}
                    name="rate"
                    value={itemData.rate.value}
                    label={item?.isConsumable ? 'Amount' : 'Rate'}
                    onChange={onChangeHandler}
                    fullWidth
                    error={itemData['rate']['error']}
                    helperText={
                      itemData['rate']['error'] ? itemData.rate.helperText : ''
                    }
                  />
                </Grid>
              </Grid>
            )}

            {submissionType === 'LOST' && (
              <Grid container spacing={2} mt={2}>
                <Grid item={true} xs={12}>
                  <CustomNumberField
                    maxLength={8}
                    name="lostItemCost"
                    value={itemData.lostItemCost.value}
                    label="Total Cost"
                    onChange={onChangeHandler}
                    fullWidth
                    error={itemData['lostItemCost']['error']}
                    helperText={
                      itemData['lostItemCost']['error']
                        ? itemData.lostItemCost.helperText
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            )}

            {item?.entryType === EntryType.OUT &&
              item?.isConsumable !== true &&
              (userData.config.isMinDaysEnabled || item.minRentalDays > 1) && (
                <Grid container spacing={2} mt={2}>
                  <Grid item={true} xs={12}>
                    <CustomNumberField
                      maxLength={3}
                      name="minRentalDays"
                      value={itemData.minRentalDays.value}
                      label="Min Rental Days"
                      onChange={onChangeHandler}
                      fullWidth
                      error={itemData['minRentalDays']['error']}
                      helperText={
                        itemData['minRentalDays']['error']
                          ? itemData.minRentalDays.helperText
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              )}

            <ChallanSection
              challanNo={item.challanNo}
              userDefinedChallanNumber={userDefinedChallanNumber}
              setDefinedChallanNumber={setDefinedChallanNumber}
              selectedChallan={selectedChallan}
              setSelectedChallan={setSelectedChallan}
              bill_id={item.billId}
              setReadyToSubmit={() => {}}
              datePickerOpen={datePickerOpen}
              setDatePickerOpen={setDatePickerOpen}
              date={date}
              setDate={dateChangeHandler}
              singleRow={false}
              challan_type={item?.entryType === EntryType.OUT ? 'OUT' : 'IN'}
              mdValue={0}
            />
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
