import { DeleteStaffAPIResponse } from '../../../services/staff'
import { BaseReducerState } from '../../../services'
import { ActionTypes } from '../actions'

export type DeleteStaffState = BaseReducerState & {
  data: null
}

export const initialStateDeleteStaff: DeleteStaffState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const deleteStaff = (
  state: DeleteStaffState = initialStateDeleteStaff,
  action: { type: string; res: any }
): DeleteStaffState => {
  switch (action.type) {
    case `${ActionTypes.DELETE_STAFF}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.DELETE_STAFF}_SUCCESS`: {
      const { success, message } = <DeleteStaffAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
      }
    }
    case `${ActionTypes.DELETE_STAFF}_FAILURE`:
      const res = <DeleteStaffAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.DELETE_STAFF}_RESET`:
      return {
        ...initialStateDeleteStaff,
      }
  }
  return state
}
