import { FC, useState, useEffect, useRef, ReactNode } from 'react'
import { Grid, Typography, Divider, TextField, Button } from '@mui/material'
import { grey } from '@mui/material/colors'

// Components
import { SlipsScreenType, TabPanel, TabsEnums } from '../../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  fetchQuotationEntriesAction,
  fetchQuotationEntriesResetAction,
  fetchSlipsListAction,
} from '../../../../store/slips'
import { useParams } from 'react-router'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {
  getSlipPDFAction,
  getSlipPDFResetAction,
} from '../../../../store/items'
import { showAlertAction } from '../../../../store/alerts'
import PageContainerWrapper from '../../../../components/PageContainerWrapper'
import PageTitleWrapper from '../../../../components/PageTitleWrapper'
import {
  getDisplayDateTime,
  getFormattedEndDateString,
  getFormattedStartDateString,
} from '../../../../utils'
import PageHeader from './PageHeader'
import { QuotationItemComponent } from './QuotationItemComponent'
import { Bill } from '../../../../services'
import EditIcon from '@mui/icons-material/Edit'
import { AddEditTncItem } from './QuotationTnC'
import { getTncAction, getTncResetAction } from '../../../../store/tnc'
import { Box } from '@mui/system'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const getslipDataToShow = (searchTerm, searchResults, data) =>
  searchTerm?.length > 0 ? searchResults : data

export interface QuotationProps {
  children?: React.ReactNode
}

const QuotationItem: FC<QuotationProps> = ({}) => {
  const { bill_id } = useParams()
  const { screen_type } = useParams()

  const dispatchAction = useAppDispatch()
  const { data, searchResults, searchTerm } = useAppSelector(
    (state) => state.manageSlips
  )
  const { data: accountTnc, message: tncErrorMessage } = useAppSelector(
    (state) => state.getAccountTncState
  )
  const [value, setValue] = useState(0)
  const [showUpdateTnc, setShowUpdateTnc] = useState(false)
  const ref = useRef<any>(null)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [selectScreenType, setSelectedScreenType] = useState<SlipsScreenType>(
    SlipsScreenType.Account
  )
  const [editItem, openEditItem] = useState<boolean>(false)
  const [account, setAccount] = useState<Bill>(null)

  useEffect(() => {
    const type =
      screen_type === 'DATE'
        ? SlipsScreenType.Date
        : screen_type === 'Stock'
        ? SlipsScreenType.Stock
        : SlipsScreenType.Account
    setSelectedScreenType(type)
    if (bill_id) {
      dispatchAction(fetchQuotationEntriesAction(bill_id))
      dispatchAction(getTncAction(Number(bill_id)))
      window.scrollTo({ behavior: 'smooth', top: 0 })
    }
  }, [bill_id, dispatchAction])

  const {
    data: slipPdfLink,
    success: getPDFSuccess,
    error: getPDFError,
    message,
  } = useAppSelector((state) => state.slipPDF)

  const handleViewPDF = (showRate: boolean, title: string) => {
    const slipType =
      value === 0
        ? TabsEnums.All
        : value === 1
        ? TabsEnums.Out
        : value === 2
        ? TabsEnums.In
        : value === 3
        ? TabsEnums.Left
        : value === 4
        ? TabsEnums.Payment
        : TabsEnums.All

    const getPDFRequest = {
      billId: Number(bill_id),
      showRate: showRate,
      slipType: `${slipType}`,
      title: title,
      isQuotation: true,
    }
    dispatchAction(getSlipPDFAction(getPDFRequest))
  }

  useEffect(() => {
    dispatchAction(fetchQuotationEntriesResetAction()) // To manage the Alert
    return () => {
      dispatchAction(fetchQuotationEntriesResetAction()) // To manage the Alert
      dispatchAction(getTncResetAction()) // To manage the Alert
    }
  }, [])

  useEffect(() => {
    if (getPDFSuccess) {
      window.open(slipPdfLink, '_blank')
    } else if (getPDFError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [getPDFSuccess, getPDFError, message])

  useEffect(() => {
    if (data?.accountInfo) {
      setAccount(data?.accountInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <PageTitleWrapper>
        <PageHeader
          refr={ref}
          onExportPdf={() => {
            handleViewPDF(true, null)
          }}
          pageTitle={
            data?.accountInfo?.status === 'QUOTATION'
              ? 'Quotation Details'
              : data?.accountInfo?.status === 'AGREEMENT'
              ? 'Agreement Details'
              : ''
          }
        />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Grid pl={3.1} pt={2} pb={2} container direction="row">
          <Grid item xs={4} display={'flex'}>
            <Typography fontWeight={'bold'}>Party Name: </Typography>
            <Typography ml={1}>{account?.customer?.name}</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            display={'flex'}
            sx={{ borderLeft: 1, borderLeftColor: 'lightgray', pl: 3 }}
          >
            <Typography fontWeight={'bold'}>Quotation No: </Typography>
            <Typography ml={1}>{account?.id}</Typography>
          </Grid>
          <Grid item xs={4} display={'flex'}>
            <Typography fontWeight={'bold'}>Address: </Typography>
            <Typography ml={1}>{account?.customer?.address}</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            display={'flex'}
            sx={{ borderLeft: 1, borderLeftColor: 'lightgray', pl: 3 }}
          >
            <Typography fontWeight={'bold'}>Last Updated: </Typography>
            <Typography ml={1}>
              {getDisplayDateTime(account?.updateDate)}
            </Typography>
          </Grid>
          <Grid xs={12} item pr={3} pt={2} pb={1}>
            <Divider />
            <Grid container>
              <Grid xs={6} item>
                <Typography variant="h5" mt={1.5}>
                  Terms and Conditions
                </Typography>
              </Grid>
              <Grid xs={6} sx={{ flex: 1, textAlign: 'right', pt: 1 }} item>
                <Button
                  sx={{ backgroundColor: 'green' }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setShowUpdateTnc(true)
                  }}
                  startIcon={<EditIcon />}
                >
                  {' '}
                  Edit{' '}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                  }}
                >
                  {accountTnc &&
                  accountTnc.tnc &&
                  accountTnc.tnc.trim().length > 0
                    ? accountTnc.tnc.trim()
                    : 'No terms and conditions have been added'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <QuotationItemComponent
          account={data.accountInfo}
          data={getslipDataToShow(searchTerm, searchResults, data)?.all}
          separatorHeight={Number(0.2)}
          billId={Number(bill_id)}
          onClickViewPDF={handleViewPDF}
          screenType={selectScreenType}
        />
      </PageContainerWrapper>
      {showUpdateTnc && (
        <AddEditTncItem
          accountTnc={
            accountTnc
              ? accountTnc
              : { tnc: '', account: { id: Number(bill_id) } }
          }
          onClose={() => setShowUpdateTnc(false)}
        />
      )}
    </>
  )
}

export default QuotationItem
