import {
  fetchChallans,
  fetchSlipsList,
  updateEntryDate,
  getInvoiceNumber,
} from '../../../services'

export const ActionTypes = {
  FETCH_SLIPS: 'FETCH_SLIPS',
  ADD_PAYMENT: 'ADD_PAYMENT',
  SLIPS_SEARCH_RESULTS: 'SLIPS_SEARCH_RESULTS',
  UPDATE_ENTRY_DATE: 'UPDATE_ENTRY_DATE',
  FETCH_CHALLAN: 'FETCH_CHALLAN',
  FETCH_INVOICE: 'FETCH_INVOICE',
}

export const fetchSlipsListAction = (
  billId,
  startDate?: string,
  endDate?: string,
  isQuotation?: boolean
) => ({
  type: ActionTypes.FETCH_SLIPS,
  promise: fetchSlipsList(billId, startDate, endDate, isQuotation),
  data: { billId },
})

export const fetchQuotationEntriesAction = (billId) => ({
  type: ActionTypes.FETCH_SLIPS,
  promise: fetchSlipsList(billId, null, null, true),
  data: { billId },
})

export const fetchQuotationEntriesResetAction = () => ({
  type: `${ActionTypes.FETCH_SLIPS}_RESET`,
})

export const fetchSlipsResetAction = () => ({
  type: `${ActionTypes.FETCH_SLIPS}_RESET`,
})

export const refreshFetchSlipsAction = () => {
  return (dispatch, getState) => {
    const { billId } = getState().manageSlips
    if (billId) dispatch(fetchSlipsListAction(billId))
  }
}

// Search fucntion
/**
 *
 * @param searchTerm
 * @param data
 * @returns
 */
export const searchSlips = (searchTerm, data) => {
  const searchResults = { ...data }
  const { all, in: inItems, left, out } = data
  if (all?.length) {
    searchResults.all = all
      .map((item) => {
        const tempItem = { ...item }
        const { itemEntries } = tempItem
        tempItem.itemEntries = itemEntries.filter((itemEntry) => {
          const { itemName } = itemEntry
          if (itemName?.toLowerCase().includes(searchTerm?.toLowerCase())) {
            return true
          } else {
            return false
          }
        })
        return tempItem
      })
      .filter((enteries) => enteries?.itemEntries.length > 0)
  }

  if (inItems?.length) {
    searchResults.in = inItems
      .map((item) => {
        const tempItem = { ...item }
        const { itemEntries } = tempItem
        tempItem.itemEntries = itemEntries.filter((itemEntry) => {
          const { itemName } = itemEntry
          if (itemName?.toLowerCase().includes(searchTerm?.toLowerCase())) {
            return true
          } else {
            return false
          }
        })
        return tempItem
      })
      .filter((enteries) => enteries?.itemEntries.length > 0)
  }

  if (left?.length) {
    searchResults.left = left
      .map((item) => {
        const tempItem = { ...item }
        const { itemEntries } = tempItem
        tempItem.itemEntries = itemEntries.filter((itemEntry) => {
          const { itemName } = itemEntry
          if (itemName?.toLowerCase().includes(searchTerm?.toLowerCase())) {
            return true
          } else {
            return false
          }
        })
        return tempItem
      })
      .filter((enteries) => enteries?.itemEntries.length > 0)
  }

  if (out?.length) {
    searchResults.out = out
      .map((item) => {
        const tempItem = { ...item }
        const { itemEntries } = tempItem
        tempItem.itemEntries = itemEntries.filter((itemEntry) => {
          const { itemName } = itemEntry
          if (itemName?.toLowerCase().includes(searchTerm?.toLowerCase())) {
            return true
          } else {
            return false
          }
        })
        return tempItem
      })
      .filter((enteries) => enteries?.itemEntries.length > 0)
  }
  return searchResults
}

/**
 *
 * @param searchTerm
 * @returns
 */
export const searchSlipsAction = (searchTerm) => {
  return (dispatch, getState) => {
    const { data } = getState().manageSlips
    const searchResults = searchSlips(searchTerm, data)
    dispatch({
      type: ActionTypes.SLIPS_SEARCH_RESULTS,
      res: { data: searchResults, searchTerm },
    })
  }
}

/**
 *
 * @param request
 * @returns
 */

export const updateEntryDateAction = (request) => ({
  type: ActionTypes.UPDATE_ENTRY_DATE,
  promise: updateEntryDate(request),
})

export const resetEntryDateAction = () => ({
  type: `${ActionTypes.UPDATE_ENTRY_DATE}_RESET`,
})

export const fetchChallansAction = (
  accountId: number,
  challan_type?: string
) => ({
  type: ActionTypes.FETCH_CHALLAN,
  promise: fetchChallans(accountId, challan_type),
})

export const fetchChallansResetAction = () => ({
  type: `${ActionTypes.FETCH_CHALLAN}_RESET`,
})

export const getInvoiceNumberAction = (accountId: number) => ({
  type: ActionTypes.FETCH_INVOICE,
  promise: getInvoiceNumber(accountId),
})
