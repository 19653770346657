import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { getAccessToken } from '../../utils'

export default function PrivateRoute({ children }) {
  const { data: userData } = useAppSelector((state) => state.userData)
  const token = getAccessToken()
  if (!token) {
    return <Navigate to="/login" replace={true} />
  } else if(userData && userData.onboardingDone === false) {
    return <Navigate to="/onboarding" replace={true} />
  } else {
    return <>{children}</>
  }
}
