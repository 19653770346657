/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, FC, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

import {
  Grid,
  Divider,
  Link,
  Tabs,
  Tab,
  Typography,
  Tooltip,
  Button,
} from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid'
import { Helmet } from 'react-helmet-async'

import CurrentAccountHeader from './PageHeader'
import PageTitleWrapper from '../../../components/PageTitleWrapper'
import PageContainerWrapper from '../../../components/PageContainerWrapper'
import {
  Empty,
  AccountTabsEnums,
  AccountTabsValueEnums,
} from '../../../components'

import Footer from '../../../components/Footer'
import './currentaccounts.css'

import { useAppDispatch, useAppSelector } from '../../../hooks'
import { fetchCustomerAccountAction } from '../../../store/customerAccounts'
import { showAlertAction } from '../../../store/alerts'

import {
  getBillTillTodayPDFAction,
  getBillTillTodayPDFResetAction,
} from '../../../store/items'

// Components
import IssueMoreItem from './IssueMoreItem'
import SubmitItem from './SubmitItem'

import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import AddBoxIcon from '@mui/icons-material/AddBox'

import AvatarWithErrorHandling from './AvatarWithErrorHandling'
import { currencyFormatter } from '../../../utils'
import { CurrentAccountMoreActionsEnum } from '../../../services/types'
import { BillPDFRequest } from '../../../services'
import { getBillTypeText } from '../../../utils/appUtils'

import AddQuotationItems from './Quotation/AddQuotationItems'

import { tabBarColor, tabBarTextColor } from '../slips/ViewSlips'
import { MoreActions } from './MoreActions'
import OtherCharges from './OtherCharges'
import { RenewAccount } from './RenewAccount'
import { CloseAccount } from './CloseAccount'
import { DeleteAccount } from './DeleteAccount'
import { AddAccountItem } from './AddNewAccountItem'
import CreateNewTask from './../Task/CreateNewTask'
import { BillSettings } from '../slips/BillSettings'
import { ReopenAccount } from './ReopenAccount'
import { BillBetweenDatesItem } from './BillBetweenDatesItem'
import { PlotAreaDatesItem } from './PlotAreaDatesPicker'

export const ADD_ACCOUNT = 'ADD NEW SITE'
const QUOTATION = 'QUOTATION'
const AGREEMENT = 'AGREEMENT'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const getGridDataToShow = (searchTerm, searchResults, data) =>
  searchTerm?.length > 0 ? searchResults : data
export const activeTabColor = 'white'
function CustomersComponent() {
  const {
    list: { data, searchResults, searchTerm, selectedTab },
  } = useAppSelector((state) => state.manageCustomerAccounts)
  const {
    data: billPdfLink,
    success: getPDFSuccess,
    error: getPDFError,
    // loading: pfdLoading,
    message,
  } = useAppSelector((state) => state.billPDF)

  const dispatchAction = useAppDispatch()
  const [currentTab, setCurrentTab] = useState(
    selectedTab || AccountTabsValueEnums.OPEN
  )
  const [totalDueAmount, setTotalDueAmount] = useState(0)
  const [totalAccounts, setTotalAccounts] = useState(0)
  const [buttonTitle, setButtonTitle] = useState(ADD_ACCOUNT)
  const [othercharges, setOtherCharges] = useState<boolean>(false)

  useEffect(() => {
    if (data || searchResults) {
      setTotalDue()
    }
  }, [data, searchResults])

  useEffect(() => {
    if (!selectedTab || selectedTab !== currentTab) {
      setCurrentTab(selectedTab)
    }
  }, [selectedTab])

  useEffect(() => {
    fetchAccounts()
    const title =
      currentTab === AccountTabsValueEnums.QUOTATION
        ? QUOTATION
        : currentTab === AccountTabsValueEnums.AGREEMENT
        ? AGREEMENT
        : ADD_ACCOUNT
    setButtonTitle(title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const fetchAccounts = () => {
    if (
      currentTab !== selectedTab ||
      !data ||
      !data.all ||
      data.all.length === 0
    ) {
      dispatchAction(fetchCustomerAccountAction(currentTab))
    }
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  function setTotalDue() {
    const array = getGridDataToShow(searchTerm, searchResults, data)
    const accounts = array ? array : []
    let amount = 0
    accounts.forEach((val, index) => {
      amount += val.totalDue
    })
    setTotalDueAmount(amount)
    setTotalAccounts(accounts.length)
  }

  function getId(value: any, field: string) {
    const testIdPrefix = `id_${value.api.getRowIndexRelativeToVisibleRows(
      value.row.id
    )}`
    return `${testIdPrefix}_${field}`
  }

  const handleViewPDF = (request: BillPDFRequest) => {
    dispatchAction(getBillTillTodayPDFAction(request))
  }

  useEffect(() => {
    if (getPDFSuccess) {
      const newWindow = window.open(billPdfLink, '_blank')
      if (newWindow) {
        newWindow.opener = null
      }
      dispatchAction(getBillTillTodayPDFResetAction())
    } else if (getPDFError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      dispatchAction(getBillTillTodayPDFResetAction())
    }
  }, [getPDFSuccess, getPDFError, message])

  const columns: GridColDef[] = [
    {
      field: 'customer',
      headerName: 'Customer',
      minWidth: 230,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (value) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AvatarWithErrorHandling
              src={value.row?.customer?.profileUrl}
              alt="Avatar"
              avatarStyle={{
                width: 36,
                height: 36,
                borderRadius: '50%',
                objectFit: 'cover',
                marginRight: 10,
              }}
            />
            
            <Tooltip arrow title="Account Details">
              <Link
                href={`${(value.row.status === 'QUOTATION' ||
                value.row.status === AGREEMENT) ? "/quotation/view-quotation/"+value.row.id : "/slips/view-slips/"+value.row.id+"/"+value.row.customer.id }`}
                data-cy={getId(value, 'link_name')}
              >
                {value.row.customer.name}
              </Link>
            </Tooltip>
          </div>
        )
      },
    },
    {
      field: 'siteAddress',
      headerName: '(Site No)Site Address',
      minWidth: 260,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (value) => {
        return (
          <>
            <Typography
              id="account_number"
              variant="h4"
              pr={2}
              data-cy={getId(value, 'ac_no')}
            >
              {value.row.id}
            </Typography>
            <Typography data-cy={getId(value, 'site_address')}>
              {value.row.siteAddress}
            </Typography>
          </>
        )
      },
    },

    {
      field: 'billType',
      flex: 1,
      headerName: 'Bill Type',
      headerClassName: 'super-app-theme--header',
      renderCell: (value) => {
        return (
          <div
            data-cy={getId(value, 'bill_type')}
            className={`type${getBillTypeText(value.row)?.replace(' ', '_')}`}
          >
            {getBillTypeText(value.row)}
          </div>
        )
      },
    },
    {
      field: 'updateDate',
      flex: 1,
      valueGetter: (params) => {
        return new Date(params.row.updateDate)
      },
      headerName: 'Update Date',
      headerClassName: 'super-app-theme--header',
      type: 'date',
    },
    {
      field: 'totalDue',
      headerName: 'Due',
      type: 'number',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (value) => (
        <Typography
          data-cy={getId(value, 'total_due')}
          sx={{ color: value.row.totalDue > 0 ? 'crimson' : 'green' }}
        >
          {value.row.totalDue
            ? currencyFormatter.format(value.row.totalDue)
            : ''}
        </Typography>
      ),
    },
    {
      field: 'remainingPieces',
      headerName: 'Pcs',
      type: 'number',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: (value) => {
        return (
          <Typography id="id_pcs" data-cy={getId(value, 'pcs')}>
            {value.row.remainingPieces ? value.row.remainingPieces : '0'}
          </Typography>
        )
      },
    },

    {
      field: 'billType1',
      headerName: '',
      headerAlign: 'right',
      minWidth: 230,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (value) => {
        const ref = useRef<any>(null)
        const [issueMoreItem, setIssueMoreItem] = useState<boolean>(false)
        const [issueQuotationItem, setIssueQuotationItem] =
          useState<boolean>(false)
        const [submitItem, setSubmitItem] = useState<boolean>(false)
        const [showMore, setShowMore] = useState<boolean>(false)
        const [showDeleteAccountDailog, setShowDeleteAccountDailog] =
          useState<boolean>(false)
        const navigate = useNavigate()
        const actionsVisibility =
          value.row.status === 'OPEN' ? 'visible' : 'hidden'
        const [showMoreAction, setShowMoreAction] =
          useState<CurrentAccountMoreActionsEnum | null>(null)
        console.log(showMoreAction)
        return (
          <>
            <Stack
              direction="row"
              alignItems="right"
              display={'flex'}
              justifyContent={'right'}
              flex={1}
            >
              <Tooltip title="Issue Items" arrow>
                <IconButton
                  id="issue_items_button"
                  data-cy={getId(value, 'button_issue_items')}
                  sx={{ visibility: actionsVisibility }}
                  onClick={(event) => {
                    event.stopPropagation()
                    setIssueMoreItem(true)
                  }}
                >
                  {' '}
                  <LogoutIcon className={'actionButtonRed'} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Receive Items" arrow>
                <IconButton
                  data-cy={getId(value, 'button_receive_items')}
                  id="submit_items_button"
                  sx={{ visibility: actionsVisibility }}
                  onClick={(event) => {
                    event.stopPropagation()
                    setSubmitItem(true)
                  }}
                >
                  {' '}
                  <LoginIcon className={'actionButtonGreen'} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  value.row.status === 'QUOTATION'
                    ? 'Quotation details'
                    : value.row.status === AGREEMENT
                    ? 'Agreement Details'
                    : 'Site Details'
                }
                arrow
              >
                <IconButton
                  data-cy={getId(value, 'button_slips')}
                  id={
                    value.row.status === 'QUOTATION'
                      ? 'view_quotation_details_button'
                      : value.row.status === 'AGREEMENT'
                      ? 'view_agreement_details_button'
                      : 'view_slips_button'
                  }
                  onClick={() => {
                    if (
                      value.row.status === 'QUOTATION' ||
                      value.row.status === AGREEMENT
                    ) {
                      navigate(`/quotation/view-quotation/${value.row.id}`)
                    } else {
                      navigate(
                        `/slips/view-slips/${value.row.id}/${value.row.customer.id}`
                      )
                    }
                  }}
                >
                  <DescriptionIcon className={'actionButtonYellow'} />
                </IconButton>
              </Tooltip>
              {(selectedTab === 'QUOTATION' || selectedTab === AGREEMENT) && (
                <div>
                  {' '}
                  <Tooltip title="Add new entry" arrow>
                    <IconButton
                      id="issue_quotation_items_button"
                      onClick={(event) => {
                        event.stopPropagation()
                        setIssueQuotationItem(true)
                      }}
                    >
                      <AddBoxIcon className={'actionButtonRed'} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add Other Charge" arrow>
                    <IconButton
                      id="issue_quotation_items_button"
                      onClick={(event) => {
                        event.stopPropagation()
                        setOtherCharges(true)
                      }}
                    >
                      <LocalShippingIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      id="delete_quotation_button"
                      onClick={(event) => {
                        event.stopPropagation()
                        setShowDeleteAccountDailog(true)
                      }}
                    >
                      <DeleteIcon className={'actionButtonRed'} />
                    </IconButton>
                  </Tooltip>
                </div>
              )}

              {selectedTab !== 'QUOTATION' && selectedTab !== AGREEMENT && (
                <div>
                  <IconButton
                    id="more_actions_button"
                    data-cy={getId(value, 'button_more')}
                    onClick={(event) => {
                      event.stopPropagation()
                       setShowMore(true)
                      }}
                    ref={ref}
                  >
                    {' '}
                    <Tooltip title="More actions" arrow>
                      <MoreVertIcon className={'actionButtonBlack'} />
                    </Tooltip>
                  </IconButton>
                </div>
              )}
            </Stack>
            {othercharges && (
                <OtherCharges
                  bill_id={value.row.id}
                  customer_id={value.row.customer.id}
                  onClose={() => setOtherCharges(false)}
                  title={`${value.row.customer.name}, ${value.row.siteAddress}`}
                  isQuotation={true}
                />
            )}
            {issueMoreItem && (
              <IssueMoreItem
                bill_id={value.row.id}
                customer_id={value.row.customer.id}
                onClose={() => setIssueMoreItem(false)}
                billInfo={value.row}
                billType={value.row.billType}
              />
            )}
            {issueQuotationItem && (
              <AddQuotationItems
                bill_id={value.row.id}
                customer_id={value.row.customer.id}
                onClose={() => setIssueQuotationItem(false)}
                account={value.row}
              />
            )}

            {submitItem && (
              <SubmitItem
                bill_id={value.row.id}
                customer_id={value.row.customer.id}
                onClose={() => setSubmitItem(false)}
                billType={value.row.billType}
              />
            )}

            {showDeleteAccountDailog && (
              <DeleteAccount
                account={value.row}
                onClose={() => {
                  setShowDeleteAccountDailog(false)
                }}
              />
            )}

            {showMoreAction ==
              CurrentAccountMoreActionsEnum.ONE_DAY_DISCOUNT_SETTING && (
              <BillSettings
                onClose={() => setShowMoreAction(null)}
                account={value.row}
              />
            )}
            {showMoreAction ==
              CurrentAccountMoreActionsEnum.LOST_OR_DAMAGE_CHARGES && (
              <SubmitItem
                bill_id={value.row?.id}
                customer_id={value.row?.customer.id}
                onClose={() => setShowMoreAction(null)}
                submission_type={'LOST'}
                billType={value?.row?.billType}
              />
            )}

            {showMoreAction ==
              CurrentAccountMoreActionsEnum.ADD_OTHER_CHARGES && (
              <OtherCharges
                bill_id={value.row?.id}
                customer_id={value.row?.customer.id}
                onClose={() => setShowMoreAction(null)}
                title={`${value.row?.customer.name}, ${value.row?.siteAddress}`}
                isQuotation={false}
              />
            )}

            {showMoreAction == CurrentAccountMoreActionsEnum.EDIT_ACCOUNT && (
              <AddAccountItem
                onClose={() => setShowMoreAction(null)}
                partialBill={value.row}
              />
            )}

            {showMoreAction == CurrentAccountMoreActionsEnum.REOPEN_ACCOUNT && (
              <ReopenAccount
                accountId={value.row.id}
                onClose={() => setShowMoreAction(null)}
                onSuccess={() => {
                  setShowMoreAction(null)
                  setCurrentTab(AccountTabsValueEnums.OPEN)
                }}
              />
            )}

            {showMoreAction ==
              CurrentAccountMoreActionsEnum.MOVE_BALANCE_TO_NEW_ACCOUNT && (
              <RenewAccount
                bill={value.row}
                onClose={() => setShowMoreAction(null)}
              />
            )}

            {showMoreAction == CurrentAccountMoreActionsEnum.CLOSE_ACCOUNT && (
              <CloseAccount
                account={value.row}
                onClose={() => setShowMoreAction(null)}
              />
            )}

            {showMoreAction ==
              CurrentAccountMoreActionsEnum.CREATE_NEW_TASK && (
              <CreateNewTask
                account={value.row}
                onClose={() => setShowMoreAction(null)}
              />
            )}

            {showMoreAction == CurrentAccountMoreActionsEnum.DELETE && (
              <DeleteAccount
                account={value.row}
                onClose={() => setShowMoreAction(null)}
              />
            )}

            {showMoreAction ==
              CurrentAccountMoreActionsEnum.BILL_BETWEEN_TWO_DATES && (
              <BillBetweenDatesItem
                onClose={(request?: BillPDFRequest) => {
                  setShowMoreAction(null)
                  if (request) {
                    handleViewPDF(request)
                  }
                }}
                bill={value.row}
              />
            )}

            {showMoreAction ==
              CurrentAccountMoreActionsEnum.SHOW_PLOT_AREA_DATE_PICKER && (
              <PlotAreaDatesItem
                bill={value.row}
                onClose={(
                  startDate?: string,
                  endDate?: string,
                  fetchBill?: boolean
                ) => {
                  if (fetchBill) {
                    handleViewPDF({
                      billId: value.row?.id,
                      sqft_start_date: startDate,
                      sqft_end_date: endDate,
                    })
                  }
                }}
              />
            )}

            {showMore && (
              <MoreActions
                myref={ref}
                showMore={showMore}
                bill={value.row}
                onClose={() => {
                  setShowMore(false)
                }}
                setShowMoreAction={setShowMoreAction}
                onChangeTab={(tab: string) => {
                  setCurrentTab(tab)
                }}
              />
            )}
          </>
        )
      },
    },
  ]

  const navigate = useNavigate()

  const handleEvent: GridEventListener<'rowClick'> = (
    params, 
    event, 
    details,
  ) => {
     navigate(`/slips/view-slips/${params.row.id}/${params.row.customer.id}`)
 };
  

  return (
    <>
      <Helmet>
        <title>Manage Accounts</title>
      </Helmet>
      <PageTitleWrapper>
        <CurrentAccountHeader
          totalAccounts={totalAccounts}
          totalDue={totalDueAmount}
          buttonTitle={buttonTitle}
          currentTab={currentTab}
        />
      </PageTitleWrapper>

      <PageContainerWrapper>
        <Grid container>
          <Grid item md={12}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="Slips"
              textColor={tabBarTextColor}
              sx={{ backgroundColor: tabBarColor, color: tabBarTextColor }}
              TabIndicatorProps={{
                style: {
                  transition: 'none',
                  backgroundColor: tabBarTextColor,
                  height: 4,
                },
              }}
            >
              <Tab
                label={AccountTabsEnums.Current}
                {...a11yProps(0)}
                value={AccountTabsValueEnums.OPEN}
              />
              <Tab
                label={AccountTabsEnums.Billed}
                {...a11yProps(1)}
                value={AccountTabsValueEnums.BILL_CREATED}
              />
              <Tab
                label={AccountTabsEnums.Closed}
                {...a11yProps(2)}
                value={AccountTabsValueEnums.CLOSED}
              />
              <Tab
                label={AccountTabsEnums.Quotation}
                {...a11yProps(2)}
                value={AccountTabsValueEnums.QUOTATION}
              />
              <Tab
                label={AccountTabsEnums.Agreement}
                {...a11yProps(2)}
                value={AccountTabsValueEnums.AGREEMENT}
              />
            </Tabs>
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}></Grid>
        </Grid>
        <Divider />
        {getGridDataToShow(searchTerm, searchResults, data).length >= 0 ? (
          <Box
            sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#f9f9f9',
                color: 'black',
              },
            }}
          >
            <DataGrid
            checkboxSelection={false}
              onRowClick={handleEvent}
              sx={{ border: 0 }}
              rows={getGridDataToShow(searchTerm, searchResults, data)}
              columns={columns}
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                totalDue:
                  currentTab !== AccountTabsValueEnums.QUOTATION &&
                  currentTab !== AccountTabsValueEnums.AGREEMENT,
                billType:
                  currentTab !== AccountTabsValueEnums.QUOTATION &&
                  currentTab !== AccountTabsValueEnums.AGREEMENT,
                remainingPieces:
                  currentTab !== AccountTabsValueEnums.QUOTATION &&
                  currentTab !== AccountTabsValueEnums.AGREEMENT,
              }}
            />
          </Box>
        ) : (
          <Empty>{''}</Empty>
        )}
      </PageContainerWrapper>
      <Footer />
    </>
  )
}

export default CustomersComponent
