import { DeleteTaskResponse, BaseReducerState } from '../../../services'
import { ActionTypes } from '../actions'

export type DeleteTaskState = BaseReducerState

const initialState: DeleteTaskState = {
  loading: false,
  success: false,
  message: '',
  error: null,
}

export const deleteTask = (
  state: DeleteTaskState = initialState,
  action: { type: string; res: any }
): DeleteTaskState => {
  switch (action.type) {
    case `${ActionTypes.DELETE_TASK}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        error: null,
      }

    case `${ActionTypes.DELETE_TASK}_SUCCESS`: {
      const { success, message } = <DeleteTaskResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
      }
    }
    case `${ActionTypes.DELETE_TASK}_FAILURE`:
      const res = <DeleteTaskResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.DELETE_TASK}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
