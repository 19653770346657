import { FC } from 'react'
import { Typography, Grid } from '@mui/material'


const TnCHeader: FC<{}> = () => {
 
  return (
    <>
      <Grid container  alignItems="stretch">
        <Grid item md={3}>
          <Typography variant="h3" component="h3" gutterBottom pl={2.5}>
            Terms and Conditions
          </Typography>
         
        </Grid>
       
      </Grid>

    </>
  )
}

export default TnCHeader
