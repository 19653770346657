import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography, IconButton, TextField } from '@mui/material'
import { showAlertAction } from '../../../../../src/store/alerts'
import { useAppDispatch } from '../../../../../src/hooks'
import { EwayItem, getEwayItems } from './Services'

export const totalValue = (item: EwayItem): number => {
  const { taxableAmount, igstRate, cgstRate, sgstRate } = item

  if (igstRate > 0) {
    return Number(taxableAmount) + Number((taxableAmount * igstRate) / 100)
  }

  const gstRate = cgstRate + sgstRate
  return Number(taxableAmount) + Number((taxableAmount * gstRate) / 100)
}

export const sumTaxableAmount = (items: EwayItem[]): number => {
  return items.reduce(
    (sum, item) => Number(sum) + Number(item.taxableAmount),
    0
  )
}

export const sumTotalValue = (items: EwayItem[]): number => {
  return items.reduce((sum, item) => Number(sum) + Number(totalValue(item)), 0)
}

export const sumCgstValue = (items: EwayItem[]): number => {
  return items.reduce(
    (sum, item) =>
      Number(sum) + Number((item.taxableAmount * item.cgstRate) / 100),
    0
  )
}

export const sumSgstValue = (items: EwayItem[]): number => {
  return items.reduce(
    (sum, item) =>
      Number(sum) + Number((item.taxableAmount * item.sgstRate) / 100),
    0
  )
}

export const sumIgstValue = (items: EwayItem[]): number => {
  return items.reduce(
    (sum, item) =>
      Number(sum) + Number((item.taxableAmount * item.igstRate) / 100),
    0
  )
}

const ItemListBox = ({ challanId, setEwayItemList }) => {
  const [itemEntries, setItemEntries] = useState<EwayItem[]>([])
  const dispatchAction = useAppDispatch()
  const handleInputChange = (index, event) => {
    const { name, value } = event.target
    const updatedItems = [...itemEntries]
    updatedItems[index] = { ...updatedItems[index], [name]: value }
    setItemEntries(updatedItems)
    setEwayItemList(updatedItems)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const isIGstValid = (item: EwayItem) => {
    if (item.igstRate && item.igstRate > 0) {
      return true
    }
    if (
      (item.cgstRate && item.cgstRate > 0) ||
      (item.sgstRate && item.sgstRate > 0)
    ) {
      return true
    }
    return false
  }

  const isCGstValid = (item: EwayItem) => {
    if (item.igstRate && item.igstRate > 0) {
      return true
    }
    if (item.cgstRate && item.cgstRate > 0) {
      return true
    }
    return false
  }

  const isSGstValid = (item: EwayItem) => {
    if (item.igstRate && item.igstRate > 0) {
      return true
    }
    if (item.sgstRate && item.sgstRate > 0) {
      return true
    }
    return false
  }

  const fetchData = async () => {
    getEwayItems(challanId).then((response) => {
      if (response.success) {
        const data = response.data
        if (data) {
          setItemEntries(data)
        }
      } else {
        dispatchAction(
          showAlertAction({
            message: response.message,
            severity: 'error',
          })
        )
      }
    })
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 4, mb: 2, fontWeight: 'bold' }}>
        ITEM LIST
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={2}>
          <Typography variant="h6">Item Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">SAC</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">Quantity</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">Taxable Value</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="h6">SGST(%)</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="h6">CGST(%)</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="h6">IGST(%)</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="h6">Value</Typography>
        </Grid>
      </Grid>
      {itemEntries.map((item, index) => (
        <Grid
          container
          spacing={2}
          key={index}
          alignItems="center"
          sx={{ mb: 1.5 }}
        >
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Item Name"
              name="itemName"
              value={item.productName}
              onChange={(event) => handleInputChange(index, event)}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              size="small"
              fullWidth
              label="SAC"
              name="hsnCode"
              error={!item.hsnCode}
              value={item.hsnCode || ''}
              onChange={(event) => handleInputChange(index, event)}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              fullWidth
              error={!item.quantity || item.quantity <= 0}
              size="small"
              label="Quantity"
              name="quantity"
              type="number"
              value={item.quantity}
              onChange={(event) => handleInputChange(index, event)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              error={!item.taxableAmount || item.taxableAmount <= 0}
              size="small"
              fullWidth
              label="Value"
              name="taxableAmount"
              type="number"
              value={item.taxableAmount}
              onChange={(event) => handleInputChange(index, event)}
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              size="small"
              fullWidth
              label="SGST(%)"
              name="sgstRate"
              value={item.sgstRate || ''}
              onChange={(event) => handleInputChange(index, event)}
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              size="small"
              fullWidth
              label="CGST(%)"
              name="cgstRate"
              value={item.cgstRate || ''}
              onChange={(event) => handleInputChange(index, event)}
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              fullWidth
              size="small"
              label="IGST(%)"
              name="igstRate"
              value={item.igstRate || ''}
              onChange={(event) => handleInputChange(index, event)}
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              fullWidth
              size="small"
              label="Total"
              name="total"
              value={totalValue(item)}
            />
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={2} alignItems="center" sx={{ mt: 1.5 }}>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            fullWidth
            label="Total Taxable Amt"
            name="taxableAmount"
            type="number"
            value={sumTaxableAmount(itemEntries)}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            size="small"
            label="Total SGST Value"
            name="total"
            value={sumSgstValue(itemEntries)}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            size="small"
            label="Total CGST Value"
            name="total"
            value={sumCgstValue(itemEntries)}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            size="small"
            label="Total IGST Value"
            name="total"
            value={sumIgstValue(itemEntries)}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            size="small"
            label="Total Invoice Value"
            name="total"
            value={sumTotalValue(itemEntries)}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ItemListBox
