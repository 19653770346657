import axios from 'axios'
import CryptoJS from 'crypto-js'
import {
  getAccessToken,
  removeToken,
  getSelectedStoreId,
  removeSelectedStoreId,
} from './session.manager'
import ApplicationLinks from '../ApplicationLinks'
import { showSubscribePopupAction } from '../store/subscriptionPlan'
import { showPaymentDuePopupAction } from '../store/pendingBills'
import { formatDateWithTimezone } from './utils'

export const http = axios.create({
  baseURL: process.env.BASE_API_URL,
})

function generateHash(message) {
  const secret =
    'contrastText,backgroundColor:(o.vars||o).palette[e.color].main}))'
  const hash = CryptoJS.HmacSHA256(message, secret).toString(
    CryptoJS.enc.Base64
  )
  return hash
}

export const interceptor = (store) => {
  http.defaults.headers.common['api_key'] = process.env.API_KEY
  http.defaults.headers.common['time-zone'] =
    Intl.DateTimeFormat().resolvedOptions().timeZone
  http.defaults.headers.common['platform'] = 'web'
  http.defaults.headers.common['app-version-code'] = '84'

  http.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json'

    // Generate current date-time and signature for each request
    const currentDatetime = formatDateWithTimezone(new Date()) //dd-MM-yyyy HH:mm:ss.SSS Z
    const signature = generateHash(currentDatetime)

    config.headers['X-HMAC-Signature'] = signature
    config.headers['Date-Time'] = currentDatetime

    const token = getAccessToken()
    if (token) {
      config.headers['token'] = token
    }
    const store_id = getSelectedStoreId()
    if (store_id) {
      config.headers['store_id'] = store_id
    }
    return config
  })

  http.interceptors.response.use(
    (response) => {
      if (response.data.code === 401) {
        removeToken()
        removeSelectedStoreId()
        window.location.assign(ApplicationLinks.loginLink())
        return Promise.reject(response)
      } else if (response.data.code === 406) {
        window.location.assign(ApplicationLinks.onboardingLink())
        return Promise.reject(response)
      } else if (response.data.code === 702) {
        if (process.env.OFFLINE) {
          store.dispatch(showSubscribePopupAction()) // show subscription plan
        } else {
          store.dispatch(showPaymentDuePopupAction()) // show payment due popup
        }
      }
      return response
    },
    (error) => {
      // Handle error and trigger warning message
      const errorMessage = navigator?.onLine
        ? error.message
        : 'Please check your internet connection!!!'
      store.dispatch({
        type: 'ERROR',
        message: errorMessage,
      })
      if (!navigator?.onLine && !process.env.OFFLINE) {
        alert(errorMessage)
      }
      return Promise.reject(error)
    }
  )
}
