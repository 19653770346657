import { FC, useState, useEffect, Fragment, useContext } from 'react'
import {
  ListItem,
  Divider,
  styled,
  CardContent,
  Card,
  Typography,
  CardActions,
  Button,
  Grid,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { fetchDiscountedCategoriesAction, updateDiscountedCategoriesAction, updateDiscountedCategoriesResetAction } from '../../../../store/itemCategory'
import { CreateBillManagerContext } from '../CreateBillManager'
import {
  getFormattedEndDateString,
  getFormattedStartDateString,
} from '../../../../utils'
import DiscountCategories from './DiscountCategories'
import { showAlertAction } from '../../../../store/alerts'

export interface OneDayDiscountProps {
  children?: React.ReactNode
  hideDayDiscountList: boolean
  plotArea?: number
  sqftStartDate?: string
  sqftEndDate?: string
  onStartDateChange?: (newDate?: string) => void
  onEndDateChange?: (newDate?: string) => void
}
const OneDayDiscount: FC<OneDayDiscountProps> = ({
  hideDayDiscountList,
  plotArea,
  sqftStartDate,
  sqftEndDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [checked, setChecked] = useState([])
  const { billId, activeStep, setActiveStep } = useContext(
    CreateBillManagerContext
  )
  const dispatchAction = useAppDispatch()

  const {
    success: catUpdateSuccess, message: errorMessage
  } = useAppSelector((state) => state.updateOneDayDiscount)


  useEffect(() => {
    setStartDate(new Date(sqftStartDate))
    setEndDate(new Date(sqftEndDate))
  }, [sqftStartDate, sqftEndDate])

  useEffect(() => {
    if(catUpdateSuccess) {
      dispatchAction(fetchDiscountedCategoriesAction(billId))
      setActiveStep(activeStep + 1)
      dispatchAction(updateDiscountedCategoriesResetAction())
    } else if(errorMessage) {
      dispatchAction(showAlertAction({ message: errorMessage, severity: 'error' }))
    }
  
  }, [catUpdateSuccess, errorMessage])

  const handleNext = (e) => {
    e.preventDefault()
    dispatchAction(updateDiscountedCategoriesAction(billId, checked))
   
  }

  const startDateChangeHandler = (value) => {
    setStartDate(value)
    onStartDateChange(getFormattedStartDateString(new Date(value)))
  }

  const endDateChangeHandler = (value) => {
    setEndDate(value)
    onEndDateChange(getFormattedEndDateString(new Date(value)))
  }
  return (
    <>
      <Grid
        container
        xs={12}
        direction="column"
        spacing={2}
        sx={{
          height: '70vh',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
        }}
      >
        <Grid item md={11}>
          <Card sx={{ boxShadow: 0 }}>
            <Divider />
            <CardContent sx={{ boxShadow: 0 }}>
              {plotArea > 0 && (
                <>
                  <Typography variant="h6" sx={{ mt: 0 }}>
                    {' '}
                    Please select start date and end date of the SQFT type bill
                  </Typography>
                  <br />
                  <DatePicker
                    value={startDate}
                    onChange={startDateChangeHandler}
                    label="Start Date"
                    format="dd/MM/yyyy"
                  />{' '}
                  <br />
                  <br />
                  <DatePicker
                    value={endDate}
                    onChange={endDateChangeHandler}
                    label="End Date"
                    format="dd/MM/yyyy"
                  />
                  <br />
                  <br />
                </>
              )}
              {!hideDayDiscountList && (
                <DiscountCategories onCategoriesChange={(newCats) => {
                   setChecked(newCats)
                }}/>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={1}>
          <CardActions sx={{ justifyContent: 'right', mr: 1 }}>
            <Button
              size="medium"
              color="primary"
              variant="contained"
              onClick={handleNext}
            >
              Next
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </>
  )
}

export default OneDayDiscount
