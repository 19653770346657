import {
  CreateOnboardingStocksAPIResponse,
  FetchOnboardingStocksAPIResponse,
} from '../../../services/onboarding'
import { BaseReducerState, OnboardingStock } from '../../../services'
import { ActionTypes } from '../actions'

// Fetch onboarding stock
export type FetchOnboardingStockState = BaseReducerState & {
  data: OnboardingStock[]
}

export const initialState: FetchOnboardingStockState = {
  loading: false,
  success: false,
  message: null,
  data: null,
  error: null,
}

export const fetchOnboardingStock = (
  state: FetchOnboardingStockState = initialState,
  action: { type: string; res: any }
): FetchOnboardingStockState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_ONBOARDING_STOCK}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.FETCH_ONBOARDING_STOCK}_SUCCESS`: {
      const { success, data, message } = <FetchOnboardingStocksAPIResponse>(
        action.res
      )
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.FETCH_ONBOARDING_STOCK}_FAILURE`: {
      const { success, data, message } = <FetchOnboardingStocksAPIResponse>(
        action.res
      )
      return {
        ...state,
        loading: false,
        success,
        message,
        data,
      }
    }

    case `${ActionTypes.FETCH_ONBOARDING_STOCK}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}

// Create onboarding stock
export type CreateOnboardingStockState = BaseReducerState & {
  data: boolean | null
}

export const createInitialState: CreateOnboardingStockState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const createOnboardingStock = (
  state: CreateOnboardingStockState = createInitialState,
  action: { type: string; res: any }
): CreateOnboardingStockState => {
  switch (action.type) {
    case `${ActionTypes.CREATE_ONBOARDING_STOCK}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.CREATE_ONBOARDING_STOCK}_SUCCESS`: {
      const { success, data, message } = <CreateOnboardingStocksAPIResponse>(
        action.res
      )
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.CREATE_ONBOARDING_STOCK}_FAILURE`:
      const res = <CreateOnboardingStocksAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.CREATE_ONBOARDING_STOCK}_RESET`:
      return {
        ...createInitialState,
      }
  }
  return state
}
