import React, { useState, useEffect } from 'react'
import { TextField, Button, Grid, Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import PageTitleWrapper from '../../../../src/components/PageTitleWrapper'
import PageContainerWrapper from '../../../../src/components/PageContainerWrapper'
import TnCHeader from './PageHeader'
import { getStoreTnc, saveStoreTnc } from './Services'
import { showAlertAction } from '../../../../src/store/alerts'
import { useAppDispatch } from '../../../../src/hooks'

const TnCView: React.FC = () => {
  const [bankAccountDetails, setBankAccountDetails] = useState('')
  const [invoiceTnc, setInvoiceTnc] = useState('')
  const [challanTnc, setChallanTnc] = useState('')
  const [quotationTnc, setQuotationTnc] = useState('')
  const [agreementTnc, setAgreementTnc] = useState('')
  const [nonGstBankAccount, setNonGstBankAccount] = useState('')
  const [invoiceTitle, setInvoiceTitle] = useState('')
  const [id, setId] = useState(null)
  const dispatchAction = useAppDispatch()

  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = async () => {
    getStoreTnc().then((response) => {
      if (response.success) {
        const data = response.data
        if (data) {
          setId(data.id)
          setBankAccountDetails(data.bankAccountDetails)
          setInvoiceTnc(data.invoiceTnc)
          setChallanTnc(data.challanTnc)
          setQuotationTnc(data.quotationTnc)
          setAgreementTnc(data.agreementTnc)
          setNonGstBankAccount(data.nonGstBankAccount)
        }
      } else {
        dispatchAction(
          showAlertAction({
            message: response.message,
            severity: 'error',
          })
        )
      }
    })
    try {
      const response = await fetch('https://shutteringapp.com/api/tnc')
      if (response.ok) {
        const data = await response.json()
        setBankAccountDetails(data.bankAccountDetails)
        setInvoiceTnc(data.invoiceTnc)
        setChallanTnc(data.challanTnc)
        setQuotationTnc(data.quotationTnc)
        setAgreementTnc(data.agreementTnc)
        setInvoiceTitle(data.invoiceTitle)
        setNonGstBankAccount(data.nonGstBankAccount)
      } else {
        console.error('Failed to fetch data:', response.statusText)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }

  const handleSubmit = async () => {
    const data = {
      id,
      bankAccountDetails,
      invoiceTnc,
      challanTnc,
      quotationTnc,
      agreementTnc,
      invoiceTitle,
      nonGstBankAccount
    }

    saveStoreTnc(data).then((response) => {
      if (response.success) {
        dispatchAction(
          showAlertAction({
            message: response.message,
            severity: 'success',
          })
        )
        fetchData()
      } else {
        dispatchAction(
          showAlertAction({
            message: response.message,
            severity: 'error',
          })
        )
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Manage Parties</title>
      </Helmet>
      <PageTitleWrapper>
        <TnCHeader />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Invoice T&C"
                fullWidth
                multiline
                rows={6}
                value={invoiceTnc}
                onChange={(e) => setInvoiceTnc(e.target.value)}
              />
              <TextField
                sx={{ mt: 4 }}
                label="Challan T&C"
                fullWidth
                multiline
                rows={6}
                value={challanTnc}
                onChange={(e) => setChallanTnc(e.target.value)}
              />
              <TextField
                sx={{ mt: 4 }}
                label="Bank Account Details(GST)"
                fullWidth
                multiline
                rows={4}
                value={bankAccountDetails}
                onChange={(e) => setBankAccountDetails(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Quotation T&C"
                fullWidth
                multiline
                rows={6}
                value={quotationTnc}
                onChange={(e) => setQuotationTnc(e.target.value)}
              />
              <TextField
                sx={{ mt: 4 }}
                label="Agreement T&C"
                fullWidth
                multiline
                rows={6}
                value={agreementTnc}
                onChange={(e) => setAgreementTnc(e.target.value)}
              />
               <TextField
                sx={{ mt: 4 }}
                label="Bank Account Details(Non GST)"
                fullWidth
                multiline
                rows={4}
                value={nonGstBankAccount}
                onChange={(e) => setNonGstBankAccount(e.target.value)}
              />
               <TextField
                sx={{ mt: 4 }}
                label="Invoice Title"
                fullWidth
                multiline
                rows={1}
                value={invoiceTitle}
                onChange={(e) => setInvoiceTitle(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button sx={{ mt: 2 }} variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </PageContainerWrapper>
    </>
  )
}

export default TnCView
