import { FC, useState, useReducer, useRef, useEffect } from 'react'
import { Grid, TextField, Autocomplete, Button, Box } from '@mui/material'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'

import { PrepareIssueItem, SelectOptions, EntryType } from '../../../services'

import CustomNumberField from '../Users/profile/CustomNumberField'

export const HORZ_SPACING = 1.5
type IssueItemFields =
  | 'item'
  | 'quantity'
  | 'rate'
  | 'total_cost'
  | 'height'
  | 'width'
  | 'length'
type IssueItemFieldsState = Record<
  IssueItemFields,
  Partial<{
    value: string
    error: boolean
    helperText: string
  }>
>

type IssueItemErrors = Record<IssueItemFields, Record<string, string>>

const issueItemErrors: IssueItemErrors = {
  item: {
    empty: 'Select item',
  },
  quantity: {
    empty: 'Enter quantity',
    maxLimit: 'Only <QUANTITY> <ITEM> <TYPE>',
  },
  rate: {
    empty: 'Enter rate',
  },

  total_cost: {
    empty: 'Enter total cost',
  },
  height: {
    empty: 'Enter height',
  },
  width: {
    empty: 'Enter width',
  },
  length: {
    empty: 'Enter length',
  },
}

const initialItemIssueState: IssueItemFieldsState = {
  item: {
    error: false,
    helperText: ' ',
  },
  quantity: {
    error: false,
    helperText: ' ',
  },
  rate: {
    error: false,
    helperText: ' ',
  },
  total_cost: {
    error: false,
    helperText: ' ',
  },
  height: {
    error: false,
    helperText: ' ',
  },
  width: {
    error: false,
    helperText: ' ',
  },
  length: {
    error: false,
    helperText: ' ',
  },
}
const itemReducer = (state: Partial<IssueItemFieldsState>, action) => {
  const {
    type,
    payload: { name, value },
    helperText,
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: helperText
            ? helperText
            : initialItemIssueState[name]?.helperText,
        },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: issueItemErrors[name]['empty'],
        },
      }
    case 'ERROR_MAX_LIMIT':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: helperText,
        },
      }
    case 'RESET':
      return {
        ...initialItemIssueState,
        [name]: {
          ...state[name],
          error: false,
          value: value,
          helperText: initialItemIssueState[name]?.helperText,
        },
      }
    default:
      return state
  }
}

type ItemComponentProps = {
  selectOptions: SelectOptions[]
  entryType?: EntryType
  addItem: (item: PrepareIssueItem) => void
  submission_type?: 'LOST' | 'SUBMIT'
  billType: string
  defaultRateZero: boolean
}

const ItemComponent: FC<ItemComponentProps> = ({
  selectOptions,
  entryType = EntryType.OUT,
  addItem,
  submission_type = 'SUBMIT',
  billType,
  defaultRateZero,
}) => {
  console.log(selectOptions)

  const [item, setItem] = useState<PrepareIssueItem | null>()
  const [selectedItem, setSelectedItem] = useState<SelectOptions | null>()
  const [itemData, dispatch] = useReducer(itemReducer, initialItemIssueState)
  const [value, setValue] = useState<SelectOptions | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [entryCategroy, setEntryCategory] = useState<string | null>(null)

  const [rateDict, setRateDict] = useState({})
  const autocompleteRef = useRef(null)

  const addItemHandler = () => {
    let error = false

    if (selectedItem.isArea) {
      // IF item is of type Area them we have to do the empty check of height, width and length
      if (!item?.height || item.height <= 0) {
        error = true
        dispatch({ type: 'ERROR', payload: { name: 'height', value: null } })
      }

      if (!item?.width || item.width <= 0) {
        error = true
        dispatch({ type: 'ERROR', payload: { name: 'width', value: null } })
      }

      if (!item?.length || item.length <= 0) {
        error = true
        dispatch({ type: 'ERROR', payload: { name: 'length', value: null } })
      }
    } else {
      if (!item?.item?.id) {
        error = true
        dispatch({ type: 'ERROR', payload: { name: 'item', value: null } })
      }
      if (!item?.quantity || item.quantity <= 0) {
        error = true
        dispatch({ type: 'ERROR', payload: { name: 'quantity', value: null } })
      }
      if (
        billType !== 'PER_SQFT' &&
        !item?.rate &&
        item?.rate !== 0 &&
        (entryType === EntryType.OUT || submission_type === 'LOST')
      ) {
        error = true
        dispatch({ type: 'ERROR', payload: { name: 'rate', value: null } })
        dispatch({
          type: 'ERROR',
          payload: { name: 'total_cost', value: null },
        })
      }
    }

    if (!error) {
      // Validataion for quantity check
      if (entryType == EntryType.OUT && selectedItem) {
        setRateDict((prevState) => ({
          ...prevState,
          [selectedItem.category]: item.rate,
        }))
      }

      if (selectedItem?.isArea) {
        item.isArea = true
        item.quantity = 1 // We will not show the quantity on UI but pass the hardcoded value 1 to the API
      }
      if (submission_type === 'LOST') {
        const itemtoAdd = { ...item }
        itemtoAdd.lostItemCost = itemtoAdd.rate
        delete itemtoAdd.rate
        itemtoAdd.rate = selectedItem?.rate
        addItem(itemtoAdd)
      } else {
        console.log('ITem to add', item)
        addItem(item)
      }

      setItem((item) => {
        return {
          entryType,
          rate: null,
          item: null,
          quantity: null,
        }
      })
      const category = value.category
      setValue(null)
      setEntryCategory(category)
    }
  }

  useEffect(() => {
    if (entryCategroy) {
      let hasMoreItems = false
      for (let i = 0; i < selectOptions.length; i++) {
        const option = selectOptions[i].label.toLowerCase()
        if (option.startsWith(entryCategroy.toLowerCase() + ' ')) {
          setInputValue(entryCategroy + ' ')
          hasMoreItems = true
          break
        }
      }
      if (!hasMoreItems) {
        setInputValue('')
      }
      autocompleteRef.current.focus()
      setEntryCategory(null)
    }
  }, [selectOptions])

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Enter key
      addItemHandler()
      event.preventDefault()
      event.stopPropagation()
       // Stop all other Enter key handlers
    }
  }

  const onInputChange = (e) => {
    const { name, value } = e.target
    setItem((item) => {
      return {
        ...item,
        [name]: value,
      }
    })
    if (value && name) {
      if (
        name == 'quantity' &&
        selectedItem &&
        value > selectedItem.availableQuantity
      ) {
        const helperText = issueItemErrors[name]['maxLimit']
          .replace('<QUANTITY>', selectedItem.availableQuantity)
          .replace(
            '<ITEM>',
            `${selectedItem.availableQuantity > 1 ? 'items are' : 'item is'}`
          )
          .replace(
            '<TYPE>',
            `${entryType == EntryType.OUT ? 'available' : 'remaining'}`
          )
        dispatch({
          type: 'ERROR_MAX_LIMIT',
          payload: { name: name, value: selectedItem.availableQuantity },
          helperText,
        })
        setItem((item) => {
          return {
            ...item,
            [name]: selectedItem.availableQuantity,
          }
        })
      } else {
        dispatch({ type: 'SET', payload: { name: name, value: value } })
      }
    } else {
      dispatch({ type: 'ERROR', payload: { name: name, value: value } })
    }
  }

  const onAutocompleteInputChange = (e) => {
    const { name, value } = e.target
    setInputValue(value)
  }

  const quantityTextFieldRef = useRef(null)

  const onSelectChange = (
    e: any,
    newValue: {
      label: string
      id: number
      rate: number
      availableQuantity: number
      category: string
      outAreaEntryId?: number
    }
  ) => {
    setValue(newValue)
    setInputValue(newValue?.label ? newValue?.label : '')

    const updatedItem = newValue
    setSelectedItem(updatedItem)
    setItem((item) => {
      return {
        ...item,
        entryType,
        quantity: 0,
        rate:
          defaultRateZero || billType === 'PER_SQFT'
            ? 0
            : submission_type === 'LOST'
            ? null
            : updatedItem?.rate
            ? updatedItem?.rate
            : updatedItem?.rate,
        item: {
          ...item?.item,
          id: updatedItem?.id,
          label: updatedItem?.label,
          category: updatedItem?.category,
        },
        outAreaEntryId: updatedItem?.outAreaEntryId,
      }
    })
    if (newValue?.id) {
      dispatch({ type: 'SET', payload: { name: 'item', value: newValue?.id } })
      dispatch({
        type: 'RESET',
        payload: { name: 'item', value: newValue?.id },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'quantity', value: 0 },
        helperText: `${updatedItem.availableQuantity} ${
          entryType == EntryType.OUT ? 'available' : 'remaining'
        }`,
      })
    } else {
      dispatch({ type: 'ERROR', payload: { name: 'item', value: null } })
    }
    quantityTextFieldRef.current?.focus()
  }

  useEffect(() => {
    console.log('item=-=-=-=-=-=-=', item)
  }, [item])

  const mdValue =
    entryType === EntryType.OUT || submission_type === 'LOST' ? 3.5 : 5.25

  return (
    <Grid
      container
      spacing={0}
      xs={12}
      sx={{ backgroundColor: 'white', paddingBottom: 0, mb: 0 }}
    >
      <Grid
        item={true}
        spacing={0}
        xs={12}
        md={mdValue}
        sx={{ backgroundColor: 'white', alignItems: 'left' }}
      >
        <Autocomplete
          size="small"
          sx={{ backgroundColor: 'white', padding: 0, margin: 0 }}
          disablePortal
          id="combo-box-demo"
          value={value}
          inputValue={inputValue}
          options={selectOptions}
          onChange={onSelectChange}
          openOnFocus={true}
          renderOption={(props, option) => {
            const bgColor = option.rate === 0 ? '#eb627a' : '#314d67'
            const type = option.rate === 0 ? 'per sqft' : 'per item'
            const id =
              billType === 'MIX' && entryType === 'IN'
                ? `${option.label} ${type}`
                : `${option.label}`
            const outAreaEntryIdKey = option.outAreaEntryId
              ? `-${option.outAreaEntryId}`
              : ''
            const key = `${option.id}-${option.rate}${outAreaEntryIdKey}`
            return (
              <Grid component={'li'} container {...props} key={key}>
                <Grid id={id} item xs pl={1} pr={1} pt={0.7} pb={0.7}>
                  {option.label}
                </Grid>
                {billType === 'MIX' && entryType === 'IN' && (
                  <Grid item width={100} textAlign="right" p={1}>
                    <span
                      style={{
                        paddingLeft: 7,
                        paddingRight: 7,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderRadius: 20,
                        backgroundColor: bgColor,
                        color: 'white',
                      }}
                    >
                      {type}
                    </span>
                  </Grid>
                )}
              </Grid>
            )
          }}
          renderInput={(params) => (
            <TextField
              style={{ width: '100%', marginTop: 0 }}
              size="small"
              {...params}
              label="Select Item"
              inputRef={autocompleteRef}
              required
              onChange={onAutocompleteInputChange}
              error={itemData['item']['error']}
              helperText={itemData['item']['helperText']}
              sx={{ margin: 0 }}
            />
          )}
        />
      </Grid>

      {selectedItem?.isArea && (
        <>
         <Grid item={true} xs={12} pl={HORZ_SPACING} md={3.5 / 2.0}>
            <CustomNumberField
              style={{ width: '100%', margin: 0 }}
              maxLength={6}
              name="length"
              InputLabelProps={{ shrink: !!item?.length }}
              value={item?.length ? item?.length : ''}
              label="Length"
              onChange={onInputChange}
              onKeyDown={handleKeyDown}
              error={itemData['length']['error']}
              helperText={itemData['length']['helperText']}
              sx={{ width: '15ch' }}
              required
            ></CustomNumberField>
          </Grid>

          <Grid item={true} xs={12} md={3.5 / 2.0} pl={HORZ_SPACING}>
            <CustomNumberField
              style={{ width: '100%', margin: 0 }}
              maxLength={6}
              name="height"
              InputLabelProps={{ shrink: !!item?.height }}
              value={item?.height ? item?.height : ''}
              label="Height"
              onChange={onInputChange}
              onKeyDown={handleKeyDown}
              error={itemData['height']['error']}
              helperText={itemData['height']['helperText']}
            ></CustomNumberField>
          </Grid>
         
         <Grid item={true} xs={12} md={3.5 / 2.0} pl={HORZ_SPACING}>
            <CustomNumberField
              style={{ width: '100%', margin: 0 }}
              maxLength={6}
              name="width"
              InputLabelProps={{ shrink: !!item?.width }}
              value={item?.width ? item?.width : ''}
              label="Width"
              onChange={onInputChange}
              onKeyDown={handleKeyDown}
              error={itemData['width']['error']}
              helperText={itemData['width']['helperText']}
              sx={{ width: '15ch' }}
              required
            ></CustomNumberField>
          </Grid>

         
        </>
      )}

      {!selectedItem?.isArea && (
        <Grid item={true} xs={12} pl={HORZ_SPACING} md={mdValue}>
          <CustomNumberField
            maxLength={6}
            fullWidth
            name="quantity"
            InputLabelProps={{ shrink: !!item?.quantity }}
            value={item?.quantity ? item?.quantity : ''}
            label="Quantity"
            onChange={onInputChange}
            onKeyDown={handleKeyDown}
            inputRef={quantityTextFieldRef}
            error={itemData['quantity']['error']}
            helperText={itemData['quantity']['helperText']}
            required
            style={{ width: '100%', marginTop: 0 }}
          ></CustomNumberField>
        </Grid>
      )}

      {(entryType === EntryType.OUT || submission_type === 'LOST') && (
        <Grid
          item={true}
          xs={12}
          md={selectedItem?.isArea ? mdValue / 2.0 : mdValue}
          pl={HORZ_SPACING}
        >
          <CustomNumberField
            style={{ width: '100%', margin: 0 }}
            maxLength={6}
            id={`id_typo_textfield_price`}
            name="rate"
            value={
              billType === 'PER_SQFT' &&
              (!selectedItem || selectedItem.category !== 'Sell Items')
                ? 0
                : item?.rate || item?.rate === 0
                ? item?.rate
                : ''
            }
            label={submission_type === 'LOST' ? 'Total Cost' : 'Rate'}
            onKeyDown={handleKeyDown}
            onChange={onInputChange}
            error={
              itemData[submission_type === 'LOST' ? 'total_cost' : 'rate'][
                'error'
              ]
            }
           
            helperText={itemData['rate']['helperText']}
          ></CustomNumberField>
        </Grid>
      )}

      <Grid
        item={true}
        md={1.5}
        xs={12}
        sx={{ backgroundColor: 'white', pl: HORZ_SPACING }}
      >
        <Box>
          <Button
            fullWidth
            sx={{
              mt: {
                height: 37.5,
                backgroundColor: 'rgb(0, 128, 0, 0.5)',
                color: 'green',
                borderRadius: 4,
              },
            }}
            size="small"
            onClick={addItemHandler}
            startIcon={<AddTwoToneIcon />}
          >
            Add
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
export default ItemComponent
