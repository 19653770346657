import { Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'

import { FormInputProps } from './../FormInputProps'

export const FormInputTextArea = ({
  name,
  control,
  label,
  rules,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
                    size='small'
          multiline
          rows={5}
          helperText={error ? error.message : null}
          size={'medium'}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
        />
      )}
    />
  )
}
