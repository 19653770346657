import { FC, useState, useEffect } from 'react'
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Button,
} from '@mui/material'
import { useAppDispatch, usePayRazorpay } from '../../../hooks'
import {
  PendingBills,
  createPaynow,
  createVerifySubscribedPlan,
} from '../../../services'
import { getDisplayDateTime, getDisplayDate, loadScript, currencyFormatter } from '../../../utils'
import { showAlertAction } from '../../../store/alerts'
import {
  fetchPendingBillsAction,
  hidePaymentDuePopupAction,
} from '../../../store/pendingBills'
interface ListTableProps {
  className?: string
  pendingBills: PendingBills[]
}
function getId(pendingBills: PendingBills, field: string) {
  return `id_${pendingBills.id}_${field}`
}

const ListTable: FC<ListTableProps> = ({ pendingBills }) => {
  const boldFontWeight = 600
  const dispatchAction = useAppDispatch()
  const [makeRazorPayment] = usePayRazorpay()
  const [jsLoadStatus, setJsLoadStatus] = useState(false)
  // Load the javascript
  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
      .then(() => {
        setJsLoadStatus(true)
      })
      .catch((errr) => {
        console.error('Razorpay SDK failed to load. Are you online?', errr)
        setJsLoadStatus(false)
      })
  }, [])

  const paymentFailed = function () {
    dispatchAction(
      showAlertAction({ message: 'Payment failed', severity: 'error' })
    )
  }
  const paymentSuccess = async (response) => {
    const verifyRequest = {
      signature: response.razorpay_signature,
      payment_id: response.razorpay_payment_id,
    }
    const verifyResponse = await createVerifySubscribedPlan(verifyRequest)
    if (verifyResponse?.data || verifyResponse?.success) {
      dispatchAction(hidePaymentDuePopupAction())
      dispatchAction(fetchPendingBillsAction(false))
    } else {
      dispatchAction(
        showAlertAction({
          message: verifyResponse?.message || 'Opps something went wrong',
          severity: 'error',
        })
      )
    }
  }
  const displayRazorpay = async (id: number, month: string) => {
    const payNowResponse = await createPaynow(id)
    if (!payNowResponse?.success || !payNowResponse?.data) {
      dispatchAction(
        showAlertAction({
          message: payNowResponse?.message || 'Opps something went wrong',
          severity: 'error',
        })
      )
      return false
    }
    if (!jsLoadStatus) {
      return false
    }
    const subscribePlanData = { ...payNowResponse?.data, description: month }
    makeRazorPayment(subscribePlanData, paymentSuccess, paymentFailed)
  }
  return (
    <>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f9f9f9', height: 52 }}>
              <TableCell sx={{ color: 'black', fontWeight: boldFontWeight }}>
                Billing Month
              </TableCell>
            
              <TableCell sx={{ color: 'black', fontWeight: boldFontWeight }}>
                Total Billing (Usage)
              </TableCell>
              <TableCell sx={{ color: 'black', fontWeight: boldFontWeight }}>
                App Charges
              </TableCell>
              <TableCell sx={{ color: 'black', fontWeight: boldFontWeight }}>
                Due Date
              </TableCell>
         
              <TableCell
                align="right"
                sx={{ color: 'black', fontWeight: boldFontWeight, pr: 3 }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingBills.map((pendingBill) => {
              return (
                <TableRow hover key={pendingBill.id} sx={{ height: 52 }}>
                  <TableCell>
                    <Typography
                      data-cy={getId(pendingBill, 'month')}
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {pendingBill.month}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      data-cy={getId(pendingBill, 'consumption')}
                      variant="body1"
                      color="text.primary"
                      maxWidth={400}
                      gutterBottom
                      noWrap
                    >
                      {currencyFormatter.format(pendingBill.consumption)}
                    </Typography>
                  </TableCell>
                  
                  
                  <TableCell>
                  <span style={{textDecoration:'line-through', fontSize:12, color:'red'}}>{currencyFormatter.format(pendingBill.amount)}</span> <span style={{color:'green'}}>{currencyFormatter.format(pendingBill.amountAfterDiscount)}</span>

                  </TableCell>
                  <TableCell>
                    <Typography
                      data-cy={getId(pendingBill, 'dueDate')}
                      variant="body1"
                      color={pendingBill.status === 'PAID' ? 'green' : 'red'}
                      gutterBottom
                      noWrap
                    >
                      {pendingBill.status === 'DUE' ? getDisplayDate(pendingBill.dueDate) : 'PAID'}
                    </Typography>
                  </TableCell>
                 
                  <TableCell align="right">
                    {pendingBill.status?.toLocaleLowerCase() == 'due' ? (
                      <Button
                        id="buy_plan_button"
                        variant="contained"
                        size='small'
                        sx={{ backgroundColor: 'green' }}
                        onClick={() =>
                          displayRazorpay(pendingBill.id, pendingBill.month)
                        }
                      > Pay
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ListTable
