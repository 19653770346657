import { useEffect, useState } from 'react'
import { Box, Typography, Grid, Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  fetchSubscriptionPlanAction,
  fetchSubscriptionPlanResetAction,
} from '../../../../store/subscriptionPlan/actions'
import PlanList from './PlanList'
import { showAlertAction } from '../../../../../src/store/alerts'
import { OfflineLoader } from '../../../../../src/components/OfflineLoader'

const SubscribePlan = () => {
  const dispatchAction = useAppDispatch()
  const [showLoader, setShowLoader] = useState<boolean>()
  const { data, message, success } = useAppSelector(
    (state) => state.subscriptionPlan
  )

  const monthly_billing = 5000000
  useEffect(() => {
    dispatchAction(fetchSubscriptionPlanAction(monthly_billing))
  }, [])

  useEffect(() => {
    if (!success && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [data, success])

  return (
    <>
      <Box
        component="form"
        sx={{ padding: '40px' }}
        noValidate
        autoComplete="off"
      >
        <Typography component="h1" sx={{ fontSize: 16 }}>
          Plans are monthly/Quaterly/Half Yearly and Yearly basis. Please select
          your plan
        </Typography>
        {data?.length && (
          <Grid container sx={{ mt: 0, flexDirection: 'column' }}>
            <PlanList
              plans={data}
              monthly_billing={monthly_billing}
              setShowLoader={setShowLoader}
            />
          </Grid>
        )}
      </Box>
    </>
  )
}

export default SubscribePlan
