import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material'
import logo from './images/app_logo_blu.png'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import ApplicationLinks from '../../ApplicationLinks'

const FooterLink = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.common.white};
      `
)

export const WebsiteFooter = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const theme = useTheme()

  // Detect if the screen size is small (e.g., mobile)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#314367',
        height: 'auto', // Allow height to adjust based on content
        color: `${theme.palette.common.white}`,
        padding: isMobile ? '20px 10px' : '0', // Add padding for mobile
      }}
    >
      <Grid container sx={{ width: isMobile ? '90%' : '70%' }}>
        <Grid item xs={12} sm={6} sx={{ textAlign: isMobile ? 'center' : 'left', marginBottom: isMobile ? '20px' : '0' }}>
          <Box>
            <Grid container direction={'row'} spacing={2} justifyContent={isMobile ? 'center' : 'flex-start'}>
              <Grid item>
                <img src={logo} alt="shuttering" style={{ width: 44 }} />
              </Grid>
              <Grid item>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Typography> © TEJSE {currentYear}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Tejse Online Services Private Limited
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12} sm={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMobile ? 'center' : 'flex-end',
            textAlign: isMobile ? 'center' : 'right',
          }}
        >
          <Box>
            <FooterLink href={ApplicationLinks.privacyPolicyLink()}>
              Privacy Policy
            </FooterLink>
            {' | '}
            <FooterLink href={ApplicationLinks.termsAndConditionsink()}>
              Terms and Conditions
            </FooterLink>
            {' | '}
            <FooterLink href={ApplicationLinks.shippingPolicyLink()}>
              Shipping Policy
            </FooterLink>
            {' | '}
            <FooterLink href={ApplicationLinks.refundAndCancelationLink()}>
              Cancellation and Refund Policy
            </FooterLink>
            {' | '}
            <FooterLink href={ApplicationLinks.contactLink()}>
              Contact Us
            </FooterLink>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
