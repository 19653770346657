import { FC, useEffect, useState } from 'react'
import { Box, Checkbox, Grid, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import { Bill, BillPDFRequest } from '../../../services'

// Components
import { AppDialog } from '../../../components'

// Redux
import {
  getFormattedEndDateString,
  getFormattedStartDateString,
} from '../../../utils'

export interface PlotAreaDatesProps {
  onClose?: (startDate?: string, endDate?: string, fetchBill?: boolean) => void
  bill: Bill
}

export const PlotAreaDatesItem: FC<PlotAreaDatesProps> = ({
  onClose,
  bill,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [isStartDateBlank, setStartDateBlank] = useState<boolean>(false)
  const [isEndDateBlank, setEndDateBlank] = useState<boolean>(false)

  useEffect(() => {
    if (bill) {
      setStartDateBlank(false)
      setEndDateBlank(false)
      if (bill.sqftStartDate) {
        setStartDate(new Date(bill.sqftStartDate))
      }
      if (bill.sqftEndDate) {
        setEndDate(new Date(bill.sqftEndDate))
      }
    }
  }, [bill])
  const startDateChangeHandler = (value) => {
    setStartDate(value)
  }

  const endDateChangeHandler = (value) => {
    setEndDate(value)
  }

  const submitHandle = () => {
    let date1 = null
    let date2 = null
    if (startDate) {
      date1 = getFormattedStartDateString(new Date(startDate))
    }
    if (endDate) {
      date2 = getFormattedEndDateString(new Date(endDate))
    }
    if (!date1) {
      setStartDateBlank(true)
    }
    if (!date2) {
      setEndDateBlank(true)
    }
    onClose(date1, date2, true)
  }

  return (
    <AppDialog
      id="edit-item"
      title={'Select start date and end date of SQFT items'}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
      fixHeight={false}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} mr={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2} mt={0.5}>
              <Grid item={true} xs={12}>
                <DatePicker
                  label="Start Date (Leave blank to start from first day)"
                  value={startDate}
                  onChange={startDateChangeHandler}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: 'outlined',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item={true} xs={12} mt={2}>
                <DatePicker
                  label="End Date(Leave blank for Today)"
                  value={endDate}
                  onChange={endDateChangeHandler}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: 'outlined',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
