import { FC, createContext, ReactNode, useState } from 'react'
import { showOneDayDiscount } from '.'
import { MerchantEnteredDays } from '../../../services'
export interface CreateBillManagerType {
  billId?: number
  totalRentCopy?: number
  setTotalRent?: (totalRentCopy: number) => void
  activeStep?: number
  setActiveStep?: (step: number) => void
  merchantEnteredDataArray?: Array<MerchantEnteredDays>
  setMerchantEnteredDataArray?: (
    merchantEnteredDataArray: Array<MerchantEnteredDays>
  ) => void
}

export const CreateBillManagerContext = createContext<CreateBillManagerType>({})

type CreateBillManagerProps = {
  children: ReactNode
  bill_id: number
}
export const CreateBillManager: FC<CreateBillManagerProps> = ({
  children,
  bill_id,
}) => {
  const [billId] = useState<number>(bill_id)
  const [totalRentCopy, setTotalRent] = useState<number>(0)
  const [activeStep, setActiveStep] = useState<number>(showOneDayDiscount ? 0 : 1)
  const [merchantEnteredDataArray, setMerchantEnteredDataArray] = useState<
    Array<MerchantEnteredDays>
  >([])

  return (
    <CreateBillManagerContext.Provider
      value={{
        billId,
        totalRentCopy,
        setTotalRent,
        activeStep,
        setActiveStep,
        merchantEnteredDataArray,
        setMerchantEnteredDataArray,
      }}
    >
      {children}
    </CreateBillManagerContext.Provider>
  )
}
