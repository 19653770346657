import { UserLoginData, UserLoginResponse } from 'src/services'
import { ActionTypes } from '../actions'

export type UpdateProfileState = {
  loading: boolean
  success: boolean
  message?: string
  error: string
  data: UserLoginData | null
}

const initialState: UpdateProfileState = {
  loading: false,
  success: false,
  error: null,
  data: null,
}

export const updateProfile = (
  state: UpdateProfileState = initialState,
  action: { type: string; res: UserLoginResponse }
): UpdateProfileState => {
  switch (action.type) {
    case `${ActionTypes.UPDATE_USER_PROFILE}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
      }
    case `${ActionTypes.UPDATE_USER_PROFILE}_SUCCESS`: {
      const { success, data, message } = action.res
      return {
        ...state,
        success,
        message,
        loading: false,
        ...(success && data && { data: data }),
      }
    }

    case `${ActionTypes.UPDATE_USER_PROFILE}_FAILURE`: {
      const { error, message } = action.res
      return {
        ...state,
        message,
        loading: false,
        success: false,
        error,
        data: null,
      }
    }

    case `${ActionTypes.UPDATE_USER_PROFILE}_RESET`: {
      return {
        ...initialState,
      }
    }
  }
  return state
}
