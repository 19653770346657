import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import PageTitleWrapper from '../../../components/PageTitleWrapper'
import PageContainerWrapper from '../../../components/PageContainerWrapper'
import { Grid } from '@mui/material'
import Footer from '../../../components/Footer'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { fetchStaffsAction } from '../../../store/staff'

import StaffList from './StaffList'
import StaffsHeader from './PageHeader'
import { useParams } from 'react-router'

export interface MyStaffProps {
  children?: React.ReactNode
}

const MyStaff: FC<MyStaffProps> = ({}) => {
  const { data } = useAppSelector((state) => state.fetchStaff)
  const dispatchAction = useAppDispatch()

  useEffect(() => {
    dispatchAction(fetchStaffsAction())
  }, [])

  return (
    <>
      <Helmet>
        <title>Manage Staffs</title>
      </Helmet>
      <PageTitleWrapper>
        <StaffsHeader />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <StaffList staffs={data} />
          </Grid>
        </Grid>
      </PageContainerWrapper>
      <Footer />
    </>
  )
}

export default MyStaff
