import { FC, useEffect } from 'react'

// Components
import { ConfirmationAppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'
import { deleteMonthlyBillResetAction, deleteMonthlyBillsAction, fetchMonthlyBillsAction } from '../../../store/monthlyBills'
import { MonthlyBill } from 'src/services'
import { SiteRecentBill } from '../RecentBills/types'

export interface DeleteMonthlyBillProps {
  onClose: (reload?: boolean) => void
  bill?: MonthlyBill
  recentInvoice?: SiteRecentBill
}

export const DeleteMonthlyBill: FC<DeleteMonthlyBillProps> = ({
  onClose,
  bill,
  recentInvoice
}) => {
  const dispatchAction = useAppDispatch()
  const {success : result, message } = useAppSelector((state) => state.deleteMonthlyBill)

  useEffect(() => {
    return () => {
      dispatchAction(deleteMonthlyBillResetAction())
    }
  }, [])

  useEffect(() => {
    if (result) {
      dispatchAction(
        showAlertAction({
          message: 'Deleted successfully',
          severity: 'success',
        })
      )
      onClose(true) // To close the popup
    } else if (message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onClose()
    }
  }, [result, message])

  const deleteHandler = () => {
    dispatchAction(deleteMonthlyBillsAction(bill ? bill.id : recentInvoice.interimBillId))
  }

  return (
    <ConfirmationAppDialog
      id="delete-item"
      title={bill ? `Delete bill of month: ${bill.intervalName}` : `Delete invoice #${recentInvoice.invoiceNo}?`}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onClose,
        },
        {
          text: 'Delete',
          color: 'error',
          variant: 'contained',
          onClickHandler: deleteHandler,
        },
      ]}
    >
      Do you really want to delete this invoice?
    </ConfirmationAppDialog>
  )
}
