import {
  BaseReducerState,
  SubscriptionPlan,
  SubscriptionPlanAPIResponse,
} from '../../../services'
import { ActionTypes } from '../actions'

export type SubscriptionPlanState = BaseReducerState & {
  data: Array<SubscriptionPlan>
  monthly_billing: number
}

export const initialState: SubscriptionPlanState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
  monthly_billing: 0,
}

export const subscriptionPlan = (
  state: SubscriptionPlanState = initialState,
  action: { type: string; res: any }
): SubscriptionPlanState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_SUBSCRIPTION_PLAN}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.FETCH_SUBSCRIPTION_PLAN}_SUCCESS`: {
      const { success, data, message, monthly_billing } = <
        SubscriptionPlanAPIResponse
      >action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        monthly_billing,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.FETCH_SUBSCRIPTION_PLAN}_FAILURE`:
      const res = <SubscriptionPlanAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.FETCH_SUBSCRIPTION_PLAN}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}

/**
 * Subscriibe Popup related
 */
export interface subscribePopupState {
  show: boolean
}

const subscribePopup: subscribePopupState = {
  show: false,
}

export const manageSubscribePopup = (
  state: subscribePopupState = subscribePopup,
  action: { type: string; payload: any }
): subscribePopupState => {
  switch (action.type) {
    case `${ActionTypes.SHOW_SUBCRIBE_POPUP}`:
      return {
        ...state,
        show: true,
      }
    case `${ActionTypes.HIDE_SUBCRIBE_POPUP}`:
      return {
        ...state,
        show: false,
      }
  }
  return state
}
