import { AxiosResponse } from 'axios'
import { http } from '../../../../../src/utils'

export interface EwayItem {
  productName: string
  productDesc: string
  hsnCode: number
  quantity: number
  qtyUnit: string
  taxableAmount: number
  sgstRate: number
  cgstRate: number
  igstRate: number
  cessRate: number
}

export function getEwayItems(challanId: number) {
  const url = `/entry/pvt/eway_items?challanId=${challanId}`
  return http.get(url).then((response: AxiosResponse) => response.data)
}
