import { forwardRef } from 'react'
import { useTheme, Button } from '@mui/material'

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import ReceiptIcon from '@mui/icons-material/Receipt'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import DeleteIcon from '@mui/icons-material/Delete'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import DateRangeIcon from '@mui/icons-material/DateRange'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt'
import EditIcon from '@mui/icons-material/Edit'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone'

const iconComponent = {
  fileupload: <FileUploadOutlinedIcon fontSize="small" />,
  filedownload: <FileDownloadOutlinedIcon fontSize="small" />,
  receipt: <ReceiptIcon fontSize="small" />,
  summary: <SummarizeOutlinedIcon fontSize="small" />,
  filealign: <FormatAlignJustifyIcon fontSize="small" />,
  more: <MoreVertIcon fontSize="small" />,
  print: <LocalPrintshopIcon fontSize="small" />,
  delete: <DeleteIcon fontSize="small" />,
  lost: <BrokenImageIcon fontSize="small" />,
  dateRange: <DateRangeIcon fontSize="small" />,
  saveAs: <SaveAsIcon fontSize="small" />,
  quote: <RequestQuoteIcon fontSize="small" />,
  edit: <EditIcon />,
  dnd: <DoDisturbAltIcon fontSize="small" />,
  file_open: <FileOpenIcon fontSize="small" />,
  other_charge: <LocalShippingIcon fontSize="small" />,
  month: <CalendarMonthIcon fontSize="small" />,
  settings: <SettingsSuggestIcon fontSize="small" />,
  create_new_task: <AddTaskTwoToneIcon fontSize="small" />,
}

export interface CardActionButtonProps {
  onClickHandler: (data: unknown) => void
  buttonText: string
  icon: string
  data: unknown
  disabled?: boolean
  id?: string
}

type Ref = HTMLButtonElement

export const CardActionButton = forwardRef<Ref, CardActionButtonProps>(
  (props, ref) => {
    const theme = useTheme()
    const {
      onClickHandler,
      buttonText,
      data,
      icon,
      disabled = false,
      id,
    } = props
    return (
      <Button
        id={id}
        variant="text"
        startIcon={iconComponent[icon]}
        size="small"
        onClick={() => onClickHandler(data)}
        ref={ref}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    )
  }
)
