import { FC, useState, useContext, useEffect } from 'react'
import {
  Typography,
  TableCell,
  TableRow,
  ButtonGroup,
  Button,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { currencyFormatter } from '../../../../utils'

import { BillEntry, MerchantEnteredDays } from '../../../../services'
import { CreateBillManagerContext } from '../CreateBillManager'
export interface BillRowProps {
  children?: React.ReactNode
  billEntry: BillEntry
  billTitle: string
  rowHeight: number
}

const BillRow: FC<BillRowProps> = ({ billEntry, billTitle, rowHeight }) => {
  const [merchantData, setMerchantData] = useState<MerchantEnteredDays>({})
  const [billAmountCopy, setBillAmountCopy] = useState<number>(0)
  const {
    billId,
    setTotalRent,
    totalRentCopy,
    merchantEnteredDataArray,
    setMerchantEnteredDataArray,
  } = useContext(CreateBillManagerContext)

  const {
    itemName,
    rentPerDay,
    quantity,
    rate,
    billAmount,
    isDaysCountEditable,
    merchantEnteredDays,
    lostItemCost,
    days,
  } = billEntry

  useEffect(() => {
    setBillAmountCopy(billAmount)
  }, [billAmount])

  useEffect(() => {
    if (merchantEnteredDays && !merchantData) {
      setMerchantData({
        ...merchantEnteredDays,
      })
    } else {
      setMerchantData({
        ...merchantData,
        enteredDays: billEntry.days,
        inEntryId: billEntry.inEntryId,
        outEntryId: billEntry.outEntryId,
        billId: billId,
      })
    }
  }, [billEntry, merchantEnteredDays])

  const addDay = (e) => {
    setMerchantData({
      ...merchantData,
      enteredDays: merchantData?.enteredDays + 1,
    })
    setBillAmountCopy(billAmountCopy + rentPerDay)
    setTotalRent(totalRentCopy + rentPerDay)
  }

  const removeDay = (e) => {
    setMerchantData({
      ...merchantData,
      enteredDays: merchantData?.enteredDays - 1,
    })
    setBillAmountCopy(billAmountCopy - rentPerDay)
    setTotalRent(totalRentCopy - rentPerDay)
  }

  useEffect(() => {
    const merchantEnteredDataArrayCopy = [...merchantEnteredDataArray]

    if (merchantData?.enteredDays) {
      // Step 1: Check if merchantEnteredDays is NOT EMPTY
      if (merchantEnteredDays) {
        const indexIfElementAlredyAdded =
          merchantEnteredDataArrayCopy.findIndex(
            (data) =>
              data.billId === merchantData.billId &&
              data.inEntryId === merchantData.inEntryId &&
              data.outEntryId === merchantData.outEntryId
          )
        if (indexIfElementAlredyAdded >= 0) {
          merchantEnteredDataArrayCopy[indexIfElementAlredyAdded].enteredDays =
            merchantData?.enteredDays
        } else {
          merchantEnteredDataArrayCopy.push({
            ...merchantData,
            id: merchantEnteredDays.id,
          })
        }
        setMerchantEnteredDataArray(merchantEnteredDataArrayCopy)
      } else if (merchantData?.enteredDays !== days) {
        const indexIfElementAlredyAdded =
          merchantEnteredDataArrayCopy.findIndex(
            (data) =>
              data.billId === merchantData.billId &&
              data.inEntryId === merchantData.inEntryId &&
              data.outEntryId === merchantData.outEntryId
          )
        if (indexIfElementAlredyAdded >= 0) {
          merchantEnteredDataArrayCopy[indexIfElementAlredyAdded].enteredDays =
            merchantData?.enteredDays
        } else {
          merchantEnteredDataArrayCopy.push(merchantData)
        }
        setMerchantEnteredDataArray(merchantEnteredDataArrayCopy)
      } else if (merchantData?.enteredDays === days) {
        const filteredMerchantEnteredDataArray =
          merchantEnteredDataArrayCopy.filter(
            (data) =>
              data.billId !== merchantData.billId &&
              data.inEntryId !== merchantData.inEntryId &&
              data.outEntryId !== merchantData.outEntryId
          )
        setMerchantEnteredDataArray(filteredMerchantEnteredDataArray)
      } else {
      }
    }
  }, [merchantData.enteredDays])

  const rateQuantityRentPerDay =
    billTitle == 'Lost or damaged'
      ? `${quantity}`
      : `${rate} x ${quantity} = ${rentPerDay}`
  return (
    <TableRow sx={{ height: rowHeight }}>
      <TableCell>
        <Typography variant="body1" color="text.primary" noWrap>
          {itemName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {currencyFormatter.format(rate)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {quantity}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {currencyFormatter.format(rentPerDay)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        {isDaysCountEditable && (
          <ButtonGroup variant="contained" aria-label="outlined button group">
            <Button
              color="error"
              sx={{ height: 30, width: 30 }}
              onClick={removeDay}
              disabled={merchantData?.enteredDays == 1}
            >
              <RemoveIcon fontSize="small" />
            </Button>
            <Button variant="text" sx={{ height: 30, width: 30 }}>
              {merchantData?.enteredDays}
            </Button>
            <Button
              color="success"
              onClick={addDay}
              sx={{ height: 30, width: 30 }}
            >
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        )}
      </TableCell>
      <TableCell align="right">
        {billTitle == 'Lost or damaged'
          ? lostItemCost
          : currencyFormatter.format(billAmountCopy)}
      </TableCell>
    </TableRow>
  )
}

export default BillRow
