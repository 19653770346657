import { FC, Fragment, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Paper, Tooltip, IconButton } from '@mui/material';
import { tableStyles } from '../Reports';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { fetchLedgerAction, fetchLedgerResetAction } from '../../../store/ledger'; // API Actions
import { getShortFormattedDate } from '../../../utils'; // Utility function for date formatting
import LedgerSummaryHeader from '../LedgerSummary/PageHeader';

export interface LedgerProps {
  bill: any;
}

export const LedgerSummary: FC<LedgerProps> = ({ bill }) => {
  const classes = tableStyles();
  const dispatchAction = useAppDispatch();

  const { success, data: ledgerResponse, message } = useAppSelector(
    (state) => state.fetchLedger
  );

  useEffect(() => {
    dispatchAction(fetchLedgerResetAction()); // Reset the ledger state on component load
  }, []);

  useEffect(() => {
    if (bill) {
      dispatchAction(fetchLedgerAction({ accountId: bill.id })); // Fetch ledger data based on bill ID
    }
  }, [bill]);

  let count = 0;

  return (
    <>
        <Table aria-label="ledger report">
          <TableHead>
            <TableRow sx={{backgroundColor:'#cccccc', color:'black'}}>
              <TableCell className={classes.tableCell} width={20} sx={{color:'black'}}>S.N.</TableCell>
              <TableCell className={classes.tableCell}  sx={{color:'black'}}>Date</TableCell>
              <TableCell className={classes.tableCell}  sx={{color:'black'}}>Particular</TableCell>
              <TableCell className={classes.tableCell}  sx={{color:'black'}}>Entry Type</TableCell>
              <TableCell className={classes.tableCell}  sx={{color:'black'}}>Ref No</TableCell>
              <TableCell className={classes.tableCell}  sx={{color:'black'}}>Debit</TableCell>
              <TableCell className={classes.tableCell}  sx={{color:'black'}}>Credit</TableCell>
              <TableCell className={classes.tableCell}  sx={{color:'black'}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ledgerResponse?.entries.map((entry, index) => (
              <Fragment key={index}>
                <TableRow sx={{backgroundColor: entry.title === 'Sum' || entry.title === 'Closing Balance' ? '#f0f0f1' : 'white'}}>
                  <TableCell sx={{fontWeight: entry.title === 'Sum' || entry.title === 'Closing Balance' ? 'bold' : 400}} className={classes.tableCell}>{entry.title === 'Sum' || entry.title === 'Closing Balance' ? '' : ++count}</TableCell>
                  <TableCell sx={{fontWeight: entry.title === 'Sum' || entry.title === 'Closing Balance' ? 'bold' : 400}} className={classes.tableCell}>{entry.title === 'Sum' || entry.title === 'Closing Balance' ? '' : (entry.entryDate ? getShortFormattedDate(entry.entryDate) : '')}</TableCell>
                  <TableCell sx={{fontWeight: entry.title === 'Sum' || entry.title === 'Closing Balance' ? 'bold' : 400}} className={classes.tableCell}>{entry.title}</TableCell>
                  <TableCell sx={{fontWeight: entry.title === 'Sum' || entry.title === 'Closing Balance' ? 'bold' : 400}} className={classes.tableCell}>{entry.entryType}</TableCell>
                  <TableCell sx={{fontWeight: entry.title === 'Sum' || entry.title === 'Closing Balance' ? 'bold' : 400}} className={classes.tableCell}>{entry.invoiceNo || '-'}</TableCell>
                  <TableCell sx={{fontWeight: entry.title === 'Sum' || entry.title === 'Closing Balance' ? 'bold' : 400}} className={classes.tableCell}>{entry.debitAmount > 0 ? entry.debitAmount : '-'}</TableCell>
                  <TableCell sx={{fontWeight: entry.title === 'Sum' || entry.title === 'Closing Balance' ? 'bold' : 400}} className={classes.tableCell}>{entry.creditAmount > 0 ? entry.creditAmount : '-'}</TableCell>
                  <TableCell sx={{fontWeight: entry.title === 'Sum' || entry.title === 'Closing Balance' ? 'bold' : 400}} className={classes.tableCell}>
                    {entry.pdfLink && (
                      <Tooltip title="View Bill" arrow>
                        <IconButton sx={{p:0,m:0}} onClick={() => window.open(entry.pdfLink, '_blank')}>
                          <PictureAsPdfIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
    </>
  );
};

export default LedgerSummary;
