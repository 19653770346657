import { Button } from '@mui/material'
import { FC } from 'react'

export interface BarButtonProps {
  id: string
  startIcon: any
  onClick: () => void
  bgColor?: string
  title: string
  refr?: any
}

export const BarButton: FC<BarButtonProps> = ({
  id,
  startIcon,
  onClick,
  bgColor,
  title,
  refr,
}) => {
  return (
    <div
      style={{
        borderColor: bgColor,
        borderWidth: 2,
        borderStyle: 'solid',
        height: 30,
        borderRadius: 15,
        marginLeft: 15,
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Button
        id={id}
        sx={{ color: bgColor, height: 30 }}
        startIcon={startIcon}
        onClick={() => {
          onClick()
        }}
        ref={refr}
      >
        {title}
      </Button>
    </div>
  )
}
