import { FetchBillResponse, LeftItemsResponse } from '../../../services'
import { ActionTypes } from '../actions'
import { BaseReducerState, SelectOptions } from '../../../services/types'

export interface BillByIdDetail {
  data: LeftItemsResponse
  selectOptions: SelectOptions[] | null
}

export type ManageBillState = BaseReducerState & BillByIdDetail

const initialState: ManageBillState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  selectOptions: [],
}

export const manageBills = (
  state: ManageBillState = initialState,
  action: { type: string; res: FetchBillResponse | SelectOptions[] }
): ManageBillState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_BILL_BY_ID}_REQUEST`: {
      return {
        ...initialState,
        loading: true,
      }
    }

    case `${ActionTypes.FETCH_BILL_BY_ID}_SUCCESS`: {
      const { success, data, message } = <FetchBillResponse>action.res

      const selectOptions = data.enteries.map((item): SelectOptions => {
        return {
          label: item.itemName,
          id: item.id,
          rate: item.rate,
          availableQuantity: item.availableQuantity,
          category: item.category.name,
          isArea: item.isArea,
          height: item.height,
          width: item.width,
          length: item.length,
          outAreaEntryId: item.outAreaEntryId,
        }
      })

      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
        selectOptions,
      }
    }

    case `${ActionTypes.FETCH_BILL_BY_ID}_FAILURE`: {
      const { message } = <FetchBillResponse>action.res
      return {
        ...state,
        loading: false,
        success: false,
        message: message,
      }
    }

    case `${ActionTypes.UPDATE_ITEM_SUBMIT_SELECT_OPTIONS}`: {
      const selectOptions = <SelectOptions[]>action.res
      return {
        ...state,
        selectOptions,
      }
    }

    case `${ActionTypes.FETCH_BILL_BY_ID}_RESET`: {
      return {
        ...initialState,
      }
    }
  }
  return state
}
