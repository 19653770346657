import { GSTINDetails, GSTINDetailsAPIResponse } from '../../../services/gstr'
import { BaseReducerState } from '../../../services'
import { ActionTypes } from '../actions'

export type FetchGSTINDetailsReportState = BaseReducerState & {
  data: GSTINDetails
}

export const initialState: FetchGSTINDetailsReportState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const fetchGSTINDetails = (
  state: FetchGSTINDetailsReportState = initialState,
  action: { type: string; res: any }
): FetchGSTINDetailsReportState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_GSTIN_DETAILS}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.FETCH_GSTIN_DETAILS}_SUCCESS`: {
      const { success, data, message } = <GSTINDetailsAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.FETCH_GSTIN_DETAILS}_FAILURE`:
      const res = <GSTINDetailsAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.FETCH_GSTIN_DETAILS}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
