import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response } from '../types'
import { AccountRequest, Bill } from './items'

/**
 * Add Other Charges
 * ======================================
 */

export type Charges = {
  amount: number
  chargeTitle: string
  itemType?: string
}

export type AddOtherChargesRequest = {
  billId: number
  date: string
  customerId: number
  charges: Charges[]
  challanNo?: string
  isQuotation: boolean
  challanId?: number
}

export type BillPDFRequest = {
  billId: number
  from_date?: string
  to_date?: string
  exclude_payment?: boolean
  sqft_start_date?: string
  sqft_end_date?: string
}

export interface AddOtherChargesAPIResponse extends Response {
  data: boolean
}

export interface EditBillAPIResponse extends Response {
  data: Bill
}
// Issue Item Request
export function addOtherCharges(request: AddOtherChargesRequest) {
  return http
    .put('/entry/pvt/add_other_charges', request)
    .then((response: AxiosResponse) => response)
}

export function updateBill(request: AccountRequest) {
  return http
    .post('/bill/pvt/v2', request)
    .then((response: AxiosResponse) => response)
}

export function getTodayBill(request: BillPDFRequest) {
  let url = `/bill/pvt/bill_pdf?id=${request.billId}`
  if (request.from_date != null) {
    url = `/bill/pvt/bill_pdf?id=${request.billId}&from_date=${request.from_date}`
  }
  if (request.to_date != null) {
    url = `${url}&to_date=${request.to_date}`
  }
  if (request.exclude_payment != null) {
    url = `${url}&exclude_payment=${request.exclude_payment}`
  }
  if (request.sqft_start_date != null) {
    url = `${url}&sqft_start_date=${request.sqft_start_date}`
  }
  if (request.sqft_end_date != null) {
    url = `${url}&sqft_end_date=${request.sqft_end_date}`
  }
  return http.get(url).then((response: AxiosResponse) => response)
}
