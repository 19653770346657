import { AxiosResponse } from "axios";
import { http } from "../../../../src/utils";
export interface StoreTncs {
  id?: number
  quotationTnc?: string
  bankAccountDetails?: string
  agreementTnc?: string
  challanTnc?: string
  invoiceTnc?: string
  invoiceTitle?: string
  nonGstBankAccount?: string
}

export function saveStoreTnc(
  storeTncs: StoreTncs
) {
  let url = `/store_tnc/pvt`
  return http
    .post(
      url,
      storeTncs
    )
    .then((response: AxiosResponse) => response.data)
}

export function getStoreTnc() {
  let url = `/store_tnc/pvt`
  return http
    .get(
      url    
    )
    .then((response: AxiosResponse) => response.data)
}