import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { useAppDispatch, useAppSelector, usePayRazorpay } from '../../../hooks'
import { createPaynow, createVerifySubscribedPlan } from '../../../services'
import { currencyFormatter, loadScript } from '../../../utils'
import { showAlertAction } from '../../../store/alerts'
import {
  fetchPendingBillsAction,
  hidePaymentDuePopupAction,
} from '../../../store/pendingBills'

const TypographyDesc = styled(Typography)(
  () => `
        padding-bottom: 15px ; 
   `
)

const PaymentDuePopupContent = () => {
  const dispatchAction = useAppDispatch()
  const [makeRazorPayment] = usePayRazorpay()
  const [jsLoadStatus, setJsLoadStatus] = useState(false)
  const { data } = useAppSelector((state) => state.pendingBills)
  // Load the javascript
  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
      .then(() => {
        setJsLoadStatus(true)
      })
      .catch((errr) => {
        console.error('Razorpay SDK failed to load. Are you online?', errr)
        setJsLoadStatus(false)
      })
  }, [])
  useEffect(() => {
    dispatchAction(fetchPendingBillsAction(true))
  }, [])
  const paymentFailed = function () {
    dispatchAction(
      showAlertAction({ message: 'Payment failed', severity: 'error' })
    )
  }
  const paymentSuccess = async (response) => {
    const verifyRequest = {
      signature: response.razorpay_signature,
      payment_id: response.razorpay_payment_id,
    }
    const verifyResponse = await createVerifySubscribedPlan(verifyRequest)
    if (verifyResponse?.data || verifyResponse?.success) {
      dispatchAction(hidePaymentDuePopupAction())
      dispatchAction(fetchPendingBillsAction(false))
    } else {
      dispatchAction(
        showAlertAction({
          message: verifyResponse?.message || 'Opps something went wrong',
          severity: 'error',
        })
      )
    }
  }
  const displayRazorpay = async (id: number, month: string) => {
    const payNowResponse = await createPaynow(id)
    if (!payNowResponse?.success || !payNowResponse?.data) {
      dispatchAction(
        showAlertAction({
          message: payNowResponse?.message || 'Opps something went wrong',
          severity: 'error',
        })
      )
      return false
    }
    if (!jsLoadStatus) {
      return false
    }
    const subscribePlanData = { ...payNowResponse?.data, description: month }
    makeRazorPayment(subscribePlanData, paymentSuccess, paymentFailed)
  }
  const [pendingBill] = data
  return (
    <Card variant="outlined">
      <CardContent sx={{m:3, textAlign:'center'}} >
        {/* <Typography color="red" gutterBottom>
        Your outstanding charges from the previous month are now due. Please proceed with payment to continue.
        </Typography> */}
        <Typography fontSize={16} component="div" align={'center'} mt={2}>
          Billing Period
        </Typography>
        <Typography fontSize={16} mt={1} fontWeight={'bold'} color="text.primary" >
          {pendingBill?.month}
        </Typography>
        <Typography fontSize={16} component="div" mt={3}>
        Your total billing for this period
        </Typography>
        <Typography fontSize={16} fontWeight={'bold'}  mt={1}>
          {currencyFormatter.format(pendingBill?.consumption)}
        </Typography>
        <Typography fontSize={16} component="div" mt={2}>
          App charges for this month
        </Typography>
        <span style={{textDecoration:'line-through', color:'red', fontSize:12, marginRight:5, marginTop:10}}>{currencyFormatter.format(pendingBill?.amount)}</span>
        <span style={{color:'green', fontWeight:'bold', fontSize:16 }}>{currencyFormatter.format(pendingBill?.amountAfterDiscount)}</span>
<br/><br/><br/>
        <Button
          id="buy_plan_button"
          variant="contained"
          sx={{ backgroundColor: 'green' }}
          onClick={() => displayRazorpay(pendingBill?.id, pendingBill?.month)}
        >
          Pay {currencyFormatter.format(pendingBill?.amountAfterDiscount)} now
        </Button>
      </CardContent>
    </Card>
  )
}
export default PaymentDuePopupContent
