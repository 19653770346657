import { AxiosResponse } from "axios";
import { http } from "../../../../src/utils";

export function getNextInvoiceNo(
    accountId: number,
    invoiceDate: string
  ) {
    return http
      .get(
        `/interim_bill/pvt/next_inv_no?account_id=${accountId}&invoice_date=${invoiceDate}`
      )
      .then((response: AxiosResponse) => response.data)
  }