import { FC, ChangeEvent, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Divider,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
} from '@mui/material'

import { Staff } from 'src/services'
import DeleteIcon from '@mui/icons-material/Delete'
import AddStaff from './AddStaff'
import { DeleteStaff } from './Delete'
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
interface StaffListTableProps {
  className?: string
  staffs: Staff[]
}

const applyPagination = (
  staffs: Staff[],
  page: number,
  limit: number
): Staff[] => {
  if (!staffs) {
    return []
  }
  return staffs.slice(page * limit, page * limit + limit)
}

const StaffListTable: FC<StaffListTableProps> = ({ staffs }) => {
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null)
  const [selectedStaffToDelete, setSelectedStaffToDelete] =
    useState<Staff | null>(null)

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  const paginatedStaffs = applyPagination(staffs, page, limit)
  const theme = useTheme()
  const boldFontWeight = 600

  return (
    <>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f9f9f9', height: 52 }}>
              <TableCell sx={{ color: 'black', fontWeight: boldFontWeight }}>
                Name
              </TableCell>
              <TableCell sx={{ color: 'black', fontWeight: boldFontWeight }}>
                Mobile Number
              </TableCell>
              <TableCell
                align="right"
                sx={{ color: 'black', fontWeight: boldFontWeight, pr: 2 }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedStaffs.map((staff) => {
              return (
                <TableRow hover key={staff.mobile} sx={{ height: 52 }}>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {staff.userName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {staff.mobile}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                  <Tooltip title="Edit User" arrow>
                      <IconButton
                       
                        onClick={() => {
                          setSelectedStaff(staff)
                        }}
                      >
                        <EditCalendarIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete User" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: 'crimson',
                        }}
                        color="inherit"
                        onClick={() => {
                          setSelectedStaffToDelete(staff)
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={staffs ? staffs.length : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[50, 100, 500]}
        />
      </Box>
      {selectedStaff && (
        <AddStaff
          onClose={() => {
            setSelectedStaff(null)
          }}
          staff={selectedStaff}
        />
      )}
      
      {selectedStaffToDelete && (
        <DeleteStaff
          onClose={() => {
            setSelectedStaffToDelete(null)
          }}
          staff={selectedStaffToDelete}
        />
      )}
      
    </>
  )
}

StaffListTable.propTypes = {
  staffs: PropTypes.array.isRequired,
}

StaffListTable.defaultProps = {
  staffs: [],
}

export default StaffListTable
