/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react'
import { AccountTabsValueEnums } from '../../../components'
import './currentaccounts.css'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'

import {
  getBillTillTodayPDFAction,
  getBillTillTodayPDFResetAction,
} from '../../../store/items'

// Components
import SubmitItem from './SubmitItem'

import { CurrentAccountMoreActionsEnum } from '../../../services/types'
import { BillPDFRequest } from '../../../services'
import OtherCharges from './OtherCharges'
import { RenewAccount } from './RenewAccount'
import { CloseAccount } from './CloseAccount'
import { DeleteAccount } from './DeleteAccount'
import { AddAccountItem } from './AddNewAccountItem'
import CreateNewTask from '../Task/CreateNewTask'
import { BillSettings } from '../slips/BillSettings'
import { ReopenAccount } from './ReopenAccount'
import { BillBetweenDatesItem } from './BillBetweenDatesItem'
import { PlotAreaDatesItem } from './PlotAreaDatesPicker'

function ShowMoreActionsWrapper({
  billInfo,
  showMoreAction,
  setShowMoreAction,
}) {
  const dispatchAction = useAppDispatch()

  const handleViewPDF = (request: BillPDFRequest) => {
    dispatchAction(getBillTillTodayPDFAction(request))
  }

  return (
    <>
      {showMoreAction ==
        CurrentAccountMoreActionsEnum.ONE_DAY_DISCOUNT_SETTING && (
        <BillSettings
          onClose={() => setShowMoreAction(null)}
          account={billInfo}
        />
      )}
      {showMoreAction ==
        CurrentAccountMoreActionsEnum.LOST_OR_DAMAGE_CHARGES && (
        <SubmitItem
          bill_id={billInfo?.id}
          customer_id={billInfo?.customer.id}
          onClose={() => setShowMoreAction(null)}
          submission_type={'LOST'}
          billType={billInfo?.billType}
        />
      )}

      {showMoreAction == CurrentAccountMoreActionsEnum.ADD_OTHER_CHARGES && (
        <OtherCharges
          bill_id={billInfo?.id}
          customer_id={billInfo?.customer.id}
          onClose={() => setShowMoreAction(null)}
          title={`${billInfo?.customer.name}, ${billInfo?.siteAddress}`}
          isQuotation={false}
        />
      )}

      {showMoreAction == CurrentAccountMoreActionsEnum.EDIT_ACCOUNT && (
        <AddAccountItem
          onClose={() => setShowMoreAction(null)}
          partialBill={billInfo}
        />
      )}

      {showMoreAction == CurrentAccountMoreActionsEnum.REOPEN_ACCOUNT && (
        <ReopenAccount
          accountId={billInfo.id}
          onClose={() => setShowMoreAction(null)}
          onSuccess={() => {
            setShowMoreAction(null)
          }}
        />
      )}

      {showMoreAction ==
        CurrentAccountMoreActionsEnum.MOVE_BALANCE_TO_NEW_ACCOUNT && (
        <RenewAccount bill={billInfo} onClose={() => setShowMoreAction(null)} />
      )}

      {showMoreAction == CurrentAccountMoreActionsEnum.CLOSE_ACCOUNT && (
        <CloseAccount
          account={billInfo}
          onClose={() => setShowMoreAction(null)}
        />
      )}

      {showMoreAction == CurrentAccountMoreActionsEnum.CREATE_NEW_TASK && (
        <CreateNewTask
          account={billInfo}
          onClose={() => setShowMoreAction(null)}
        />
      )}

      {showMoreAction == CurrentAccountMoreActionsEnum.DELETE && (
        <DeleteAccount
          account={billInfo}
          onClose={() => setShowMoreAction(null)}
        />
      )}

      {showMoreAction ==
        CurrentAccountMoreActionsEnum.BILL_BETWEEN_TWO_DATES && (
        <BillBetweenDatesItem
          onClose={(request?: BillPDFRequest) => {
            setShowMoreAction(null)
            if (request) {
              handleViewPDF(request)
            }
          }}
          bill={billInfo}
        />
      )}

      {showMoreAction ==
        CurrentAccountMoreActionsEnum.SHOW_PLOT_AREA_DATE_PICKER && (
        <PlotAreaDatesItem
          bill={billInfo}
          onClose={(
            startDate?: string,
            endDate?: string,
            fetchBill?: boolean
          ) => {
            if (fetchBill) {
              handleViewPDF({
                billId: billInfo?.id,
                sqft_start_date: startDate,
                sqft_end_date: endDate,
              })
            }
            setShowMoreAction(null)
          }}
        />
      )}
    </>
  )
}

export default ShowMoreActionsWrapper
