import { FC, useState, useReducer, useEffect } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import { Bill, renewAccount } from '../../../services'

// Components
import { AppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'
import { getFormattedDate } from '../../../utils'
import {
  fetchCustomerAccountAction,
  renewAccountAction,
  renewAccountResetAction,
} from '../../../store/customerAccounts'

export interface RenewAccountProps {
  onClose?: () => void
  bill: Bill
}

export const RenewAccount: FC<RenewAccountProps> = ({ onClose, bill }) => {
  const dispatchAction = useAppDispatch()
  const {
    success: renewAccountSuccess,
    error: renewAccountError,
    message,
  } = useAppSelector((state) => state.renewAccount)
  const [isClose, setClosePopup] = useState<boolean>(false)
  const [shouldShiftDueAmount, setShouldShiftDueAmount] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())

  useEffect(() => {
    if (renewAccountSuccess) {
      dispatchAction(fetchCustomerAccountAction(bill.status))
      dispatchAction(
        showAlertAction({
          message:
            'New account created and items moved to new account Successfully',
          severity: 'success',
        })
      )
      onClose()
    } else if (renewAccountError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [renewAccountSuccess, renewAccountError, message])

  const handleCheckboxChange = (event) => {
    setShouldShiftDueAmount(event.target.checked)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const submitHandle = () => {
    const apiRequest = {
      billId: bill.id,
      fromDate: getFormattedDate(selectedDate),
      customerId: bill.customer.id,
      shouldShiftDueAmount: shouldShiftDueAmount,
    }
    dispatchAction(renewAccountAction(apiRequest))
  }
  useEffect(() => {
    return () => {
      dispatchAction(renewAccountResetAction()) // To manage the Alert
    }
  }, [])
  return (
    <AppDialog
      id="renew-account"
      title={`Move balance items to a new account (Party Name: ${bill?.customer.name}, Site Address: ${bill?.siteAddress})`}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} mr={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2} mt={0.5}>
              <Grid item={true} xs={12}>
                <DatePicker
                  label="New account start date"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1.2}>
              <Grid item={true} xs={12} sx={{ ml: 1.5 }}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={shouldShiftDueAmount}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Move due amount to new account?"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>{' '}
      </Grid>
    </AppDialog>
  )
}
