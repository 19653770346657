import {
  addStaff,
  fetchStaff,
  AddStaffRequest,
  deleteStaff,
} from '../../../services/staff'

export const ActionTypes = {
  ADD_STAFF: 'ADD_STAFF',
  FETCH_STAFF: 'FETCH_STAFF',
  DELETE_STAFF: 'DELETE_STAFF',
}

export const addStaffAction = (request: AddStaffRequest) => ({
  type: ActionTypes.ADD_STAFF,
  promise: addStaff(request),
})

export const fetchStaffsAction = () => ({
  type: ActionTypes.FETCH_STAFF,
  promise: fetchStaff(),
})

export const addStaffResetAction = () => ({
  type: `${ActionTypes.ADD_STAFF}_RESET`,
})

export const fetchStaffResetAction = () => ({
  type: `${ActionTypes.FETCH_STAFF}_RESET`,
})

export const deleteStaffsAction = (mobile: string) => ({
  type: ActionTypes.DELETE_STAFF,
  promise: deleteStaff(mobile),
})

export const deleteStaffResetAction = () => ({
  type: `${ActionTypes.DELETE_STAFF}_RESET`,
})
