import { FC, useState, useEffect, ReactNode, useContext } from 'react'
import { Stepper, Step, StepButton, createMuiTheme } from '@mui/material'
import { CreateBillManagerContext } from './CreateBillManager'

const steps = ['One Day Discount', 'Days Counting', 'Generate Bill']

export interface CustomStepperPros {
  children?: ReactNode
}

const CustomStepper: FC<CustomStepperPros> = () => {
  
  const { activeStep, setActiveStep } = useContext(CreateBillManagerContext)

  return (
    <Stepper activeStep={activeStep} sx={{
      '& .MuiStepLabel-root .Mui-completed': {
        color: 'green', // circle color (COMPLETED)
      },
      '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
        {
          color: 'green', // Just text label (COMPLETED)
        },
      '& .MuiStepLabel-root .Mui-active': {
        color: 'green', // circle color (ACTIVE)
      },
      '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
        {
          color: 'green', // Just text label (ACTIVE)
        },
      '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
        fill: 'white', // circle's number (ACTIVE)
      },
    }}>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton
            color="inherit"
            onClick={() => {
              setActiveStep(index)
            }}
          >
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}

export default CustomStepper
