import { http } from '../../../../src/utils'

import { AxiosResponse } from 'axios'

export const ActionTypes = {
  GET_INVOICES_CHART_DATA: 'GET_INVOICES_CHART_DATA'
}

export const getInvoicesChartData = (startDate?: string, endDate?: string) => {
  let url = '/charts/pvt/rent'
  if(startDate && endDate) {
    url = `/charts/pvt/rent?start_date=${startDate}&end_date=${endDate}`
  }
  return http
    .get(url)
    .then((response: AxiosResponse) => response)
}

export const getInvoicesChartDataAction = (startDate?: string, endDate?: string) => ({
  type: ActionTypes.GET_INVOICES_CHART_DATA,
  promise: getInvoicesChartData(startDate, endDate),
})
