import { AxiosResponse } from 'axios'
import { http } from '../../utils'
import { Response, Customer } from '../types'

export interface FetchCustomersResponse extends Response {
  data: Customer[]
}

export interface DeleteCustomerAPIResponse extends Response {
  data: null
}

export type AddCustomerRequest = {
  id?: number
  name: string
  mobile: string
  address: string
  gstin?: string
  stateCode?: string
  state?: string
  pan?: string
  registrationType?: string
}

export interface AddCustomersResponse extends Response {
  data: Customer
}

export const addCustomer = (request: AddCustomerRequest) => {
  return http
    .post<AddCustomerRequest>('/customer/pvt', request)
    .then((response: AxiosResponse) => response)
}
export function fetchCustomers() {
  return http.get('/customer/pvt').then((response: AxiosResponse) => response)
}

export function fetchCustomersV2() {
  return http.get('/customer/pvt/v2').then((response: AxiosResponse) => response)
}

export const deleteCustomer = (id: number) => {
  return http
    .delete(`/customer/pvt/${id}`)
    .then((response: AxiosResponse) => response)
}
