import { useRoutes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import enGB from 'date-fns/locale/en-GB'
import { CssBaseline } from '@mui/material'
import ThemeProvider from './theme/ThemeProvider'
import router from './router'
import store from './store'
import { isLoggedIn, interceptor } from './utils'
interceptor(store)
function App() {
  const content = useRoutes(router(isLoggedIn()))
  return (
    <Provider store={store}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <CssBaseline />
          {content}
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  )
}
export default App
