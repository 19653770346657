import { AxiosResponse } from "axios";
import { http } from "../../../utils";

export function fetchSitesPdf(
    startDate: string,
    endDate: string
) {

    let url = `/charts/pvt/site_wise_monthly_rent_pdf?start_date=${startDate}&end_date=${endDate}`
    return http
        .get(
            url
        )
        .then((response: AxiosResponse) => response.data)
}