import { FC, useRef, useState, Fragment } from 'react'
import {
  Typography,
  List,
  Grid,
  Card,
  CardContent,
  Divider,
  IconButton,
  Button,
  Popover,
  ListItem,
  useTheme,
} from '@mui/material'
import { SlipsScreenType, TabsEnums } from '../../../components'
import { ListItemComponent } from './ListItemComponent'
import { ItemEntryDetail } from '../../../services/slips'
import PrintIcon from '@mui/icons-material/Print'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { currencyFormatter } from '../../../utils'

import './slips_style.css'
import { TitleActionBarComponent } from './TitleActionBarComponent'
import { Bill } from 'src/services'
interface AllItemComponentProps {
  data: ItemEntryDetail[]
  separatorHeight: number
  billId: number
  screenType: SlipsScreenType
  billType: string
  onClickViewPDF: (showRate: boolean, title?: string, challanId?: number) => void
  checked: boolean
  selectTab?: (index: number) => void
  account?: Bill
}

export const AllItemComponent: FC<AllItemComponentProps> = ({
  data,
  separatorHeight,
  billId,
  screenType,
  billType,
  onClickViewPDF,
  checked,
  selectTab,
  account,
}) => {
  const ref = useRef<unknown>(null)
  const [showMore, setShowMore] = useState<boolean>(false)
  const theme = useTheme()

  return (
    <Fragment>
      {data && data.length > 0 && (
        <Fragment>
          <Divider />
          <Grid
            id="properties_name"
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              minHeight: '4vh',
              pl: 2,
              pr: 2,
              backgroundColor: '#f9f9f9',
              fontWeight: 'bold',
            }}
          >
            <Grid xs={3} sx={{ pl: 4 }}>
              Item Name
            </Grid>
            <Grid xs={2}>Entry Time</Grid>
            <Grid xs={2}>Rate/Amount</Grid>
            <Grid xs={2}>
              <Typography fontWeight={'bold'} pl={4}>
                Quantity
              </Typography>
            </Grid>
            <Grid xs={2}>Entry Type</Grid>
            <Grid xs={1} sx={{ paddingLeft: 4 }}>
              Actions
            </Grid>
          </Grid>

          {data &&
            data.map((items, index) => {
              return (
                <Fragment key={index}>
                  <Divider />
                  <TitleActionBarComponent
                    data={items}
                    onClickViewPDF={onClickViewPDF}
                    screenType={screenType}
                    billType={billType}
                    selectedTab="ALL"
                    accountId={billId}
                    account={account}
                  />
                  <List
                    id="entries_list_items_per_date"
                    sx={{
                      width: '100%',
                      p: 0,
                      m: 0,
                    }}
                    component="div"
                    aria-labelledby="subheader"
                    key={index}
                  >
                    {items.itemEntries &&
                      items.itemEntries.map((item, i) => {
                        return (
                          <>
                            {(!checked || item.id >= 0) && (
                              <Fragment key={i}>
                                <Divider />
                                <ListItemComponent
                                  item={item}
                                  screenType={screenType}
                                  key={i}
                                  componentName={TabsEnums.All}
                                  billId={billId}
                                />
                              </Fragment>
                            )}
                          </>
                        )
                      })}
                  </List>
                </Fragment>
              )
            })}
        </Fragment>
      )}
    </Fragment>
  )
}
