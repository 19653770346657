import {
  FetchCustomerAccountsResponse,
  CustomerAccount,
} from '../../../services'
import { ActionTypes, searchCustomerAccounts } from '../actions'
import { BaseReducerState } from '../../../services/types'

export interface CustomerAccountList {
  data: CustomerAccount[]
  searchResults: CustomerAccount[]
  searchTerm: string
  selectedTab: string
}

export type CustomerListState = BaseReducerState & CustomerAccountList
export type CustomerAccountsManageState = {
  list: CustomerListState
}

type SearchUpdate = {
  data: CustomerAccount[]
  searchTerm: string
}

const initialState: CustomerAccountsManageState = {
  list: {
    loading: false,
    success: false,
    message: '',
    data: [],
    searchResults: [],
    searchTerm: '',
    selectedTab: 'OPEN',
  },
}

export const manageCustomerAccounts = (
  state: CustomerAccountsManageState = initialState,
  action: {
    type: string
    res: FetchCustomerAccountsResponse | SearchUpdate
    data: { selectedTab: string }
  }
): CustomerAccountsManageState => {
  switch (action.type) {
    case `${ActionTypes.FETCH_CUSTOMER_ACCOUNTS}_REQUEST`:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          success: false,
          message: null,
          // data: [],
        },
      }

    case `${ActionTypes.FETCH_CUSTOMER_ACCOUNTS}_SUCCESS`: {
      const {
        data: { selectedTab },
        res,
      } = action
      const { success, data, message } = <FetchCustomerAccountsResponse>res
      const searchResults = state?.list?.searchTerm
        ? searchCustomerAccounts(state?.list?.searchTerm, data)
        : []
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success,
          message,
          selectedTab,
          ...(success && data && { data }),
          ...(state?.list?.searchTerm && { searchResults }),
        },
      }
    }

    case `${ActionTypes.UPDATE_SEARCH_RESULTS}`: {
      const { data, searchTerm } = <SearchUpdate>action.res
      return {
        ...state,
        list: {
          ...state.list,
          searchResults: data,
          searchTerm,
        },
      }
    }

    case `${ActionTypes.FETCH_CUSTOMER_ACCOUNTS}_FAILURE`: {
      const { message } = <FetchCustomerAccountsResponse>action.res
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success: false,
          message: message,
        },
      }
    }

    default:
      return state
  }
}
