import {
  RenewAccountRequest,
  closeAccount,
  deleteAccount,
  fetchCustomerAccounts,
  renewAccount,
  reopenAccount,
  getAgreements,
} from '../../../services'

export const ActionTypes = {
  FETCH_CUSTOMER_ACCOUNTS: 'FETCH_CUSTOMER_ACCOUNTS',
  UPDATE_SEARCH_RESULTS: 'UPDATE_SEARCH_RESULTS',
  CLOSE_ACCOUNT: 'CLOSE_ACCOUNT',
  REOPEN_ACCOUNT: 'REOPEN_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  RENEW_ACCOUNT: 'RENEW_ACCOUNT',
  GET_AGREEMENTS: 'GET_AGREEMENTS',
}

export const fetchCustomerAccountAction = (selectedTab: string) => ({
  type: ActionTypes.FETCH_CUSTOMER_ACCOUNTS,
  promise: fetchCustomerAccounts(selectedTab),
  data: { selectedTab },
})

export const closeAccountAction = (status: number) => ({
  type: ActionTypes.CLOSE_ACCOUNT,
  promise: closeAccount(status),
})

export const reopenAccountAction = (status: number) => ({
  type: ActionTypes.REOPEN_ACCOUNT,
  promise: reopenAccount(status),
})

export const closeAccountResetAction = () => ({
  type: `${ActionTypes.CLOSE_ACCOUNT}_RESET`,
})

export const reopenAccountResetAction = () => ({
  type: `${ActionTypes.REOPEN_ACCOUNT}_RESET`,
})

export const deleteAccountAction = (accountId: number) => ({
  type: ActionTypes.DELETE_ACCOUNT,
  promise: deleteAccount(accountId),
})

export const deleteAccountResetAction = () => ({
  type: `${ActionTypes.DELETE_ACCOUNT}_RESET`,
})

export const renewAccountAction = (request: RenewAccountRequest) => ({
  type: ActionTypes.RENEW_ACCOUNT,
  promise: renewAccount(request),
})

export const renewAccountResetAction = () => ({
  type: `${ActionTypes.RENEW_ACCOUNT}_RESET`,
})

export const getAgreementsAction = (customerId: number) => ({
  type: ActionTypes.GET_AGREEMENTS,
  promise: getAgreements(customerId),
})

export const getAgreementsResetAction = () => ({
  type: `${ActionTypes.GET_AGREEMENTS}_RESET`,
})

// Search fucntion
export const searchCustomerAccounts = (searchTerm, data) => {
  let searchResults = []
  if (data?.length) {
    searchResults = data.filter((account) => {
      const {
        id,
        siteAddress,
        displayAcNo,
        customer: { address, mobile, name },
      } = account
      if (
        `${id}`.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        `${displayAcNo}`.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        address?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        mobile?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        siteAddress?.toLowerCase().includes(searchTerm?.toLowerCase())
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return searchResults
}

export const searchCustomerAccountsAction = (searchTerm) => {
  return (dispatch, getState) => {
    const { list } = getState().manageCustomerAccounts
    const { data } = list
    const searchResults = searchCustomerAccounts(searchTerm, data)
    dispatch({
      type: ActionTypes.UPDATE_SEARCH_RESULTS,
      res: { data: searchResults, searchTerm },
    })
  }
}
