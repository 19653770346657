import { FC, useRef, useState, Fragment, useEffect } from 'react'
import { List, Grid, Divider, useTheme } from '@mui/material'
import { SlipsScreenType, TabsEnums } from '../../../../components'
import { ListItemComponent } from './ListItemComponent'
import { ItemEntryDetail } from '../../../../services/slips'

import './quotation.css'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  getSlipPDFAction,
  getSlipPDFResetAction,
} from '../../../../store/items'
import { showAlertAction } from '../../../../store/alerts'
import { Bill } from '../../../../services'
interface QuotationItemComponentProps {
  account: Bill
  data: ItemEntryDetail[]
  separatorHeight: number
  billId: number
  screenType: SlipsScreenType
  onClickViewPDF: (showRate: boolean, title: string) => void
}

export const QuotationItemComponent: FC<QuotationItemComponentProps> = ({
  account,
  data,
  billId,
  onClickViewPDF,
}) => {
  const ref = useRef(null)
  const [showMore, setShowMore] = useState<boolean>(false)
  const theme = useTheme()
  const {
    data: slipPdfLink,
    success: getPDFSuccess,
    error: getPDFError,
    message,
  } = useAppSelector((state) => state.slipPDF)

  const dispatchAction = useAppDispatch()

  const handleViewPDF = (showRate: boolean, title: string) => {
    const slipType = TabsEnums.All

    const getPDFRequest = {
      billId: billId,
      showRate: showRate,
      slipType: `${slipType}`,
      title: title,
      isQuotation: true
    }
    dispatchAction(getSlipPDFAction(getPDFRequest))
  }

  useEffect(() => {
    return () => {
      dispatchAction(getSlipPDFResetAction()) // To manage the Alert
    }
  }, [])

  useEffect(() => {
    if (getPDFSuccess) {
      window.open(slipPdfLink, '_blank')
    } else if (getPDFError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [getPDFSuccess, getPDFError, message])
  const xsValue = account?.status === 'QUOTATION' ? 2.75 : 3.666
  return (
    <>
      <Grid
        container
        sx={{ p: 0, height: 50, backgroundColor: '#f9f9f9' }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={xsValue}
          sx={{ alignItems: 'center', fontWeight: 'bold', pl: 3.1 }}
        >
          Item
        </Grid>
        <Grid item xs={2.75} sx={{ alignItems: 'center', fontWeight: 'bold' }}>
          {' '}
          Rate{' '}
        </Grid>
        {account?.status === 'QUOTATION' && (<Grid
          item
          xs={xsValue}
          sx={{
            alignItems: 'center',
            alignContent: 'center',
            fontWeight: 'bold',
          }}
        >
          Quantity
        </Grid>)}
        <Grid item xs={xsValue} sx={{ alignItems: 'center', fontWeight: 'bold' }}>
          Rent/Day
        </Grid>
        <Grid item xs={1} fontWeight="bold">
          {' '}
          Actions
        </Grid>
      </Grid>
      {data &&
        data.map((items, index) => {
          return (
            <Grid key={index} sx={{ mb: 0, overflow: 'scroll' }}>
              <List
                sx={{
                  width: '100%',
                  p: 0,
                  m: 0,
                }}
                key={index}
              >
                {items.itemEntries &&
                  items.itemEntries.map((item, i) => {
                    return (
                      <>
                        <Divider />
                        <ListItemComponent
                          account={account}
                          item={item}
                          key={i}
                          componentName={TabsEnums.All}
                          billId={billId}
                        />
                      </>
                    )
                  })}
              </List>
            </Grid>
          )
        })}
    </>
  )
}
