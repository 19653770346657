import { FC, useEffect } from 'react'

// Components
import { ConfirmationAppDialog } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'
import {
  reopenAccountAction,
  reopenAccountResetAction,
  fetchCustomerAccountAction,
} from '../../../store/customerAccounts'

export interface ReopenAccountProps {
  onClose: () => void
  onSuccess: () => void
  accountId: number
}

export const ReopenAccount: FC<ReopenAccountProps> = ({
  onClose,
  onSuccess,
  accountId,
}) => {
  const dispatchAction = useAppDispatch()
  const { success: reopenAccountSuccess, message } = useAppSelector(
    (state) => state.reopenAccount
  )

  useEffect(() => {
    if (reopenAccountSuccess) {
      dispatchAction(
        showAlertAction({
          message: 'Account reopened successfully',
          severity: 'success',
        })
      )
      onSuccess() // To close the popup
    } else if (message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onClose()
    }
  }, [reopenAccountSuccess, message])

  const reopenHandler = () => {
    dispatchAction(reopenAccountAction(accountId))
  }

  useEffect(() => {
    return () => {
      dispatchAction(reopenAccountResetAction()) // To manage the Alert
    }
  }, [])

  return (
    <ConfirmationAppDialog
      id="reopen-item"
      title={`Reopen Account: ${accountId}`}
      onClose={onClose}
      close={false}
      dialogActionsButtons={[
        {
          text: 'Cancel',
          color: 'success',
          variant: 'outlined',
          onClickHandler: onClose,
        },
        {
          text: 'Reopen',
          color: 'error',
          variant: 'contained',
          onClickHandler: reopenHandler,
        },
      ]}
    >
      Do you really want to reopen this account?
    </ConfirmationAppDialog>
  )
}
