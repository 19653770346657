import { BaseReducerState} from '../../../services'
import { ActionTypes } from './actions'
import { MonthOverview, LedgerSummaryAPIResponse } from './types'

export type LedgerSummaryState = BaseReducerState & {
  data: [MonthOverview]
}

export const initialState: LedgerSummaryState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const ledgerSummaryState = (
  state: LedgerSummaryState = initialState,
  action: { type: string; res: any }
): LedgerSummaryState => {
  switch (action.type) {
    case `${ActionTypes.GET_LEDGER_SUMMARY_DATA}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.GET_LEDGER_SUMMARY_DATA}_SUCCESS`: {
      const { success, data, message } = <LedgerSummaryAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
        ...(success && data && { data }),
      }
    }
    case `${ActionTypes.GET_LEDGER_SUMMARY_DATA}_FAILURE`:
      const res = <LedgerSummaryAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.GET_LEDGER_SUMMARY_DATA}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
