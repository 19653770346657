export const fetchMiddleware = (store) => {
  return (next) => (action) => {
    const { promise, type, data, ...rest } = action

    if (!promise) return next(action)

    const SUCCESS = `${type}_SUCCESS`
    const REQUEST = `${type}_REQUEST`
    const FAILURE = `${type}_FAILURE`

    next({ ...rest, type: REQUEST })

    return promise
      .then((res) => {
        return res.json ? res.json() : res
      })
      .then((res) => {
        if (res.data.success) {
          next({
            ...rest,
            res: res.data,
            data,
            type: SUCCESS,
          })
          return {
            status: true,
            res,
            error: {},
          }
        } else {
          next({
            ...rest,
            res: res.data,
            data,
            type: FAILURE,
          })
          return {
            status: false,
            res,
            error: {},
          }
        }
      })
      .catch((e) => {
        console.error('error =>>>>>>>>>>>>>>>>', e)
        const error = e?.code
        next({
          ...rest,
          error,
          res: { message: 'Something went wrong', error: error },
          data,
          type: FAILURE,
        })

        return {
          status: false,
          res: {},
          e,
        }
      })
  }
}
