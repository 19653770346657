import { FC, useEffect, useState } from 'react'
import { Autocomplete, Divider, Grid, TextField } from '@mui/material'
import { Item, ItemCategory } from '../../../services'
import { Box } from '@mui/system'
import CustomNumberField from '../Users/profile/CustomNumberField'

export interface SelectedStockItemProps {
  item: Item
}

const SelectedStockItem: FC<SelectedStockItemProps> = ({ item }) => {
  const rentTypes = [
    { id: 0, label: 'Per Pc' },
    { id: 1, label: 'Running Meter' },
  ]
  const [rentType, setRentType] = useState<null | any>(rentTypes[0])
  const [updatedItem, setUpdatedItem] = useState(item)
  const onInputChange = (e) => {
    const { name, valueAsNumber } = e.target
    if (name === 'rent') {
      item.rate = valueAsNumber
    } else {
      item.availableQuantity = valueAsNumber
    }
    setUpdatedItem({ ...item })
  }

  return (
    <>
      <Grid container sx={{ height: 70, alignItems: 'center', pl: 2, pr: 2 }}>
        <Grid item xs={3}>
          {item.itemName}
        </Grid>
        <Grid item xs={2.5}>
          <CustomNumberField
            maxLength={8}
            value={updatedItem.availableQuantity}
            name="quantity"
            onChange={onInputChange}
            label="Stock Quantity"
            size="small"
            error={
              updatedItem.availableQuantity === null ||
              item.availableQuantity === null
            }
            required
          />
        </Grid>
        <Grid item xs={2.5}>
          <CustomNumberField
            maxLength={8}
            name="rent"
            onChange={onInputChange}
            value={updatedItem.rate >= 0 ? updatedItem.rate : ''}
            label="Per day rent"
            size="small"
            error={updatedItem.rate === null || item.rate === null}
            sx={{ ml: 2 }}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
                  size='small'           
            sx={{ ml: 2, maxWidth: 240 }}
            options={rentTypes}
            value={rentType}
            disableClearable
            disabled={!item.length}
            onChange={(e, newValue) => {
              item.runningLength = newValue === 'Per Pc' ? 0 : item.length
              setRentType(newValue)
            }}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField
                    size='small' {...params} label="Rent Type" required />
            )}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}

export default SelectedStockItem
