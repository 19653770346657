import { Autocomplete, Grid, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import SelectStateItem, {
  StateOption,
  getState,
} from '../content/applications/Customers/SelectStateItem'
import { useAppDispatch, useAppSelector } from '../../src/hooks'
import { fetchGSTINAction, fetchGSTINResetAction } from '../../src/store/gstr'
import { GSTINDetails } from 'src/services/gstr'
import { da } from 'date-fns/locale'

export interface GSTINDetailsItemProps {
  children?: React.ReactNode
  initialGstin?: string
  initialgstinError?: boolean
  onUpdate: (gstin?: string, gstinObj?: GSTINDetails) => void
  minWidth?: number
}

const GSTINDetailsItem: FC<GSTINDetailsItemProps> = ({
  initialGstin,
  initialgstinError,
  onUpdate,
  minWidth,
}) => {
  const [gstin, setGstin] = useState<string>()
  const [gstinError, setGstinError] = useState<boolean>()
  const [gstinChanged, setGstinChanged] = useState<boolean>(false)
  const dispatchAction = useAppDispatch()
  const { success, data } = useAppSelector((state) => state.fetchGSTINDetails)

  const onChangeHandler = (e) => {
    const {
      target: { name, value },
    } = e
    setGstin(value)
    setGstinChanged(true)
    setGstinError(false)
  }
  useEffect(() => {
    setGstin(initialGstin)
  }, [initialGstin])
  useEffect(() => {
    setGstinError(initialgstinError)
  }, [initialgstinError])

  useEffect(() => {
    if (gstin && gstin.length === 15 && gstinChanged) {
      dispatchAction(fetchGSTINAction(gstin))
      onUpdate(gstin, null)
    }
  }, [gstin, gstinChanged])

  useEffect(() => {
    if (gstin) {
      onUpdate(gstin, data)
    }
    dispatchAction(fetchGSTINResetAction())
  }, [success])

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          size="small"
          required={false}
          name="gstin"
          value={gstin ?? ''}
          label="GSTIN"
          error={gstinError}
          onChange={onChangeHandler}
          fullWidth
          sx={{ minWidth: minWidth ? minWidth : 150 }}
        ></TextField>
      </Grid>
    </Grid>
  )
}

export default GSTINDetailsItem
