import { DeleteCustomerAPIResponse } from '../../../services/customers'
import { BaseReducerState, Customer } from '../../../services'
import { ActionTypes } from '../actions'

export type DeleteCustomerState = BaseReducerState & {
  data: null
}

export const initialState: DeleteCustomerState = {
  loading: false,
  success: false,
  message: '',
  data: null,
  error: null,
}

export const deleteCustomer = (
  state: DeleteCustomerState = initialState,
  action: { type: string; res: any }
): DeleteCustomerState => {
  switch (action.type) {
    case `${ActionTypes.DELETE_CUSTOMER}_REQUEST`:
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
        data: null,
        error: null,
      }

    case `${ActionTypes.DELETE_CUSTOMER}_SUCCESS`: {
      const { success, data, message } = <DeleteCustomerAPIResponse>action.res
      return {
        ...state,
        loading: false,
        success,
        message,
      }
    }
    case `${ActionTypes.DELETE_CUSTOMER}_FAILURE`:
      const res = <DeleteCustomerAPIResponse>action.res
      const { message } = res || {}
      res.error ??= message
      const { error } = res
      return {
        ...state,
        loading: false,
        success: false,
        message,
        error,
      }

    case `${ActionTypes.DELETE_CUSTOMER}_RESET`:
      return {
        ...initialState,
      }
  }
  return state
}
